import React from 'react';

const SubComponentListCard = ({
    id = '',
    title,
    description,
    icon,
    legend
}) =>  <div className="card card-stats card-SubComponentList" id={`${id}-container`}>
    <div className="content">
        <div className="row">
            <div className="col-xs-12">
                <p className="title" id={`${id}-title`}>{title}</p>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-9 body" id={`${id}-description`}>
                {description}
            </div>
            <div className="col-xs-3 body">
                <span id={`${id}-icon`}>{icon}</span>
            </div>
        </div>
    </div>
    <div className="footer">
        <hr />
        <div className="stats">
            {legend}
        </div>
    </div>
</div>;

export default SubComponentListCard;