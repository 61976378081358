import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Field, reduxForm, reset } from 'redux-form';
import { LinkContainer } from 'react-router-bootstrap';

import {
    NavDropdown,
    MenuItem,
    Modal,
    Row,
    Col,
    NavItem,
    FormGroup,
    ControlLabel
} from 'react-bootstrap';

import * as accountActions from '../../modules/account';
import {
    withHelperText,
    renderInput,
    formFieldHelper,
    renderTextarea
} from '../utils/renderUtils';

import { showToast } from '../utils/popUpUtils';
import { getPathObject } from '../../routes/RouteUtils';

import ApplicationNavigator from './ApplicationNavigator';
import ADPIcon from './Icon';

let doublePress = 0;

const updateUserPrefObject = ( globalConfig, updatedBookmarks ) => {
    const preferences = Object.assign({}, globalConfig.userPreferences );

    return {
        ...preferences,
        bookmarks:updatedBookmarks,
        sortBy: preferences.sortBy || 'N/A',
        sortOrder: preferences.sortOrder || 'N/A',
        resultsPerPage: preferences.resultsPerPage || 'N/A'
    };
};

class BookmarkManager extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            display: false,
            showQuickNav: false,
            doublePress: 0,
            quickNavText: ''
        };
    }

    componentDidMount() {
        document.onkeyup = ( evt ) => {
            evt = evt || window.event;
            if ( this.state.showQuickNav && evt.keyCode === 9 ){
                evt.preventDefault();
            }
            if ( doublePress !== 0 && evt.keyCode === 17 ){
                this.displayQuickNav();
            } else if ( evt.keyCode === 17 ) {
                doublePress = setTimeout(() => { doublePress = 0; }, 300 );
            }
        };
    }

    componentDidUpdate( prevProps ) {
        const { preferencesSuccess, preferencesError } = this.props;
        if ( preferencesSuccess ) {
            showToast( 'success', 'Preferences updated !!' );
            this.props.resetAccountProps({ preferencesSuccess: undefined });
            this.props.resetForm( 'BookmarkManagerForm' );
        }

        if ( preferencesError ) {
            showToast( 'error', 'Request failed !!' );
            this.props.resetAccountProps({ preferencesError: undefined });
        }

        if ( prevProps.location !== this.props.location ){
            if ( this.state.showQuickNav ){
                Swal.close();
                this.displayQuickNav();
            }
        }
    }

    bookmarkThisPage = ( values ) => {
        this.setState({ display: false });
        const currentPath = this.props.location.pathname;
        const preferences = Object.assign({}, this.props.globalConfig.userPreferences );
        const bookmarks = preferences.bookmarks || [];

        const newBookmark = {
            name: values.name || `Bookmarked page - ${bookmarks.length + 1}`,
            path: currentPath,
            description: values.description || '--'
        };

        showToast( 'loading', 'Saving bookmark' );
        this.props.setPreferences( updateUserPrefObject( this.props.globalConfig, [
            ...bookmarks,
            newBookmark
        ]));
    }

    showBookmarkOptions = () => {
        const { permanentPaths } = this.props.globalConfig;

        const unbookmarkableLinks = [ permanentPaths.home.path, permanentPaths.userAgreement.path, permanentPaths.setupMFA.path ];
        if ( unbookmarkableLinks.includes( this.props.location.pathname )) {
            showToast( 'warning', 'Cannot bookmark this page' );
        } else {
            this.setState({ display: true });
        }
    }

    showUnbookmarkOption = () => {
        const currentPath = this.props.location.pathname;
        const preferences = Object.assign({}, this.props.globalConfig.userPreferences );
        const bookmarks = preferences.bookmarks || [];

        showToast( 'loading', 'Updating bookmarks...' );
        this.props.setPreferences( updateUserPrefObject( this.props.globalConfig, bookmarks.filter( el => el.path !== currentPath )));
    }

    displayQuickNav = () => {
        this.setState(( oldState ) => ({
            showQuickNav: !oldState.showQuickNav,
            quickNavText: ''
        }));
    }

    render() {
        const { user, handleSubmit, globalConfig, history } = this.props;
        const { permanentPaths, userPreferences } = globalConfig;
        const { quickActions = [], bookmarks = [] } = ( user &&  userPreferences ) ? userPreferences : {};
        const activeBookmark = bookmarks.filter( el => el.path === this.props.location.pathname ).length > 0;
        return (( user && user.UserId ) ? <React.Fragment>
            { withHelperText( `${ activeBookmark ? 'Remove Bookmark' : 'Bookmark this page' }`, <NavItem eventKey="bookmark" id="sidebar-bookmarks-link" onClick={() => {
                activeBookmark ?  this.showUnbookmarkOption() : this.showBookmarkOptions();
            }} className="top-nav-regular-icon">
                <span className="hidden-xs hidden-sm"><ADPIcon icon="star" className={`${ activeBookmark ? 'text-success' : '' }`}/></span>
                <p className="hidden-md hidden-lg"><ADPIcon icon="star" className={ activeBookmark ? 'text-success' : '' } /> { activeBookmark ? 'Remove Bookmark' : 'Bookmark this page' }</p>
            </NavItem> )}
            { withHelperText( 'Navigator', <NavItem eventKey="quickNav" id="sidebar-navigator-link" onClick={() => this.displayQuickNav()} className="top-nav-regular-icon">
                <span className="hidden-xs hidden-sm"><ADPIcon icon="route" /></span>
                <p className="hidden-md hidden-lg"><ADPIcon icon="route" /> Navigator</p>
            </NavItem> ) }
            { withHelperText( 'Quick Actions', <NavDropdown eventKey={1}  title={<>
                <span className="hidden-xs hidden-sm">
                    <ADPIcon icon="gavel" />
                </span>
                <p className="hidden-md hidden-lg"><ADPIcon icon="gavel"  />Quick Actions <b className="caret" /></p>
            </>}
            className="top-nav-regular-icon"
            noCaret
            id="sidebar-quickactions-link"
            >
                <div className="role-header">
                    <p>Quick Actions</p>
                </div>
                <MenuItem divider />
                { ( quickActions && quickActions.length > 0 )
                    ? quickActions.map(( quickAction, qIndex ) => {
                        return <LinkContainer to={permanentPaths[quickAction].path} key={qIndex}>
                            <MenuItem eventKey={qIndex} id={`sidebar-quickactions-${permanentPaths[quickAction].name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-link`}>
                                <ADPIcon icon={permanentPaths[quickAction].icon} /> {permanentPaths[quickAction].name}
                            </MenuItem>
                        </LinkContainer>;
                    }) : <LinkContainer to={ permanentPaths['preferences'].path } id={`sidebar-quickactions-${permanentPaths['preferences'].name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-link`}>
                        <MenuItem eventKey="noQuickActions">
                            <ADPIcon icon={ permanentPaths['preferences'].icon } className="text-info"  /> { permanentPaths['preferences'].name }
                        </MenuItem>
                    </LinkContainer>
                }
            </NavDropdown> ) }

            <ApplicationNavigator
                userRole={this.props.userRole}
                commonResources={this.props.commonResources}
                globalConfig={globalConfig}
                history={history}
                toggleModal={( toggleValue ) => this.setState({
                    showQuickNav: toggleValue
                })}
                showQuickNav={this.state.showQuickNav}
            />


            <Modal
                className="bookmarkOptionsModal"
                show={this.state.display}
                onHide={() => this.setState({ display: false })}
                aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader'>Bookmark this page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row id="BookmarkManagerForm-container">
                        <form onSubmit={handleSubmit( this.bookmarkThisPage )} name="BookmarkManagerForm" id="BookmarkManagerForm">
                            <Col sm={12}>
                                <FormGroup controlId="name">
                                    { formFieldHelper( 'Bookmark name < 50 characters' )}
                                    <ControlLabel className="required-field" id="BookmarkManagerForm-name-label">Custom Bookmark Name</ControlLabel>
                                    <Field
                                        name="name"
                                        id="BookmarkManagerForm-name"
                                        className="form-control"
                                        component={renderInput}
                                        placeholder="Custom Bookmark Name"
                                    />
                                </FormGroup>
                            </Col>
                            <div className="clearfix"></div>
                            <Col sm={12}>
                                <FormGroup controlId="description">
                                    { formFieldHelper( 'Short Description for the bookmark between 10 - 140 characters' )}
                                    <ControlLabel className="required-field" id="BookmarkManagerForm-description-label">Short Description</ControlLabel>
                                    <Field rows="3" name="description" className="form-control" id="BookmarkManagerForm-description" placeholder="Short Description" component={renderTextarea} />
                                </FormGroup>
                            </Col>
                            <div className="clearfix"></div><br/>
                            <Col sm={12}>
                                <button type="submit" className="btn btn-fill btn-wd pull-right">Bookmark</button>
                            </Col>
                        </form>
                    </Row>
                </Modal.Body>
            </Modal>
        </React.Fragment>
            : <span>&nbsp;</span> );
    }
}

const validate = ( values ) => {
    const errors = {};
    if ( !values.name ){
        errors.name = 'Required';
    } else if ( values.name.length > 50 ){
        errors.name = 'Bookmark name too long';
    }

    if ( !values.description ){
        errors.description = 'Required';
    } else if ( values.description.length < 10 ){
        errors.description = 'Description too short';
    } else if ( values.description.length > 140 ){
        errors.description = 'Description too long';
    }
    return errors;
};

BookmarkManager = reduxForm({
    form: 'BookmarkManagerForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate
})( BookmarkManager );

const mapStateToProps = ( state, props ) => {
    const { pathname } = props.location;
    const permPathObject = getPathObject( state.globalConfig.permanentPaths, pathname );
    return {
        user: state.account.user,
        userRole: state.account.userRole,
        processing: state.account.processing,
        globalConfig: state.globalConfig,
        commonResources: state.common,
        preferencesError: state.account.preferencesError,
        preferencesSuccess: state.account.preferencesSuccess,
        initialValues: {
            name: permPathObject.name || `${pathname.split( '/' )[1] || ''} ${pathname.split( '/' ).pop() || 'page'}`,
            description: permPathObject.description || pathname.replace( /\//g, ' ' ).trim() }
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        resetForm: ( formName ) => dispatch( reset( formName )),
        setPreferences: ( preferences ) => dispatch( accountActions.setUserPreferences( preferences )),
        resetAccountProps: ( propsToReset ) => dispatch( accountActions.resetAccountProps( propsToReset ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( BookmarkManager ));
