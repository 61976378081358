import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';

const SET_ML_ERROR = 'SET_ML_ERROR';
const CHANGE_ML_FETCHING = 'CHANGE_ML_FETCHING';
const FETCH_MODALS = 'FETCH_MODALS';
const MODEL_SUCCESS = 'MODEL_SUCCESS';
const FETCH_FILE_MODALS = 'FETCH_FILE_MODALS';
const FILE_MODALS_STATUS = 'FILE_MODALS_STATUS';
const ML_RUN_MODEL = 'ML_RUN_MODEL';
const FETCH_UNREGISTERED_MODALS = 'FETCH_UNREGISTERED_MODALS';
const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
const ML_UPLOAD_FILE_STATUS = 'ML_UPLOAD_FILE_STATUS';
const ML_CHANGE_UPLOAD_PROGRESS = 'ML_CHANGE_UPLOAD_PROGRESS';
const RESET_ML_PROPS = 'RESET_ML_PROPS';
const FETCH_CERS = 'FETCH_CERS';
const CER_SUCCESS = 'CER_SUCCESS';
const DELETE_CER_SUCCESS = 'DELETE_CER_SUCCESS';
const REGISTER_CER_SUCCESS = 'REGISTER_CER_SUCCESS';
const REVERT_CER_SUCCESS = 'REVERT_CER_SUCCESS';
const RETRAIN_CER_SUCCESS = 'RETRAIN_CER_SUCCESS';
const RUN_CER = 'RUN_CER';
const ML_DOWNLOAD_LOG = 'ML_DOWNLOAD_LOG';
const CHANGE_ML_AUTH_USERS_FETCHING = 'CHANGE_ML_AUTH_USERS_FETCHING';
const FETCH_ML_AUTHORIZED_USERS_SUCCESS = 'FETCH_ML_AUTHORIZED_USERS_SUCCESS';
const SET_ML_AUTHORIZED_USERS_ERROR = 'SET_ML_AUTHORIZED_USERS_ERROR';
const DELETE_ML_USER_ACCESS_SUCCESS = 'DELETE_ML_USER_ACCESS_SUCCESS';
const UPDATE_ML_USER_ACCESS_SUCCESS = 'UPDATE_ML_USER_ACCESS_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_ML_ERROR:
    case CHANGE_ML_FETCHING:
    case FETCH_MODALS:
    case MODEL_SUCCESS:
    case FETCH_FILE_MODALS:
    case FILE_MODALS_STATUS:
    case ML_RUN_MODEL:
    case FETCH_UNREGISTERED_MODALS:
    case DELETE_MODEL_SUCCESS:
    case ML_UPLOAD_FILE_STATUS:
    case ML_CHANGE_UPLOAD_PROGRESS:
    case RESET_ML_PROPS:
    case FETCH_CERS:
    case CER_SUCCESS:
    case DELETE_CER_SUCCESS:
    case REGISTER_CER_SUCCESS:
    case REVERT_CER_SUCCESS:
    case RETRAIN_CER_SUCCESS:
    case RUN_CER:
    case ML_DOWNLOAD_LOG:
    case CHANGE_ML_AUTH_USERS_FETCHING :
    case FETCH_ML_AUTHORIZED_USERS_SUCCESS :
    case SET_ML_AUTHORIZED_USERS_ERROR :
    case DELETE_ML_USER_ACCESS_SUCCESS :
    case UPDATE_ML_USER_ACCESS_SUCCESS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const addmodel = ( model ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addModelAPI = urlBuilder([ 'models' ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isRegistering: true } });
        return client.post( addModelAPI, model )
            .then( response => {
                dispatch({
                    type: MODEL_SUCCESS,
                    data: {
                        modelsuccess: response.data,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        modelError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'addmodel', error_obj, dispatch );
            });
    };
};

export const getmodals = ( offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getModelsAPI =  ( offset && limit && sortOrder && sortBy ) ? urlBuilder([ 'models' ], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]) : urlBuilder([ 'models' ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { fetchingModels: true, fetchModelsError: undefined } });
        return client.get( getModelsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_MODALS,
                        data: {
                            modelsList: response.data,
                            fetchingModels: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        fetchModelsError: extractError( error_obj ),
                        fetchingModels: undefined
                    }
                });
                respond( 'getmodals', error_obj, dispatch );
            });
    };
};

export const getModelDetails = ( modelId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getModelDetailsAPI = urlBuilder([ 'models', modelId ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { fetchingModel: true, model: undefined, fetchModelError: undefined } });
        return client.get( getModelDetailsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    const temp = response.data;
                    temp.InputSchema = temp.InputSchema !== 'N/A' ? JSON.stringify( temp.InputSchema ) : 'N/A';
                    temp.OutputSchema = temp.OutputSchema !== 'N/A' ? JSON.stringify( temp.OutputSchema ) : 'N/A';
                    dispatch({
                        type: MODEL_SUCCESS,
                        data: {
                            model: temp,
                            fetchingModel: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        fetchModelError: extractError( error_obj ),
                        fetchingModel: undefined
                    }
                });
                respond( 'getModelDetails', error_obj, dispatch );
            });
    };
};

export const editmodel = ( model, modelId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const editModelAPI = urlBuilder([ 'models', modelId ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isRegistering: true, editModelError: undefined } });
        return client.put( editModelAPI, model )
            .then( _response => {
                dispatch({
                    type: MODEL_SUCCESS,
                    data: {
                        editModelSuccess: true,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        editModelError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'editmodel', error_obj, dispatch );
            });
    };
};

export const deleteModel = ( modelId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteModelAPI = urlBuilder([ 'models', modelId ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isRegistering: true } });
        return client.delete( deleteModelAPI )
            .then( _response => {
                dispatch({
                    type: DELETE_MODEL_SUCCESS,
                    data: { deleteModelSuccess: true, isRegistering: undefined }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        deleteModelError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'deleteModel', error_obj, dispatch );
            });
    };
};

export const getFileModels = ( datasetId, filename ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getfileModelsAPI = urlBuilder([ 'datasets', datasetId, 'files/models' ], [`filename=${filename}`]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { fetchingFileModels: true, fileModelsList: undefined, fetchFileModelsError: undefined } });
        return client.get( getfileModelsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_FILE_MODALS,
                        data: {
                            fileModelsList: response.data.models,
                            fetchingFileModels: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        fetchFileModelsError: extractError( error_obj ),
                        fetchingFileModels: undefined
                    }
                });
                respond( 'getfilemodals', error_obj, dispatch );
            });
    };
};

export const runAnalytics = ( flag, datasetId, model_ER_id, filename, requestbody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const runmodelAPI = ( flag === 'ml' )
            ? urlBuilder([ 'datasets', datasetId, 'files/models', model_ER_id, 'run' ])
            : urlBuilder([ 'datasets', datasetId, 'entitiesjobs', model_ER_id ], [`filename=${filename}`]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { invokeAnalyticsSuccess: undefined, invokeAnalyticsError: undefined} });
        return client.post( runmodelAPI, requestbody )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: ML_RUN_MODEL,
                        data:{
                            invokeAnalyticsSuccess : true
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: { invokeAnalyticsError: extractError( error_obj ) }
                });
                respond( 'runmodel', error_obj, dispatch );
            });
    };
};

export const uploadFile = ( uploadDataURL, payload, ) => {
    return ( dispatch, state ) => {
        dispatch({ type: CHANGE_ML_FETCHING, data: { isuploading: true } });
        const config = {
            headers: {
                'Content-Type': ''
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.floor(( progressEvent.loaded * 100 ) / progressEvent.total );
                dispatch({ type: ML_CHANGE_UPLOAD_PROGRESS, data: { uploadProgress: percentCompleted }});
            }
        };
        const client = getHttp( dispatch, state, false, false );
        return client.put( uploadDataURL, payload, config )
            .then( _response => {
                dispatch({
                    type: ML_UPLOAD_FILE_STATUS,
                    data: {
                        uploadSuccess: 'upload successful',
                        isuploading: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: ML_UPLOAD_FILE_STATUS,
                    data: {
                        uploadFailure: 'failed to upload file',
                        isuploading: undefined
                    }
                });
                respond( 'uploadFile', error_obj, dispatch );
            });
    };
};

export const getCers = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getCersAPI = urlBuilder([ 'cers' ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { fetchingCers: true, fetchCersError: undefined } });
        return client.get( getCersAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_CERS,
                        data: {
                            cersList: response.data.cers || [],
                            fetchingCers: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        fetchCersError: extractError( error_obj ),
                        fetchingCers: undefined
                    }
                });
                respond( 'getCers', error_obj, dispatch );
            });
    };
};

export const getCerDetails = ( cerId, silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getCerDetailsAPI = urlBuilder([ 'cers', cerId ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { fetchingCer: !silent, silentFetching: silent, fetchCerError: undefined } });
        return client.get( getCerDetailsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: CER_SUCCESS,
                        data: {
                            cerDetail: response.data,
                            fetchingCer: undefined, silentFetching: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        fetchCerError: extractError( error_obj ),
                        fetchingCer: undefined, silentFetching: undefined
                    }
                });
                respond( 'getCerDetails', error_obj, dispatch );
            });
    };
};

export const registerNewCer = ( cer ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const registerCerAPI = urlBuilder([ 'cers' ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isRegistering: true } });
        return client.post( registerCerAPI, cer )
            .then( response => {
                dispatch({
                    type: REGISTER_CER_SUCCESS,
                    data: {
                        cerRegistrationSuccess: true,
                        cerId: response.data.EntityRecognizerId,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        cerRegistrationError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'registerNewCer', error_obj, dispatch );
            });
    };
};

export const retrainCer = ( body, cerId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const reTrainCerAPI = urlBuilder([ 'cers', cerId ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isRegistering: true, cerRetrainError: undefined } });
        return client.put( reTrainCerAPI, body )
            .then( _response => {
                dispatch({
                    type: RETRAIN_CER_SUCCESS,
                    data: {
                        cerRetrainSuccess: true,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        cerRetrainError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'retrainCer', error_obj, dispatch );
            });
    };
};

export const revertCer = ( cerId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const revertCerAPI = urlBuilder([ 'cers', 'revert', cerId ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isRegistering: true } });
        return client.delete( revertCerAPI )
            .then( response => {
                dispatch({
                    type: REVERT_CER_SUCCESS,
                    data: {
                        revertCerSuccess: response.data,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        revertCerError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'revertCer', error_obj, dispatch );
            });
    };
};

export const deleteCer = ( cerId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteCerAPI = urlBuilder([ 'cers', cerId ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isRegistering: true } });
        return client.delete( deleteCerAPI )
            .then( response => {
                dispatch({
                    type: DELETE_CER_SUCCESS,
                    data: {
                        deleteCerSuccess: response.data,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        deleteCerError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'deleteCer', error_obj, dispatch );
            });
    };
};

export const getUnregisteredModels = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getModelsAPI = urlBuilder([ 'models', 'unregistered' ]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { isFetching: true } });
        return client.get( getModelsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_UNREGISTERED_MODALS,
                        data: {
                            unregisteredmodelsList: response.data.models,
                            isFetching: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        error: extractError( error_obj ),
                        isFetching: undefined
                    }
                });
                respond( 'getUnregisteredModels', error_obj, dispatch );
            });
    };
};

export const downloadModelLogs = ( datasetId, modelId, modelRunId, servicename = 'sagemaker', serviceloggroup = 'TransformJobs' ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const logdownloadAPI = urlBuilder([ 'datasets', datasetId, 'files/models', modelId, 'run', modelRunId, 'logs' ],
            [`servicename=${servicename}`, `serviceloggroup=${serviceloggroup}`]);
        dispatch({ type: CHANGE_ML_FETCHING, data: { downloadingLog: true } });
        return client.get( logdownloadAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({ type: ML_DOWNLOAD_LOG, data: { logDownloadSuccess: true, downloadingLog: undefined } });
                    window.open( response.data.url, '_blank' );
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_ERROR,
                    data: {
                        logDownloadError: extractError( error_obj ),
                        downloadingLog: undefined
                    }
                });
                respond( 'downloadModelLogs', error_obj, dispatch );
            });
    };
};

export const getAuthorizations = ( type = 'models', modelId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder([ type, modelId, 'authorizedusers' ]);
        dispatch({ type: CHANGE_ML_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_ML_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_ML_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( type = 'models', requestBody, modelId, userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder([ type, modelId, 'users', userId, 'grants' ]);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                dispatch({
                    type: UPDATE_ML_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( type = 'models', userId, modelId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder([ type, modelId, 'users', userId, 'grants' ]);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                dispatch({
                    type: DELETE_ML_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ML_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetMLProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_ML_PROPS, data: propsToReset });
    };
};