import Swal from 'sweetalert2';
import { USER_LOGOUT } from './common_actions';
import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { alertAndHide } from '../components/utils/popUpUtils';

export const SET_ETL_ERROR = 'SET_ETL_ERROR';
export const CHANGE_LIBRARIES_PROCESSING = 'CHANGE_LIBRARIES_PROCESSING';
export const SET_LIBRARIES_DATA = 'SET_LIBRARIES_DATA';


const CHANGE_ETL_PROCESSING = 'CHANGE_ETL_PROCESSING';
const REGISTER_JOB = 'REGISTER_JOB';
const RESET_CODE = 'RESET_CODE';
const JOBS_LIST = 'JOBS_LIST';
const JOB_DETAILS = 'JOB_DETAILS';
const JOB_EXECUTIONS_LIST = 'JOB_EXECUTIONS_LIST';
const DELETE_JOB = 'DELETE_JOB';
const ETL_DOWNLOAD_LOG = 'ETL_DOWNLOAD_LOG';
const ADD_EXECUTION = 'ADD_EXECUTION';
const GET_JOB_SCRIPT = 'GET_JOB_SCRIPT';
const GET_JOB_SCRIPT_FILE = 'GET_JOB_SCRIPT_FILE';
const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';
const ETL_UPLOAD_FILE_STATUS = 'ETL_UPLOAD_FILE_STATUS';
const PUBLISH_JOB = 'PUBLISH_JOB';
const SAVE_SCRIPT_FILE = 'SAVE_SCRIPT_FILE';
const RESET_ETL_PROPS = 'RESET_ETL_PROPS';
const STOP_EXECUTION = 'STOP_EXECUTION';
const ETL_CHANGE_UPLOAD_PROGRESS = 'ETL_CHANGE_UPLOAD_PROGRESS';
const CHANGE_ETL_AUTH_USERS_FETCHING = 'CHANGE_ETL_AUTH_USERS_FETCHING';
const FETCH_ETL_AUTHORIZED_USERS_SUCCESS = 'FETCH_ETL_AUTHORIZED_USERS_SUCCESS';
const SET_ETL_AUTHORIZED_USERS_ERROR = 'SET_ETL_AUTHORIZED_USERS_ERROR';
const DELETE_ETL_USER_ACCESS_SUCCESS = 'DELETE_ETL_USER_ACCESS_SUCCESS';
const UPDATE_ETL_USER_ACCESS_SUCCESS = 'UPDATE_ETL_USER_ACCESS_SUCCESS';
const EDIT_EXTLIB_SUCCESS = 'EDIT_EXTLIB_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_ETL_ERROR:
    case CHANGE_ETL_PROCESSING:
    case RESET_ETL_PROPS:
    case REGISTER_JOB:
    case RESET_CODE:
    case JOBS_LIST:
    case JOB_DETAILS:
    case JOB_EXECUTIONS_LIST:
    case DELETE_JOB:
    case ETL_DOWNLOAD_LOG:
    case ADD_EXECUTION:
    case GET_JOB_SCRIPT:
    case GET_JOB_SCRIPT_FILE:
    case EDIT_JOB_SUCCESS:
    case ETL_UPLOAD_FILE_STATUS:
    case PUBLISH_JOB:
    case SAVE_SCRIPT_FILE:
    case STOP_EXECUTION:
    case ETL_CHANGE_UPLOAD_PROGRESS:
    case EDIT_EXTLIB_SUCCESS:
    case CHANGE_ETL_AUTH_USERS_FETCHING :
    case FETCH_ETL_AUTHORIZED_USERS_SUCCESS :
    case SET_ETL_AUTHORIZED_USERS_ERROR :
    case DELETE_ETL_USER_ACCESS_SUCCESS :
    case UPDATE_ETL_USER_ACCESS_SUCCESS:
    case CHANGE_LIBRARIES_PROCESSING:
    case SET_LIBRARIES_DATA:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};


export const addjob = ( job ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addJobAPI = urlBuilder([ 'jobs' ]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { isRegistering: true, registrationError: undefined } });
        return client.post( addJobAPI, job )
            .then( response => {
                dispatch({
                    type: REGISTER_JOB,
                    data: {
                        newJobId: response.data.JobId,
                        isRegistering: undefined,
                        code: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_JOB,
                    data: {
                        registrationError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'addjob', error_obj, dispatch );
            });
    };
};

export const getJobs = ( offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const jobsAPI = ( offset && limit && sortOrder && sortBy ) ? urlBuilder([ 'jobs' ], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]) : urlBuilder([ 'jobs' ]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { fetchingJobs: true, fetchJobsError: undefined } });
        return client.get( jobsAPI )
            .then( response => {
                dispatch({
                    type: JOBS_LIST,
                    data: {
                        jobsList: response.data,
                        fetchingJobs: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        fetchJobsError: extractError( error_obj ),
                        fetchingJobs: undefined
                    }
                });
                respond( 'getJobs', error_obj, dispatch );
            });
    };
};

export const getJobDetails = ( jobId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getJobDetailsAPI = urlBuilder([ 'jobs', jobId ]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { fetchingJobDetails: true, fetchingJobError: undefined, extUpdated: undefined } });
        return client.get( getJobDetailsAPI )
            .then( response => {
                const results = response.data;
                const members = [];
                if ( results.DefaultArguments ){
                    Object.keys( results.DefaultArguments ).map( function( key ) {
                        return members.push({ key, 'value' : results.DefaultArguments[key]});
                    });
                }
                results.members = members;
                dispatch({
                    type: JOB_DETAILS,
                    data: {
                        job: results,
                        fetchingJobDetails: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        job: undefined, fetchingJobError: extractError( error_obj ),
                        fetchingJobDetails: undefined
                    }
                });
                respond( 'getJobDetails', error_obj, dispatch );
            });
    };
};

export const getExecutionStatus = ( jobId, offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getExecutionStatusAPI = urlBuilder([ 'jobs', jobId, 'executions' ], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { fetchingExecutions: true, fetchingExecutionsError: undefined } });
        return client.get( getExecutionStatusAPI )
            .then( response => {
                dispatch({
                    type: JOB_EXECUTIONS_LIST,
                    data: {
                        executions: response.data,
                        fetchingExecutions: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        fetchingExecutionsError: extractError( error_obj ),
                        fetchingExecutions: undefined
                    }
                });
                respond( 'getExecutionStatus', error_obj, dispatch );
            });
    };
};

export const deletejob = ( jobId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deletejobAPI = urlBuilder([ 'jobs', jobId ]);
        dispatch({ type: SET_ETL_ERROR, data: { jobDeleteError: undefined } });
        return client.delete( deletejobAPI )
            .then( _response => {
                dispatch({
                    type: DELETE_JOB,
                    data: {
                        jobDeleted: true
                    }
                });
            })
            .catch( error_obj => {
                if ( error_obj.response.data.DependentResources ) {
                    Swal.close();
                    dispatch({
                        type: DELETE_JOB,
                        data: {
                            entitiesDependencies: error_obj.response.data.DependentResources,
                            cancelFailed: extractError( error_obj )
                        }
                    });
                } else {
                    dispatch({
                        type: SET_ETL_ERROR,
                        data: { jobDeleteError: extractError( error_obj )  }
                    });
                }
                respond( 'deletejob', error_obj, dispatch );
            });
    };
};

export const logdownload = ( jobId, executionId, type ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const logdownloadAPI = urlBuilder([ 'jobs', jobId, 'executions', executionId, 'logs' ], ['servicename=glue', `logtype=${type}`]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { downloadingLog: true } });
        return client.get( logdownloadAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({ type: ETL_DOWNLOAD_LOG, data: { logDownloadSuccess: true, downloadingLog: undefined } });
                    window.open( response.data.url, '_blank' );
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        logDownloadError: extractError( error_obj ),
                        downloadingLog: undefined
                    }
                });
                respond( 'logdownload', error_obj, dispatch );
            });
    };
};

export const addexecution = ( execution, jobid ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addexecutionAPI = urlBuilder([ 'jobs', jobid, 'executions' ]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { isRegistering: true, executeError: undefined } });
        return client.post( addexecutionAPI, execution )
            .then( _response => {
                dispatch({
                    type: ADD_EXECUTION,
                    data: {
                        execute: true,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        executeError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'addexecution', error_obj, dispatch );
            });
    };
};

export const stopexecution = ( executionid, jobid ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const stopexecutionAPI = urlBuilder([ 'jobs', jobid, 'executions', executionid ]);
        return client.put( stopexecutionAPI, '' )
            .then( _response => {
                dispatch({
                    type: STOP_EXECUTION,
                    data: {
                        stopexecute: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: { stopexecuteError: extractError( error_obj ) }
                });
            });
    };
};

export const editjob = ( job, jobid ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const editJobAPI = urlBuilder([ 'jobs', jobid ]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { isRegistering: true, jobUpdateError: undefined } });
        return client.put( editJobAPI, job )
            .then( _response => {
                dispatch({
                    type: EDIT_JOB_SUCCESS,
                    data: {
                        jobUpdated: true,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        jobUpdateError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'editjob', error_obj, dispatch );
            });
    };
};

export const getJobScript = ( jobId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const fileLoadClient = getHttp( dispatch, state, false, false );
        const getJobScriptAPI = urlBuilder([ 'jobs', jobId, 'script' ]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { fetchingScript: true, scriptError: undefined, script: undefined } });
        return client.get( getJobScriptAPI )
            .then( response => {
                fileLoadClient.get( extractMessage( response.data ), {
                    headers: {
                        'Content-Type': ''
                    }
                }).then( codeFile => {
                    dispatch({
                        type: GET_JOB_SCRIPT,
                        data: {
                            script: codeFile.data,
                            fetchingScript: undefined
                        }
                    });
                }).catch( _fileReadError => {
                    const errMessage = _fileReadError.message || 'Network Error, cannot fetch script file';
                    dispatch({
                        type: GET_JOB_SCRIPT_FILE,
                        data: {
                            script: `## ${errMessage}`,
                            scriptError: errMessage,
                            fetchingScript: undefined
                        }
                    });
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        script: '',
                        scriptError: extractError( error_obj ),
                        fetchingScript: undefined
                    }
                });
                respond( 'getJobScript', error_obj, dispatch );
            });
    };
};

export const saveScript = ( silent = false, publish = false, jobId, file ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const fileLoadClient = getHttp( dispatch, state, false, false );
        const saveScriptAPI = urlBuilder([ 'jobs', jobId, 'script' ]);
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { saveScriptProcessing: !silent, scriptSaved: undefined, scriptSaveError: undefined } });
        return client.post( saveScriptAPI, ' ' )
            .then( response => {
                fileLoadClient.put( extractMessage( response.data ), file, {
                    headers: {
                        'Content-Type': ''
                    }
                }).then( _codeFile => {
                    if ( publish ){
                        dispatch( publishJob( jobId ));
                    }
                    dispatch({
                        type: SAVE_SCRIPT_FILE,
                        data: {
                            scriptSaved: ( !silent && !publish ),
                            saveScriptProcessing: undefined,
                            autoSaved: silent ? {
                                type: 'success'
                            } : undefined
                        }
                    });
                }).catch( _fileReadError => {
                    dispatch({
                        type: SAVE_SCRIPT_FILE,
                        data: {
                            scriptSaveError: silent ? undefined : _fileReadError,
                            saveScriptProcessing: undefined,
                            autoSaved: silent ? {
                                type: 'error'
                            } : undefined
                        }
                    });
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: {
                        autoSaved: silent ? {
                            type: 'error'
                        } : undefined,
                        scriptSaveError: silent ? undefined : extractError( error_obj ),
                        saveScriptProcessing: undefined
                    }
                });
                respond( 'saveScript', error_obj, dispatch );
            });
    };
};

export const publishJob = ( jobId ) => {
    return ( dispatch, state ) => {
        const config = {
            params: {
                publish: true
            }
        };

        const client = getHttp( dispatch, state );
        const publishJobAPI = urlBuilder([ 'jobs', jobId ]);
        return client.put( publishJobAPI, '', config )
            .then( _response => {
                dispatch({
                    type: PUBLISH_JOB,
                    data: {
                        jobPublished: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: { jobPublishError: extractError( error_obj )  }
                });
                respond( 'publishJob', error_obj, dispatch );
            });
    };
};

export const uploadFile = ( uploadDataURL, payload, _UploadPath, _jobid ) => {
    return ( dispatch, state ) => {
        dispatch({ type: CHANGE_ETL_PROCESSING, data: { isuploading: true, uploadSuccess: undefined, uploadFailure: undefined } });
        const config = {
            headers: {
                'Content-Type': ''
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.floor(( progressEvent.loaded * 100 ) / progressEvent.total );
                dispatch({ type: ETL_CHANGE_UPLOAD_PROGRESS, data: { uploadProgress: percentCompleted }});
            }
        };
        const client = getHttp( dispatch, state, false, false );
        return client.put( uploadDataURL, payload, config )
            .then( _response => {
                dispatch({
                    type: ETL_UPLOAD_FILE_STATUS,
                    data: {
                        uploadSuccess: 'upload successful',
                        isuploading: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: ETL_UPLOAD_FILE_STATUS,
                    data: {
                        uploadFailure: 'failed to upload file',
                        isuploading: undefined
                    }
                });
                respond( 'uploadFile', error_obj, dispatch );
            });
    };
};

export const updateExtLibs = ( UploadPath, jobid ) => {
    return ( dispatch, state ) => {
        const requestBody = {
            PythonLibPaths: UploadPath
        };
        const client = getHttp( dispatch, state );
        const updateLibsAPI = urlBuilder([ 'jobs', jobid, 'libs' ]);
        return client.put( updateLibsAPI, requestBody )
            .then( _response => {
                dispatch({
                    type: EDIT_EXTLIB_SUCCESS,
                    data: {
                        extUpdated: true
                    }
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'updateExtLibs', error_obj, dispatch );
            });
    };
};

export const getExternalLibraryPresigned = ( requestBody, jobid ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const extLibPresignedAPI = urlBuilder([ 'jobs', jobid, 'libs' ]);
        return client.post( extLibPresignedAPI, requestBody )
            .then( response => {
                dispatch({
                    type: EDIT_EXTLIB_SUCCESS,
                    data: {
                        extLibPresigned: response.data
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ETL_ERROR,
                    data: { extLibPresignedError: extractError( error_obj ) }
                });
                respond( 'getExternalLibraryPresigned', error_obj, dispatch );
            });
    };
};

export const getAuthorizations = ( jobId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder([ 'jobs', jobId, 'authorizedusers' ]);
        dispatch({ type: CHANGE_ETL_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_ETL_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_ETL_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( requestBody, jobId, userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder([ 'jobs', jobId, 'users', userId, 'grants' ]);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                alertAndHide( 'success', 'Access updated', response?.data?.Message, () => {
                    dispatch( getAuthorizations( jobId ));
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'request failed', extractError( error_obj ));
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( userId, jobId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder([ 'jobs', jobId, 'users', userId, 'grants' ]);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                alertAndHide( 'success', 'Access updated', response?.data?.Message, () => {
                    dispatch( getAuthorizations( jobId ));
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'request failed', extractError( error_obj ));
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetETLProps = ( propsToReset = {}) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_ETL_PROPS, data: propsToReset });
    };
};

