import $ from 'jquery';
import AWS from 'aws-sdk/global';
import Swal from 'sweetalert2';
import { CognitoAuth } from 'amazon-cognito-auth-js';

import config from '../config.json';
import { loadAccount } from './account';
import { RESET_RESOURCES } from './common';
import { USER_LOGOUT } from './common_actions';
import { SET_SESSION } from './globalConfig/reducer';
import { alertAndHide, showLoading } from '../components/utils/popUpUtils';
import { respond, getHttp, urlBuilder, extractError } from './utils';
import { findDuration, toMoment  } from '../components/utils/dateUtils';
// const idpLogin = ( config.IDENTITY_PROVIDER && config.IDENTITY_PROVIDER.length > 0 );

const AmazonCognitoIdentity = require( 'amazon-cognito-identity-js' );

const LOGIN_PROGRESS = 'LOGIN_PROGRESS';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const SET_MFA = 'SET_MFA';
const UPDATE_MFA_SETTINGS = 'UPDATE_MFA_SETTINGS';
const KEEP_ALIVE = 'KEEP_ALIVE';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const VERIFICATION = 'VERIFICATION';
const RESET_PASSWORD = 'RESET_PASSWORD';
const FORCE_RESET_PASSWORD = 'FORCE_RESET_PASSWORD';
const USER_CRED = 'USER_CRED';
const REGISTRATION = 'REGISTRATION';
const HEARTBEAT = 'HEARTBEAT';
const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
const RESET_AUTH_PROPS = 'RESET_AUTH_PROPS';

const AWS_REGION = config.region;
const COGNITO_USER_POOL_ID = config.userPool;
const COGNITO_CLIENT_ID = config.clientId;
const COGNITO_IDENTITY_POOL_ID = config.identityPool;
const APP_WEB_DOMAIN = config.APP_WEB_DOMAIN || window.location.origin;
const TOKEN_SCOPES_ARRAY = config.TOKEN_SCOPES_ARRAY || [];
const IDENTITY_PROVIDER = config.IDENTITY_PROVIDER || '';
const DOMAIN = `cognito-idp.${  AWS_REGION  }.amazonaws.com/${  COGNITO_USER_POOL_ID  }`;
const USER_POOL = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID
});

AWS.config.region = AWS_REGION;

const JWT = require( 'jsonwebtoken' );

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case LOGIN_PROGRESS:
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
    case SET_MFA:
    case UPDATE_MFA_SETTINGS:
    case KEEP_ALIVE:
    case CHANGE_PASSWORD:
    case VERIFICATION:
    case RESET_PASSWORD:
    case USER_CRED:
    case FORCE_RESET_PASSWORD:
    case REGISTRATION:
    case HEARTBEAT:
    case TOKEN_REFRESH_SUCCESS:
    case RESET_AUTH_PROPS:
        return {...state, ...action.data};
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

let currentUser = undefined;
const getCognitoUser = ( username ) => {
    return typeof currentUser !== 'undefined' ? currentUser : new AmazonCognitoIdentity.CognitoUser({
        Username : username,
        Pool : USER_POOL
    });
};

export const setSession = ( session ) => {
    return ( dispatch ) => {
        dispatch({ type: SET_SESSION, session });
    };
};

/************************************************* START IDP LOGIN STUFF *********************************************/

const idpAuthData = {
    ClientId: COGNITO_CLIENT_ID,
    AppWebDomain: APP_WEB_DOMAIN,
    TokenScopesArray: TOKEN_SCOPES_ARRAY,
    IdentityProvider: IDENTITY_PROVIDER,
    RedirectUriSignIn: `${window.location.origin  }/callback`,
    RedirectUriSignOut: `${window.location.origin  }/auth/login`
};

const auth = new CognitoAuth( idpAuthData );

export const idpLoginDispatch = ( dispatch ) => {
    auth.userhandler = {
        onSuccess ( _result ) {
            console.info( 'idp login dispatch success' );
        },
        onFailure ( err ) {
            dispatch({
                type: LOGIN_FAILURE,
                data: {
                    loginError: err.message || JSON.stringify( err ),
                    loginProgress: false
                }
            });
        }
    };
    auth.useCodeGrantFlow();
    auth.getSession();
};

export const authReceived = ( href ) => {
    return ( dispatch ) => {
        auth.userhandler = {
            onSuccess ( result ) {
                const decoded = JWT.decode( result.getIdToken().getJwtToken(), {complete: true});
                const email = decoded.payload['email'] ? decoded.payload['email'] : undefined;
                const username = decoded.payload['custom:username'];

                dispatch({ type: RESET_RESOURCES });
                dispatch({ type: SET_SESSION, session: true });
                dispatch({
                    type: LOGIN_SUCCESS,
                    data: {
                        loginProgress: false,
                        token: result.getIdToken().getJwtToken(),
                        sessionActive: true,
                        refreshToken: result.getRefreshToken().getToken(),
                        loginTime: toMoment( null, 'epoch' ),
                        lastActivity: toMoment( null, 'epoch' ),
                        globalId: username,
                        email,
                        userName: username
                    }
                });
            },
            onFailure ( err ) {
                dispatch({
                    type: LOGIN_FAILURE,
                    data: {
                        loginError: err.message || JSON.stringify( err ),
                        loginProgress: false
                    }
                });
            }
        };
        auth.parseCognitoWebResponse( href );
    };
};

/************************************************* END IDP LOGIN STUFF *********************************************/

const loginDispatch = ( dispatch, history, user, password ) => {
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username : user,
        Password : password
    });

    const cognitoUser = getCognitoUser( user );

    cognitoUser.authenticateUser( authenticationDetails, {
        onSuccess ( result ) {
            const decoded = JWT.decode( result.getIdToken().getJwtToken(), {complete: true});
            const email = decoded.payload['email'] ? decoded.payload['email'] : undefined;
            const username = decoded.payload['cognito:username']
                ? decoded.payload['cognito:username']
                : email
                    ? email.substring( 0, email.lastIndexOf( '@' ))
                    : undefined;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
                Logins : {}
            });
            AWS.config.credentials.params.Logins[DOMAIN]  = result.getIdToken().getJwtToken();
            AWS.config.credentials.refresh(( error ) => {
                if ( error ) {
                    console.error( error );
                } else {
                    console.info( 'Successfully logged in!' );
                }
            });
            dispatch({ type: RESET_RESOURCES });
            dispatch({ type: SET_SESSION, session: true });
            dispatch({
                type: LOGIN_SUCCESS,
                data: {
                    loginProgress: false,
                    token: result.getIdToken().getJwtToken(),
                    sessionActive: true,
                    refreshToken: result.getRefreshToken().getToken(),
                    loginTime: toMoment( null, 'epoch' ),
                    lastActivity: toMoment( null, 'epoch' ),
                    email,
                    userName: username
                }
            });
            currentUser = cognitoUser;
            history.push( '/' );
        },

        onFailure( err ) {
            dispatch({
                type: LOGIN_FAILURE,
                data: {
                    loginError: err.message || JSON.stringify( err ),
                    loginProgress: false
                }
            });
        },

        totpRequired( _secretCode ) {
            dispatch({ type: SET_MFA, data: { mfaEnabled: true }});
            getTotp()
                .then( challengeAnswer => {
                    cognitoUser.sendMFACode( challengeAnswer, this, 'SOFTWARE_TOKEN_MFA' );
                });
        },

        newPasswordRequired( userAttributes, _requiredAttributes ) {
            delete userAttributes.email_verified;
            delete userAttributes.email;
            // cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes);
            dispatch({ type: FORCE_RESET_PASSWORD,
                data : {
                    userName: user,
                    sessionId: cognitoUser.Session,
                    forcereset : true,
                    sessionActive: true,
                    loginProgress: false
                }
            });
        }
    });
};

export const Register = ( values ) => {
    return ( dispatch, _state ) => {
        const attributeList = [];
        const dataEmail = {
            Name : 'email',
            Value : values.Email
        };
        const dataName = {
            Name : 'name',
            Value : values.name
        };

        const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute( dataEmail );
        const attributeName = new AmazonCognitoIdentity.CognitoUserAttribute( dataName );

        attributeList.push( attributeEmail );
        attributeList.push( attributeName );

        USER_POOL.signUp( values.Username, values.ConfirmPassword, attributeList, null, function( err, _result ){
            if ( err ) {
                Swal.fire( 'Registration Error', err.message, 'error' );
                return;
            }
            dispatch({ type: REGISTRATION,
                data : {
                    isRegistered : true
                }
            });

        });
    };
};

export const changepassword = ( oldPassword, newPassword ) => {
    return ( dispatch, _state ) => {
        // var cognitoUser = getCognitoUser(state().auth.userName)
        const cognitoUser = USER_POOL.getCurrentUser();
        if ( cognitoUser === null ){
            Swal.fire( 'Aunthentication Error', 'Please try changing password by logging again!', 'error' );
        }
        if ( cognitoUser !== null ) {
            cognitoUser.getSession( function( err, _session ) {
                if ( err ) {
                    return;
                }
            });
        }
        cognitoUser !== null && cognitoUser.changePassword( oldPassword, newPassword, function( err, result ) {
            if ( err ) {
                Swal.fire( 'Error', err.message, 'error' );
                return;
            }
            if ( result === 'SUCCESS' ){
                logout();
                Swal.fire({
                    title: 'Password Successfully Changed!',
                    text:'Please login with the new password',
                    type: 'success'
                });
                dispatch({ type: SET_SESSION, session: false });
                dispatch({ type: USER_LOGOUT });
            }
        });
    };
};

export const updateUserStatus = ( username, status, silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateUserStatusAPI = urlBuilder(['users', username, 'preferences'], ['operation=set-mfa', `mfaStatus=${status}`]);
        if ( !silent ) {
            showLoading( 'Updating MFA status...' );
        }
        return client.put( updateUserStatusAPI, ' ' )
            .then( response => {
                if ( response.status === 200 && !silent ) {
                    alertAndHide( 'success', 'MFA updated Successfully !!', '' );
                } else {
                    Swal.hideLoading();
                    dispatch( loadAccount( username ));
                }
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'updateUserStatus', error_obj, dispatch );
            });

    };
};

const getTotp = async ( error ) => {
    const { value: totpCode } = await Swal.fire({
        title: 'Enter your MFA Code',
        text: error || '',
        input: 'text',
        inputValue: '',
        showCancelButton: true,
        confirmButtonText: 'Verify MFA',
        reverseButtons: true,
        focusConfirm: false,
        onOpen: () => {
            $( '.swal2-confirm' ).attr( 'id', '2FA-verify-btn' );
        },
        inputAttributes: {
            maxLength: 10,
            id: '2FA'
        },
        inputValidator: ( value ) => {
            if ( !value ) {
                return 'Please enter MFA Code';
            }
        }
    });
    return totpCode;
};

export const verifySoftwareToken = ( username, challengeAnswer ) => {
    return ( dispatch, _state ) => {
        const totpMfaSettings = {
            PreferredMfa: true,
            Enabled: true
        };
        const cognitoUser = USER_POOL.getCurrentUser();
        if ( cognitoUser === null ){
            Swal.fire( 'Aunthentication Error', 'Please try later', 'error' );
        }
        if ( cognitoUser !== null ) {
            cognitoUser.getSession( function( err, _session ) {
                if ( err ) {
                    return;
                }
            });
        }
        dispatch({
            type: UPDATE_MFA_SETTINGS,
            data: { mfaError: undefined, fetchingMfa: true }
        });
        cognitoUser.verifySoftwareToken( challengeAnswer, `${username}OtpDevice`, {
            onSuccess: () => {
                cognitoUser.setUserMfaPreference( null, totpMfaSettings, ( err, _result ) => {
                    if ( err ) {
                        dispatch({
                            type: UPDATE_MFA_SETTINGS,
                            data: {
                                mfaError: err.message || JSON.stringify( err ),
                                fetchingMfa: undefined
                            }
                        });
                    } else {
                        dispatch({ type: SET_MFA, data: { mfaEnabled: true }});
                        dispatch({
                            type: UPDATE_MFA_SETTINGS,
                            data: {
                                secretCode: undefined,
                                fetchingMfa: undefined
                            }
                        });
                    }
                });
            },
            onFailure: ( err ) => {
                dispatch({
                    type: UPDATE_MFA_SETTINGS,
                    data: {
                        mfaError: err?.Message || err?.message || err,
                        fetchingMfa: undefined
                    }
                });
            }
        });
    };
};

export const toggleMFA = ( username, enable = false ) => {
    return ( dispatch, _state ) => {
        const cognitoUser = USER_POOL.getCurrentUser();
        if ( cognitoUser === null ){
            Swal.fire( 'Aunthentication Error', 'Please try later', 'error' );
        }
        if ( cognitoUser !== null ) {
            cognitoUser.getSession( function( err, _session ) {
                if ( err ) {
                    return;
                }
            });
        }
        if ( enable ){
            cognitoUser.associateSoftwareToken({
                associateSecretCode: ( secretCode ) => {
                    dispatch({ type: UPDATE_MFA_SETTINGS, data: { secretCode, mfaError: undefined } });
                },
                onFailure: ( err ) => {
                    Swal.fire( 'Error', err, 'error' );
                }
            });
        } else {
            if ( config.ENFORCE_COGNITO_MFA && config.ENFORCE_COGNITO_MFA === 'OPTIONAL' ) {
                dispatch({ type: SET_MFA, data: { mfaEnabled: false }});
                cognitoUser.setUserMfaPreference( null, { Enable: false }, ( err, _result ) => {
                    if ( err ) {
                        Swal.fire( err.message || JSON.stringify( err ), '', 'error' );
                    } else {
                        dispatch( updateUserStatus( username, 'disabled' ));
                    }
                });
            } else {
                Swal.fire( 'Error', `MFA is set to ${config?.ENFORCE_COGNITO_MFA || 'OFF'}, User cannot disable their MFA`, 'warning' );
            }
        }
    };
};

export const forcepassword = ( newPassword, userAttributes, username, sessionId ) => {
    return ( dispatch, _state ) => {
        const cognitoUser = getCognitoUser( username );
        cognitoUser.Session = sessionId;
        cognitoUser.completeNewPasswordChallenge( newPassword, userAttributes, {
            onSuccess( _result ) {
                logout();
                dispatch({ type: FORCE_RESET_PASSWORD,
                    data : {
                        isupdated : true
                    }
                });
                dispatch({ type: SET_SESSION, session: false });
                dispatch({ type: USER_LOGOUT });
            },
            onFailure( err ) {
                Swal.fire( 'Error', err.message, 'error' );
            }
        });
    };
};

export function forgotPassword( username ) {
    return ( dispatch, _state ) => {
        const cognitoUser = getCognitoUser( username );
        cognitoUser.forgotPassword({
            onSuccess( _result ) {
                Swal.fire( 'Verification Code Sent' );
            },
            onFailure( err ) {
                Swal.fire( 'Error', err.message, 'error' );
            },
            inputVerificationCode() {
                dispatch({ type: VERIFICATION,
                    data : {
                        isverifysent : true
                    }
                });
            }
        });
    };
}

export function confirmPassword( username, verificationCode, newPassword ) {
    return ( dispatch, _state ) => {
        const cognitoUser = getCognitoUser( username );
        cognitoUser.confirmPassword( verificationCode, newPassword, {
            onSuccess( _result ) {
                dispatch({ type: RESET_PASSWORD,
                    data : {
                        isreset : true
                    }
                });
            },
            onFailure( err ) {
                Swal.fire( 'Error', err.message, 'error' );
            }
        });
    };
}

export const logout = ( history ) => {

    return ( dispatch, getState ) => {
        const { idpLogin } = getState().globalConfig;
        let loginPath = '';

        try {
            loginPath = getState().globalConfig.permanentPaths.login.path;
        } catch ( err ) {
            console.error( 'Error setting permanent paths' );
            loginPath = '/auth/login';
        }

        Swal.close();
        try {
            const cognitoUser = USER_POOL.getCurrentUser();
            if ( !idpLogin && cognitoUser !== null ){
                cognitoUser.signOut();
            } else if ( idpLogin ){
                auth.signOut();
            }
        } catch ( error ) {
            console.error( error );
            dispatch({ type: SET_SESSION, session: false });
            dispatch({ type: USER_LOGOUT });
        } finally {
            dispatch({ type: SET_SESSION, session: false });
            dispatch({ type: USER_LOGOUT });
            if ( typeof history !== 'undefined' ){
                history.push( loginPath );
            } else {
                window.location.replace( loginPath );
            }
        }

    };
};

export const login = ( history, user, password ) => {
    return ( dispatch, getState ) => {
        const { idpLogin } = getState().globalConfig;

        dispatch({ type: LOGIN_PROGRESS, data: {
            loginProgress: true
        } });
        if ( idpLogin ){
            idpLoginDispatch( dispatch );
        } else {
            loginDispatch( dispatch, history, user, password );
        }
    };
};

export const keepAlive = ( loginTime, refreshToken, userName, forceReset = false, retry_count = 0 ) => {
    return ( dispatch ) => {
        // refresh token for every 30 minutes
        if ( forceReset || ( findDuration( toMoment( null, 'epoch' ), toMoment( loginTime, 'epoch' )).toFixed( 2 ) > 29 )) {
            const cognitoUser = getCognitoUser( userName );
            const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: refreshToken });
            cognitoUser.refreshSession( RefreshToken, ( err, session ) => {
                if ( err ) {
                    if ( retry_count < 3 ){
                        Swal.fire({
                            title: 'Session Error !',
                            html: `Error continuing session.<br>${  err.message}` ? err.message : '',
                            type: 'warning',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            showCancelButton: true,
                            reverseButtons: true,
                            cancelButtonText: 'Sign out',
                            confirmButtonText: 'Reload session',
                            backdrop: 'rgba(102, 102, 102, 0.32)'
                        }).then(( result ) => {
                            if ( result.value ) {
                                dispatch( keepAlive( loginTime, refreshToken, userName, true, retry_count + 1 ));
                            } else if ( result.dismiss === Swal.DismissReason.cancel ){
                                dispatch( logout());
                            }
                        });
                    } else {
                        // user tried to refresh session more than 3 times, possibly refreshToken is Expired
                        // at this point, only course of action is to logout
                        Swal.fire({
                            title: 'Session expired',
                            html: `Error continuing session.<br>${  err.message}` ? err.message : '',
                            type: 'error',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Logout',
                            backdrop: 'rgba(102, 102, 102, 0.8)',
                            onClose: () => {
                                dispatch({ type: USER_LOGOUT });
                                dispatch( logout());
                            }
                        });
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.log( `Tokens refreshed @${  toMoment( null, 'obj', 'HH:mm:ss' )}` );
                    Swal.close();
                    dispatch({
                        type: TOKEN_REFRESH_SUCCESS,
                        data: {
                            token: session.idToken.jwtToken,
                            refreshToken: session.refreshToken.token,
                            loginTime: toMoment( null, 'epoch' ),
                            lastActivity: toMoment( null, 'epoch' )
                        }
                    });
                }
            });
        } else {
            dispatch({
                type: HEARTBEAT,
                data: {
                    lastActivity: toMoment( null, 'epoch' )
                }
            });
        }
    };
};

export const resetLogin = () => {
    return ( dispatch, _state ) => {
        dispatch({ type: SET_SESSION, session: false });
        dispatch({ type: USER_LOGOUT });
    };
};

export const resetAuthProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_AUTH_PROPS, data: propsToReset });
    };
};