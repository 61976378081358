import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Card from '../layout/Cards/Card';
import noCognitoSupport from '../../assets/img/noCognitoSupport.svg';

const NoCognitoSupport = ({ inComponent = false }) => (
    <Row>
        <Col sm={10} md={6} smOffset={1} mdOffset={3}>
            <br/><br/>
            <Card
                extraClasses={'text-center'}
                title={<span className="text-center text-info text-bold">
                    <img src={noCognitoSupport} alt="" />
                    <br/><br/>
                    IDP Support Only
                </span>}
                content={<Row>
                    <Col sm={12} className="text-muted">
                        <p>The page you are looking for cannot be accessed as
                        the support for authentication, group mapping, attribute assertions and password management are
                        provided and managed by ID Provider only.</p>
                        <br/>
                    </Col>
                </Row>}
                legend={!inComponent && <LinkContainer to="/">
                    <button className="btn btn-fill btn-wd">Return to Home</button>
                </LinkContainer>}
                ftTextCenter
            />
        </Col>
    </Row>
);
export default NoCognitoSupport;