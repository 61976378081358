import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ADPIcon from '../common/Icon';
import Card from '../layout/Cards/Card';

const ComponentError = ({ recover = [], error, title }) => (
    <Row id="componentError-container">
        <Col sm={10} md={6} smOffset={1} mdOffset={3}>
            <br/><br/>
            <Card
                extraClasses={'text-center'}
                content={<Row>
                    <Col sm={12} className="text-muted">
                        <h4 className="text-warning text-bold">
                            <ADPIcon icon="exclamation-triangle" size="2x" />
                            <p>{title || 'Oops, something went wrong !!'}</p>
                        </h4>
                        <p className="constrict">{ error }</p><br/>
                    </Col>
                </Row>}
                legend={ recover.map(( item, index ) => {
                    return <button key={index} onClick={() => item.cta()} className={`btn btn-fill btn-wd btn-errorList ${item.classes}`}>
                        <ADPIcon icon={`${item.icon || 'undo'}`} /> {item.text}
                    </button>;
                }) }
                ftTextCenter
            />
        </Col>
    </Row>
);
export default ComponentError;