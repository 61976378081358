import React from 'react';
import { connect } from 'react-redux';
import Forcereset from './auth/Forcereset';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Page from './layout/Cards/ComponentView';
import LoadingResources from './common/LoadingResources';

import * as accountActions from '../modules/account';

//This LandingPage component decides which component has to be loaded??
//Depending upon the User object from current state props (user)
class LandingPage extends React.Component {

    componentDidUpdate( prevProps ) {
        if ( prevProps.userName !== this.props.userName ){
            this.props.loadAccount( this.props.userName );
        }
        if ( this.props.user && this.props.user.UserId && !this.props.userRole && !this.props.switchRoleError && !this.props.fetchingUser ){
            this.props.switchRole( this.props.user.DefaultRole );
        }
    }

    gotoHome = () => {
        this.props.history.push( this.props.homePath );
    }

    render() {
        const { fetchingUser, user, userRole, userFetchError, switchRoleError, forcereset, userName, globalConfig, pendingRequests } = this.props;
        const { permanentPaths = {} } = globalConfig;
        const displayUsername = ( user && user.Name ) ? `${user.Name.charAt( 0 ).toUpperCase() + user.Name.slice( 1 )}` : (( userName && userName.length > 0 ) ? `${userName.charAt( 0 ).toUpperCase() + userName.slice( 1 )}` : 'User' );
        return ( <Page title={ 'Login Progress' }>
            <br />
            <Row>
                { forcereset ? <Forcereset /> : ( fetchingUser || pendingRequests.length > 0 ) ? <LoadingResources
                    user={ user }
                    userRole={ userRole }
                    fetchingUser={ fetchingUser }
                    pendingRequests={ pendingRequests }
                    displayUsername={ displayUsername }
                /> : <React.Fragment>
                    { ( userFetchError || switchRoleError ) ? <Col sm={12} className="jumbotron" id="landing-user-load-error-container">
                        <h2 className="text-danger">Error loading content...</h2>
                        <p>{userFetchError || switchRoleError}</p>
                        { userFetchError && <span>
                            <button onClick={() => this.props.loadAccount() } className="btn btn-xs btn-fill" id="landing-reloadAccount-btn">Click here</button> to retry
                        </span>
                        }
                        { switchRoleError && <span>
                            <button onClick={() => this.props.switchRole( 'defaultrole' ) } className="btn btn-xs btn-fill" id="landing-reloadRole-btn">Click here</button> to retry
                        </span>
                        }
                    </Col> : <React.Fragment>
                        { user && !user.UserId && <Redirect to={ permanentPaths.userAgreement.path } /> }
                        { user && user.UserId && userRole && <Redirect to={ permanentPaths.home.path } /> }
                    </React.Fragment>
                    }
                </React.Fragment>
                }
            </Row>
        </Page> );
    }
}

// Mapping the current state props to ths following
const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        userName: state.auth.userName,
        userRole: state.account.userRole,
        forcereset : state.auth.forcereset,
        globalConfig: state.globalConfig,
        fetchingUser: state.account.fetchingUser,
        userFetchError: state.account.userFetchError,
        switchRoleError: state.account.switchRoleError,
        pendingRequests: state.common.requests?.pending || []
    };
};

//Dispatcher Methods for Loading current Account and Reseting Errors if exits!
const mapDispatchToProps = ( dispatch ) => {
    return {
        loadAccount: ( username ) => dispatch( accountActions.loadAccount( username )),
        switchRole: ( roleId ) => dispatch( accountActions.switchRole( roleId, true ))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( LandingPage );
