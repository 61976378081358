import { respond, getHttp, urlBuilder, extractError } from './utils';
import { USER_LOGOUT } from './common_actions';

const SET_SAML_ERROR = 'SET_SAML_ERROR';
const CHANGE_SAML_FETCHING = 'CHANGE_SAML_FETCHING';
const RESET_SAML_PROPERTIES = 'RESET_SAML_PROPERTIES';
const FETCH_SAML_MAPPINGS = 'FETCH_SAML_MAPPINGS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_SAML_ERROR:
    case CHANGE_SAML_FETCHING:
    case FETCH_SAML_MAPPINGS:
    case RESET_SAML_PROPERTIES:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const getSamlMappings = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSMAPI = urlBuilder(['samlgrouprolemappings']);
        dispatch({ type: CHANGE_SAML_FETCHING, data: { fetchingSM: true } });
        return client.get( getSMAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_SAML_MAPPINGS,
                        data: {
                            samlmappings: response.data.mappings,
                            fetchingSM: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SAML_ERROR,
                    data: {
                        fetchSMError: extractError( error_obj ),
                        fetchingSM: undefined
                    }
                });
                respond( 'getSamlMappings', error_obj, dispatch );
            });
    };
};

export const updateSamlMappings = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateSMAPI = urlBuilder(['samlgrouprolemappings']);
        return client.post( updateSMAPI, requestBody )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_SAML_MAPPINGS,
                        data: {
                            updateSMSuccess: response.data
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SAML_ERROR,
                    data: {
                        updateSMError: extractError( error_obj )
                    }
                });
                respond( 'updateSamlMappings', error_obj, dispatch );
            });
    };
};

export const resetSamlProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_SAML_PROPERTIES, data: propsToReset });
    };
};
