import { connect } from 'react-redux';
import React, { Component, Suspense } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import * as authActions from '../modules/auth';
import { AccessRoute } from '../routes/RouteUtils';
// layout components
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Loading from '../components/utils/Loading';
import Sidebar from '../components/layout/Sidebar';
import PagesHeader from '../components/layout/PagesHeader';
import UserAgreement from '../components/user/UserAgreement';
// global usage common components
import Home from '../components/Home';
import SetupMFA from '../components/auth/SetupMFA';
import LandingPage from '../components/LandingPage';
import PageNotFound from '../components/errors/PageNotFound';
import NotAuthorized from '../components/errors/NotAuthorized';
import AppErrorBoundary from '../components/errors/AppErrorBoundary';
import NoCognitoSupport from '../components/errors/NoCognitoSupport.js';
// auth components
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import Callback from '../components/auth/Callback';
import Forgotpwd from '../components/auth/Forgotpwd';

import Test from '../components/ztest/Test';
import RestAPI from '../components/ztest/RestAPI';
import APIDocs from '../components/common/APIDocs';
import Etl from '../components/etl/ETL';
import ML from '../components/ml/ML';
import DatasetSearch from '../components/search/DatasetSearch';
import bgImage from '../assets/img/mask.png';

const SettingsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.stw" */ '../routes/nestedRoutes/SettingsWrapper' ));
const DatasetsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.dst" */ '../routes/nestedRoutes/DatasetsWrapper' ));
const ConnectionsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.cnw" */ '../routes/nestedRoutes/ConnectionsWrapper' ));
const DashboardsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.dshw" */ '../routes/nestedRoutes/DashboardsWrapper' ));
const ModelsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.mdw" */ '../routes/nestedRoutes/ModelsWrapper' ));
const CersWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.erw" */ '../routes/nestedRoutes/CersWrapper' ));
const JobsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.jbw" */ '../routes/nestedRoutes/JobsWrapper' ));
const EndpointsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.epw" */ '../routes/nestedRoutes/EndpointsWrapper' ));
const NotebooksWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.nbw" */ '../routes/nestedRoutes/NotebooksWrapper' ));
const SqlqueryWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.sqw" */ '../routes/nestedRoutes/SqlqueryWrapper' ));
const SchedulesWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.scw" */ '../routes/nestedRoutes/SchedulesWrapper' ));
const ManagementWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.amw" */ '../routes/nestedRoutes/ManagementWrapper' ));
const ForecastsWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.fcw" */ '../routes/nestedRoutes/ForecastWrapper' ));
const LibrariesWrapper = React.lazy(
    () => import( /* webpackChunkName: "custom.lw" */ '../routes/nestedRoutes/LibrariesWrapper' ));

let ps;

class App extends Component {

    componentDidMount() {
        if ( navigator.platform.indexOf( 'Win' ) > -1 ) {
            ps = new PerfectScrollbar( this.mainPanel );
        }
        if ( document.documentElement.className.indexOf( 'nav-open' ) !== -1 ) {
            document.documentElement.classList.toggle( 'nav-open' );
        }
    }

    componentWillUnmount() {
        if ( navigator.platform.indexOf( 'Win' ) > -1 ) {
            ps.destroy();
            ps = null;
        }
    }

    componentDidUpdate( e ) {
        if ( navigator.platform.indexOf( 'Win' ) > -1 ) {
            setTimeout(() => {
                ps.update();
            }, 350 );
        }
        if ( e.history.action === 'PUSH' ) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            if ( !this.props.location.pathname.startsWith( '/auth' ) && this.mainPanel ) {
                this.mainPanel.scrollTop = 0;
            }
        }
        if (
            window.innerWidth < 993 &&
            e.history.action === 'PUSH' &&
            document.documentElement.className.indexOf( 'nav-open' ) !== -1
        ) {
            // document.documentElement.classList.toggle("nav-open");
        }

        if ( this.props.sessionActive && this.props.sessionActive !== this.props.globalConfig.session ) {
            this.props.setSession( true );
        }
    }

    setRef = ( node ) => { this.mainPanel = node; }

    render() {
        const { globalConfig = {}, mfaEnabled } = this.props;
        const { idpLogin, permanentPaths, session = false, ENFORCE_COGNITO_MFA = 'OFF' } = globalConfig;
        return <React.Fragment>
            { !session ? <div id="mainDiv">
                <PagesHeader globalConfig={globalConfig} pathname={this.props.location.pathname} />
                <div className="wrapper wrapper-full-page">
                    <div
                        className="full-page login-page"
                        data-color="black"
                        data-image={bgImage}
                    >
                        <div className="content " ref={this.setRef}>
                            <Switch>
                                <Route exact path="/" render={props => ( this.props.sessionActive ?
                                    <LandingPage {...props} homePath={permanentPaths.home.path} />
                                    : this.props.forcereset ? <LandingPage {...props} /> : <Redirect to={permanentPaths.login.path} /> )} />
                                <Route path={permanentPaths.login.path} render={( props ) => <Login {...props} forgotPasswordPath={permanentPaths.forgotPassword.path} />} />
                                <Route path={permanentPaths.register.path} component={idpLogin ? NoCognitoSupport : Register} />
                                <Route path={permanentPaths.forgotPassword.path} component={idpLogin ? NoCognitoSupport : Forgotpwd} />
                                <Route path={permanentPaths.callback.path} component={Callback} />
                                <Route path={permanentPaths.apidocs.path} component={APIDocs} />
                                <Route path="*" component={() => <Redirect to={permanentPaths.login.path} />} />
                            </Switch>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div> : <div className="wrapper" id="mainDiv">
                <Sidebar />
                <div
                    className={navigator.platform.indexOf( 'Win' ) > -1 ? 'main-panel ps' : 'main-panel'}
                    ref={this.setRef}
                >
                    <Header />
                    <div className="main-content">
                        <AppErrorBoundary>
                            <Suspense fallback={<div><Loading /></div>}>
                                { ( !mfaEnabled && ENFORCE_COGNITO_MFA === 'MANDATORY' && !idpLogin )
                                    ? <Switch>
                                        <Route path={permanentPaths.setupMFA.path} render={() => <SetupMFA {...this.props} />} />
                                        <Route path="*" render={() => <Redirect to={permanentPaths.setupMFA.path}/> }/>
                                    </Switch>
                                    : <Switch>
                                        <Route exact path="/" render={( props ) =>
                                            ( this.props.sessionActive ? <LandingPage {...props} /> : this.props.forcereset ? <LandingPage {...props} /> : <Redirect to={permanentPaths.login.path} /> )} />
                                        <Route exact path={permanentPaths.home.path} component={Home} />
                                        <Route path={permanentPaths.callback.path} component={Callback} />
                                        <Route path={permanentPaths.userAgreement.path} component={UserAgreement} />
                                        <Route path={permanentPaths.login.path} render={() => <LandingPage {...this.props} />} />
                                        <Route path={permanentPaths.register.path} render={() => <LandingPage {...this.props} />} />
                                        <Route path={permanentPaths.forgotPassword.path} render={() => <LandingPage {...this.props} />} />
                                        <Route path={permanentPaths.setupMFA.path} render={() => <SetupMFA {...this.props} />} />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.settings.path} component={SettingsWrapper} action="any" />
                                        <AccessRoute permanentPaths={permanentPaths} path={`${permanentPaths.search.path}/:query?`} component={DatasetSearch} action="searchDatasets" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.datasets.path} component={DatasetsWrapper} action="datasetsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.runQuery.path} component={SqlqueryWrapper} action="runQueryDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.dashboards.path} component={DashboardsWrapper} action="dashboardsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.connections.path} component={ConnectionsWrapper} action="connectionsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} exact path={permanentPaths.analytics.path} component={ML} action="analyticsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.models.path} component={ModelsWrapper} action="analyticsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.entityRecognizers.path} component={CersWrapper} action="analyticsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.notebooks.path} component={NotebooksWrapper} action="analyticsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.forecast.path} component={ForecastsWrapper} action="analyticsDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.libraries.path} component={LibrariesWrapper} action="etlDisplay" />

                                        <AccessRoute permanentPaths={permanentPaths} exact path={permanentPaths.etl.path} component={Etl} action="etlDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.jobs.path} component={JobsWrapper} action="etlDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.endpoints.path} component={EndpointsWrapper} action="etlDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.etlNotebooks.path} component={NotebooksWrapper} action="etlDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.schedules.path} component={SchedulesWrapper} action="schedulesDisplay" />
                                        <AccessRoute permanentPaths={permanentPaths} path={permanentPaths.management.path} component={ManagementWrapper} action="manageDisplay" />
                                        <Route path={permanentPaths.devTest.path} render={() => window.location.hostname === 'localhost' ? <Test {...this.props} /> : <PageNotFound />} />
                                        <Route path={permanentPaths.restapi.path} render={() => window.location.hostname === 'localhost' ? <RestAPI {...this.props} /> : <PageNotFound />} />
                                        <Route path={permanentPaths.notAuthorized.path} render={() => <NotAuthorized {...this.props} homeRoute={permanentPaths.home.path} />} />
                                        <Route path={permanentPaths.apidocs.path} component={APIDocs} />
                                        <Route path="*" component={PageNotFound} />
                                    </Switch>
                                }
                            </Suspense>
                        </AppErrorBoundary>
                    </div>
                </div>
            </div>
            }
        </React.Fragment>;
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        globalConfig: state.globalConfig,
        sessionActive: state.auth.sessionActive,
        mfaEnabled: state.auth.mfaEnabled || false,
        forcereset: state.auth.forcereset
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        keepAlive: () => dispatch( authActions.keepAlive()),
        setSession: ( session ) => dispatch( authActions.setSession( session ))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( App );
