import React from 'react';
import {
    ButtonToolbar,
    ButtonGroup,
    DropdownButton
} from 'react-bootstrap';


import CardActionButton from './CardActionButton';
import CardActionMenuItem from './CardActionMenuItem';

const PageHeaderButtons = ({
    buttonText = 'Actions',
    id = '',
    listOfButtons
}) => <>
    <ButtonToolbar className="hidden-sm hidden-xs page-buttons" id={`${id}-hl-container`}>
        {
            listOfButtons.map(({
                renderCondition = true,
                hasAccess,
                title,
                icon,
                ...props
            }) => renderCondition ? <CardActionButton
                key={ icon }
                hasAccess={ hasAccess }
                title={ title }
                icon={ icon }
                id={ `${id}-hl`}
                {...props}
            /> : undefined )
        }
    </ButtonToolbar>
    <ButtonToolbar className="pull-right hidden-md hidden-lg" id={`${id}-hl-container`}>
        <ButtonGroup className="page-buttons__dropdown">
            <DropdownButton title={ buttonText } id={`${id}-hl-actions`}>
                {
                    listOfButtons.map(({
                        renderCondition = true,
                        hasAccess,
                        title,
                        icon,
                        ...props
                    }) =>  renderCondition ? <CardActionMenuItem
                        key={ icon }
                        hasAccess={ hasAccess }
                        title={ title }
                        icon={ icon }
                        id={ `${id}-hl` }
                        {...props}
                    /> : undefined )
                }
            </DropdownButton>
        </ButtonGroup>
    </ButtonToolbar>
</>


;

export default PageHeaderButtons;