import React from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';

import { Row, Col, FormGroup, InputGroup, FormControl } from 'react-bootstrap';

import ADPIcon from '../common/Icon';

import Card from '../layout/Cards/Card';
import * as authActions from '../../modules/auth';

class Login extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            username: '',
            password: ''
        };
    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.login( this.state.username, this.state.password );
    }

    render() {
        const { loginError, loginProgress = false, globalConfig = {} } = this.props;
        const { idpLogin, session = false, permanentPaths = {} } = globalConfig;
        return ( <div>
            { session && <Redirect to='/' /> }
            <Row>
                <Col xs={10} md={4} xsOffset={1} mdOffset={4} id="loginform-container">
                    <form onSubmit={this.handleSubmit} id="loginForm">
                        <Card
                            textCenter
                            title="Sign In"
                            category={loginError && <i className="text-danger pull-left">{loginError}</i>}
                            content={ !idpLogin &&
                                <div>
                                    <FormGroup controlId="username">
                                        <InputGroup>
                                            <InputGroup.Addon><ADPIcon icon="user-circle" id="loginform-username-icon" /></InputGroup.Addon>
                                            <FormControl
                                                autoFocus
                                                type="text"
                                                disabled={loginProgress}
                                                placeholder="Username"
                                                value={this.state.username}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup controlId="password">
                                        <InputGroup>
                                            <InputGroup.Addon><ADPIcon icon="key"  id="loginform-password-icon"/></InputGroup.Addon>
                                            <FormControl
                                                type="password"
                                                placeholder="Password"
                                                autoComplete="off"
                                                disabled={loginProgress}
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                    </FormGroup>{/* eslint-disable-next-line  */}
                                    <LinkContainer to={this.props.forgotPasswordPath}><a className="pull-left" id="loginform-forgotPassword-link">Forgot password?</a></LinkContainer>
                                    <br/>
                                </div>
                            }
                            legend={<>
                                { idpLogin
                                    ? <button type="button" id="loginform-submit-btn" className="btn btn-wd btn-fill btn-block" onClick={() => this.props.login()}>Sign In with ID Provider</button>
                                    : <button
                                        id="loginform-submit-btn"
                                        className="btn btn-fill btn-wd btn-block"
                                        disabled={!this.validateForm() || loginProgress }
                                        type="submit"
                                    >{ loginProgress ? <span><ADPIcon icon="refresh" /> Signing in...</span> : 'Sign In' }</button>
                                }<br/>
                                {/* eslint-disable-next-line */}
                                <span className="text-muted">New User ? <LinkContainer to={permanentPaths.register.path}><a className="text-anchor" id="loginform-register-link">Register</a></LinkContainer></span>
                            </>
                            }
                            ftTextCenter
                        />
                    </form>
                </Col>
            </Row>
        </div> );
    }
}


// Mapping the current state props to ths following
const mapStateToProps = ( state, _props ) => {
    return {
        loginError: state.auth.loginError,
        loginProgress: state.auth.loginProgress,
        globalConfig: state.globalConfig
    };
};

const mapDispatchToProps = ( dispatch, props ) => {
    return {
        login: ( user, password ) => dispatch( authActions.login( props.history, user, password )),
        resetLogin: () => dispatch( authActions.resetLogin()),
        resetAuthProps: ( propsToReset ) => dispatch( authActions.resetAuthProps( propsToReset ))
    };
};


export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Login ));