import Swal from 'sweetalert2';

import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';
import { alertAndHide, showLoading, showToast } from '../components/utils/popUpUtils';

export const CHANGE_DATASET_FETCHING = 'CHANGE_DATASET_FETCHING';
export const SET_DATASET_ERROR = 'SET_DATASET_ERROR';
export const FETCH_DATASETS = 'FETCH_DATASETS';
export const FETCH_REDSHIFT_VIEWS = 'FETCH_REDSHIFT_VIEWS';
export const SET_USER_GRANT_LIST = 'SET_USER_GRANT_LIST';

const SEARCH_DATASETS = 'SEARCH_DATASETS';
const SEARCH_DATASET_FILES = 'SEARCH_DATASET_FILES';
const CANCEL_REGISTRATION = 'CANCEL_REGISTRATION';
const FETCH_AUTHORIZED_USERS = 'FETCH_AUTHORIZED_USERS';
const UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS';
const UPDATE_FILE_TAGS = 'UPDATE_FILE_TAGS';
const DATASET_FILES = 'DATASET_FILES';
const FILE_DOWNLOAD = 'FILE_DOWNLOAD';
const FILE_AUTO_ML = 'FILE_AUTO_ML;';
const RESET_DATASET_ERROR = 'RESET_DATASET_ERROR';
const UPDATE_DATASET = 'UPDATE_DATASET';
const DATASET_CHANGE_UPLOAD_PROGRESS = 'DATASET_CHANGE_UPLOAD_PROGRESS';
const DATASET_UPLOAD_FILE_STATUS = 'DATASET_UPLOAD_FILE_STATUS';
const FETCH_BUSINESS_DOMAINS = 'FETCH_BUSINESS_DOMAINS';
const REGISTER_DATASET_SUCCESS = 'REGISTER_DATASET_SUCCESS';
const ACCESS_REQUESTED = 'ACCESS_REQUESTED';
const GRANT_REQUESTS_FETCH = 'GRANT_REQUESTS_FETCH';
const REGISTER_DOMAIN_SUCCESS = 'REGISTER_DOMAIN_SUCCESS';
const REGISTER_CLASSIFICATION_SUCCESS = 'REGISTER_CLASSIFICATION_SUCCESS';
const FETCH_DC = 'FETCH_DC';
const BULK_UPDATE_DATASETS = 'BULK_UPDATE_DATASETS';
const BULK_DELETE_DATASETS = 'BULK_DELETE_DATASETS';
const CHANGE_DATASET_AUTH_USERS_FETCHING = 'CHANGE_DATASET_AUTH_USERS_FETCHING';
const FETCH_DATASET_AUTHORIZED_USERS_SUCCESS = 'FETCH_DATASET_AUTHORIZED_USERS_SUCCESS';
const SET_DATASET_AUTHORIZED_USERS_ERROR = 'SET_DATASET_AUTHORIZED_USERS_ERROR';
const DELETE_DATASET_USER_ACCESS_SUCCESS = 'DELETE_DATASET_USER_ACCESS_SUCCESS';
const UPDATE_DATASET_USER_ACCESS_SUCCESS = 'UPDATE_DATASET_USER_ACCESS_SUCCESS';


export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_DATASET_ERROR:
    case CHANGE_DATASET_FETCHING:
    case SEARCH_DATASETS:
    case SEARCH_DATASET_FILES:
    case FETCH_DATASETS:
    case CANCEL_REGISTRATION:
    case FETCH_AUTHORIZED_USERS:
    case UPDATE_USER_ACCESS:
    case UPDATE_FILE_TAGS:
    case DATASET_FILES:
    case FILE_DOWNLOAD:
    case FILE_AUTO_ML:
    case RESET_DATASET_ERROR:
    case UPDATE_DATASET:
    case DATASET_CHANGE_UPLOAD_PROGRESS:
    case DATASET_UPLOAD_FILE_STATUS:
    case FETCH_BUSINESS_DOMAINS:
    case REGISTER_DATASET_SUCCESS:
    case ACCESS_REQUESTED:
    case GRANT_REQUESTS_FETCH:
    case REGISTER_DOMAIN_SUCCESS:
    case REGISTER_CLASSIFICATION_SUCCESS:
    case FETCH_DC:
    case BULK_UPDATE_DATASETS:
    case BULK_DELETE_DATASETS:
    case CHANGE_DATASET_AUTH_USERS_FETCHING:
    case FETCH_DATASET_AUTHORIZED_USERS_SUCCESS:
    case SET_DATASET_AUTHORIZED_USERS_ERROR:
    case DELETE_DATASET_USER_ACCESS_SUCCESS:
    case UPDATE_DATASET_USER_ACCESS_SUCCESS:
    case FETCH_REDSHIFT_VIEWS:
    case SET_USER_GRANT_LIST:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const searchDatasets = ( searchQuery, offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const searchDatasetsAPI = ( offset && limit && sortOrder && sortBy ) ? urlBuilder([ 'datasets', 'search' ], [`queryString=${searchQuery}`,
            `offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`
        ]) : urlBuilder(['datasets', 'search'], [`queryString=${searchQuery}`]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { searchingDatasets: true, searchError: undefined, searchResults: undefined } });
        return client.get( searchDatasetsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: SEARCH_DATASETS,
                        data: {
                            searchResults: response.data,
                            searchingDatasets: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        searchError: extractError( error_obj ),
                        searchingDatasets: undefined
                    }
                });
                respond( 'searchDatasets', error_obj, dispatch );
            });
    };
};

export const getDatasetFilesWithQuery = ( searchQuery, datasetId, offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const searchDatasetsAPI = ( offset && limit && sortOrder && sortBy ) ? urlBuilder([ 'datasets', datasetId, 'searchfiles' ], [`queryString=${searchQuery}`,
            `offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`
        ]) : urlBuilder(['datasets', datasetId, 'searchfiles'], [`queryString=${searchQuery}`]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { searchingDatasetFiles: true, searchFilesError: undefined, searchFilesResults: undefined } });
        return client.get( searchDatasetsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: SEARCH_DATASET_FILES,
                        data: {
                            searchFilesResults: response.data,
                            searchingDatasetFiles: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        searchFilesError: extractError( error_obj ),
                        searchingDatasetFiles: undefined
                    }
                });
                respond( 'getDatasetFilesWithQuery', error_obj, dispatch );
            });
    };
};

export const updateTagsForFile = ( datasetId, filesList = [], tagsList = [], successCallback = () => {}, component ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateTagsAPI = urlBuilder(['datasets', datasetId, 'files']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { updatingTags: true, tagsUpdated: undefined, tagsUpdateError: undefined } });
        return client.put( updateTagsAPI, {
            'Operation': 'tags',
            'Files': [...filesList],
            'Tags': tagsList
        })
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: UPDATE_FILE_TAGS,
                        data: {
                            tagsUpdated: response.data,
                            updatingTags: undefined
                        }
                    });
                    if ( component === 'deespsearch' ){
                        alertAndHide( 'success', 'Tags updated', response.data?.Message, () => {
                            successCallback( 'Tags Updated Successfully !!' );
                        });
                    } else {
                        successCallback( 'Tags Updated Successfully !!' );
                    }
                }
            })
            .catch( error_obj => {
                if ( error_obj && component === 'deespsearch' ){
                    showToast( 'error', extractError( error_obj ));
                } else {
                    dispatch({
                        type: SET_DATASET_ERROR,
                        data: {
                            tagsUpdateError: extractError( error_obj ),
                            updatingTags: undefined
                        }
                    });
                }
                respond( 'updateTagsForFile', error_obj, dispatch );
            });
    };
};

export const registerDataset = ( dataset ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const registerDatasetAPI = urlBuilder(['datasets']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { isRegistering: true, registrationError: undefined } });
        return client.post( registerDatasetAPI, dataset )
            .then( response => {
                dispatch({
                    type: REGISTER_DATASET_SUCCESS,
                    data: {
                        registrationSuccess: true,
                        datasetId: response.data.DatasetId,
                        dataset: undefined,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        registrationError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'registerDataset', error_obj, dispatch );
            });
    };
};

export const uploadSample = ( key, filePayload, content ) => {
    return ( dispatch, state ) => {
        const config = {
            headers: {
                'Authorization': state().auth.token,
                key,
                'Content-Type': content
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.floor(( progressEvent.loaded * 100 ) / progressEvent.total );
                dispatch({ type: DATASET_CHANGE_UPLOAD_PROGRESS, data: { sampleUploadProgress: percentCompleted } });
            }
        };

        const client = getHttp( dispatch, state, false );
        const registerDatasetAPI = urlBuilder(['datasets', 'samplefile']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { uploadingSample: true, uploadFailure: undefined } });
        return client.post( registerDatasetAPI, filePayload, config )
            .then( response => {
                dispatch({
                    type: DATASET_UPLOAD_FILE_STATUS,
                    data: {
                        uploadSuccess: extractMessage( response.data ),
                        uploadingSample: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        uploadFailure: extractError( error_obj ),
                        uploadingSample: undefined
                    }
                });
                respond( 'uploadSample', error_obj, dispatch );
            });
    };
};

export const getUserDatasets = ( silent = false, offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getUserDatasetsAPI = urlBuilder(['datasets'], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingDatasets: !silent, silentFetching: silent, fetchDatasetsError: undefined } });
        return client.get( getUserDatasetsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_DATASETS,
                        data: {
                            userdatasets: response.data,
                            fetchingDatasets: undefined, silentFetching: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchDatasetsError: extractError( error_obj ),
                        fetchingDatasets: undefined, silentFetching: undefined
                    }
                });
                respond( 'getUserDatasets', error_obj, dispatch );
            });
    };
};

export const getUserDatasetsWithFields = ( filterJdbcDatasets = false, ...args ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const projectionExpression = filterJdbcDatasets === true ? `${args.join( ',' )}&bulkJdbcDatasets=false` : `${filterJdbcDatasets},${args.join( ',' )}`;

        const getUserDatasetsAPI = urlBuilder(['datasets'], [`projectionExpression=${projectionExpression}`]);

        dispatch({ type: CHANGE_DATASET_FETCHING, data: { isFetching: true, fetchDatasetsError: undefined } });
        return client.get( getUserDatasetsAPI )
            .then( response => {

                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_DATASETS,
                        data: {
                            userdatasetsWithFields: response.data.datasets,
                            isFetching: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchDatasetsError: extractError( error_obj ),
                        isFetching: undefined
                    }
                });
                respond( 'getUserDatasetsWithFields', error_obj, dispatch );
            });
    };
};

export const getDatasetDetails = ( datasetId, silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDatasetDetailsAPI = urlBuilder(['datasets', datasetId]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingDetails: !silent, silentFetchingDataset: silent, fetchDatasetError: undefined } });
        return client.get( getDatasetDetailsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DATASETS,
                    data: {
                        dataset: response.data,
                        fetchingDetails: undefined,
                        silentFetchingDataset: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchDatasetError: extractError( error_obj ),
                        fetchingDetails: undefined, silentFetchingDataset: undefined
                    }
                });
                respond( 'getDatasetDetails', error_obj, dispatch );
            });
    };
};

export const cancelRegistration = ( datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const cancelRegistrationAPI = urlBuilder(['datasets', datasetId]);

        return client.delete( cancelRegistrationAPI )
            .then( _response => {
                Swal.close();
                dispatch({
                    type: CANCEL_REGISTRATION,
                    data: {
                        cancelDatasetRegistration: true
                    }
                });
            })
            .catch( error_obj => {
                Swal.close();
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        entitiesDependencies: error_obj.response.data.DependentResources,
                        cancelFailed: extractError( error_obj )
                    }
                });
                respond( 'cancelRegistration', error_obj, dispatch );
            });
    };
};

export const getFiles = ( datasetId, offset, limit, sortOrder, sortBy, showFiles ) => {
    return ( dispatch, state ) => {
        const { dataset: { dataset } } = state();
        const client = getHttp( dispatch, state );
        const searchDatasetsAPI = urlBuilder(['datasets', datasetId, 'files'], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingFiles: true, fetchFilesError: undefined } });

        if (( dataset.TableUpdate === 'reload' || dataset.TargetLocation === 's3' )) {
            dispatch( getAllFilesWithStatus( datasetId, showFiles || ( dataset.TableUpdate === 'reload' ? 'pending' : 'completed' )));
            dispatch( getRecentExecutions( datasetId ));
        }

        return client.get( searchDatasetsAPI )
            .then( response => {
                const results = response.data;
                dispatch({
                    type: DATASET_FILES,
                    data: {
                        filesList: results,
                        fetchingFiles: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchFilesError: extractError( error_obj ),
                        fetchingFiles: undefined
                    }
                });
                respond( 'getFiles', error_obj, dispatch );
            });
    };
};

export const filedownload = ( datasetId, filename ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const filedownloadAPI = urlBuilder(['datasets', datasetId, 'files/download'], [`filename=${filename}`]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { downloadingFile: true } });
        return client.get( filedownloadAPI )
            .then( response => {
                const downloadurl = response.data.url;
                if ( response.status === 200 ) {
                    window.open( downloadurl, '_blank' );
                    dispatch({
                        type: FILE_DOWNLOAD,
                        data: {
                            fileDownloadSuccess: true,
                            downloadingFile: undefined,
                            fileDownloadError: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fileDownloadError: extractError( error_obj ),
                        downloadingFile: undefined,
                        fileDownloadSuccess: false
                    }
                });
                respond( 'getfilemodals', error_obj, dispatch );
            });
    };
};

export const getAllFilesWithStatus = ( datasetId, status = 'pending', successCallback ) => {
    return ( dispatch, state ) => {
        if ( status !== 'allFiles' ) {
            const client = getHttp( dispatch, state );
            const getAllFilesPendingAPI = urlBuilder(['datasets', datasetId, 'files'], [`status=${status}`]);

            dispatch({
                type: CHANGE_DATASET_FETCHING, data: {
                    allFilesList: [],
                    fetchingAllFiles: true,
                    fetchingFilesError: undefined

                }
            });

            return client.get( getAllFilesPendingAPI )
                .then(( response ) => {
                    dispatch({
                        type: DATASET_FILES,
                        data: {
                            allFilesList: response.data.files,
                            ...( status === 'pending' ? { pendingFilesNo: response.data.files.length } : {}),
                            fetchingAllFiles: undefined
                        }
                    });
                    successCallback();
                })
                .catch( error_obj => {
                    dispatch({
                        type: CHANGE_DATASET_FETCHING,
                        data: {
                            fileProcesssing: undefined,
                            fetchingFilesError: extractError( error_obj )

                        }
                    });

                    respond( 'getAllPendingFiles', error_obj, dispatch );
                });
        }
    };
};

export const getRecentExecutions = ( datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getRecentExecutionsAPI = urlBuilder(['datasets', datasetId, 'datareloads']);

        dispatch({
            type: CHANGE_DATASET_FETCHING, data: {
                recentExecutions: [],
                fetchingRecentExecutions: true,
                recentExecutionsError: undefined

            }
        });

        return client.get( getRecentExecutionsAPI )
            .then(( response ) => {
                dispatch({
                    type: DATASET_FILES,
                    data: {
                        recentExecutions: response.data.datareloads,
                        fetchingRecentExecutions: undefined
                    }
                });

            })
            .catch( error_obj => {
                dispatch({
                    type: CHANGE_DATASET_FETCHING,
                    data: {
                        fetchingRecentExecutions: undefined,
                        recentExecutionsError: extractError( error_obj )

                    }
                });

                respond( 'getRecentExecutions', error_obj, dispatch );
            });
    };
};

export const processFiles = ( datasetId, filesList, successCallback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const fileProcessAPI = urlBuilder(['datasets', datasetId, 'datareloads']);

        dispatch({
            type: CHANGE_DATASET_FETCHING,
            data: {
                fileProcessError: undefined,
                fileProcesssing: true
            }
        });

        if ( filesList.length < 1 ) {
            alertAndHide( 'error', 'No Files Selected', 'Please select the pending files you need to delete' );
            return {};
        }

        return client.post( fileProcessAPI, { 'Files': [...filesList] })
            .then(( response ) => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: DATASET_FILES,
                        data: {
                            fileProcessError: undefined,
                            fileProcesssing: undefined
                        }
                    });
                    successCallback( extractMessage( response.data ));
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: CHANGE_DATASET_FETCHING,
                    data: {
                        fileProcesssing: undefined,
                        fileProcessError: extractError( error_obj ) || 'Network Error'
                    }
                });

                respond( 'updateDomain', error_obj, dispatch );
            });
    };
};

export const deleteFiles = ( datasetId, filesList, operation, truncateDataset, successCallback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const fileDeleteAPI = urlBuilder(['datasets', datasetId, 'files']);

        dispatch({
            type: CHANGE_DATASET_FETCHING, data: {
                fileProcessError: undefined,
                fileProcesssing: true
            }
        });

        if ( filesList.length < 1 && truncateDataset === false ) {
            alertAndHide( 'error', 'No Files Selected', 'Please select the pending files you need to delete' );
            return {};
        } else if ( truncateDataset === true && filesList.length === 0 ) {
            alertAndHide( 'question', 'Are you sure?', 'All the files associated with the dataset will be deleted', undefined, true, 'Acknowledge & Continue' )
                .then( result => {
                    if ( result.value ) {
                        showLoading( 'Deleting dataset files...' );
                        return client.put( fileDeleteAPI, {
                            'Operation': operation,
                            'Files': [...filesList],
                            'TruncateDataset': truncateDataset
                        }).then(( response ) => {
                            if ( response.status === 200 ) {
                                dispatch({
                                    type: DATASET_FILES,
                                    data: {
                                        fileProcesssing: undefined
                                    }
                                });
                                alertAndHide( 'success', 'Truncate Success', extractMessage( response.data ), successCallback );
                                // successCallback( extractMessage( response.data ) );
                            }

                        }).catch( error_obj => {
                            dispatch({
                                type: CHANGE_DATASET_FETCHING,
                                data: {
                                    fileProcesssing: undefined,
                                    fileProcessError: extractError( error_obj ) || 'Network Error'
                                }
                            });
                            alertAndHide( 'error', 'Error truncating dataset', extractError( error_obj ) || 'Network Error' );

                            respond( 'deletePendingFiles', error_obj, dispatch );
                        });
                    }
                });
        } else {
            alertAndHide( 'warning', 'Are you sure?', `Do you want to ${operation.replace( '_', ' ' )} all selected files ?`, () => { }, true )
                .then( result => {
                    if ( result.value ) {
                        return client.put( fileDeleteAPI, {
                            'Operation': operation,
                            'Files': [...filesList],
                            'TruncateDataset': truncateDataset
                        }).then(( response ) => {
                            if ( response.status === 200 ) {
                                dispatch({
                                    type: DATASET_FILES,
                                    data: {
                                        fileProcesssing: undefined
                                    }
                                });
                                successCallback( extractMessage( response.data ));
                            }
                        }).catch( error_obj => {
                            dispatch({
                                type: CHANGE_DATASET_FETCHING,
                                data: {
                                    fileProcesssing: undefined,
                                    fileProcessError: extractError( error_obj ) || 'Network Error'
                                }
                            });
                            respond( 'deletePendingFiles', error_obj, dispatch );
                        });
                    }
                });
        }
    };
};

export const getDatasetAnalytics = ( datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDatasetAnalyticsAPI = urlBuilder(['datasets', datasetId, 'analytics']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { metadataFetch: true, datasetAnalytics: undefined, datasetAnalyticsError: undefined } });
        return client.get( getDatasetAnalyticsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FILE_AUTO_ML,
                        data: {
                            datasetAnalytics: response.data,
                            metadataFetch: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        datasetAnalyticsError: extractError( error_obj ),
                        metadataFetch: undefined
                    }
                });
                respond( 'getDatasetAnalytics', error_obj, dispatch );
            });
    };
};

export const getAnalyticsOnFile = ( datasetId, filename ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getfileModelsAPI = urlBuilder(['datasets', datasetId, 'files/analytics'], [`filename=${filename}`]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingFileAnalytics: true, fileAnalytics: undefined, fetchFileAnalyticsError: undefined } });
        return client.get( getfileModelsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FILE_AUTO_ML,
                        data: {
                            fileAnalytics: response.data,
                            fetchingFileAnalytics: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchFileAnalyticsError: extractError( error_obj ),
                        fetchingFileAnalytics: undefined
                    }
                });
                respond( 'getAnalyticsOnFile', error_obj, dispatch );
            });
    };
};

export const getmodelrunstatus = ( datasetId, filename ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const modelstatusAPI = urlBuilder(['datasets', datasetId, 'files/models', 'runstatus'], [`filename=${filename}`]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingInvocations: true, fileInvocations: undefined, fetchInvocationsError: undefined } });
        return client.get( modelstatusAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FILE_AUTO_ML,
                        data: {
                            fileInvocations: response.data.modelruns,
                            fetchingInvocations: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchInvocationsError: extractError( error_obj ),
                        fetchingInvocations: undefined
                    }
                });
                respond( 'getmodelrunstatus', error_obj, dispatch );
            });
    };
};

export const updateDataset = ( schema, datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateDatasetAPI = urlBuilder(['datasets', datasetId, 'updatemetadata']);
        return client.put( updateDatasetAPI, schema )
            .then( response => {
                const results = extractMessage( response.data );
                dispatch({
                    type: UPDATE_DATASET,
                    data: {
                        updateSuccess: results
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { updateError: extractError( error_obj ) }
                });
                respond( 'updateDataset', error_obj, dispatch );
            });
    };
};

export const uploadFile = ( uploadDataURL, payload, fileIndex, isAnalytic ) => {
    return ( dispatch, state ) => {
        const config = isAnalytic ? {
            headers: {
                'Content-Type': 'text/csv'
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.floor(( progressEvent.loaded * 100 ) / progressEvent.total );
                dispatch({
                    type: DATASET_CHANGE_UPLOAD_PROGRESS, data: {
                        uploadProgress: {
                            ...state().dataset.uploadProgress || {},
                            [fileIndex]: percentCompleted
                        }
                    }
                });
            }
        } : {
            headers: {
                'Content-Type': ''
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.floor(( progressEvent.loaded * 100 ) / progressEvent.total );
                dispatch({
                    type: DATASET_CHANGE_UPLOAD_PROGRESS, data: {
                        uploadProgress: {
                            ...state().dataset.uploadProgress || {},
                            [fileIndex]: percentCompleted
                        }
                    }
                });
            }
        };

        const client = getHttp( dispatch, state, false );
        return client.put( uploadDataURL, payload, config )
            .then( _response => {
            })
            .catch( error_obj => {
                dispatch({
                    type: DATASET_UPLOAD_FILE_STATUS,
                    data: {
                        uploadProgress: {
                            ...state().dataset.uploadProgress || {},
                            [fileIndex]: 0
                        },
                        fileUploadFailure: {
                            ...state().dataset.fileUploadFailure || {},
                            [fileIndex]: extractError( error_obj ) || 'Failed to upload file'
                        }
                    }
                });
                respond( 'uploadFile', error_obj, dispatch );
            });
    };
};

export const getfilepresignedURL = ( file, datasetid, isAnalytic ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getfilepresignedURLAPI = urlBuilder(['datasets', 'file']);
        dispatch({
            type: CHANGE_DATASET_FETCHING, data: {
                fetchingURL: {
                    ...state().dataset.fetchingURL || {},
                    [file.fileIndex]: true
                }
            }
        });
        return client.post( getfilepresignedURLAPI, {
            'FileName': file.filename,
            'DatasetId': datasetid
        })
            .then( response => {
                const url = extractMessage( response.data );
                dispatch({
                    type: CHANGE_DATASET_FETCHING, data: {
                        fetchingURL: {
                            ...state().dataset.fetchingURL || {},
                            [file.fileIndex]: false
                        }
                    }
                });
                dispatch( uploadFile( url, file.actualfile, file.fileIndex, isAnalytic ));
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fileUploadFailure: {
                            ...state().dataset.fileUploadFailure || {},
                            [file.fileIndex]: extractError( error_obj )
                        },
                        fetchingURL: {
                            ...state().dataset.fetchingURL || {},
                            [file.fileIndex]: false
                        }
                    }
                });
                respond( 'getfilepresignedURL', error_obj, dispatch );
            });
    };
};

export const getDomains = ( ...args ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDomainsAPI = args.length > 0 ? urlBuilder(['domains'], [`projectionExpression=${args.join( ',' )}`]) : urlBuilder(['domains']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingDomains: true, fetchDomainsError: undefined } });
        return client.get( getDomainsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_BUSINESS_DOMAINS,
                    data: args.length > 0 ? {
                        domainsWithFields: response.data.domains,
                        fetchingDomains: undefined
                    } : {
                        domains: response.data.domains,
                        fetchingDomains: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchDomainsError: extractError( error_obj ),
                        fetchingDomains: undefined
                    }
                });
                respond( 'getDomains', error_obj, dispatch );
            });
    };
};

export const addDomain = ( domain ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addDomainAPI = urlBuilder(['domains']);
        return client.post( addDomainAPI, domain )
            .then(() => {
                dispatch({
                    type: REGISTER_DOMAIN_SUCCESS,
                    data: {
                        addDomainSuccess: true,
                        domainsWithFields: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { addDomainError: extractError( error_obj ) }
                });
                respond( 'addDomain', error_obj, dispatch );
            });
    };
};

export const updateDomain = ( domain ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateDomainAPI = urlBuilder(['domains', domain.DomainName]);
        return client.put( updateDomainAPI, domain )
            .then(() => {
                dispatch({
                    type: REGISTER_DOMAIN_SUCCESS,
                    data: {
                        updateDomainSuccess: true,
                        domainsWithFields: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { updateDomainError: extractError( error_obj ) }
                });
                respond( 'updateDomain', error_obj, dispatch );
            });
    };
};

export const deleteDomain = ( domainName ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteDomainAPI = urlBuilder(['domains', domainName]);
        return client.delete( deleteDomainAPI )
            .then(() => {
                dispatch({
                    type: REGISTER_DOMAIN_SUCCESS,
                    data: {
                        deleteDomainSuccess: true,
                        domainsWithFields: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { deleteDomainError: extractError( error_obj ) }
                });
                respond( 'deleteDomain', error_obj, dispatch );
            });
    };
};

export const addClassification = ( domain ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addClassificationAPI = urlBuilder(['datasets', 'dataclassifications']);
        return client.post( addClassificationAPI, domain )
            .then(() => {
                dispatch({
                    type: REGISTER_CLASSIFICATION_SUCCESS,
                    data: {
                        addClassificationSuccess: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { addClassificationError: extractError( error_obj ) }
                });
                respond( 'addClassification', error_obj, dispatch );
            });
    };
};

export const getDataClassifications = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDataClassificationsAPI = urlBuilder(['datasets', 'dataclassifications']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingDCS: true, fetchClassificationsError: undefined } });
        return client.get( getDataClassificationsAPI )
            .then( response => {
                const results = response.data.dataclassifications;
                dispatch({
                    type: FETCH_DC,
                    data: {
                        dataClassifications: results,
                        fetchingDCS: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchClassificationsError: extractError( error_obj ),
                        fetchingDCS: undefined
                    }
                });
                respond( 'getDataClassifications', error_obj, dispatch );
            });
    };
};

export const updateClassification = ( classification ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateClassificationAPI = urlBuilder(['datasets', 'dataclassifications', classification.DataClassificationName]);
        return client.put( updateClassificationAPI, classification )
            .then(() => {
                dispatch({
                    type: REGISTER_CLASSIFICATION_SUCCESS,
                    data: {
                        updateClassificationSuccess: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { updateClassificationError: extractError( error_obj ) }
                });
                respond( 'updateDataClassification', error_obj, dispatch );
            });
    };
};

export const getlistofgrantaccess = ( silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getlistofgrantaccessAPI = urlBuilder(['users', state().auth.userName, 'grantrequests']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingGrantRequests: !silent, silentFetching: silent, fetchGrantRequestsError: undefined } });
        return client.get( getlistofgrantaccessAPI )
            .then( response => {
                dispatch({
                    type: GRANT_REQUESTS_FETCH,
                    data: {
                        grantrequestlist: response.data.Requests,
                        fetchingGrantRequests: undefined, silentFetching: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchGrantRequestsError: extractError( error_obj ),
                        fetchingGrantRequests: undefined, silentFetching: undefined
                    }
                });
                respond( 'getlistofgrantaccess', error_obj, dispatch );
            });
    };
};

export const rejectRequest = ( requestBody, datasetId ) => {
    return ( dispatch, state ) => {
        const config = {
            data: requestBody
        };

        const client = getHttp( dispatch, state );
        const requestDatasetAccessAPI = urlBuilder(['datasets', datasetId, 'users', state().auth.userName, 'grantrequests']);
        return client.delete( requestDatasetAccessAPI, config )
            .then(() => {
                dispatch({
                    type: ACCESS_REQUESTED,
                    data: {
                        requestRejected: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { requestError: extractError( error_obj ) }
                });
                respond( 'rejectRequest', error_obj, dispatch );
            });
    };
};

export const deleteRequest = ( userId, requestId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteAccessRequestAPI = urlBuilder(['users', userId, 'grantrequests', requestId]);

        return client.delete( deleteAccessRequestAPI )
            .then(() => {
                dispatch({
                    type: ACCESS_REQUESTED,
                    data: {
                        requestDeletedSuccess: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    requestDeletedError: { requestError: extractError( error_obj ) }
                });
                respond( 'deleteRequest', error_obj, dispatch );
            });
    };
};

export const approveRequest = ( requestBody, datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const requestDatasetAccessAPI = urlBuilder(['datasets', datasetId, 'users', state().auth.userName, 'grantrequests']);
        return client.put( requestDatasetAccessAPI, requestBody )
            .then(() => {
                dispatch({
                    type: ACCESS_REQUESTED,
                    data: {
                        requestApproved: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: { requestError: extractError( error_obj ) }
                });
                respond( 'approveRequest', error_obj, dispatch );
            });
    };
};

export const publishDataset = ( schema, datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const publishDatasetAPI = urlBuilder(['datasets', datasetId]);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { publishingDataset: true } });
        return client.put( publishDatasetAPI, schema )
            .then( response => {
                dispatch({
                    type: REGISTER_DATASET_SUCCESS,
                    data: {
                        registrationSuccess: extractMessage( response.data ),
                        publishingDataset: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        registrationError: extractError( error_obj ),
                        publishingDataset: undefined
                    }
                });
                respond( 'publishDataset', error_obj, dispatch );
            });
    };
};

export const requestDatasetAccess = ( requestBody, datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const requestDatasetAccessAPI = urlBuilder(['datasets', datasetId, 'users', state().auth.userName, 'grantrequests']);
        return client.post( requestDatasetAccessAPI, requestBody )
            .then( response => {
                const results = response.data.RequestId;
                results ?
                    dispatch({
                        type: ACCESS_REQUESTED,
                        data: {
                            accessSuccess: results
                        }
                    }) : dispatch({
                        type: SET_DATASET_ERROR,
                        data: { accessError: 'Cannot find Dataset with given ID' }
                    });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        accessError: extractError( error_obj )
                    }
                });
                respond( 'requestDatasetAccess', error_obj, dispatch );
            });
    };
};

export const bulkUpdateDatasets = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const bulkUpdateDatasetAPI = urlBuilder(['datasets', 'batch-updatemetadata']);
        return client.put( bulkUpdateDatasetAPI, requestBody )
            .then( response => {
                const results = extractMessage( response.data );
                dispatch({
                    type: BULK_UPDATE_DATASETS,
                    data: {
                        bulkUpdateSuccess: results
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        bulkUpdateError: extractError( error_obj )
                    }
                });
                respond( 'bulkUpdateDatasets', error_obj, dispatch );
            });
    };
};

export const bulkDeleteDatasets = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const bulkDeleteDatasetAPI = urlBuilder(['datasets', 'batch-delete']);
        const config = {
            DatasetIds: requestBody
        };
        return client.put( bulkDeleteDatasetAPI, config )
            .then( response => {
                const results = response.data;
                dispatch({
                    type: BULK_DELETE_DATASETS,
                    data: {
                        bulkDeleteSuccess: results
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        bulkDeleteError: extractError( error_obj )
                    }
                });
                respond( 'bulkDeleteDatasets', error_obj, dispatch );
            });
    };
};


export const getDatasetAuthorizedUsers = ( datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const authorizedUsersAPI = urlBuilder(['datasets', datasetId, 'authorizedusers']);
        dispatch({ type: CHANGE_DATASET_FETCHING, data: { fetchingAuthorizedUsers: true, fetchingAuthorizedUsersError: undefined } });
        return client.get( authorizedUsersAPI )
            .then( response => {
                const results = response.data.users;
                dispatch({
                    type: FETCH_AUTHORIZED_USERS,
                    data: {
                        authorizedUsers: results,
                        fetchingAuthorizedUsers: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        fetchingAuthorizedUsersError: extractError( error_obj ),
                        fetchingAuthorizedUsers: undefined
                    }
                });
                respond( 'getDatasetAuthorizedUsers', error_obj, dispatch );
            });
    };
};

export const getAuthorizations = ( datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder(['datasets', datasetId, 'authorizedusers']);
        dispatch({ type: CHANGE_DATASET_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DATASET_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_DATASET_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( requestBody, datasetId, userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder(['datasets', datasetId, 'users', userId, 'grants']);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                dispatch({
                    type: UPDATE_DATASET_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( userId, datasetId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder(['datasets', datasetId, 'users', userId, 'grants']);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                dispatch({
                    type: DELETE_DATASET_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetDatasetProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_DATASET_ERROR, data: propsToReset });
    };
};

export const resetFileProps = () => {
    return ( dispatch ) => {
        dispatch({
            type: RESET_DATASET_ERROR, data: {
                fetchingURL: {},
                sampleUploadProgress: 0,
                uploadProgress: {},
                fileUploadFailure: {}
            }
        });
    };
};

