import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';

const SET_NOTEBOOKS_ERROR = 'SET_NOTEBOOKS_ERROR';
const CHANGE_NOTEBOOKS_FETCHING = 'CHANGE_NOTEBOOKS_FETCHING';
const FETCH_NOTEBOOKS = 'FETCH_NOTEBOOKS';
const NOTEBOOK_STATUS = 'NOTEBOOK_STATUS';
const REGISTER_NOTEBOOK_SUCCESS = 'REGISTER_NOTEBOOK_SUCCESS';
const REGISTER_NOTEBOOK_FAILURE = 'REGISTER_NOTEBOOK_FAILURE';
const RESET_NOTEBOOKS_PROPS = 'RESET_NOTEBOOKS_PROPS';
const CHANGE_NOTEBOOKS_AUTH_USERS_FETCHING = 'CHANGE_NOTEBOOKS_AUTH_USERS_FETCHING';
const FETCH_NOTEBOOKS_AUTHORIZED_USERS_SUCCESS = 'FETCH_NOTEBOOKS_AUTHORIZED_USERS_SUCCESS';
const SET_NOTEBOOKS_AUTHORIZED_USERS_ERROR = 'SET_NOTEBOOKS_AUTHORIZED_USERS_ERROR';
const DELETE_NOTEBOOKS_USER_ACCESS_SUCCESS = 'DELETE_NOTEBOOKS_USER_ACCESS_SUCCESS';
const UPDATE_NOTEBOOKS_USER_ACCESS_SUCCESS = 'UPDATE_NOTEBOOKS_USER_ACCESS_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_NOTEBOOKS_ERROR:
    case CHANGE_NOTEBOOKS_FETCHING:
    case FETCH_NOTEBOOKS:
    case REGISTER_NOTEBOOK_SUCCESS:
    case REGISTER_NOTEBOOK_FAILURE:
    case NOTEBOOK_STATUS:
    case RESET_NOTEBOOKS_PROPS:
    case CHANGE_NOTEBOOKS_AUTH_USERS_FETCHING :
    case FETCH_NOTEBOOKS_AUTHORIZED_USERS_SUCCESS :
    case SET_NOTEBOOKS_AUTHORIZED_USERS_ERROR :
    case DELETE_NOTEBOOKS_USER_ACCESS_SUCCESS :
    case UPDATE_NOTEBOOKS_USER_ACCESS_SUCCESS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const getNotebooks = ( notebookType = 'sagemaker', silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getNotebooksAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }` ]);
        dispatch({ type: CHANGE_NOTEBOOKS_FETCHING, data: { fetchingNotebooks: !silent, silentFetchingNotebooks: silent, fetchNotebooksError: undefined } });
        return client.get( getNotebooksAPI )
            .then( response => {
                dispatch({
                    type: FETCH_NOTEBOOKS,
                    data: {
                        notebooksList: response.data.notebooks,
                        fetchingNotebooks: undefined, silentFetchingNotebooks: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_NOTEBOOKS_ERROR,
                    data: {
                        fetchNotebooksError: extractError( error_obj ),
                        fetchingNotebooks: undefined, silentFetchingNotebooks: undefined
                    }
                });
                respond( 'getNotebooks', error_obj, dispatch );
            });
    };
};

export const getNotebookDetails = ( notebookId, notebookType, silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getNotebookAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }`, notebookId ]);
        dispatch({ type: CHANGE_NOTEBOOKS_FETCHING, data: { fetchingNotebooks: !silent, fetchNotebooksError: undefined, silentFetchingNotebook: silent } });
        return client.get( getNotebookAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_NOTEBOOKS,
                        data: {
                            notebook: response.data,
                            fetchingNotebooks: undefined, silentFetchingNotebook: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_NOTEBOOKS_ERROR,
                    data: {
                        fetchNotebooksError: extractError( error_obj ),
                        fetchingNotebooks: undefined, silentFetchingNotebook: undefined
                    }
                });
                respond( 'getNotebookDetails', error_obj, dispatch );
            });
    };
};

export const addNotebook = ( notebook, notebookType ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addNotebookAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }` ]);
        dispatch({ type: CHANGE_NOTEBOOKS_FETCHING, data: { registeringNotebook: true, registrationError: undefined } });
        return client.post( addNotebookAPI, notebook )
            .then( response => {
                dispatch({
                    type: REGISTER_NOTEBOOK_SUCCESS,
                    data: {
                        registrationSuccess: response.data,
                        registeringNotebook: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_NOTEBOOKS_ERROR,
                    data: {
                        registrationError: extractError( error_obj ),
                        registeringNotebook: undefined
                    }
                });
                respond( 'addNotebook', error_obj, dispatch );
            });
    };
};

export const updateNotebook = ( notebook, accessObj, notebookId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateNotebookAPI = urlBuilder([ 'notebooks', notebookId ], ['operation=update']);
        const updateNotebookAccessAPI = urlBuilder([ 'notebooks', notebookId ], ['operation=update-access']);
        dispatch({ type: CHANGE_NOTEBOOKS_FETCHING, data: { registeringNotebook: true, registrationError: undefined } });
        return client.put( updateNotebookAPI, notebook )
            .then( response => {
                client.put( updateNotebookAccessAPI, accessObj )
                    .then( _response2 => {
                        dispatch({
                            type: REGISTER_NOTEBOOK_SUCCESS,
                            data: {
                                registrationSuccess: response.data,
                                registeringNotebook: undefined
                            }
                        });
                    })
                    .catch( error_obj => {
                        dispatch({
                            type: SET_NOTEBOOKS_ERROR,
                            data: {
                                registrationError: extractError( error_obj ),
                                registeringNotebook: undefined
                            }
                        });
                    });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_NOTEBOOKS_ERROR,
                    data: {
                        registrationError: extractError( error_obj ),
                        registeringNotebook: undefined
                    }
                });
                respond( 'updateNotebook', error_obj, dispatch );
            });
    };
};

export const setNotebookStatus = ( notebookId, model, status, notebookType ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const setNotebookStatusAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }`, notebookId ], [`operation=${status}`]);
        dispatch({ type: CHANGE_NOTEBOOKS_FETCHING, data: { processing: true, notebookStatusError: undefined } });
        return client.put( setNotebookStatusAPI, model )
            .then( response => {
                dispatch({
                    type: NOTEBOOK_STATUS,
                    data: {
                        notebookStatusSuccess: response.data,
                        processing: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: NOTEBOOK_STATUS,
                    data: {
                        notebookStatusError: extractError( error_obj ),
                        processing: undefined
                    }
                });
                respond( 'setNotebookStatus', error_obj, dispatch );
            });
    };
};

export const deleteNotebook = ( notebookId, notebookType ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteNotebookAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }`, notebookId ]);
        dispatch({ type: CHANGE_NOTEBOOKS_FETCHING, data: { processing: true, notebookDeleteError: undefined } });
        return client.delete( deleteNotebookAPI )
            .then( response => {
                dispatch({
                    type: NOTEBOOK_STATUS,
                    data: {
                        notebookDeleteSuccess: response.data,
                        processing: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: NOTEBOOK_STATUS,
                    data: {
                        notebookDeleteError: extractError( error_obj ),
                        processing: undefined
                    }
                });
                respond( 'deleteNotebook', error_obj, dispatch );
            });
    };
};


export const getAuthorizations = ( notebookId, notebookType ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }`, notebookId, 'authorizedusers' ]);
        dispatch({ type: CHANGE_NOTEBOOKS_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_NOTEBOOKS_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_NOTEBOOKS_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( requestBody, notebookId, userId, notebookType ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }`, notebookId, 'users', userId, 'grants' ]);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                dispatch({
                    type: UPDATE_NOTEBOOKS_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_NOTEBOOKS_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( userId, notebookId, notebookType ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder([ `${ notebookType === 'sagemaker' ? 'notebooks' : 'etlnotebooks' }`, notebookId, 'users', userId, 'grants' ]);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                dispatch({
                    type: DELETE_NOTEBOOKS_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_NOTEBOOKS_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetNotebooksProps = ( propsToReset = {}) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_NOTEBOOKS_PROPS, data: propsToReset });
    };
};


