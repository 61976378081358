import React, { useState } from 'react';
import Select from 'react-select';

import {
    Modal
} from 'react-bootstrap';

import ADPIcon from './Icon';
import { showLoading } from '../utils/popUpUtils';
import { hasAccess } from '../../routes/RouteUtils';
import { commonResourcesMapping } from '../../modules/common';

const APP_LINKS_GROUP = 'goToAppLinks';
const DATASET_LINKS_GROUP = 'searchDatasets';
const BOOKMARKS_LINKS_GROUP = 'goToBookmarks';

const NavigatorListOption = ({ text, icon }) => <>
    <ADPIcon icon={icon}  className="text-grey"/>
    { text }
</>;
const SearchableResourceTypes = Object.keys( commonResourcesMapping ).filter( el => typeof commonResourcesMapping[el]?.searchPath !== 'undefined' );

const constructListOptions = ( searchText, userRole, globalConfig, commonResources ) => {
    const { permanentPaths, userPreferences } = globalConfig;
    const { bookmarks = [] } =  userPreferences || {};

    if ( !searchText || searchText.length <= 0 ) {
        return [];
    }

    const listOfLinks = Object.keys( permanentPaths )
        .filter( el => hasAccess( userRole, permanentPaths[el].permission ) &&
                                ( permanentPaths[el].quickNavSearch || [])
                                    .some( helper => helper.toLowerCase().startsWith( searchText.toLowerCase())))
        .map(( pathKey ) => {
            const optionValue = permanentPaths[pathKey].quickNavSearch
                .filter( el => el.toLowerCase().startsWith( searchText.toLowerCase())).pop();
            return {
                value: optionValue,
                label: <NavigatorListOption icon={permanentPaths[pathKey].icon} text={ permanentPaths[pathKey].name } />,
                link: permanentPaths[pathKey].path,
                group: APP_LINKS_GROUP
            };
        });

    const listOfBookmarks =  bookmarks.filter( el => (
        el.name.toLowerCase().indexOf( searchText.toLowerCase()) > -1 ||
            el.description.toLowerCase().indexOf( searchText.toLowerCase()) > -1 ))
        .map(( bookmark  ) => {
            const bookmarkInstance = Object.keys( permanentPaths ).filter(( permanentPath ) => permanentPaths[permanentPath].path === bookmark.path );
            const icon = bookmarkInstance.length > 0 ? permanentPaths[bookmarkInstance].icon || 'star' : 'star';

            return {
                value: bookmark.name,
                label: <NavigatorListOption icon={icon} text={bookmark.name} />,
                group: BOOKMARKS_LINKS_GROUP,
                link: bookmark.path
            };
        });

    const listOfResources = SearchableResourceTypes.map(( resourceType ) => {
        const { fields, responseMapping, searchPath } = commonResourcesMapping[resourceType];
        const { path, icon, name } = permanentPaths[searchPath];
        const [ ID, NAME ] = fields;
        const resourceListing = commonResources?.[resourceType]?.[responseMapping].map( obj => {
            if ( obj[NAME].toLowerCase().indexOf( searchText.toLowerCase()) > -1 ){
                return {
                    value: obj[NAME],
                    label: <NavigatorListOption icon={icon} text={obj[NAME]} />,
                    group: resourceType.toUpperCase(),
                    link: `${path}/${obj[ID]}/details`
                };
            }
            return undefined;
        }).filter( el => typeof el === 'object' );

        return {
            label: `${name} matched`,
            options: resourceListing
        };
    }).filter( el => el?.options?.length > 0 );

    const datasetListings = hasAccess( userRole, 'searchDatasets' ) ? [{
        value: `Search for ${searchText} in datasets`,
        label: <NavigatorListOption icon="search" text={`Search for '${searchText}' in datasets`} />,
        searchText,
        group: DATASET_LINKS_GROUP
    }] : [];

    return  [
        ( listOfLinks.length > 0 ? {
            label: 'Navigate to',
            options: [
                ...listOfLinks
            ]
        } : {}),
        ( listOfBookmarks.length > 0 ? {
            label: 'Bookmarks matched',
            options: [
                ...listOfBookmarks
            ]} : {})
    ]
        .concat( listOfResources )
        .concat([
            ( datasetListings.length > 0 ? {
                label: 'Search in Datasets',
                options: [
                    ...datasetListings
                ]} : {})]
        ).filter( el => el?.options?.length > 0 );
};

const selectAppNavOption = ( selectedOption, history, permanentPaths, callback ) => {
    if ( !selectedOption || !selectedOption.group ) {
        return;
    }
    if ( selectedOption.group === DATASET_LINKS_GROUP ) {
        history.push( `${permanentPaths.search.path}/${selectedOption.searchText}` );
    } else {
        callback( false );
        showLoading( 'Navigation in progress...' );
        window.location.href = selectedOption.link;
    }
};

const ApplicationNavigator = ({
    userRole,
    commonResources,
    globalConfig,
    showQuickNav,
    history,
    toggleModal
}) => {
    const [ quickNavText, setQuickNavText ] =  useState( '' );

    let textInput = null;

    return showQuickNav && <Modal
        className="quickNavModal"
        show={showQuickNav}
        onEntered={() => textInput.focus()}
        onHide={() => toggleModal( false )}
        aria-labelledby="ModalHeader" >
        <Modal.Header>
            <ADPIcon icon="route"  className="text-grey text-bold"/>
            <Select
                name="quickNav"
                placeholder="Application Navigator"
                id="application-navigator-input"
                value={ quickNavText }
                onInputChange={( e ) => setQuickNavText( e ? e.replace( /^\s+/g, '' ).replace( / +(?= )/g, '' ) : '' )}
                onChange={( e ) => selectAppNavOption( e, history, globalConfig.permanentPaths, toggleModal )}
                options={constructListOptions( quickNavText, userRole, globalConfig, commonResources )}
                isSearchable
                classNamePrefix="select"
                ref={( input ) => { textInput = input;}}
                tabSelectsValue={false}
                menuIsOpen={ quickNavText && quickNavText.length > 0 }
                noOptionsMessage={() => ''}
            />
        </Modal.Header>
    </Modal>;
};

export default ApplicationNavigator;