import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Grid, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';

import Card from '../layout/Cards/Card';
import * as authActions from '../../modules/auth';
import Tooltips from '../../mappings/helper_tooltips.json';
import { renderInput, formFieldHelper } from '../utils/renderUtils';

const standard_timeout = 180000;
class Verify extends React.Component {

    constructor( props ) {
        super( props );
        this.isValidated = this.isValidated.bind( this );
        this.state = {
            countdown: 0
        };
    }

    reset = () => {
        this.props.resetAuthProps({
            isverifysent: undefined,
            isreset: undefined
        });
        Swal.fire({
            title: 'Password Successfully Changed!',
            text: 'You have successfully reset your password. Please login with the new password',
            type: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(( _result ) => {
            this.props.history.push( '/' );
        });
    };

    componentDidMount() {
        this.restartTimer();
    }

    componentDidUpdate() {
        if ( this.props.isreset ) {
            this.reset();
        }
    }

    componentWillUnmount() {
        clearInterval( this.state.intervalId );
        this.props.resetAuthProps({ isverifysent: false });
    }


    restartTimer() {
        const intervalId = setInterval( this.timer, 1000 );
        this.setState({
            intervalId,
            countdown: standard_timeout
        });
    }

    timer = () => {
        this.setState(( prevState ) => ({
            countdown: prevState.countdown - 1000
        }), () => {
            if ( this.state.countdown <= 0 ) {
                clearInterval( this.state.intervalId );
            }
        });
    }

    msToTime = ( s = 0 ) => {
        const ms = s % 1000;
        s = ( s - ms ) / 1000;
        const secs = s % 60;
        s = ( s - secs ) / 60;
        const mins = s % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    }

    resendCode = () => {
        this.restartTimer();
        this.props.forgotPassword( this.props.username );
    }

    isValidated( values ) {
        this.props.confirmPassword( this.props.username, values.Verificationcode, values.confirmpassword );
    }

    render() {
        const { handleSubmit, isreset } = this.props;
        const { countdown } = this.state;
        return (
            <div className='content'>
                {/* { isreset && this.logout() } */}
                {!isreset && <Grid>
                    <Row>
                        <Col md={6} sm={8} mdOffset={3} smOffset={2} id="verifyForm-container">
                            <form onSubmit={handleSubmit( this.isValidated )} name="verify" id="verify">
                                <Card
                                    textCenter
                                    title="Reset Password"
                                    content={<>
                                        {countdown > ( standard_timeout - 15000 ) && <span className="text-success">Verification code sent to your registered email address<br /><br /></span>}
                                        <FormGroup controlId="Verificationcode">
                                            {formFieldHelper( 'A code has been sent to your registered email address, please check your and inbox / spam folder' )}
                                            <ControlLabel className="required-label" id="verifyForm-code-label">Verification Code</ControlLabel>
                                            <Field name="Verificationcode" type="text" className="form-control" id="Verificationcode" placeholder="Verification Code" component={renderInput} />
                                        </FormGroup>
                                        <FormGroup controlId="newpassword">
                                            {formFieldHelper( Tooltips.User.PasswordRequirement )}
                                            <ControlLabel className="required-label" id="verifyForm-password-label">New Password</ControlLabel>
                                            <Field name="newpassword" type="password" className="form-control" id="newpassword" placeholder="New Password" component={renderInput} />
                                        </FormGroup>
                                        <FormGroup controlId="confirmpassword">
                                            <ControlLabel className="required-label" id="verifyForm-confirmPassword-label">Confirm Password</ControlLabel>
                                            <Field name="confirmpassword" type="password" className="form-control" id="confirmpassword" placeholder="Confirm Password" component={renderInput} />
                                        </FormGroup>
                                        <br />
                                        <button className="btn btn-cancel btn-fill btn-wd" type="button"  id="verifyForm-resend-btn"
                                            onClick={() => this.resendCode()} disabled={countdown > 0}>
                                            Resend Code {countdown > 0 && `( ${this.msToTime( this.state.countdown )} )`}
                                        </button>
                                        <button className="btn btn-fill btn-wd pull-right" id="verifyForm-submit-btn" type="submit">Submit</button>
                                        <br />
                                    </>}
                                    ftTextCenter
                                />
                            </form>
                        </Col>
                    </Row>
                    <div className='clearfix'></div><br />
                </Grid>}
            </div>
        );
    }
}

const validate = values => {
    const errors = {};
    if ( !values.Verificationcode ) {
        errors.Verificationcode = 'Required!';
    }
    if ( !values.newpassword ) {
        errors.newpassword = 'Required!';
    }
    if ( !values.confirmpassword ) {
        errors.confirmpassword = 'Required!';
    }
    if ( values ) {
        if ( values.newpassword !== values.confirmpassword ) {
            errors.confirmpassword = 'Passwords must be same!';
        }
    }
    return errors;
};

Verify = reduxForm({
    form: 'Verify',
    validate
})( Verify );

// Mapping the current state props to ths following:
const mapStateToProps = ( state, _props ) => {
    return {
        isreset: state.auth.isreset
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        forgotPassword: ( username ) => dispatch( authActions.forgotPassword( username )),
        confirmPassword: ( username, verificationCode, newpassword ) => dispatch( authActions.confirmPassword( username, verificationCode, newpassword )),
        resetAuthProps: ( propsToReset ) => dispatch( authActions.resetAuthProps( propsToReset ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Verify ));
