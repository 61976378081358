import React from 'react';
import { Row, Col } from 'react-bootstrap';

import TextLoader from './TextLoader';
import IconLoader from './IconLoader';
import PageHeader from '../../components/layout/Cards/PageHeader';

const ListLoader = ({
    count,
    // width,
    heading,
    noOfIconCTA,
    type
}) => <>
    { type !== 'componentList' && <>
        <div className={`card list list-loader list-loader-${type}`}>

            <h4>{heading}</h4>
            { new Array( count ).fill( '' ).map(( _x, i ) => <div className="list-loader__components" key={i}>
                <Row>
                    <Col sm={9}>
                        <TextLoader lines={1} width={100} />
                        <TextLoader lines={1} width={30} />
                    </Col>
                    {
                        new Array( noOfIconCTA ).fill( '' ).map(( _x2, i2 ) => <Col sm={1}  key={i2}
                            className="list-loader__components-hasIconCTA">
                            <IconLoader width={20} height={20}/>
                            <TextLoader lines={1} width={65} />
                        </Col> )
                    }
                </Row>

            </div> )}

        </div>
    </>
    }
    { type === 'componentList' && <>
        { heading && <PageHeader title={heading} /> }
        <Col sm={12} className="list-loader__components-tabs">
            {
                new Array( 4 ).fill( '' ).map(( _x, i ) => <Col sm={3} key={i}>
                    <TextLoader lines={1} width={40} />
                </Col> )
            }
        </Col>
        <Col sm={12}>
            <div className="card">
                <Row>
                    {new Array( 12 ).fill( '' ).map(( _x, i ) => <Col sm={3} key={i}>
                        <div className={`list-loader__components list-loader-${type}`}>
                            <TextLoader lines={1} width={100} />
                            <TextLoader lines={1} width={75} />
                        </div>
                    </Col>

                    )}
                    <Col sm={5}>
                        <Col sm={5}>
                            <IconLoader width={20} height={20}/>
                        </Col>
                        <Col sm={11}>
                            <TextLoader lines={1} width={75} />
                        </Col>
                    </Col>
                </Row>
            </div>
        </Col>


    </>
    }

</>;

export default ListLoader;