import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, FormGroup,
    ControlLabel, Tabs, Tab } from 'react-bootstrap';

import ADPIcon from '../common/Icon';

import * as authActions from '../../modules/auth';

const QRCode = require( 'qrcode.react' );

class MFAModal extends React.Component {

    state = {
        totpCode: undefined
    }

    render() {
        const { globalConfig, secretCode, mfaError, fetchingMfa, profile, userName } = this.props;
        const { PROJECT_SHORT_NAME } = globalConfig;
        return ( <Row>
            <Col sm={12}>
                <small className='pull-right text-danger'>* required</small>
            </Col>
            <Col sm={12}>
                <Tabs defaultActiveKey={1} id="enbale-mfa-scan-options">
                    <Tab eventKey={1} title={'QR Code'} className="text-center">
                        <br/>
                        <QRCode
                            value={ encodeURI( `otpauth://totp/${PROJECT_SHORT_NAME}:${profile?.EmailId || userName}?secret=${secretCode}&issuer=${PROJECT_SHORT_NAME}` ) }
                            renderAs={'svg'}
                            level={'H'}
                            size={256}
                        />
                        <br/>
                        <blockquote className="pull-left text-justify small">Scan the above QR code in your 2FA app and enter the 6-digit code generated below to finalize MFA setup</blockquote>
                    </Tab>
                    <Tab eventKey={2} title={'Secret Code'} className="PATdisplay">
                        <div className="jumbotron">
                            <h4 className="text-primary" id="mfa-secretcode">{secretCode}</h4>
                        </div>
                        <blockquote className="pull-left text-justify small">Provide the secret code in your 2FA app and enter the 6-digit code generated below to finalize MFA setup</blockquote>
                    </Tab>
                </Tabs>
            </Col>
            <Col sm={12}>
            </Col>
            <Col sm={12}>
                <FormGroup controlId="totpCode">
                    <ControlLabel className="required-label" id="mfa-entercode-label">Enter code</ControlLabel>
                    <input type="text" className="form-control" id="mfa-entercode" onChange={( e ) => this.setState({ totpCode: e?.target?.value })} disabled={ !secretCode || fetchingMfa } />
                    { ( this.state.totpCode && !/^[0-9]{6}$/.test( this.state.totpCode )) && <p className="text-danger" id="mfa-invalidcode-error">Invalid code</p> }
                </FormGroup>
            </Col>
            { mfaError && <Col sm={12} className="text-danger" id="mfa-error">{mfaError}</Col> }
            <br />
            <Col sm={12}>
                <button onClick={() => this.props.verifySoftwareToken( userName, this.state.totpCode )} className="btn btn-fill pull-right" type="submit" id="mfa-submit-btn" disabled={ !this.state.totpCode }>
                    { fetchingMfa ? <ADPIcon icon="sync" spin/> : 'Verify Code' }
                </button>
            </Col>
        </Row>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        profile : state.account.user,
        userName: state.auth.userName,
        secretCode: state.auth.secretCode,
        fetchingMfa: state.auth.fetchingMfa || false,
        mfaError: state.auth.mfaError,
        globalConfig: state.globalConfig
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        verifySoftwareToken: ( username, token ) => dispatch( authActions.verifySoftwareToken( username, token ))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( MFAModal );
