import React from 'react';

const TextLoader = ({
    lines,
    width
}) => <>
    {
        new Array( lines ).fill( '' ).map(() => <div className="loader__text-row" key="" style={{ width: `${width}%`}} />
        )
    }
</>;

export default TextLoader;