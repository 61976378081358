import { respond, getHttp, urlBuilder, extractError } from './utils';
import { alertAndHide } from '../components/utils/popUpUtils';

import { USER_LOGOUT } from './common_actions';

const GET_DATASET_GRANTS = 'GET_DATASET_GRANTS';
const SET_DATASET_FETCHING = 'SET_DATASET_FETCHING';
const SET_DATASET_ERROR = 'SET_DATASET_ERROR';

const initialState = {
    datasetGrants: [],
    fetchingGrants: true,
    datasetGrantsError: undefined
};

export default ( state = initialState, action ) => {
    switch ( action.type ) {
    case GET_DATASET_GRANTS:
    case SET_DATASET_FETCHING:
    case SET_DATASET_ERROR:
        return {
            ...state,
            ...action.data
        };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export function getDatasetDetails() {
    return ( dispatch, getState ) => {
        const client = getHttp( dispatch, getState );
        const getGrantsDatasetAPI = urlBuilder(['users', 'reports', 'grants'], ['service=datasets']);
        dispatch({ type: SET_DATASET_FETCHING, data: { fetchingGrants: true } });

        return client.get( getGrantsDatasetAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: GET_DATASET_GRANTS,
                        data: {
                            datasetGrants: response.data.datasets_grants || [],
                            fetchingGrants: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DATASET_ERROR,
                    data: {
                        datasetGrantsError: extractError( error_obj ),
                        fetchingGrants: undefined
                    }
                });

                alertAndHide( 'error', extractError( error_obj ), '' );

                respond( 'getDatasetDetails', error_obj, dispatch );
            });
    };
}
