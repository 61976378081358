import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { Row, Col, Modal, FormGroup, InputGroup, FormControl, ControlLabel } from 'react-bootstrap';
// import FileViewer from 'react-file-viewer';

import ADPIcon from '../common/Icon';
import Card from '../layout/Cards/Card';
import Analytics from '../datasets/Analytics';
import { getFilePreview } from './FilePreview';
import ComponentError from '../errors/ComponentError';
import LoaderTemplate from '../loaders/LoaderTemplate';
import * as datasetActions from '../../modules/dataset';
import SortingSection from '../common/SortingSection';
import { paginator, withHelperText, formFieldHelper } from '../utils/renderUtils';
import Harmonium from '../utils/Harmonium';
import { formatFileName } from './FilesModal';
import { alertAndHide, showToast } from '../utils/popUpUtils';

const FILES_SORT_OPTIONS = {
    Name: 'FileName',
    LastModifiedTime: 'LastModifiedTime',
    LastModifiedBy: 'LastModifiedBy'
};

class DatasetFiles extends React.PureComponent {

    constructor( props ) {
        super( props );
        const { userPreferences, DEFAULT_SORT_PREFERENCE } = this.props.globalConfig;

        const {
            sortBy,
            resultsPerPage,
            sortOrder
        } = userPreferences;

        this.state = {
            permCopy: [],
            offset: 1,
            limit: resultsPerPage || DEFAULT_SORT_PREFERENCE.resultsPerPage,
            totalCount: 0,
            sortBy: FILES_SORT_OPTIONS[sortBy] || FILES_SORT_OPTIONS[DEFAULT_SORT_PREFERENCE.sortBy] || FILES_SORT_OPTIONS.Name,
            sortOrder: sortOrder || DEFAULT_SORT_PREFERENCE.sortOrder,
            pageCount: 0,
            pageSelected: 0,
            file: undefined,
            queryString: this.props.queryString || '',
            updateTagsForFile: undefined,
            currentTags: [],
            inputValue: ''
        };
    }

    componentDidMount() {
        if ( this.props.queryString ){
            this.loadFiles();
        }
    }

    componentWillUnmount() {
        this.props.resetDatasetProps({
            searchFilesResults: undefined
        });
    }

    componentDidUpdate( prevProps, prevState ) {
        const { searchFilesResults, tagsUpdateError, tagsUpdated } = this.props;
        if ( searchFilesResults && prevProps.searchFilesResults !== this.props.searchFilesResults ) {
            const { limit, offset } = this.state;
            if ( prevState.totalCount !== searchFilesResults.total_count ){
                this.setState({
                    totalCount: searchFilesResults.total_count, pageCount: Math.ceil( searchFilesResults.total_count / limit )
                });
            }
            this.setState({
                pageCount: Math.ceil( searchFilesResults.total_count / limit ),
                pageSelected: ( Math.ceil( offset / limit ) - 1 ),
                permCopy: searchFilesResults.files
            });
        }
        if ( tagsUpdateError ) {
            showToast( 'error', tagsUpdateError );
            this.props.resetDatasetProps({
                tagsUpdateError: undefined
            });
        }
        if ( tagsUpdated ){
            showToast( 'success', 'Tags updated successfully !!' );
            const newCopy = [...prevState.permCopy];
            const newTags = prevState?.currentTags?.map( t => t.value );
            if ( prevState?.permCopy?.filter( x => x.FileName === prevState.updateTagsForFile )?.length > 0 ){
                newCopy.filter( x => x.FileName === prevState.updateTagsForFile )[0].Tags = newTags;
            }
            this.setState({
                permCopy: [...newCopy],
                updateTagsForFile: undefined,
                currentTags: []
            }, () => {
                this.props.resetDatasetProps({
                    tagsUpdated: undefined
                });
            });
        }
    }

    loadFiles = () => {
        if ( this.state?.queryString?.length > 0 ){
            this.props.getDatasetFilesWithQuery( this.state.queryString, this.props.dataset.DatasetId, this.state.offset, this.state.limit, this.state.sortOrder, this.state.sortBy );
        } else {
            alertAndHide( 'warning', 'Empty Search Query', '' );
        }
    }

    handlePaginationHeaderUpdate = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value, offset: 1 }, () => {
            this.loadFiles();
        });
    }

    handlePageClick = ( data ) => {
        const offset = Math.ceil( data.selected * this.state.limit ) + 1;
        this.setState({ offset }, () => {
            this.loadFiles();
        });
    }

    handleKeyDown = ( event ) => {
        const { inputValue, currentTags = [] } = this.state;
        if ( !inputValue || currentTags?.map( x => x.value )?.includes( inputValue )) {return;}
        switch ( event.key ) {
        case 'Enter':
        case 'Tab':
            const tags = currentTags || [];
            this.setState({
                inputValue: '',
                currentTags: [...tags, ({ label: inputValue, value: inputValue })]
            });
            event.preventDefault();
            break;
        default:
            // do nothing
        }
    }

    render() {
        const { dataset, searchingDatasetFiles, searchFilesResults = { files: [] }, searchFilesError, globalConfig, modalSupport = false,
            updatingTags, fetchingFileAnalytics, fileAnalytics, fetchFileAnalyticsError } = this.props;
        return ( <>
            { !modalSupport && <Row>
                <Col sm={12}>
                    <Card
                        content={<form onSubmit={( e ) => { e.preventDefault(); this.loadFiles(); }} className="search">
                            <FormGroup>
                                <InputGroup>
                                    <FormControl type="text" placeholder="Search files / Search '*' for all files" name="queryString" className="searchInput" value={this.state.queryString} onChange={( e ) => this.setState({ queryString: e?.target?.value || '' })} title="Search Query Term" />
                                    <InputGroup.Button>
                                        <button className="searchbutton btn btn-fill" onClick={() => {}} disabled={this.state?.query?.length === 0}>Search</button>
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>
                        </form>
                        }
                        ctTextCenter
                    />
                </Col>
            </Row> }
            { searchingDatasetFiles
                ? <LoaderTemplate hasIcon={true} type={'innerList'} count={3} />
                : searchFilesError ? <ComponentError
                    error={searchFilesError}
                    recover={[
                        { cta: this.loadFiles, text: 'Reload Files' }
                    ]}/>
                    : searchFilesResults?.files ? <>
                        <Row className="paginationHeader">
                            <SortingSection
                                listingItems={searchFilesResults.files}
                                sortByOptions={Object.values( FILES_SORT_OPTIONS )}
                                sortByValue={this.state.sortBy}
                                sortOrderValue={this.state.sortOrder}
                                resultsPerPageValue={this.state.limit}
                                isSectionDisabled={searchingDatasetFiles || this.state.totalCount === 0 || searchFilesError }
                                onSortFunction={this.handlePaginationHeaderUpdate}
                                sortOrderOptions={globalConfig['PREFERENCES_SORT_ORDER']}
                                resultsPerPageOptions={globalConfig['PREFERENCES_RESULTS_NO']} />

                            { !searchFilesError && !searchingDatasetFiles && this.state.totalCount > 0 && <Col sm={12}>
                                <p className="text-primary">showing {this.state.offset} - {( parseInt( this.state.offset, 10 ) + parseInt( searchFilesResults.files.length, 10 ) - 1 )} of {this.state.totalCount} record(s)</p>
                                { this.state.pageCount > 0 && paginator( this.state.pageSelected, this.state.pageCount, this.handlePageClick ) }
                            </Col> }
                        </Row>
                        { searchFilesResults.files.length > 0 ? <Row id="datasetSearch-datasetFiles-fullDisplay-container">
                            <Col sm={12}>
                                <table className="table table-bordered responsive" id="datasetSearch-datasetFiles-fullDisplay-table">
                                    <thead className="bg-primary">
                                        <tr>
                                            <th width="10%">File Name</th>
                                            <th width="55%">Comprehend</th>
                                            <th witdh="25%">Tags</th>
                                            <th width="10%" className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.permCopy.map(( file, index ) => <tr key={index}>
                                            <td width="15%">{ formatFileName( file.FileName ) }</td>
                                            <td width="45%">
                                                <Harmonium initial={180} step={150}>
                                                    { file?.KeyPhrases?.length > 0
                                                        ? file.KeyPhrases.map(( keyPhrase, kindex ) => <div className="git-badges" key={kindex}>
                                                            <div className="git-badge key">{keyPhrase.Value}</div>
                                                            <div className="git-badge value">{keyPhrase.Count}</div>
                                                        </div> )
                                                        : <div className="git-badges">
                                                            <div className="git-badge badge-key">No KeyPhrases Found!!</div>
                                                        </div>
                                                    }
                                                </Harmonium>
                                            </td>
                                            <td width="25%">
                                                { this.state.updateTagsForFile === file.FileName
                                                    ? <>
                                                        <FormGroup controlId="Tags">
                                                            { formFieldHelper( 'Enter tag and hit enter/tab' ) }
                                                            <ControlLabel className="required-label">Tags</ControlLabel>
                                                            <CreatableSelect
                                                                inputValue={this.state.inputValue}
                                                                isClearable
                                                                isMulti
                                                                onChange={( currentTags = []) => this.setState({ currentTags })}
                                                                onInputChange={( inputValue ) => this.setState({ inputValue })}
                                                                onKeyDown={this.handleKeyDown}
                                                                placeholder="Type something and press enter..."
                                                                noOptionsMessage={() => 'Type Something and press enter'}
                                                                value={this.state.currentTags}
                                                                classNamePrefix="select"
                                                                className='z-priority'
                                                                disabled={updatingTags}
                                                                options={[]}
                                                            />
                                                        </FormGroup>
                                                        <br/><br/>
                                                        <div>
                                                            <button className="btn btn-fill btn-success btn-xs pull-right padded" disabled={updatingTags} onClick={() => this.props.updateTagsForFile( this.props.dataset.DatasetId, [this.state.updateTagsForFile], this.state?.currentTags?.map( t => t.value ) || [])}>
                                                                <ADPIcon icon={updatingTags ? 'sync' : 'upload'} spin={updatingTags}/> Update &nbsp;
                                                            </button>
                                                            <button className="btn btn-fill btn-cancel btn-xs pull-right padded" disabled={updatingTags} onClick={() => this.setState({ updateTagsForFile: undefined, inputValue: '', currentTags: []})}>
                                                                <ADPIcon icon={'ban'} /> Cancel &nbsp;
                                                            </button>&nbsp;
                                                        </div>
                                                    </>
                                                    : <>
                                                        { file.Tags?.length > 0
                                                            ? file.Tags.map(( tag, tindex ) => <div className="git-badges" key={tindex}>
                                                                <div className="git-badge badge-key">{tag}</div>
                                                            </div>
                                                            )
                                                            : <span className="badge space-well badge-dark">No Tags Found!!</span>
                                                        }
                                                        <div className="tags-edit-btn-container">
                                                            <button className="btn btn-fill btn-info btn-xs btn-round" onClick={() => this.setState({ updateTagsForFile: file.FileName, currentTags: file?.Tags?.map( t => ({ label: t, value: t })) })}>
                                                                &nbsp;<ADPIcon icon="edit" /> Update Tags &nbsp;
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </td>
                                            <td width="15%" className="file-preview-options">
                                                <button className="btn btn-fill btn-xs" onClick={() => this.setState({ file })}>
                                                    { withHelperText( 'Preview', <ADPIcon icon="eye"></ADPIcon>, 'bottom' ) }
                                                </button>&nbsp;
                                                <button className="btn btn-fill btn-xs" onClick={() => {
                                                    this.setState({
                                                        viewAnalyticsModal: true
                                                    }, () => this.props.getAnalyticsOnFile( dataset.DatasetId, file.FileName ));
                                                }}>
                                                    { withHelperText( 'Complete Analytics', <ADPIcon icon="analytics"></ADPIcon>, 'bottom' ) }
                                                </button>&nbsp;
                                                <button className="btn btn-fill btn-xs" onClick={() => window.open( file.PresignedURL, '_blank' )}>
                                                    { withHelperText( 'Download', <ADPIcon icon="download"></ADPIcon>, 'bottom' ) }
                                                </button>
                                            </td>
                                        </tr> )}
                                    </tbody>
                                </table>
                                <Modal
                                    show={ typeof this.state.file !== 'undefined'}
                                    onHide={() => this.setState({ file: undefined })}
                                    id="datasetSearch-datasetFiles-fullDisplay-preview-modal"
                                    backdropClassName={'modal-level2'}
                                    aria-labelledby="ModalHeader">
                                    <Modal.Header closeButton>
                                        <Modal.Title id='ModalHeader'>{ formatFileName( this.state?.file?.FileName ) }</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Col sm={12}>
                                            { this.state.file && getFilePreview( this.state?.file?.FileName, this.state?.file?.PresignedURL, 'file-0' ) }
                                        </Col>
                                    </Modal.Body>
                                </Modal>
                                <Modal
                                    show={this.state.viewAnalyticsModal}
                                    onHide={() => this.setState({ viewAnalyticsModal: false })}
                                    backdropClassName={'modal-level2'}
                                    className="modal-large"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>AI/ML Results</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col sm={12}>
                                                {fetchingFileAnalytics
                                                    ? <LoaderTemplate type="componentList" />
                                                    : <Row>
                                                        {fetchFileAnalyticsError ? <Card
                                                            title="Error loading file analytics"
                                                            content={<p>{fetchFileAnalyticsError}</p>}
                                                            textCenter
                                                            ctTextCenter
                                                        /> : <Col sm={12}>
                                                            {fileAnalytics && fileAnalytics.analytics && fileAnalytics.analytics.Status === 'Unavailable'
                                                                ? <Card title="No advanced file analytics found for this file" textCenter />
                                                                : <Analytics data={fileAnalytics} />
                                                            }
                                                        </Col>
                                                        }
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </Col>
                        </Row> : <Row>
                            <Col sm={12}>
                                <Card ctTextCenter>No results found for the given search term <b className="text-primary">{ this.state.queryString }</b></Card>
                            </Col>
                        </Row>
                        }
                    </> : <Row>
                        <Col sm={12}>
                            <Card title="Search for files" extraClasses="text-center" ctTextCenter>
                                You can search in Tags, Filenames, metadata, AI/ML results and more !!
                            </Card>
                        </Col>
                    </Row>
            }
        </> );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        globalConfig: state.globalConfig,
        searchingDatasetFiles: state.dataset.searchingDatasetFiles,
        searchFilesResults: state.dataset.searchFilesResults,
        searchFilesError: state.dataset.searchFilesError,
        updatingTags: state.dataset.updatingTags,
        tagsUpdated: state.dataset.tagsUpdated,
        tagsUpdateError: state.dataset.tagsUpdateError,
        fetchingFileAnalytics: state.dataset.fetchingFileAnalytics,
        fileAnalytics: state.dataset.fileAnalytics,
        fetchFileAnalyticsError: state.dataset.fetchFileAnalyticsError
    };
};

const mapDispatchToProps = {
    getDatasetFilesWithQuery: datasetActions.getDatasetFilesWithQuery,
    updateTagsForFile: datasetActions.updateTagsForFile,
    getAnalyticsOnFile: datasetActions.getAnalyticsOnFile,
    resetDatasetProps: datasetActions.resetDatasetProps
};

export default connect( mapStateToProps, mapDispatchToProps )( DatasetFiles );