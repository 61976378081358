import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';

import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';
import * as authActions from '../../modules/auth';
import { showLoading } from '../utils/popUpUtils';
import Tooltips from '../../mappings/helper_tooltips.json';
import { renderInput, formFieldHelper } from '../utils/renderUtils';

class Forcereset extends React.Component {

    constructor( props ) {
        super( props );
        this.isValidatedform = this.isValidatedform.bind( this );
    }

    componentDidUpdate() {
        const { passwordchanged } = this.props;
        if ( passwordchanged ) {  this.logout(); }
    }

    isValidatedform( values ){
        showLoading();
        const userAttributes = {};
        userAttributes.name = values.Fullname;
        this.props.forcepassword( values.Newpassword, userAttributes, this.props.username, this.props.sessionId );
    }

    logout(){
        Swal.fire({
            title: 'Password Successfully Changed!',
            text: 'Please login with the new password',
            type: 'success',
            onClose: () => {
                this.props.history.push( '/' );
            }
        });
    }

    render() {
        const { handleSubmit, passwordchanged } = this.props;
        return ( <Page title={ 'Password change required' }>
            { !passwordchanged && <Row>
                <Col sm={8} smOffset={2} id="forceresetForm-container">
                    <Card
                        category="Administrator has requested a mandatory password change, Please update your password now !!"
                        content={ <Row>
                            <Col sm={12}>
                                <small className='pull-right text-danger'>* required</small>
                            </Col>
                            <form  onSubmit={handleSubmit( this.isValidatedform )} name="forcereset" id="forcereset">
                                <Col sm={12}>
                                    <Col sm={12} md={12}>
                                        <FormGroup controlId="Fullname">
                                            <ControlLabel className="required-label" id="forceresetForm-fullname-label">Full Name </ControlLabel>
                                            <Field name="Fullname" type="text" className="form-control" id="Fullname" placeholder="Full Name" component={renderInput}  />
                                        </FormGroup>
                                        <div className="clearfix"></div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <FormGroup controlId="New Password">
                                            { formFieldHelper( Tooltips.User.PasswordRequirement ) }
                                            <ControlLabel className="required-label" id="forceresetForm-password-label">New Password </ControlLabel>
                                            <Field name="Newpassword" type="password" className="form-control" id="Newpassword" placeholder="New Password" component={renderInput}  />
                                        </FormGroup>
                                        <div className="clearfix"></div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <FormGroup controlId="Confirm Password">
                                            <ControlLabel className="required-label" id="forceresetForm-confirmPasswrod-label">Confirm Password </ControlLabel>
                                            <Field name="ConfirmPassword" type="password" className="form-control" id="ConfirmPassword" placeholder="Confirm Password" component={renderInput}  />
                                        </FormGroup>
                                        <div className="clearfix"></div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <LinkContainer to="/"><button className="btn btn-cancel btn-fill" id="forceresetForm-cancel-btn">Cancel</button></LinkContainer>
                                        <button className="btn btn-fill pull-right" id="forceresetForm-submit-btn" type="submit">Change Password</button>
                                    </Col>
                                </Col>
                            </form>
                        </Row>
                        }
                    />
                </Col>
            </Row>}
        </Page>
        );
    }
}

const validate = values => {
    const errors = {};
    if ( !values.Fullname ) {
        errors.Fullname = 'Required!';
    }
    if ( !values.Newpassword ) {
        errors.Newpassword = 'Required!';
        // eslint-disable-next-line
    } else if ( !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]\{\}\(\)\?\-!@#%&/\\><':;|_~`])(?=.{8,99})/.test( values.Newpassword )) {
        errors.Newpassword = 'Password does not meet requirements';
    }
    if ( !values.ConfirmPassword ) {
        errors.ConfirmPassword = 'Required!';
    }
    if ( values ){
        if ( values.Newpassword !== values.ConfirmPassword ){
            errors.ConfirmPassword = 'Passwords must match !';
        }
    }

    return errors;
};

Forcereset = reduxForm({
    form: 'forcereset',
    validate
})( Forcereset );

const mapStateToProps = ( state, _props ) => {
    return {
        passwordchanged: state.auth.isupdated,
        username: state.auth.userName,
        sessionId: state.auth.sessionId
    };
};


const mapDispatchToProps = ( dispatch ) => {
    return {
        forcepassword: ( newpassword, userAttributes, username, sessionId ) => dispatch( authActions.forcepassword( newpassword, userAttributes, username, sessionId ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Forcereset ));
