import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';

const CHANGE_DASHBOARDS_FETCHING = 'CHANGE_DASHBOARDS_FETCHING';
const FETCH_DASHBOARDS_SUCCESS = 'FETCH_DASHBOARDS_SUCCESS';
const REGISTER_DASHBOARD_SUCCESS = 'REGISTER_DASHBOARD_SUCCESS';
const SET_DASHBOARDS_ERROR = 'SET_DASHBOARDS_ERROR';
const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
const RESET_DASHBOARDS_ERROR = 'RESET_DASHBOARDS_ERROR';
const CHANGE_DASHBOARD_FETCHING = 'CHANGE_DASHBOARD_FETCHING';
const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
const SET_DASHBOARD_ERROR = 'SET_DASHBOARD_ERROR';
const CHANGE_DASHBOARDS_AUTH_USERS_FETCHING = 'CHANGE_DASHBOARDS_AUTH_USERS_FETCHING';
const FETCH_DASHBOARD_AUTHORIZED_USERS_SUCCESS = 'FETCH_DASHBOARD_AUTHORIZED_USERS_SUCCESS';
const SET_DASHBOARDS_AUTHORIZED_USERS_ERROR = 'SET_DASHBOARDS_AUTHORIZED_USERS_ERROR';
const DELETE_DASHBOARDS_USER_ACCESS_SUCCESS = 'DELETE_DASHBOARDS_USER_ACCESS_SUCCESS';
const UPDATE_DASHBOARDS_USER_ACCESS_SUCCESS = 'UPDATE_DASHBOARDS_USER_ACCESS_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case CHANGE_DASHBOARDS_FETCHING :
    case FETCH_DASHBOARDS_SUCCESS :
    case REGISTER_DASHBOARD_SUCCESS :
    case SET_DASHBOARDS_ERROR :
    case DELETE_DASHBOARD_SUCCESS :
    case RESET_DASHBOARDS_ERROR :
    case CHANGE_DASHBOARD_FETCHING :
    case FETCH_DASHBOARD_SUCCESS :
    case SET_DASHBOARD_ERROR :
    case CHANGE_DASHBOARDS_AUTH_USERS_FETCHING :
    case FETCH_DASHBOARD_AUTHORIZED_USERS_SUCCESS :
    case SET_DASHBOARDS_AUTHORIZED_USERS_ERROR :
    case DELETE_DASHBOARDS_USER_ACCESS_SUCCESS :
    case UPDATE_DASHBOARDS_USER_ACCESS_SUCCESS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default :
        return state;
    }
};

export const getApplicableDashboards = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const dashboardsAPI = urlBuilder([ 'dashboards' ]);
        dispatch({ type: CHANGE_DASHBOARDS_FETCHING, data: { fetchingDashboards: true, fetchDashboardsError: undefined } });
        return client.get( dashboardsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DASHBOARDS_SUCCESS,
                    data: {
                        dashboardsList: response.data.dashboards,
                        fetchingDashboards: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DASHBOARDS_ERROR,
                    data: {
                        fetchDashboardsError: extractError( error_obj ),
                        fetchingDashboards: undefined
                    }
                });
                respond( 'getApplicableDashboards', error_obj, dispatch );
            });
    };
};

export const getDashboardDetails = ( dashboardId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const dashboardDetailsAPI = urlBuilder([ 'dashboards', dashboardId ]);
        dispatch({ type: CHANGE_DASHBOARD_FETCHING, data: { fetchingDashboardDetails: true, fetchDashboardError: undefined } });
        return client.get( dashboardDetailsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DASHBOARD_SUCCESS,
                    data: {
                        dashboard: response.data,
                        fetchingDashboardDetails: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DASHBOARD_ERROR,
                    data: {
                        fetchDashboardError: extractError( error_obj ),
                        fetchingDashboardDetails: undefined
                    }
                });
                respond( 'getDashboardDetails', error_obj, dispatch );
            });
    };
};


export const registerNewDashboard = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const registerDashboardsAPI = urlBuilder([ 'dashboards' ]);
        return client.post( registerDashboardsAPI, requestBody )
            .then( response => {
                dispatch({
                    type: REGISTER_DASHBOARD_SUCCESS,
                    data: {
                        drSuccess: response.data
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DASHBOARDS_ERROR,
                    data: { drError: extractError( error_obj ) }
                });
                respond( 'registerNewDashboard', error_obj, dispatch );
            });
    };
};

export const editDashboard = ( dashboardId, requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const editDashboardAPI = urlBuilder([ 'dashboards', dashboardId ]);
        return client.put( editDashboardAPI, requestBody )
            .then( _response => {
                dispatch({
                    type: REGISTER_DASHBOARD_SUCCESS,
                    data: {
                        dashboardUpdateSuccess: true,
                        dashboard: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DASHBOARDS_ERROR,
                    data: { dashboardUpdateError: extractError( error_obj ) }
                });
                respond( 'editDashboard', error_obj, dispatch );
            });
    };
};

export const deleteDashboard = ( dashboardId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteDashboardsAPI = urlBuilder([ 'dashboards', dashboardId ]);
        return client.delete( deleteDashboardsAPI )
            .then( response => {
                dispatch({
                    type: DELETE_DASHBOARD_SUCCESS,
                    data: {
                        deleteDashboardSuccess: response,
                        dashboard: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DASHBOARDS_ERROR,
                    data: { deleteDashboardError: extractError( error_obj ) }
                });
                respond( 'deleteDashboard', error_obj, dispatch );
            });
    };
};

export const getAuthorizations = ( dashboardId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder([ 'dashboards', dashboardId, 'authorizedusers' ]);
        dispatch({ type: CHANGE_DASHBOARDS_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DASHBOARD_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_DASHBOARDS_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( requestBody, dashboardId, userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder([ 'dashboards', dashboardId, 'users', userId, 'grants' ]);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                dispatch({
                    type: UPDATE_DASHBOARDS_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DASHBOARDS_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( userId, dashboardId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder([ 'dashboards', dashboardId, 'users', userId, 'grants' ]);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                dispatch({
                    type: DELETE_DASHBOARDS_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DASHBOARDS_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetDashboardsProps = ( propsToReset = {}) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_DASHBOARDS_ERROR, data: propsToReset });
    };
};