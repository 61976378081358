import React, { useState, useEffect } from 'react';
import {
    Modal,
    PanelGroup,
    Panel,
    Row,
    Col
} from 'react-bootstrap';

import Card from '../layout/Cards/Card';
import DatasetFiles from './DatasetFiles';
import Analytics from '../datasets/Analytics';
import ADPIcon from '../../components/common/Icon';
import { withHelperText } from '../utils/renderUtils';
import LoaderTemplate from '../loaders/LoaderTemplate';
import Switch from '../../components/common/Switch';

export const formatFileName = ( obj ) => {
    return decodeURI(( /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}_[0-9]*_(.*)/.exec( obj ) || []).pop()) ||
                                                                            ( obj?.split( '/' )[3]?.split( '_' )?.slice( 3 )?.join( ' ' )) || obj || '';
};

const FilesModal = ({
    isVisible,
    onHide,
    dataset = {},
    filedownloadAction,
    fileAnalyticsAction,
    fileAnalyticsObj = {},
    queryString
}) => {
    const [ fullDetails, setFullDetails ] = useState( !isVisible );
    useEffect(() => {
        if ( !isVisible ){
            setFullDetails( false );
        }
    }, [isVisible]);
    return <Modal
        show={isVisible}
        onHide={onHide}
        // backdrop="static"
        aria-labelledby="ModalHeader"
        className={`fixed-modal__scrollable ${ fullDetails ? 'modal-extra-large' : ''}`}>
        <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Matched Files</Modal.Title>
            <p className="text-muted">Following are the files that match <b className="text-primary">{queryString}</b> in the <b className="text-primary">{dataset.DatasetName}</b> dataset </p>
            <Switch onClick={( toggleStatus ) => setFullDetails( toggleStatus )}
                checkedLabel="Full Details"
                unCheckedLabel="Full Details"
                isCheckedByDefault={ false }
            />
        </Modal.Header>
        <Modal.Body scrollable="true" style={{ height: fullDetails ? '75vh' : '500px' }}>
            <Col sm={12}>
                { fullDetails
                    ? <DatasetFiles dataset={dataset} queryString={queryString} modalSupport={true} />
                    : <PanelGroup accordion id="datasetSearch-matchedFiles-panelGroup">
                        { dataset?.Files?.length > 0 && dataset.Files.map(( obj, index ) => <Panel key={`panel_${index}`} eventKey={`panel_${index}`}>
                            <Panel.Heading>
                                <button className="btn btn-fill btn-xs pull-right" onClick={() => filedownloadAction( dataset.DatasetId, obj )}>
                                    { withHelperText( 'Download', <ADPIcon icon="download"></ADPIcon>, 'bottom' ) }
                                </button>
                                <Panel.Toggle componentClass="a" className="pull-right btn btn-fill btn-xs">
                                    { withHelperText( 'View AI/ML analytics', <ADPIcon icon="analytics"></ADPIcon>, 'left' ) }
                                </Panel.Toggle>
                                <Panel.Title>{formatFileName( obj )}</Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse onEnter={() => fileAnalyticsAction( dataset.DatasetId, obj )}>
                                <Panel.Body>
                                    { fileAnalyticsObj.fetchingFileAnalytics
                                        ? <LoaderTemplate type="componentList" />
                                        : <Row>
                                            { fileAnalyticsObj.fetchFileAnalyticsError ? <Card
                                                title="Error loading file analytics"
                                                content={<p>{fileAnalyticsObj.fetchFileAnalyticsError}</p>}
                                                textCenter
                                                ctTextCenter
                                            /> : <Col sm={12}>
                                                { fileAnalyticsObj.fileAnalytics && <>
                                                    { fileAnalyticsObj?.fileAnalytics && fileAnalyticsObj?.fileAnalytics?.analytics && fileAnalyticsObj?.fileAnalytics?.analytics?.Status === 'Unavailable'
                                                        ? <Card title="No advanced file analytics found for this file" textCenter />
                                                        : <Analytics data={fileAnalyticsObj.fileAnalytics} />
                                                    }
                                                </>}
                                            </Col>
                                            }
                                        </Row>
                                    }
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel> )}
                    </PanelGroup>
                }
            </Col>
        </Modal.Body>
    </Modal>;
};

export default FilesModal;