import React from 'react';
import { abbrNumber } from '../../utils/renderUtils';

const StatsCard = ({
    title,
    icon,
    statsValue,
    legend
}) =>  <div className="card card-stats card-dashboard">
    <div className="content">
        <div className="row">
            <div className="col-xs-12">
                <p className="title">{title}</p>
            </div>
            <div className="col-xs-12">
                <div className="numbers">
                    {icon}{ isNaN( statsValue ) ? statsValue : abbrNumber( statsValue ) }
                </div>
            </div>
        </div>
    </div>
    <div className="footer">
        <hr />
        <div className="stats">
            {legend}
        </div>
    </div>
</div>;

export default StatsCard;