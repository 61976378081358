import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import 'swagger-ui/dist/swagger-ui.css';

import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';
import PageHeaderButtons from '../layout/Cards/PageHeaderButtons';
import configuration from '../../config.json';
import swaggerFile from './cwdl-api-definition.yaml';

class APIDocs extends React.Component {
    componentDidMount() {
        window.noZensmooth = true;
        if ( window.noZensmooth ) {
            import( 'swagger-ui' )
                .then(( SwaggerUI ) => {
                    SwaggerUI.default({
                        dom_id: '#api-docs',
                        url: swaggerFile,
                        defaultModelsExpandDepth: -1,
                        deepLinking: true,
                        docExpansion: 'list'
                    });
                });
        }
    }
    render() {
        return (( this.props.user && this.props.sessionActive ) ?
            <Page
                title="API Documentation"
                actions={
                    <PageHeaderButtons id="apidocs"
                        listOfButtons={
                            [
                                {
                                    hasAccess: true,
                                    title: 'Back',
                                    icon: 'back',
                                    onClick: () => this.props.history.go( -1 )
                                }
                            ]
                        }
                    />
                }
            >
                <Row>
                    <Col sm={12}>
                        <Card>
                            <blockquote id="apidocs-baseurl-header"><b>Base URI:</b> {configuration.API_gateway.slice( 0, -1 )}</blockquote>
                            <div id="api-docs" />
                        </Card>
                    </Col>
                </Row>
            </Page >
            : <div>
                <Row>
                    <Col xs={10} md={8} xsOffset={1} mdOffset={2}>
                        <Card>
                            <blockquote id="apidocs-baseurl-header"><b>Base URI:</b> {configuration.API_gateway.slice( 0, -1 )}</blockquote>
                            <div id="api-docs" />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        sessionActive: state.auth.sessionActive || false
    };
};

export default connect( mapStateToProps, null )( APIDocs );