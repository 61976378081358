import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ADPIcon from '../common/Icon';

import SG from './DNL01.js';
import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';

class PageNotFound extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            play: false
        };
    }

    render() {
        const { permanentPaths = {} } = this.props.globalConfig;
        return ( <Page
            title={permanentPaths.notFound.name}
            content={ this.state.play ? <Row>
                <Col sm={12} md={8} mdOffset={2}>
                    <Card
                        content={ <Row>
                            <Col sm={12}>
                                <button className="btn btn-danger btn-fill pull-left" onClick={() => this.setState({ play: false })}><ADPIcon icon="times" /> &nbsp;Close Game</button>
                            </Col>
                            <Col sm={12}>
                                <SG size={600}/>
                            </Col>
                        </Row>
                        }
                        ctTextCenter
                    />
                </Col>
            </Row>
                : <Row>
                    <Col sm={10} md={6} smOffset={1} mdOffset={3}>
                        <br/><br/>
                        <Card
                            extraClasses={'text-center'}
                            title={<span className="text-center text-bold text-info">
                                <br/>
                                <span onClick={() => this.setState({ play: true })}>
                                    <ADPIcon icon="game-console-handheld" size="5x" className="text-danger" />
                                    <span className="fa-layers-text text-muted text-bold" style={{ marginTop: '-110px' }}>404</span>
                                </span>
                            </span>}
                            content={<Row>
                                <Col sm={12} className="text-muted">
                                    <h3 className="text-info text-bold">Page Not Found !!</h3>
                                    <p>The page you are trying to access does not exist or is moved to a different location. Please check the path and try again !!</p>
                                    <br/>
                                </Col>
                            </Row>}
                            legend={<LinkContainer to="/">
                                <button className="btn btn-fill btn-wd">Return to Home</button>
                            </LinkContainer>}
                            ftTextCenter
                        />
                    </Col>
                </Row>
            }
        /> );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        globalConfig: state.globalConfig
    };
};

export default connect( mapStateToProps, null )( PageNotFound );
