import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Row, Col, Modal, Form, FormGroup, ControlLabel } from 'react-bootstrap';

import ListCard from '../layout/Cards/ListCard';
import { showLoading } from '../utils/popUpUtils';
import { convertToLocalTime } from '../utils/dateUtils';
import * as datasetActions from '../../modules/dataset';
import LoaderTemplate from '../loaders/LoaderTemplate';

// This Component returns the tabs with Access Requests and Access Approvals on that particualr user and also manages approval or rejection of request.
class AccessRequestsComponent extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            rejectionComments: '',
            approvalComments: '',
            rejectpopup: false,
            approvepopup: false,
            filter: 'received'
        };
        this.handleChange = this.handleChange.bind( this );

        this.showMessage = ( type, title, msg, resetProp ) => {
            const propsHandler = this.props;
            Swal.fire({
                title,
                type,
                text: msg ? msg : '',
                allowOutsideClick: false,
                allowEscapeKey: false,
                onOpen: () => {
                    Swal.hideLoading();
                }
            }).then(( _result ) => {
                propsHandler.resetDatasetProps({
                    [resetProp]: undefined
                });
                this.resetState();
                propsHandler.getlistofgrantaccess();
            });
        };
    }

    componentDidMount() {
        if ( !this.props.grantrequestdata ){
            this.props.getlistofgrantaccess();
        }
    }

    componentDidUpdate() {
        const { requestError, requestApproved, requestRejected, requestDeletedSuccess, requestDeletedError } = this.props;
        if ( requestError ) { this.showMessage( 'error', 'Request Failed', '', 'requestError' ); }
        if ( requestApproved ) { this.showMessage( 'success', 'Request Approved', '', 'requestApproved' ); }
        if ( requestRejected ) { this.showMessage( 'success', 'Request Rejected', '', 'requestRejected' ); }
        if ( requestDeletedSuccess ) { this.showMessage( 'success', 'Request Deleted', '', 'requestDeletedSuccess' ); }
        if ( requestDeletedError ) { this.showMessage( 'error', 'Request Failed', '', 'requestDeletedError' ); }
    }

    resetState = () => {
        this.setState({
            rejectionComments: '',
            approvalComments: '',
            rejectpopup: false,
            approvepopup: false
        });
    }

    displayModal = ( datasetId, requestId, modal, display = false ) => {
        if ( display ) {
            this.setState({
                [modal]: true,
                RequestId: requestId,
                DatasetId: datasetId
            });
        } else {
            this.resetState();
        }
    }

    handleChange( e ) {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    approveRequest = () => {
        if ( !this.state.approvalComments || this.state.approvalComments === '' ) {
            Swal.fire( 'Incomplete request', 'Required fields are missing, please try again!', 'error' );
        } else {
            showLoading();
            this.props.approveRequest(
                {
                    'Comment': this.state.approvalComments,
                    'RequestId': this.state.RequestId
                },
                this.state.DatasetId
            );
        }
    }

    rejectRequest = () => {
        if ( !this.state.rejectionComments || this.state.rejectionComments === '' ) {
            Swal.fire( 'Incomplete request', 'Required fields are missing, please try again!', 'error' );
        } else {
            showLoading();
            this.props.rejectRequest(
                JSON.stringify({
                    'Comment': this.state.rejectionComments,
                    'RequestId': this.state.RequestId
                }),
                this.state.DatasetId
            );
        }
    }

    deleteRequest = ( requestId ) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Request will be deleted permanently.',
            type: 'question',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete request !!'
        }).then(( result ) => {
            if ( result.value ) {
                showLoading();
                this.props.deleteRequest( this.props.user.UserId, requestId );
            }
        });
    }

    render() {
        const initialize = {
            AccessRequests: { pending: [] },
            AccessApprovals: { pending: [] }
        };
        const { grantrequestdata = initialize, fetchGrantRequestsError, fetchingGrantRequests, silentFetching } = this.props;
        return ( <Row>
            <Modal
                show={this.state.approvepopup}
                onHide={() => this.displayModal()}
                aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader'>Approve Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <Form onSubmit={this.handleSubmit}>
                                <FormGroup controlId="Comments">
                                    <ControlLabel className="required-label">Approval Comments</ControlLabel>
                                    <textarea type="textarea" rows="5" name="approvalComments" className="form-control" id="approvalComments" placeholder="Reason for approving the access request" value={this.state.approvalComments} onChange={this.handleChange} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm={12}>
                            <button className='btn btn-fill pull-right' onClick={this.approveRequest}>Approve</button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                show={this.state.rejectpopup}
                onHide={() => this.displayModal()}
                aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader'>Reject Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <Form onSubmit={this.handleSubmit}>
                                <FormGroup controlId="Comments">
                                    <ControlLabel className="required-label">Rejection Comments</ControlLabel>
                                    <textarea type="textarea" rows="5" name="rejectionComments" className="form-control" id="rejectionComments" placeholder="Reason for rejecting the access request" value={this.state.rejectionComments} onChange={this.handleChange} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm={12}>
                            <button className='btn btn-fill pull-right' onClick={this.rejectRequest}>Decline Request</button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Col sm={12}>
                <ListCard _title={<span>
                    Access Requests &nbsp;
                    <select value={this.state.filter} onChange={( e ) => this.setState({ filter: e.target.value })}>
                        <option value="sent">Sent</option>
                        <option value="received">Received</option>
                    </select>
                </span>}
                _caller={this.props.caller}
                _titleCta={{ text: 'Reload', icon: 'refresh', loadingState: silentFetching || fetchingGrantRequests, callback: () => this.props.getlistofgrantaccess() }}
                _contentType="activity" _options={{ scroll: true, columnsCount: 3, header: true, columnWidths: [65] }}
                _list={[]}
                />
            </Col>
            <Col sm={12}>
                {/* eslint-disable */}
                {fetchingGrantRequests ? <LoaderTemplate hasIcon={false}
                    type={'listsInSection'}
                    count={3}
                    noOfIconCTA={2}
                /> : <ListCard
                        _showNoRecords={true}
                        _caller={this.props.caller}
                        _contentType="activity" _options={{
                            scroll: this.props.caller === 'dashboard',
                            columnsCount: this.state.filter === 'sent' ? 2 : 3,
                            header: true, columnWidths: this.state.filter === 'sent' ? [80, 20] : [65, 20, 15]
                        }}
                        _list={fetchGrantRequestsError ? [] : (
                            this.state.filter === 'sent'
                                ? grantrequestdata.AccessRequests.pending.map((record, _index) => {
                                    return [<span><b>Dataset:</b> {record.DatasetName || record.DatasetId} <i>{record.LastModified ? `( On ${convertToLocalTime(record.LastModified)} )` : ''}</i></span>,
                                    record.Comment || '---', 'trash',
                                    {
                                        label: `Delete Request`,
                                        className: 'text-warning',
                                        callback: () => this.deleteRequest(record.RequestId)
                                    }];
                                })
                                : grantrequestdata.AccessApprovals.pending.map((record, _index) => {
                                    return [<span>
                                        {record.DatasetName || record.DatasetId}
                                        <i>{record.UserId ? `( From ${record.UserId} )` : ''}</i>
                                    </span>,
                                    record.Comment || '---', 'check-circle',
                                    {
                                        label: `Approve ${record.AccessType} access`,
                                        callback: () => this.displayModal(record.DatasetId, record.RequestId, 'approvepopup', true)
                                    },
                                        'times-circle', {
                                        label: 'Decline Request',
                                        callback: () => this.displayModal(record.DatasetId, record.RequestId, 'rejectpopup', true)
                                    }
                                    ];
                                })
                        )}
                    />}
                {/* eslint-enable */}
            </Col>
        </Row> );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        grantrequestdata: state.dataset.grantrequestlist,
        fetchingGrantRequests: state.dataset.fetchingGrantRequests,
        silentFetching: state.dataset.silentFetching,
        fetchGrantRequestsError: state.dataset.fetchGrantRequestsError,
        requestError: state.dataset.requestError,
        requestRejected: state.dataset.requestRejected,
        requestApproved: state.dataset.requestApproved,
        requestDeletedSuccess: state.dataset.requestDeletedSuccess,
        requestDeletedError: state.dataset.requestDeletedError,
        globalConfig: state.globalConfig
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        getlistofgrantaccess: ( silent = false ) => dispatch( datasetActions.getlistofgrantaccess( silent )),
        approveRequest: ( requestBody, datasetId ) => dispatch( datasetActions.approveRequest( requestBody, datasetId )),
        rejectRequest: ( requestBody, datasetId ) => dispatch( datasetActions.rejectRequest( requestBody, datasetId )),
        deleteRequest: ( userId, requestId ) => dispatch( datasetActions.deleteRequest( userId, requestId )),
        resetDatasetProps: ( propsToReset ) => dispatch( datasetActions.resetDatasetProps( propsToReset ))
    };
};


export default connect( mapStateToProps, mapDispatchToProps )( AccessRequestsComponent );