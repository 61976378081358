import React, { Component } from 'react';
import $ from 'jquery';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Nav, NavDropdown, MenuItem, Modal,
    Row, Col, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';

import ADPIcon from '../common/Icon';
import * as authActions from '../../modules/auth';
import { resetRbacProps } from '../../modules/rbac';
import * as accountActions from '../../modules/account';
import BookmarkManager from '../common/BookmarkManager';
import LoadingResources from '../common/LoadingResources';
import { refreshResource, withHelperText } from '../utils/renderUtils';

class HeaderLinks extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            showFeedback: false,
            feedback: '',
            switchRole: false,
            newRole: '',
            data: {}
        };
    }

    componentDidMount(){
        // this is a bad hack for react-bootstrap not closing the menu on click
        $( '#sidebar-settings-link, #sidebar-switchrole-link, #sidebar-logout-link' ).on( 'click', function () {
            $( this ).parents( '.dropdown' ).toggleClass( 'open' );
        });
    }

    componentDidUpdate( prevProps, prevState ) {
        const { switchRoleSuccess, userName, user, userRole, pendingRequests } = this.props;
        if ( user && user !== prevProps.user && this.state.switchRole ){
            const roles = Object.assign({}, user.RolesAttached );
            if ( userRole && userRole.RoleId ){
                delete roles[userRole.RoleId];
            }
            const newData = Object.assign({}, prevState.data );
            newData.roles = roles;
            this.setState({
                data: newData
            });
        }
        if ( !userName ) { this.reloadApp(); }
        if ( switchRoleSuccess && pendingRequests.length === 0 ) { this.closeSwitch( 'switched' ); }
    }

    closeSwitch = ( action = 'hide' ) => {
        this.setState({
            switchRole: false,
            newRole: '',
            roleOption: undefined,
            data: {}
        }, () => action === 'switched' && this.props.resetAccountProps({ switchRoleSuccess: undefined }) && window.location.reload());
    }

    switchRole = async ( rolesAttached, userRole ) => {
        const roles = Object.assign({}, rolesAttached );
        if ( userRole && userRole.RoleId ){
            delete roles[userRole.RoleId];
        }
        const currentRole = ( userRole && userRole.RoleId ) ? rolesAttached[userRole.RoleId] : '';
        delete rolesAttached[currentRole];
        this.setState({
            switchRole: true,
            data: { roles, userRole, newRole: currentRole }
        });
    }

    capitalize1 = ( str ) => {
        return (( str && str.length > 0 ) ? str.charAt( 0 ).toUpperCase() + str.slice( 1 ) : '' );
    }

    reloadApp = () => {
        setTimeout(() => {
            this.props.history.push( '/' );
        }, 1000 );
    }

    computeDisplayUsername = memoizeOne(( user, username ) => {
        return ( user && user.Name )
            ? ( user.Name.length < 28
                ? this.capitalize1( user.Name )
                : ( user.Name.split( ' ' )[0].length < 28 ? this.capitalize1( user.Name.split( ' ' )[0]) : `${user.Name.slice( 0, 22 )}...` ))
            : (( username && username.length > 0 ) ? this.capitalize1( username ) : 'User' );
    })

    render() {
        const { user, userRole, userName, globalConfig, pendingRequests, switchRoleSuccess } = this.props;
        const { permanentPaths = {} } = globalConfig;
        const displayUsername = this.computeDisplayUsername( user, userName );
        return (
            <div>
                <LoadingResources
                    user={ user }
                    userRole={ userRole }
                    fetchingUser={ this.props.fetchingUser }
                    pendingRequests={ pendingRequests }
                    displayUsername={ displayUsername }
                    fullLoader={ false }
                    switchRoleSuccess={ switchRoleSuccess }
                />
                <Nav pullRight id="navbar-right-menu">
                    { !window.location.pathname.startsWith( '/auth' ) && <BookmarkManager /> }
                    { withHelperText( 'Documentation', <NavDropdown eventKey={3} title={<>
                        <span className="hidden-xs hidden-sm">
                            <ADPIcon icon="book-alt" />
                        </span>
                        <p className="hidden-md hidden-lg"><ADPIcon icon={ permanentPaths.apidocs.icon } /> Docs <b className="caret" /></p>
                    </>
                    }
                    className="top-nav-regular-icon"
                    noCaret
                    id="user-profile-nav-dropdown"
                    >
                        <div className="role-header">
                            <p>Version { globalConfig.VERSION }</p>
                        </div>
                        <MenuItem divider />
                        <MenuItem id="sidebar-apidocs-link" eventKey="sidebar-apidocs-link" onClick={() => {
                            this.props.history.push( permanentPaths.apidocs.path );
                        }}>
                            <ADPIcon icon={ permanentPaths.apidocs.icon } className="hidden-xs hidden-sm" /> { permanentPaths.apidocs.name }
                        </MenuItem>
                        <MenuItem id="sidebar-userdocs-link" eventKey="sidebar-userdocs-link" href={`${ permanentPaths.userdocs.path }${ globalConfig.VERSION ? `en/v${globalConfig.VERSION}/` : ''}`} target="_blank" rel="noopener noreferrer">
                            <ADPIcon icon="books" className="hidden-xs hidden-sm" /> { permanentPaths.userdocs.name } <ADPIcon icon="external-link" />
                        </MenuItem>
                    </NavDropdown> )}
                    { withHelperText( displayUsername, <NavDropdown eventKey={4} title={<>
                        <span className="hidden-xs hidden-sm"><ADPIcon icon="user"/></span>
                        <p className="hidden-md hidden-lg"><ADPIcon icon="user"  /> { displayUsername } <b className="caret" /></p>
                    </>
                    }
                    className="top-nav-regular-icon"
                    noCaret
                    id="user-profile-nav-dropdown"
                    >
                        <div className="role-header">
                            <p>{ displayUsername }</p><br/>
                            { ( userRole && userRole.RoleName ) && <p className="text-muted">{ userRole.RoleName }</p> }
                        </div>
                        <MenuItem divider />
                        { user && user.UserId && <>
                            <MenuItem id="sidebar-settings-link" eventKey="sidebar-settings-link" onSelect={ () => this.props.history.push( permanentPaths.settings.path )}>
                                <ADPIcon icon="cogs" className="hidden-xs hidden-sm" /> Profile & Settings
                            </MenuItem>
                            <MenuItem id="sidebar-switchrole-link" eventKey="sidebar-switchrole-link" onSelect={ () => this.switchRole( user.RolesAttached, userRole )}>
                                <ADPIcon icon="exchange" className="hidden-xs hidden-sm" /> Switch Role
                            </MenuItem>
                        </>
                        }
                        <MenuItem id="sidebar-feedback-link" eventKey="sidebar-feedback-link" onSelect={ () => this.setState({ showFeedback: true })}>
                            <ADPIcon icon="comment" className="hidden-xs hidden-sm" /> Give Feedback
                        </MenuItem>
                        <MenuItem id="sidebar-logout-link" eventKey="sidebar-logout-link" onSelect={ () => this.props.logout()}>
                            <ADPIcon icon="power-off" className="hidden-xs hidden-sm text-danger" /> Sign out
                        </MenuItem>
                    </NavDropdown> )}
                </Nav>
                <Modal
                    className="modal-small"
                    show={this.state.switchRole}
                    onHide={() => this.closeSwitch( 'hide' )}
                    id="switchroleForm-modal"
                    aria-labelledby="ModalHeader" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader'>Switch Role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row id="switchroleForm-container">
                            <Col sm={12}>
                                <ControlLabel>Current Role:</ControlLabel> &nbsp;
                                <b className="text-primary">{ ( this.state.data.userRole ? ( this.state.data.userRole.RoleName || this.state.data.userRole.RoleId ) : 'N/A' ) }</b>
                            </Col>
                            <div className="clearfix"></div><br />
                            <Col sm={12}>
                                <ControlLabel>Select role to switch to:</ControlLabel> &nbsp;&nbsp;
                                { refreshResource( true, 'Roles', this.props.loadAccount, this.props.silentFetchingUser ? true : false, false, 'right' ) }
                                <br/>
                                <Select
                                    value={this.state.roleOption}
                                    onChange= {( e ) => this.setState({ newRole: e ? e.value : '', roleOption: e || undefined })}
                                    options={ this.state.data.roles ? Object.keys( this.state.data.roles ).map(( obj, index ) => {
                                        return { value: obj, label: this.state.data.roles[obj], key: index };
                                    }) : []}
                                    isClearable
                                    isSearchable
                                    id="switchroleForm-role-select"
                                    classNamePrefix="select"
                                    menuPosition={'absolute'}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isDisabled={ this.props.silentFetchingUser ? true : false }
                                />
                            </Col>
                            <div className="clearfix"></div><br /><br /><br />
                            <Col sm={12}>
                                <button onClick={() => {
                                    this.closeSwitch( 'hide' );
                                    this.props.switchRole( this.state.newRole );
                                }} className="btn btn-fill pull-right" id="switchroleForm-submit-btn" disabled={this.state.newRole.length === 0}>
                                    Switch Role
                                </button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.showFeedback}
                    onHide={() => this.setState({ showFeedback: false, feedback: '' })}
                    aria-labelledby="ModalHeader"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="ModalHeader">Give Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup controlId="formControlsSelect">
                            <ControlLabel>Tell us about your experience:</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                placeholder="Type here.."
                                value={this.state.feedback}
                                rows={5}
                                onChange={( e ) => this.setState({ feedback: e?.target?.value })}
                            />
                            <small className={this.state?.feedback?.length <= 1000 ? 'text-success' : 'text-danger'}>{this.state?.feedback?.length}/1000 characters</small>
                        </FormGroup>
                        <button className="btn btn-fill btn-wd pull-right"
                            onClick={() => {
                                this.setState({ showFeedback: false, feedback: ''});
                                this.props.submitFeedback({
                                    Message: this.state.feedback
                                });
                            }} disabled={ !/^.{1,1000}$/.test( this.state?.feedback ) }>
                            Submit
                        </button>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.account.user,
        userName: state.auth.userName,
        userRole: state.account.userRole,
        globalConfig: state.globalConfig,
        silentFetchingUser: state.account.silentFetchingUser,
        fetchingUser: state.account.fetchingUser,
        switchRoleSuccess: state.account.switchRoleSuccess,
        pendingRequests: state.common.requests?.pending || []
    };
};

const mapDispatchToProps = ( dispatch, props ) => {
    return {
        logout: () => dispatch( authActions.logout( props.history )),
        switchRole: ( roleId ) => dispatch( accountActions.switchRole( roleId )),
        loadAccount: ( username, silent = true ) => dispatch( accountActions.loadAccount( username, silent )),
        submitFeedback: ( feedback ) => dispatch( accountActions.submitFeedback( feedback, 'Feedback' )),
        resetAccountProps: ( propsToReset ) => dispatch( accountActions.resetAccountProps( propsToReset )),
        resetGroups: ( propsToReset ) => dispatch( resetRbacProps( propsToReset ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( HeaderLinks ));
