import axiosRetry from 'axios-retry';
import { keepAlive } from './auth.js';

import configuration from '../config.json';

const axios = require( 'axios' );
const enAPI = configuration.API_gateway;

export const getHttp = ( dispatch, state, defaultHeader = true, roleIdRequired = true ) => {
    // Refresh Cognito session
    dispatch( keepAlive( state().auth.loginTime, state().auth.refreshToken, state().auth.userName ));

    const client = axios.create();
    if ( defaultHeader ) {
        // timeout axios requests to APIGW after 90secs
        client.defaults.timeout = 90000;
        client.defaults.headers.common['Authorization'] = state().auth.token;
        if ( roleIdRequired ){
            client.defaults.headers.common['role_id'] = state().account.userRole.RoleId || ( state().account.user.DefaultRole || '' );
        }
    }
    axiosRetry( axios, { retries: 2, retryDelay: axiosRetry.exponentialDelay });
    return client;
};

export const urlBuilder = ( urlTerms = [], parameters = []) => {
    return enAPI.concat( urlTerms.join( '/' )).concat( parameters.length > 0 ? `?${parameters.join( '&' )}` : '' );
};

export const extractMessage = ( data, fallback = '' ) => {
    try {
        return data?.Message || data?.message || fallback;
    } catch ( e ) {
        return fallback;
    }
};

export const extractError = ( error_obj, fallbackError = 'Network Error' ) => {
    try {
        return extractMessage( error_obj?.response?.data, error_obj?.response?.data ) || extractMessage( error_obj, fallbackError );
    } catch ( e ) {
        return fallbackError;
    }
};

export const respond = ( _actionName, _error, _dispatch ) => {
    // let error_response = {};
    // use common function for all the API calls for post processing if necessary
};
