import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import ADPIcon from '../common/Icon';

class PagesHeader extends Component {
    constructor( props ) {
        super( props );
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind( this );
        this.updateWidth = this.updateWidth.bind( this );
        this.state = {
            width: window.innerWidth
        };
    }
    // function that shows/hides sidebar on responsive
    mobileSidebarToggle = () => {
        document.documentElement.classList.toggle( 'nav-open' );
    }
    updateWidth() {
        this.setState({ width: window.innerWidth });
    }
    componentDidMount() {
        window.addEventListener( 'resize', this.updateWidth );
    }
    componentWillUnmount() {
        window.removeEventListener( 'resize', this.updateWidth );
    }
    render() {
        const { PROJECT_NAME, PROJECT_SHORT_NAME, permanentPaths, idpLogin, VERSION } = this.props.globalConfig;
        return (
            <Navbar
                collapseOnSelect
                inverse
                className="navbar-primary navbar-transparent navbar-absolute"
                id="pagesHeader-navbar"
            >
                <Navbar.Header>
                    <Navbar.Brand>
                        <NavLink to={'/home'} className="nav-link" id="navbar-projectname">
                            {this.state.width > 429
                                ? PROJECT_NAME
                                : PROJECT_SHORT_NAME
                            }
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => this.mobileSidebarToggle()} id="navbar-toggle-sidebar-btn" />
                </Navbar.Header>
                <Navbar.Collapse>
                    <ul className="nav navbar-nav navbar-right" id="navbar-right-menu">
                        { !this.props.pathname.startsWith( permanentPaths.login.path ) && <li>
                            <NavLink to={ permanentPaths.login.path } className="nav-link" id="navbar-login-link">
                                <ADPIcon icon={ permanentPaths.login.icon } />
                                <p>{ permanentPaths.login.name }</p>
                            </NavLink>
                        </li> }
                        { ( !this.props.pathname.startsWith( permanentPaths.register.path ) && !idpLogin ) && <li>
                            <NavLink to={ permanentPaths.register.path } className="nav-link" id="navbar-register-link">
                                <ADPIcon icon={ permanentPaths.register.icon } />
                                <p>{ permanentPaths.register.name }</p>
                            </NavLink>
                        </li> }
                        { !this.props.pathname.startsWith( permanentPaths.apidocs.path ) && <li>
                            <NavLink to={ permanentPaths.apidocs.path } className="nav-link" id="navbar-apidoc-link">
                                <ADPIcon icon={ permanentPaths.apidocs.icon } />
                                <p>{ permanentPaths.apidocs.name }</p>
                            </NavLink>
                        </li> }
                        <li>
                            <a href={`${ permanentPaths.userdocs.path }${ VERSION ? `en/v${VERSION}/` : ''}`} target="_blank" rel="noopener noreferrer" className="nav-link" id="navbar-userdocs-link">
                                <ADPIcon icon={ permanentPaths.userdocs.icon } />
                                <p>{ permanentPaths.userdocs.name } <ADPIcon icon="external-link" /></p>
                            </a>
                        </li>
                    </ul>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default PagesHeader;
