import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Grid, Row, Col, FormGroup, InputGroup, FormControl } from 'react-bootstrap';

import ADPIcon from '../common/Icon';

import Verify from './Verify';
import Card from '../layout/Cards/Card';
import * as authActions from '../../modules/auth';

// This Component shows the registered users list of User Logged in
class Forgotpwd extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            username: ''
        };
    }

    validateForm() {
        return this.state.username.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.forgotPassword( this.state.username );
    }

    render() {
        const { isverifysent } = this.props;
        return (
            <div className='content'>
                { isverifysent &&  <Verify username={this.state.username}/> }
                { !isverifysent && <Grid>
                    <Row>
                        <Col md={4} sm={6} mdOffset={4} smOffset={3} id="forgotpwdForm-container">
                            <form onSubmit={this.handleSubmit} name="forgotpwd">
                                <Card
                                    textCenter
                                    title="Forgot Password"
                                    content={
                                        <div>
                                            <FormGroup controlId="username">
                                                <InputGroup>
                                                    <InputGroup.Addon><ADPIcon icon="user-circle" id="forgotpwdForm-username-icon" /></InputGroup.Addon>
                                                    <FormControl
                                                        autoFocus
                                                        type="text"
                                                        placeholder="Username / Email Id"
                                                        value={this.state.username}
                                                        onChange={this.handleChange}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </div>
                                    }
                                    legend={
                                        <button
                                            id="forgotpwdForm-submit-btn"
                                            className="btn btn-fill btn-wd btn-block"
                                            disabled={!this.validateForm()}
                                            type="submit"
                                        >Reset Password</button>
                                    }
                                    ftTextCenter
                                />
                            </form>
                        </Col>
                    </Row>
                </Grid>}
            </div>
        );
    }
}

const validate = values => {
    const errors = {};
    if ( !values.Username ) {
        errors.Username = 'Required!';
    }
    return errors;
};

Forgotpwd = reduxForm({
    form: 'forgotpwd',
    validate
})( Forgotpwd );

// Mapping the current state props to ths following:
const mapStateToProps = ( state, _props ) => {
    return {
        isverifysent: state.auth.isverifysent
    };
};


const mapDispatchToProps = ( dispatch ) => {
    return {
        forgotPassword: ( Username ) => dispatch( authActions.forgotPassword( Username ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Forgotpwd ));