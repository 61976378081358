import React from 'react';
import { Row, Col, ProgressBar, ListGroup, ListGroupItem } from 'react-bootstrap';

import ADPIcon from '../common/Icon';
import Card from '../layout/Cards/Card';


const LoadingResources = ({
    user,
    userRole,
    fetchingUser,
    pendingRequests,
    displayUsername,
    fullLoader = true,
    switchRoleSuccess
}) => {
    return <Col sm={12} className={`overlay ${ user && user?.UserId && userRole && !fetchingUser && pendingRequests.length === 0 ? 'hide' : ( !user?.UserId ? 'hide' : '' )}`}>
        <Card
            content={<Row>
                <Col sm={6} smOffset={3} className="text-center">
                    <ADPIcon icon="user-circle" size="6x" className="text-primary" /><br/>
                    { fullLoader
                        ? (( user && !fetchingUser ) ? <>Hello { displayUsername }</> : <ProgressBar active now={100} bsStyle="success" className="loadUserAccount progress-thin" /> )
                        : displayUsername
                    }
                    <br/><br/>
                    <ListGroup className="user-load-info" id="landing-user-load-info-container">
                        { !user
                            ? ( fetchingUser
                                ? <ListGroupItem id="landing-fetchingUser-spinner">
                                    <ADPIcon icon="spinner" spin className="text-primary" />
                                    &nbsp;{ fetchingUser }
                                </ListGroupItem> : '' )
                            : <>
                                { fullLoader && <ListGroupItem id="landing-fetchingPreferences-success">
                                    <ADPIcon icon="check-circle" className="text-success" />
                                    &nbsp;User preferences loaded
                                </ListGroupItem> }
                                { fetchingUser ? <ListGroupItem id="landing-fetchingRole-spinner">
                                    <ADPIcon icon="spinner" spin className="text-primary" />
                                    &nbsp;{ fetchingUser }
                                </ListGroupItem> : <ListGroupItem id="landing-fetchingPreferences-success">
                                    <ADPIcon icon="check-circle" className="text-success" />
                                    &nbsp;{( switchRoleSuccess || 'User role updated' )}
                                </ListGroupItem> }
                                { ( pendingRequests.length > 0 ) && <ListGroupItem id="landing-fetchingRole-spinner">
                                    <ADPIcon icon="spinner" spin className="text-primary" />
                                    &nbsp;Fetching user resources...
                                </ListGroupItem> }
                            </>
                        }
                    </ListGroup>
                </Col>
            </Row>}
        />
    </Col>;
};

export default LoadingResources;