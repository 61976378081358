import { createStore, compose, applyMiddleware } from 'redux';
import { createStateSyncMiddleware } from 'redux-state-sync';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from '../modules';

export default function configureStore() {

    const reducer = persistCombineReducers({ key: 'amorphic-data-platform', storage, blacklist: ['form'] }, reducers );

    const middlewares = [
        createStateSyncMiddleware({
            whitelist: ['USER_LOGOUT', 'USER_ROLE_UPDATE_SUCCESS', 'TOKEN_REFRESH_SUCCESS', 'LOGIN_SUCCESS', 'SWITCH_ROLE_SUCCESS']
        }),
        thunkMiddleware
    ];

    const store = createStore( reducer, {}, compose(
        applyMiddleware( ...middlewares ),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ maxAge: 5 }) : f => f
    )
    );

    const persistor = persistStore( store );

    if ( module.hot ) {
        module.hot.accept( '../modules', () => {
            const nextReducer = require( '../modules' ).default; // eslint-disable-line global-require
            store.replaceReducer( nextReducer );
        });
    }

    // window.addEventListener('storage', (e) => {
    //     store.dispatch({ type: 'OtherTab', data: e.newValue })
    // });

    return { persistor, store };
}
