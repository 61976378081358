import { respond, getHttp, urlBuilder, extractError } from './utils';
import { USER_LOGOUT } from './common_actions';

const SET_QUERY_ERROR = 'SET_QUERY_ERROR';
const CHANGE_QUERY_FETCHING = 'CHANGE_QUERY_FETCHING';
const FETCH_QUIRIES = 'FETCH_QUIRIES';
const QUERY_SUCCESS = 'QUERY_SUCCESS';
const RESET_ENDPOINT = 'RESET_ENDPOINT';
const DELETE_QUERY_SUCCESS = 'DELETE_QUERY_SUCCESS';
const DELETE_QUERY_FAILURE = 'DELETE_QUERY_FAILURE';
const FETCH_RESULTS = 'FETCH_RESULTS';
const RESET_QUERY_LIST = 'RESET_QUERY_LIST';
const RESULTS = 'RESULTS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_QUERY_ERROR:
    case CHANGE_QUERY_FETCHING:
    case FETCH_QUIRIES:
    case QUERY_SUCCESS:
    case RESET_ENDPOINT:
    case DELETE_QUERY_SUCCESS:
    case DELETE_QUERY_FAILURE:
    case FETCH_RESULTS:
    case RESET_QUERY_LIST:
    case RESULTS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const getSystemDatasets = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSystemDatasetsAPI = urlBuilder(['systemdatasets']);
        dispatch({ type: CHANGE_QUERY_FETCHING, data: { fetchingSystemDatasets: true } });
        return client.get( getSystemDatasetsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_QUIRIES,
                        data: {
                            systemdatasets: response.data,
                            fetchingSystemDatasets: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_QUERY_ERROR,
                    data: {
                        fetchSystemDatasetsError: extractError( error_obj ),
                        fetchingSystemDatasets: undefined
                    }
                });
                respond( 'getSystemDatasets', error_obj, dispatch );
            });
    };
};

export const getQueries = ( offset = 1, limit = 10, sortOrder = 'desc', sortBy = 'LastModifiedTime' ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        dispatch({ type: DELETE_QUERY_SUCCESS, data: { deleteQuerySuccess: undefined, deleteQueryError: undefined, fetchingQueries: true }});
        const getquieriesAPI = urlBuilder(['queries'], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]);
        return client.get( getquieriesAPI )
            .then( response => {
                const queriesList = response.data;
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_QUIRIES,
                        data: {
                            queryHistory: queriesList,
                            fetchingQueries: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_QUERY_ERROR,
                    data: {
                        error: extractError( error_obj ),
                        fetchingQueries: undefined
                    }
                });
                respond( 'getQueries', error_obj, dispatch );
            });
    };
};

export const runquery = ( query ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const runqueryAPI = urlBuilder(['queries']);
        dispatch({ type: FETCH_RESULTS, data: { queryResults: undefined, queryStatus: undefined, error: undefined, querySubmission: true }});
        return client.post( runqueryAPI, query )
            .then( response => {
                dispatch({
                    type: QUERY_SUCCESS,
                    data: {
                        queryId : response.data.QueryId,
                        querySubmission: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_QUERY_ERROR,
                    data: {
                        error: extractError( error_obj ),
                        querySubmission: undefined, queryId: undefined
                    }
                });
                respond( 'runquery', error_obj, dispatch );
            });
    };
};

export const getQuerydetails = ( queryId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getQuerydetailsAPI = urlBuilder([ 'queries', queryId ]);
        dispatch({ type: DELETE_QUERY_SUCCESS, data: { deleteQuerySuccess: undefined, deleteQueryError: undefined, isFetching: true}});
        return client.get( getQuerydetailsAPI )
            .then( response => {
                dispatch({
                    type: QUERY_SUCCESS,
                    data: {
                        queryStatus: response.data,
                        isFetching: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_QUERY_ERROR,
                    data: {
                        error: extractError( error_obj ),
                        isFetching: undefined
                    }
                });
                respond( 'getQuerydetails', error_obj, dispatch );
            });
    };
};

export const getQueryresults = ( queryId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getQueryresultsAPI = urlBuilder(['queries', queryId, 'results']);
        dispatch({ type: CHANGE_QUERY_FETCHING, data: { isFetching: true, results: undefined } });
        return client.get( getQueryresultsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_RESULTS,
                        data: {
                            queryResults: response.data.Rows,
                            isFetching: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({ type: CHANGE_QUERY_FETCHING, data: { isFetching: false } });
                respond( 'getQueryresults', error_obj, dispatch );
            });
    };
};

export const deleteQuery = ( id, hotQuery ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteQueryAPI = urlBuilder(['queries', id ]);
        dispatch({ type: DELETE_QUERY_SUCCESS, data: { deleteQuerySuccess: undefined, deleteQueryError: undefined }});
        return client.delete( deleteQueryAPI )
            .then( _response => {
                dispatch({
                    type: DELETE_QUERY_SUCCESS,
                    data: { deleteQuerySuccess: true, hotQuery }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: DELETE_QUERY_FAILURE,
                    data: {
                        deleteQueryError: extractError( error_obj ),
                        hotQuery
                    }
                });
                respond( 'deleteQuery', error_obj, dispatch );
            });
    };
};

export const resetqueryProps = () => {
    return ( dispatch ) => {
        dispatch({ type: RESET_ENDPOINT, data: {
            error: undefined,
            querySubmission: undefined,
            queriesuccess: undefined,
            deleteQuerySuccess: undefined,
            deleteQueryError: undefined,
            endpointError:undefined,
            queryResults : undefined,
            isFetching : undefined,
            queryId : undefined,
            queryStatus: undefined
        }});
    };
};


