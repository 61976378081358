import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import App from './app/App';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { initializeAppLanguage } from './modules/language';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/applicationSS.min.css?v=1.0';
import { library } from '@fortawesome/fontawesome-svg-core';
import RecoverBoundary from './components/errors/RecoverBoundary';

import {
    faComment, faCopyright, faLock, faUser, faHandPaper,
    faEllipsisV, faBars, faTimes, faUserEdit, faEnvelope,
    faIdCard, faUserCircle, faKey, faInfoCircle,
    faSearch, faGavel, faPowerOff, faTrash,
    faGlobe, faRobot, faCogs, faUserShield, faThLarge,
    faAngleDoubleRight, faAngleDoubleLeft, faUserTag, faAt, faCheckSquare, faSquare,
    faUsers, faUserTie, faBoxes, faAtom, faRoute, faMailBulk,
    faFileInvoiceDollar, faChartLine, faFileCode, faFile, faStar, faBullhorn, faMobileAlt,
    faSync, faCalendarCheck, faExclamationTriangle, faBriefcase, faTools, faStethoscope,
    faMoneyBillAlt, faRulerHorizontal, faBan, faCheckCircle, faPencilAlt, faRedo,
    faFileCsv, faFileAlt, faFilePdf, faFileWord, faFileImage, faFileAudio, faFileVideo,
    faExternalLinkSquareAlt, faUserTimes, faUpload, faDatabase, faCopy, faPlug, faBookMedical,
    faUserPlus, faDownload, faLaptopCode, faEye, faWindowRestore, faUserSlash, faSitemap,
    faAngleDown, faAngleRight, faPlus, faPlayCircle, faUserClock, faUserCheck, faUserMinus,
    faMedkit, faStopCircle, faCircle, faClock, faMinusCircle, faRunning, faPlusCircle, faFileExport,
    faTerminal, faHistory, faChevronCircleLeft, faChevronLeft, faCalendarAlt, faWind, faSpinner,
    faCheck, faWarehouse, faWater, faLayerGroup, faTasks, faBolt, faFileArchive, faProjectDiagram, faTimesCircle, faMinusSquare,
    faToggleOn, faToggleOff
} from '@fortawesome/free-solid-svg-icons';

import {
    faAnalytics, faUserChart, faUsersCog, faMonitorHeartRate, faPortrait, faHeadSide, faThumbtack,
    faChartNetwork, faCommentAltCheck, faCommentAltExclamation, faBullseyePointer, faBooks, faLightbulb,
    faExchange, faClone, faRandom, faUndo, faBug, faBracketsCurly, faFilter, faHandHoldingUsd, faBookAlt,
    faHeadSideBrain, faSignOut,  faSquareFull, faGameConsoleHandheld, faDoNotEnter, faSignIn, faFileSearch,
    faRegistered, faQuestion, faSortAlt, faHandshakeAlt, faHome, faCompressAlt, faUsersClass,
    faPalletAlt, faCabinetFiling, faFileChartLine, faLongArrowRight, faCaretLeft, faCaretRight,
    faVoteYea, faVoteNay, faCode, faRocket, faCommentAltLines, faBell, faMinus, faQrcode
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faComment, faCopyright, faLock, faUser, faClone, faEnvelope, faBell,
    faEllipsisV, faBars, faTimes, faTimesCircle, faUserEdit, faMinus,
    faIdCard, faUserCircle, faKey, faInfoCircle, faAt, faVoteYea, faVoteNay,
    faSearch, faGavel, faPowerOff, faTrash, faLongArrowRight, faCaretLeft, faCaretRight,
    faGlobe, faRobot, faCogs, faUserShield, faThLarge, faBullhorn, faMailBulk, faCheckSquare,
    faAngleDoubleRight, faAngleDoubleLeft, faUserTag, faCabinetFiling, faFileChartLine, faSquare,
    faUsers, faUserTie, faBoxes, faFilter, faRoute, faPalletAlt, faStethoscope, faBookAlt, faFileSearch,
    faFileInvoiceDollar, faChartLine, faFileCode, faFile, faStar, faAtom, faBookMedical,
    faSync, faCalendarCheck, faExclamationTriangle, faBriefcase, faTools, faLightbulb, faMobileAlt,
    faMoneyBillAlt, faRulerHorizontal, faBan, faCheckCircle, faPencilAlt, faGameConsoleHandheld,
    faFileCsv, faFileAlt, faFilePdf, faFileWord, faFileImage, faFileAudio, faFileVideo,
    faExternalLinkSquareAlt, faUserTimes, faUpload, faDatabase, faCopy, faPlug, faRedo, faQrcode,
    faUserPlus, faDownload, faLaptopCode, faEye, faWindowRestore, faStopCircle, faUserSlash, faRocket,
    faAngleDown, faAngleRight, faPlus, faPlayCircle, faUserClock, faUserCheck, faUserMinus, faSitemap,
    faMedkit, faAnalytics, faUserChart, faUsersCog, faMonitorHeartRate, faPortrait, faHeadSide,
    faCircle, faClock, faMinusCircle, faRunning, faPlusCircle, faChartNetwork, faCommentAltCheck,
    faCommentAltExclamation, faTerminal, faHistory, faChevronCircleLeft, faChevronLeft, faCalendarAlt,
    faWind, faBullseyePointer, faSpinner, faCheck, faWarehouse, faExchange, faWater, faLayerGroup, faTasks,
    faBolt, faBooks, faFileArchive, faProjectDiagram, faBracketsCurly, faBug, faUndo, faRandom, faHandHoldingUsd,
    faHeadSideBrain, faSignOut, faSquareFull, faDoNotEnter, faSignIn, faHandPaper, faThumbtack, faCode,
    faRegistered, faQuestion, faSortAlt, faHandshakeAlt, faHome, faCompressAlt, faUsersClass, faFileExport, faMinusSquare,
    faToggleOn, faToggleOff, faCommentAltLines
);

const { persistor, store } = configureStore();
store.dispatch( initializeAppLanguage());

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <RecoverBoundary>
                    <Route path="/" component={App} />
                </RecoverBoundary>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById( 'root' )
);
