import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';

import 'perfect-scrollbar/css/perfect-scrollbar.css';

import ADPIcon from '../common/Icon';

import HeaderLinks from './HeaderLinks';
import routes from '../../routes/menubarRoutes';
import { hasAccess } from '../../routes/RouteUtils';
import Amorphic from '../../assets/img/Amorphic.png';
import AmorphicLogo from '../../assets/img/AmorphicLogo.png';
import * as authActions from '../../modules/auth';

let ps;

class Sidebar extends Component {
    constructor( props ) {
        super( props );
        this.updateDimensions = this.updateDimensions.bind( this );
        this.state = {
            openAvatar: false,
            openDatasets: this.activeRoute( '/datasets' ) !== '' ? true : false,
            openMl: this.activeRoute( '/ml' ) !== '' ? true : false,
            openEtl: this.activeRoute( '/etl' ) !== '' ? true : false,
            openManage: this.activeRoute( '/manage' ) !== '' ? true : false,
            isWindows: navigator.platform.indexOf( 'Win' ) > -1 ? true : false,
            width: window.innerWidth
        };
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute( routeName, exactMatch = false ) {

        if ( exactMatch ) {
            return this.props.location.pathname === routeName ? 'active' : '';
        } else {
            if ( routeName instanceof Array === true ) {
                let className = '';
                routeName.forEach( element => {
                    if ( className !== 'active' && element.test( this.props.location.pathname ))  {
                        className = 'active';
                    }
                });
                return className;
            } else {
                return this.props.location.pathname.indexOf( routeName ) > -1 ? 'active' : '';
            }
        }
    }
    // if the windows width changes CSS has to make some changes
    // this functions tell react what width is the window
    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        this.updateDimensions();
        // add event listener for windows resize
        window.addEventListener( 'resize', this.updateDimensions );
        if ( navigator.platform.indexOf( 'Win' ) > -1 ) {
            ps = new PerfectScrollbar( this.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    componentWillUnmount() {
        // remove event listener for windows resize
        window.removeEventListener( 'resize', this.updateDimensions );
        if ( navigator.platform.indexOf( 'Win' ) > -1 ) {
            ps.destroy();
        }
    }

    setRef = ( node ) => { this.sidebarWrapper = node; }

    render() {
        const { user, userRole, globalConfig } = this.props;
        const { permanentPaths, PROJECT_NAME, PROJECT_SHORT_NAME, idpLogin } = globalConfig;

        return (
            <div className="sidebar" data-color="black" id="sidebar-container">
                <div className="sidebar-background" />
                <div className="logo">
                    <a href="/" className="simple-text logo-mini" id="sidebar-project-logo-link">
                        <div className="logo-img">
                            <img src={AmorphicLogo} alt={`${PROJECT_SHORT_NAME}-logo`} id="sidebar-project-shortname-logo" />
                        </div>
                    </a>
                    <a href="/" className="simple-text logo-normal" id="sidebar-project-link">
                        <img src={Amorphic} alt={PROJECT_NAME} className='img-responsive' id="sidebar-project-logo" />
                    </a>
                </div>
                <div className="sidebar-wrapper" ref={this.setRef} >
                    <ul className="nav">
                        {this.state.width <= 992 ? <HeaderLinks {...this.props} /> : null}
                        {user && user.UserId && userRole && routes.map(( prop, key ) => {
                            const st = {};
                            st[prop['state']] = !this.state[prop.state];
                            if ( !hasAccess( userRole, prop.action || permanentPaths[prop.pathIdentifier].permission )) {
                                return null;
                            } else if ( prop.collapse ) {
                                return (
                                    <li className={this.activeRoute( permanentPaths[prop.pathIdentifier].parent === 'root'
                                        ? permanentPaths[prop.pathIdentifier].path : permanentPaths[prop.pathIdentifier].parent )}
                                    key={key}>
                                        {/* eslint-disable-next-line */}
                                        <a onClick={() => this.setState(st)}
                                            id={`sidebar-${permanentPaths[prop.pathIdentifier].name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-toggle-link`}
                                        >
                                            <ADPIcon icon={permanentPaths[prop.pathIdentifier].icon} />
                                            <p>
                                                {permanentPaths[prop.pathIdentifier].name}
                                                <b className={this.state[prop.state] ? 'caret rotate-180' : 'caret'} />
                                            </p>
                                        </a>
                                        <Collapse in={this.state[prop.state]}>
                                            <ul className="nav">
                                                {prop.views.map(( innerProp, innerKey ) => {
                                                    return (
                                                        ( hasAccess( userRole, innerProp.action || permanentPaths[innerProp.pathIdentifier]?.permission || false ) && ( permanentPaths[innerProp.pathIdentifier].samlOnly ? idpLogin : true ))
                                                            ? <li className={this.activeRoute( innerProp.hiddenRoutes && innerProp.hiddenRoutes.length > 0 ?
                                                                innerProp.hiddenRoutes : ( innerProp.exactPathMatch ? permanentPaths[innerProp.pathIdentifier].path
                                                                    : permanentPaths[permanentPaths[innerProp.pathIdentifier].parent].path ), innerProp.exactPathMatch )} key={innerKey}>
                                                                <NavLink
                                                                    to={permanentPaths[innerProp.pathIdentifier].path}
                                                                    className="nav-link"
                                                                    activeClassName="active"
                                                                    id={`sidebar-${permanentPaths[innerProp.pathIdentifier].name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-link`}
                                                                >
                                                                    <span className="sidebar-mini">
                                                                        {permanentPaths[innerProp.pathIdentifier].mini}
                                                                    </span>
                                                                    <span className="sidebar-normal">
                                                                        {permanentPaths[innerProp.pathIdentifier].name}
                                                                        {innerProp.beta ? <sup>&nbsp;&nbsp;Beta</sup> : ''}
                                                                    </span>
                                                                </NavLink>
                                                            </li> : null
                                                    );
                                                })}
                                            </ul>
                                        </Collapse>
                                    </li>
                                );
                            } else {
                                if ( prop.redirect ) {
                                    return null;
                                } else {
                                    return (
                                        <li
                                            className={this.activeRoute(
                                                permanentPaths[prop.pathIdentifier].parent === 'root'
                                                    ? permanentPaths[prop.pathIdentifier].path
                                                    : permanentPaths[prop.pathIdentifier].parent
                                            )}
                                            key={key}
                                        >
                                            <NavLink
                                                to={permanentPaths[prop.pathIdentifier].path}
                                                className="nav-link"
                                                activeClassName="active"
                                                id={`sidebar-${permanentPaths[prop.pathIdentifier].name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-link`}
                                            >
                                                <ADPIcon icon={permanentPaths[prop.pathIdentifier].icon} />
                                                <p>
                                                    {permanentPaths[prop.pathIdentifier].name}
                                                    {prop.beta ? <sup>&nbsp;Beta</sup> : ''}
                                                </p>
                                            </NavLink>
                                        </li>
                                    );
                                }
                            }
                        })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.account.user,
        userRole: state.account.userRole,
        globalConfig: state.globalConfig,
        pendingRequests: state.common?.requests?.pending || []
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        login: () => dispatch( authActions.login())
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Sidebar ));
