import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';
import accessDenied from '../../assets/img/accessDenied.svg';

class NotAuthorized extends React.Component {
    render() {
        const { content = '', inComponent = true, globalConfig } = this.props;
        const { permanentPaths = {} } = globalConfig;
        return (
            inComponent ? pageContent( inComponent, content, permanentPaths.home.path ) : <Page
                title={ permanentPaths.notAuthorized.name }
                content={ pageContent( inComponent, content, permanentPaths.home.path ) }
            />
        );
    }
}

const pageContent = ( inComponent, content, homeRoute ) => {
    return <Row>
        <br/><br/>
        <Col sm={12} md={6} smOffset={1} mdOffset={3}>
            <Card
                extraClasses={'text-center'}
                title={<span className="text-center text-info text-bold">
                    <img src={ accessDenied } alt="" />
                    <br/><br/>
                    Access Denied !!
                </span>
                }
                content={<Row>
                    <Col sm={12} className="text-muted">
                        <p>You do not have sufficient permissions to { content.length > 0 ? <b className="text-danger">{content}</b> : 'this content' }.
                        If you believe that you are seeing this message in error, please contact your system administrator for access.
                        </p>
                    </Col>
                </Row>}
                legend={ !inComponent && inComponent &&
                <LinkContainer to={homeRoute}>
                    <button className="btn btn-fill btn-wd">Return to Home</button>
                </LinkContainer>
                }
                ftTextCenter
            />
        </Col>
    </Row>;
};

const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        globalConfig: state.globalConfig
    };
};

export default connect( mapStateToProps, null )( NotAuthorized );