import React from 'react';
import { Row, Col, Fade } from 'react-bootstrap';
import CardLoader from './CardLoader';
import ListLoader from './ListLoader';

const LoaderTemplate = ({
    type,
    count,
    hideLoader,
    hasIcon,
    noOfIconCTA,
    heading,
    globalConfig
}) => <Fade in={!hideLoader} unmountOnExit appear={true}>
    <>
        {
            ( type === 'cards' ) &&  <Row>
                {
                    new Array( count ).fill( '' ).map(( _x, i ) => <Col sm={12 / count} key={i} >
                        <CardLoader hasIcon={hasIcon} />
                    </Col>
                    )}
            </Row>
        }

        { type === 'listsInSection' && <ListLoader hasIcon={hasIcon}
            heading={heading}
            noOfIconCTA={noOfIconCTA}
            count={count}
            type={type} />
        }

        {
            type === 'lists' && <Row className="listsInPage">
                {
                    new Array( count ).fill( '' ).map(( _x, i ) => <Col xs={12} sm={6} md={4} lg={4} key={i}>
                        <CardLoader hasIcon={hasIcon} hasTags={true} />
                    </Col>
                    )}
            </Row>
        }

        {
            type === 'innerList' && <Row className="listsInPage">
                {
                    new Array( count ).fill( '' ).map(( _x, i ) => <Col xs={12} key={i}>
                        <CardLoader hasIcon={hasIcon} hasTags={true} titleFirst={true} />
                    </Col>
                    )}
            </Row>
        }

        {
            type === 'componentList' && <ListLoader hasIcon={hasIcon}
                heading={heading}
                globalConfig={globalConfig}
                noOfIconCTA={noOfIconCTA}
                count={count}
                type={type} />
        }
    </>
</Fade>;

export default LoaderTemplate;