import React from 'react';

const IconLoader = ({
    items,
    width,
    height
}) => <>
    {
        new Array( items ).fill( '' ).map(() => <div className="loader__icon" key=""
            style={{
                width: `${width}px`,
                height: `${height}px`}}
        /> )
    }
</>;

export default IconLoader;