import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import Page from '../layout/Cards/ComponentView';
import * as recoverActions from '../../modules/error';
import * as authActions from '../../modules/auth';

class RecoverBoundary extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError( error ) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch( error, errorInfo ) {
        this.setState({
            errorInfo
        });
    }

    attemptRecovery = () => {
        this.props.attemptRecovery();
        this.props.logout();
    }

    render() {
        if ( this.state.error || this.state.errorInfo ) {
            return (
                <Page
                    title={'Recover Application state'}
                    content={ <Row>
                        <Col sm={12}>
                            <blockquote className="text-danger">To recover from any internal app errors, User must be logged out first.</blockquote>
                            <button className="btn btn-wd btn-fill btn-primary" onClick={() => this.attemptRecovery()}>Attempt Recovery</button>
                        </Col>
                    </Row>
                    }
                />
            );
        }
        return this.props.children;
    }
}

const mapDispatchToProps = ( dispatch, props ) => {
    return {
        attemptRecovery: () => dispatch( recoverActions.resetApplication()),
        logout: () => dispatch( authActions.logout( props.history ))
    };
};

export default connect( null, mapDispatchToProps )( RecoverBoundary );