import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import routeRequirements from './routeRequirements.json';
import NotAuthorized from '../components/errors/NotAuthorized';
import AppErrorBoundary from '../components/errors/AppErrorBoundary';

const mapStateToProps = state => ({
    user: state.account.user,
    userRole: state.account.userRole
});

export const hasAccess = ( userRole = {}, action, fullMatch = false ) => {
    const userPermissions = ( userRole.RoleConsolidatedPermissions || ( userRole.RolePermissions || []));
    if ( action === 'any' ){
        return true;
    } else if ( Object.keys( routeRequirements ).includes( action )){
        if ( fullMatch ){
            return routeRequirements[action].every( perm => userPermissions.indexOf( perm ) > -1 );
        } else {
            return routeRequirements[action].some( perm => userPermissions.indexOf( perm ) > -1 );
        }
    } else if ( action.split( '.' ).length > 1 ) {
        return ( userPermissions.indexOf( action ) > -1 || userPermissions.indexOf( `${action.split( '.' )[0]}.fullaccess` ) > -1 );
    } else {
        return false;
    }
};

export const AccessCheck = ( userRole = {}, actions = {}, fullMatch = false ) => {
    const responseObj = {};
    for ( const key in actions ){
        responseObj[key] = hasAccess( userRole, actions[key], fullMatch );
    }
    return responseObj;
};

export const AccessRoute = connect( mapStateToProps )(({ component: Component, permanentPaths, action, user, userRole, redirectTo, ...rest }) => {
    return (
        <Route
            {...rest}
            render={ props =>
                ( user && user.UserId && !redirectTo ) ?
                    <AppErrorBoundary>
                        { hasAccess( userRole, action ) ? <Component permanentPaths={permanentPaths} {...props} /> : <NotAuthorized inComponent={false} /> }
                    </AppErrorBoundary>
                    : <Redirect to={redirectTo || '/'} />
            }
        />
    );
});

export const AccessBoundary = connect( mapStateToProps )(({ hasAccessTo, alternateContent, title, content, fetching, fetchError, hideHeader = true }) => {
    return (
        hasAccessTo
            ? ( typeof fetching !== 'undefined' ? fetching : ( typeof fetchError !== 'undefined' ? fetchError : content ))
            : ( alternateContent ? alternateContent : <NotAuthorized content={ title || '' } inComponent={ hideHeader } /> )
    );
});

const computePath = ( permanentPaths, identifier, currentPath ) => {
    const finalPath = permanentPaths[identifier].path + currentPath;
    if ( permanentPaths[identifier].parent === 'root' ) {
        return permanentPaths[identifier].externalLink ? finalPath : `/${finalPath}`;
    } else {
        computePath( permanentPaths, permanentPaths[identifier].parent, finalPath );
    }
};

export const routeOf = ( permanentPaths, identifier, fullObject = false ) => {
    if ( Object.keys( permanentPaths ).includes( identifier )) {
        const obj = Object.assign({}, permanentPaths[identifier]);
        obj.path = obj.parent === 'root' ? ( obj.externalLink ? obj.path : `/${obj.path}` ) : computePath( obj.parent, obj.path );
        return fullObject ? obj : obj.path;
    } else {
        return fullObject ? {} : '';
    }
};

export const getAllRoutes = ( permanentPaths ) => {
    try {
        const paths = {};
        Object.keys( permanentPaths ).filter( el => el !== 'root' ).map(( identifier ) => {
            const obj = Object.assign({}, permanentPaths[identifier]);
            obj.path = obj.parent === 'root' ? ( obj.externalLink ? obj.path : `/${obj.path}` ) : `${paths[obj.parent].path}${obj.path}`;
            paths[identifier] = obj;
            return null;
        });
        return paths;
    } catch ( _error ) {
        return {};
    }
};

export const getPathObject = ( paths, path ) => {
    const match = Object.keys( paths ).filter( el => paths[el].path === path );
    if ( match.length > 0 ){
        return paths[match.pop()];
    } else {
        return {};
    }
};