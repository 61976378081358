import { extractError, getHttp, respond, urlBuilder, extractMessage } from './utils';
import { CHANGE_USER_PREFERENCES } from './globalConfig/reducer';
import { userResources } from './common';
import { USER_LOGOUT } from './common_actions';
import { alertAndHide, showLoading } from '../components/utils/popUpUtils';

const SET_ACCOUNT_ERROR = 'SET_ACCOUNT_ERROR';
const CHANGE_ACCOUNT_FETCHING = 'CHANGE_ACCOUNT_FETCHING';
const CREATE_USER_ACCOUNT_SUCCESS = 'CREATE_USER_ACCOUNT_SUCCESS';
const FETCH_USER_ACCOUNT = 'FETCH_USER_ACCOUNT';
const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
const FETCH_USER_DATASETS = 'FETCH_USER_DATASETS';
const FETCH_USERS = 'FETCH_USERS';
const GRANT_REQUESTS = 'GRANT_REQUESTS';
const BILLING_DETAILS = 'BILLING_DETAILS';
const RESET_USER_REGISTRATION = 'RESET_USER_REGISTRATION';
const RESET_ACCOUNT_PROPS = 'RESET_ACCOUNT_PROPS';
const DWH_CREDS_RESET_SUCCESS = 'DWH_CREDS_RESET_SUCCESS';
const SYSTEM_STATUS = 'SYSTEM_STATUS';
const CHANGE_UPDATE_PROCESSING = 'CHANGE_UPDATE_PROCESSING';
const USER_ROLE_UPDATE_SUCCESS = 'USER_ROLE_UPDATE_SUCCESS';
const UPDATE_ERROR = 'UPDATE_ERROR';
const EMAIL_ALERT_PREFERENCES = 'EMAIL_ALERT_PREFERENCES';
const SWITCH_ROLE_SUCCESS = 'SWITCH_ROLE_SUCCESS';
const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
const FEEDBACK_ERROR = 'FEEDBACK_ERROR';
const GRANT_DATASETS = 'GRANT_DATASETS';
const USER_STATUS_UPDATE_SUCCESS = 'USER_STATUS_UPDATE_SUCCESS';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
const CHANGE_RESOURCES_FETCHING = 'CHANGE_RESOURCES_FETCHING';
const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
const CHANGE_ROLES_FETCHING = 'CHANGE_ROLES_FETCHING';
const FETCH_ROLES = 'FETCH_ROLES';
const SET_ROLES_ERROR = 'SET_ROLES_ERROR';
const CHANGE_ROLE_CREATION = 'CHANGE_ROLE_CREATION';
const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';
const CHANGE_ROLE_FETCHING = 'CHANGE_ROLE_FETCHING';
const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';
const ROLE_EDIT_SUCCESS = 'ROLE_EDIT_SUCCESS';
const ROLE_EDIT_ERROR = 'ROLE_EDIT_ERROR';
const CHANGE_NTFN_SETTINGS_FETCHING = 'CHANGE_NTFN_SETTINGS_FETCHING';
const FETCH_NTFN_SETTINGS_SUCCESS = 'FETCH_NTFN_SETTINGS_SUCCESS';
const FETCH_NTFN_SETTINGS_ERROR = 'FETCH_NTFN_SETTINGS_ERROR';
const UPDATE_NTFN_SETTINGS_SUCCESS = 'UPDATE_NTFN_SETTINGS_SUCCESS';
const UPDATE_NTFN_SETTINGS_ERROR = 'UPDATE_NTFN_SETTINGS_ERROR';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_ACCOUNT_ERROR:
    case CHANGE_ACCOUNT_FETCHING:
    case CREATE_USER_ACCOUNT_SUCCESS:
    case FETCH_USER_ACCOUNT:
    case FETCH_ACCOUNT_SUCCESS:
    case FETCH_USER_DATASETS:
    case FETCH_USERS:
    case GRANT_REQUESTS:
    case BILLING_DETAILS:
    case RESET_USER_REGISTRATION:
    case RESET_ACCOUNT_PROPS:
    case DWH_CREDS_RESET_SUCCESS:
    case SYSTEM_STATUS:
    case CHANGE_UPDATE_PROCESSING:
    case USER_ROLE_UPDATE_SUCCESS:
    case UPDATE_ERROR:
    case EMAIL_ALERT_PREFERENCES:
    case SWITCH_ROLE_SUCCESS:
    case FEEDBACK_SUCCESS:
    case FEEDBACK_ERROR:
    case GRANT_DATASETS:
    case USER_STATUS_UPDATE_SUCCESS:
    case DELETE_USER_ERROR:
    case DELETE_USER_SUCCESS:
    case CHANGE_RESOURCES_FETCHING:
    case FETCH_RESOURCES_SUCCESS:
    case CHANGE_ROLES_FETCHING:
    case FETCH_ROLES:
    case SET_ROLES_ERROR:
    case CHANGE_ROLE_CREATION:
    case CREATE_ROLE_SUCCESS:
    case CREATE_ROLE_ERROR:
    case CHANGE_ROLE_FETCHING:
    case FETCH_ROLE_SUCCESS:
    case DELETE_ROLE_SUCCESS:
    case DELETE_ROLE_ERROR:
    case ROLE_EDIT_SUCCESS:
    case ROLE_EDIT_ERROR:
    case CHANGE_NTFN_SETTINGS_FETCHING:
    case FETCH_NTFN_SETTINGS_SUCCESS:
    case FETCH_NTFN_SETTINGS_ERROR:
    case UPDATE_NTFN_SETTINGS_SUCCESS:
    case UPDATE_NTFN_SETTINGS_ERROR:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

// method used to create user
export const createUser = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state, true, false );
        const createUserAPI = urlBuilder(['users']);

        dispatch({
            type: CHANGE_ACCOUNT_FETCHING,
            data: {
                userCreated: undefined,
                userCreationFailed: undefined
            }
        });

        return client.post( createUserAPI, {})
            .then( response => {
                const userRegistration = response.data;
                dispatch({
                    type: CREATE_USER_ACCOUNT_SUCCESS,
                    data: {
                        userCreated: userRegistration.message,
                        DBUserName: userRegistration.DBUserName,
                        DBPassword: userRegistration.DBPassword
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        userCreationFailed: extractError( error_obj )
                    }
                });
                respond( 'createUser', error_obj, dispatch );
            });
    };
};

export const changeUserPrefs = ( userPreferences, defaultName ) => {
    return ( dispatch ) => {
        dispatch({
            type: CHANGE_USER_PREFERENCES,
            userPreferences: {
                ...userPreferences,
                fullName: userPreferences.fullName || defaultName || 'N/A',
                sortBy: userPreferences.sortBy !== 'N/A' ? userPreferences.sortBy : '',
                sortOrder: userPreferences.sortOrder !== 'N/A' ? userPreferences.sortOrder : '',
                resultsPerPage: userPreferences.resultsPerPage !== 'N/A' ? userPreferences.resultsPerPage : ''

            }
        });
    };
};

export const loadAccount = ( username, silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state, true, false );
        const loadAccountAPI = urlBuilder(['users', username || state().auth.userName]);
        const dispatchType = silent ? 'silentFetchingUser' : 'fetchingUser';
        dispatch({
            type: FETCH_USER_ACCOUNT,
            data: {
                [dispatchType] : 'Fetching user data...',
                userFetchError: undefined
            }
        });

        return client.get( loadAccountAPI )
            .then( response => {
                const userAccount = response.data.user.pop() || {};
                dispatch({
                    type: FETCH_ACCOUNT_SUCCESS,
                    data: {
                        user: userAccount,
                        [dispatchType]: undefined
                    }
                });

                dispatch( changeUserPrefs( userAccount.Preferences, userAccount.Name ));
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        userFetchError: extractError( error_obj ),
                        [dispatchType]: undefined
                    }
                });
                respond( 'loadAccount', error_obj, dispatch );
            });
    };
};

export const switchRole = ( roleId, initLoad = false, callback ) => {
    return ( dispatch, state ) => {
        const client = initLoad ? getHttp( dispatch, state, true, false ) : getHttp( dispatch, state );
        const config = initLoad ? { headers: { 'role_id': state().account.user.DefaultRole } } : {};
        const loadRoleAPI = urlBuilder(['roles', roleId]);
        let switchSuccess = false;
        let newUserRole = null;
        dispatch({ type: CHANGE_UPDATE_PROCESSING, data: { fetchingUser: 'Switching user role...', userFetchError: undefined, switchRoleError: false } });
        client.get( loadRoleAPI, config )
            .then( roleResponse => {
                newUserRole = roleResponse.data.roles;
                switchSuccess = true;
                dispatch({
                    type: SWITCH_ROLE_SUCCESS,
                    data: {
                        userRole: newUserRole
                    }
                });
            }).catch( error_obj => {
                alertAndHide( 'error', 'Switch Failed', extractError( error_obj ), () => {
                    if ( typeof callback === 'function' ){
                        callback();
                    }
                });
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        switchRoleError: extractError( error_obj ),
                        fetchingUser: undefined
                    }
                });
            }).then(() => {
                if ( switchSuccess ){
                    dispatch( userResources());
                    dispatch({
                        type: SWITCH_ROLE_SUCCESS,
                        data: {
                            switchRoleSuccess: initLoad ? undefined : `Switched to ${newUserRole.RoleName || 'selected role'}`,
                            fetchingUser: undefined
                        }
                    });
                }
            });
    };
};

export const getUserInfo = ( userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getUserInfoAPI = urlBuilder(['users', userId]);
        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { isFetching: true, error: undefined } });
        return client.get( getUserInfoAPI )
            .then( response => {
                const userAccount = response.data.user;
                if ( response.status === 200 ) {
                    if ( userAccount.length > 0 ) {
                        dispatch({
                            type: FETCH_ACCOUNT_SUCCESS,
                            data: {
                                editUser: userAccount[0],
                                isFetching: undefined
                            }
                        });
                    } else {
                        dispatch({
                            type: FETCH_ACCOUNT_SUCCESS,
                            data: {
                                editUser: {},
                                isFetching: undefined
                            }
                        });
                    }
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        error: extractError( error_obj ),
                        isFetching: undefined
                    }
                });
                respond( 'getUserInfo', error_obj, dispatch );
            });
    };
};

export const setUserPreferences = ( preferences = {}) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const setUserPreferencesAPI = urlBuilder(['users', state().account.user.UserId, 'preferences']);

        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { processing: true, preferencesError: undefined } });

        return client.put( setUserPreferencesAPI, preferences )
            .then( response => {
                dispatch({
                    type: FETCH_ACCOUNT_SUCCESS,
                    data: {
                        preferencesSuccess: extractMessage( response.data ),
                        processing: undefined
                    }
                });

                dispatch( changeUserPrefs( preferences, state().account.user.Name ));
            })
            .catch( error_obj => {
                dispatch({
                    type: FETCH_ACCOUNT_SUCCESS,
                    data: {
                        preferencesError: extractError( error_obj ),
                        processing: undefined
                    }
                });

                respond( 'setUserPreferences', error_obj, dispatch );
            });
    };
};

export const resetDWHCredentials = ( callback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const resetRedshiftCredentialsAPI = urlBuilder(['users', state().auth.userName, 'password']);
        showLoading( 'Requesting new credentials...' );
        return client.put( resetRedshiftCredentialsAPI, {})
            .then( response => {
                if ( response.status === 200 ) {
                    if ( typeof callback === 'function' ){
                        callback();
                    }
                    dispatch({
                        type: DWH_CREDS_RESET_SUCCESS,
                        data: {
                            newDWHCredentials: response.data
                        }
                    });
                }
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'resetDWHCredentials', error_obj, dispatch );
            });
    };
};

export const emailAlertsSubscription = ( type ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const emailAlertsSubscriptionAPI = urlBuilder(['alertpreferences']);
        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { apProcessing: true, apError: undefined } });
        const methodType = ( type === 'post' ) ? client.post( emailAlertsSubscriptionAPI, {}) : client.delete( emailAlertsSubscriptionAPI );
        return methodType.then( response => {
            if ( response.status === 200 ) {
                dispatch({
                    type: EMAIL_ALERT_PREFERENCES,
                    data: {
                        apSuccess: extractMessage( response.data ),
                        apType: type,
                        apProcessing: undefined
                    }
                });
            }
        })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        apError: extractError( error_obj ),
                        apType: type,
                        apProcessing: undefined
                    }
                });
                respond( 'emailAlertsSubscription', error_obj, dispatch );
            });
    };
};

export const updateUserStatus = ( userId, operation ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateUserStatusAPI = urlBuilder(['users', userId, 'update'], [`operation=${operation}`]);
        return client.put( updateUserStatusAPI, ' ' )
            .then( response => {
                if ( response.status === 200 ) {
                    alertAndHide( 'success', 'User status updated', response?.data?.message || response?.data?.Message || '', () => {
                        dispatch( getUserInfo( userId ));
                    });
                }
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'updateUserStatus', error_obj, dispatch );
            });

    };
};

export const updateRole = ( userId, roleId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateRoleAPI = urlBuilder(['users', userId, 'roles', roleId]);
        dispatch({ type: CHANGE_UPDATE_PROCESSING, data: { isRegisteringStatus: true, updateRoleError: undefined } });
        return client.put( updateRoleAPI, ' ' )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: USER_STATUS_UPDATE_SUCCESS,
                        data: {
                            updateRoleSuccess: true,
                            isRegisteringStatus: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: UPDATE_ERROR,
                    data: {
                        updateRoleError: extractError( error_obj ),
                        isRegisteringStatus: undefined
                    }
                });
                respond( 'updateRole', error_obj, dispatch );
            });

    };
};

export const deleteUser = ( userId, data ) => {
    return ( dispatch, state ) => {
        const config = {
            data
        };

        const client = getHttp( dispatch, state );
        const deleteUserAPI = urlBuilder(['users', userId]);
        return client.delete( deleteUserAPI, config )
            .then( response => {
                dispatch({
                    type: DELETE_USER_SUCCESS,
                    data: {
                        deleteUserSuccess: response
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: DELETE_USER_ERROR,
                    data: { deleteUserError: extractError( error_obj ) }
                });
                respond( 'deleteUser', error_obj, dispatch );
            });
    };
};

export const getUsersResources = ( userId, persist = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getUsersResourcesAPI = urlBuilder(['users', userId, 'resources']);
        dispatch({ type: CHANGE_RESOURCES_FETCHING, data: { fetchingResources: true, fetchResourcesError: undefined } });
        return client.get( getUsersResourcesAPI )
            .then( response => {
                const responseObj = {
                    fetchingResources: undefined
                };
                persist ? responseObj.user = { ...state().account.user, ...{ resources: response.data } } : responseObj.usersResources = response.data;
                dispatch({
                    type: FETCH_RESOURCES_SUCCESS,
                    data: responseObj
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        fetchingResources: undefined,
                        fetchResourcesError: extractError( error_obj )
                    }
                });
                respond( 'getSystemUsers', error_obj, dispatch );
            });
    };
};


export const getSystemUsers = ( silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSystemUsersAPI = urlBuilder(['users']);
        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { fetchingUsers: !silent, silentFetching: silent, fetchUsersError: undefined } });
        return client.get( getSystemUsersAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_USERS,
                        data: {
                            usersList: response.data.users,
                            fetchingUsers: undefined, silentFetching: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        fetchUsersError: extractError( error_obj ),
                        fetchingUsers: undefined, silentFetching: undefined
                    }
                });
                respond( 'getSystemUsers', error_obj, dispatch );
            });
    };
};

export const getSystemStatus = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSystemStatusAPI = urlBuilder(['healthcheck']);
        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { fetchingSystemStatus: true, systemStatusError: undefined } });
        return client.get( getSystemStatusAPI )
            .then( response => {
                dispatch({
                    type: SYSTEM_STATUS,
                    data: {
                        systemStatus: response.data,
                        fetchingSystemStatus: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        systemStatusError: extractError( error_obj ),
                        fetchingSystemStatus: undefined
                    }
                });
                respond( 'getSystemStatus', error_obj, dispatch );
            });
    };
};

export const getInactiveDatasets = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getInactiveDatasetsAPI = urlBuilder(['datasets'], ['deleted=True']);
        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { fetchInactiveDatasets: true, inactiveDatasetsError: undefined } });
        return client.get( getInactiveDatasetsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_USER_DATASETS,
                    data: {
                        inactiveDatasets: response.data.datasets,
                        fetchInactiveDatasets: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        inactiveDatasetsError: extractError( error_obj ),
                        fetchInactiveDatasets: undefined
                    }
                });
                respond( 'getInactiveDatasets', error_obj, dispatch );
            });
    };
};

export const sendGrantReports = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getGrantRequestsAPI = urlBuilder(['users/reports/grants']);
        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { processingReports: true, reportStatusError: undefined } });
        return client.get( getGrantRequestsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: GRANT_REQUESTS,
                        data: {
                            reportStatus: extractMessage( response.data ),
                            processingReports: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        reportStatusError: extractError( error_obj ),
                        processingReports: undefined
                    }
                });
                respond( 'sendGrantReports', error_obj, dispatch );
            });
    };
};

export const getBillingDetails = ( input ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getBillingDetailsAPI = urlBuilder(['usage', input]);
        dispatch({ type: CHANGE_ACCOUNT_FETCHING, data: { fetchingBill: true, billError: undefined } });
        return client.get( getBillingDetailsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: BILLING_DETAILS,
                        data: {
                            billDetails: response.data,
                            fetchingBill: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        billError: extractError( error_obj ),
                        fetchingBill: undefined
                    }
                });
                respond( 'getBillingDetails', error_obj, dispatch );
            });
    };
};

export const submitFeedback = ( feedback, componentName = 'AppErrorBoundary', callback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const feedbackAPI = urlBuilder(['feedback']);
        showLoading( 'Submitting feedback...' );
        return client.post( feedbackAPI, feedback )
            .then( response => {
                const [ title, message ] = componentName === 'AppErrorBoundary'
                    ? [ 'Report Sent !!', 'Thank you for notifying us the issue. We have recieved your report and the team will investigate further' ]
                    : [ 'Feedback submitted', '' ];
                alertAndHide( 'success', title, message, () => {
                    if ( typeof callback === 'function' ){
                        callback( response );
                    }
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed !!', error_obj( error_obj ));
                respond( 'submitFeedback', error_obj, dispatch );
            });
    };
};


export const getSystemRoles = ( silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSystemRolesAPI = urlBuilder(['roles']);
        dispatch({ type: CHANGE_ROLES_FETCHING, data: { fetchingRoles: !silent, silentFetching: silent, fetchRolesError: undefined } });
        return client.get( getSystemRolesAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_ROLES,
                        data: {
                            rolesList: response.data.roles,
                            fetchingRoles: undefined, silentFetching: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ROLES_ERROR,
                    data: {
                        fetchRolesError: extractError( error_obj ),
                        fetchingRoles: undefined, silentFetching: undefined
                    }
                });
                respond( 'getSystemRoles', error_obj, dispatch );
            });
    };
};

export const createNewRole = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const createNewRoleAPI = urlBuilder(['roles']);
        dispatch({ type: CHANGE_ROLE_CREATION, data: { creatingRole: true, createRoleError: undefined } });
        return client.post( createNewRoleAPI, requestBody )
            .then( response => {
                const createSuccess = response.data;
                if ( response.status === 200 ) {
                    dispatch({
                        type: CREATE_ROLE_SUCCESS,
                        data: {
                            createRoleSuccess: createSuccess,
                            creatingRole: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: CREATE_ROLE_ERROR,
                    data: {
                        createRoleError: extractError( error_obj ),
                        creatingRole: undefined
                    }
                });
                respond( 'createNewRole', error_obj, dispatch );
            });
    };
};

export const getRoleDetails = ( roleId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getRoleDetailsAPI = urlBuilder(['roles', roleId]);
        dispatch({ type: CHANGE_ROLE_FETCHING, data: { isFetchingRoleDetails: true, fetchRoleError: undefined } });
        return client.get( getRoleDetailsAPI )
            .then( response => {
                const roleInfo = response.data.roles;
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_ROLE_SUCCESS,
                        data: {
                            roleDetail: roleInfo,
                            isFetchingRoleDetails: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ACCOUNT_ERROR,
                    data: {
                        fetchRoleError: extractError( error_obj ),
                        isFetchingRoleDetails: undefined
                    }
                });
                respond( 'getRoleDetails', error_obj, dispatch );
            });
    };
};


export const editRole = ( roleId, requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const editRoleAPI = urlBuilder(['roles', roleId]);
        dispatch({ type: CHANGE_ROLE_CREATION, data: { isEditingRole: true, editRoleError: undefined } });
        return client.put( editRoleAPI, requestBody )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: ROLE_EDIT_SUCCESS,
                        data: {
                            editRoleSuccess: true,
                            isEditingRole: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: ROLE_EDIT_ERROR,
                    data: {
                        editRoleError: extractError( error_obj ),
                        isEditingRole: undefined
                    }
                });
                respond( 'editRole', error_obj, dispatch );
            });
    };
};


export const deleteRole = ( roleId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteRoleAPI = urlBuilder(['roles', roleId]);
        dispatch({ type: CHANGE_ROLE_FETCHING, data: { isDeleting: true } });
        return client.delete( deleteRoleAPI )
            .then( response => {
                dispatch({
                    type: DELETE_ROLE_SUCCESS,
                    data: {
                        deleteRoleSuccess: response.data,
                        isDeleting: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: DELETE_ROLE_ERROR,
                    data: {
                        deleteRoleError: extractError( error_obj ),
                        isDeleting: undefined
                    }
                });
                respond( 'deleteRole', error_obj, dispatch );
            });
    };
};

export const getNotificationSettings = ( resourceType ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getNotificationSettingsAPI = urlBuilder(['notifications'], [`resource_type=${resourceType}`]);

        dispatch({
            type: CHANGE_NTFN_SETTINGS_FETCHING,
            data: {
                fetchingNtfnSettings: true,
                fetchNtfnSettingsError: undefined
            }
        });

        return client.get( getNotificationSettingsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_NTFN_SETTINGS_SUCCESS,
                        data: {
                            ntfnResourceObj : response.data.notifications,
                            fetchingNtfnSettings: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: FETCH_NTFN_SETTINGS_ERROR,
                    data: {
                        fetchNtfnSettingsError: extractError( error_obj ),
                        fetchingNtfnSettings: undefined
                    }
                });
                respond( 'getNotificationSettings', error_obj );
            });

    };
};

export const updateNotificationSettings = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateNotificationSettingsAPI = urlBuilder(['notifications']);

        return client.put( updateNotificationSettingsAPI, requestBody )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: UPDATE_NTFN_SETTINGS_SUCCESS,
                        data: {
                            editNSuccess: extractMessage( response.data )
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: UPDATE_NTFN_SETTINGS_ERROR,
                    data: {
                        editNError: extractError( error_obj )
                    }
                });
                respond( 'updateNotificationSettings', error_obj );
            });
    };
};

export const resetAccountProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_ACCOUNT_PROPS, data: propsToReset });
    };
};
