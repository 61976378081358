import { destroy } from 'redux-form';

import { USER_LOGOUT } from './common_actions';
import { hasAccess } from '../routes/RouteUtils';
import { getHttp, urlBuilder, extractError } from './utils';
const COMMON_UPDATE = 'COMMON_UPDATE';
const RESTAPI_RESULT = 'RESTAPI_RESULT';
const CHANGE_COMMON_FETCHING = 'CHANGE_COMMON_FETCHING';
export const RESET_RESOURCES = 'RESET_RESOURCES';

export const commonResourcesMapping = {
    connections: {
        permission: 'connections.list',
        permPathKey: 'listConnections',
        urlPath: 'connections',
        searchPath: 'connections',
        responseMapping: 'connections',
        fields: [ 'ConnectionId', 'ConnectionName', 'BulkDataLoad', 'ConnectionType', 'AccessType' ]
    },
    datasets: {
        permission: 'datasets.list',
        permPathKey: 'listDatasets',
        urlPath: 'datasets',
        searchPath: 'datasets',
        responseMapping: 'datasets',
        fields: [ 'DatasetId', 'DatasetName', 'Domain', 'TargetLocation', 'RegistrationStatus', 'FileType', 'AccessType', 'ConnectionType' ]
    },
    domains: {
        permission: 'domains.list',
        urlPath: 'domains',
        responseMapping: 'domains',
        fields: [ 'DomainName', 'DisplayName' ]
    },
    dataclassifications: {
        permission: 'dataclassifications.list',
        urlPath: 'datasets/dataclassifications',
        responseMapping: 'dataclassifications',
        fields: [ 'DataClassificationName' ]
    },
    views: {
        permission: 'views.list',
        permPathKey: 'listViews',
        urlPath: 'views',
        searchPath: 'views',
        responseMapping: 'views',
        fields: [ 'ViewId', 'ViewName', 'TargetLocation', 'Domain', 'AccessType' ]
    },
    dashboards: {
        permission: 'dashboards.list',
        permPathKey: 'listDashboards',
        urlPath: 'dashboards',
        searchPath: 'dashboards',
        responseMapping: 'dashboards',
        fields: [ 'DashboardId', 'DashboardName', 'DashboardType', 'AccessType' ]
    },
    models: {
        permission: 'mlmodels.list',
        permPathKey: 'listModels',
        urlPath: 'models',
        searchPath: 'models',
        responseMapping: 'models',
        fields: [ 'ModelId', 'ModelName', 'AccessType']
    },
    cers: {
        permission: 'cer.list',
        permPathKey: 'listEntityRecognizers',
        urlPath: 'cers',
        searchPath: 'entityRecognizers',
        responseMapping: 'cers',
        fields: [ 'EntityRecognizerId', 'EntityRecognizerName', 'AccessType' ]
    },
    notebooks: {
        permission: 'notebooks.list',
        permPathKey: 'listNotebooks',
        urlPath: 'notebooks',
        searchPath: 'notebooks',
        responseMapping: 'notebooks',
        fields: [ 'NotebookId', 'NotebookName', 'NotebookType', 'AccessType' ]
    },
    forecastjobs: {
        permission: 'forecastjobs.list',
        permPathKey: 'listForecasts',
        urlPath: 'forecastjobs',
        searchPath: 'forecast',
        responseMapping: 'forecast_jobs',
        fields: [ 'ForecastJobId', 'ForecastJobName', 'AccessType']
    },
    jobs: {
        permission: 'jobs.list',
        permPathKey: 'listJobs',
        urlPath: 'jobs',
        searchPath: 'jobs',
        responseMapping: 'jobs',
        fields: [ 'Id', 'JobName', 'RegistrationStatus', 'AccessType' ]
    },
    glueendpoints: {
        permission: 'endpoints.list',
        permPathKey: 'listEndpoints',
        urlPath: 'glueendpoints',
        searchPath: 'endpoints',
        responseMapping: 'glue_endpoints',
        fields: [ 'GlueEndpointId', 'GlueEndpointName', 'AccessType' ]
    },
    etlnotebooks: {
        permission: 'etlnotebooks.list',
        permPathKey: 'listETLNotebooks',
        urlPath: 'etlnotebooks',
        searchPath: 'etlNotebooks',
        responseMapping: 'notebooks',
        fields: [ 'NotebookId', 'NotebookName', 'NotebookType', 'AccessType' ]
    },
    libraries: {
        permission: 'sharedlibs.list',
        urlPath: 'jobs/libs',
        searchPath: 'libraries',
        responseMapping: 'libraries',
        fields: [ 'LibraryId', 'LibraryName', 'AccessType']
    },
    schedules: {
        permission: 'schedules.list',
        permPathKey: 'listSchedules',
        urlPath: 'schedules',
        searchPath: 'schedules',
        responseMapping: 'schedules',
        fields: [ 'ScheduleId', 'JobName', 'AccessType' ]
    },
    parameters: {
        permission: 'parameters.list',
        urlPath: 'parameters',
        responseMapping: 'parameters',
        fields: [ 'ParameterKey', 'Scope' ]
    },
    deepsearchindices: {
        permission: 'deepsearch-indices.list',
        permPathKey: 'listDeepsearchIndices',
        urlPath: 'deepsearch-indices',
        searchPath: 'deepsearchIndices',
        responseMapping: 'deepsearch-indices',
        fields: [ 'DeepSearchIndexId', 'DeepSearchIndexName', 'AccessType' ]
    },
    users: {
        permission: 'users.list',
        urlPath: 'users',
        responseMapping: 'users',
        fields: [ 'UserId', 'Name', 'EmailId', 'IsActive' ]
    },
    groups: {
        permission: 'any',
        permPathKey: 'groups',
        urlPath: 'groups',
        responseMapping: 'groups',
        fields: [ 'GroupId', 'GroupName', 'GroupType', 'GroupAdmins', 'Members']
    }
};

const initialState = {
    requests: { pending: [] },
    connections: { fetching: false, connections: [], error: false },
    datasets: { fetching: false, datasets: [], error: false },
    domains: { fetching: false, domains: [], error: false },
    dataclassifications: { fetching: false, dataclassifications: [], error: false },
    views: { fetching: false, views: [], error: false },
    dashboards: { fetching: false, dashboards: [], error: false },
    models: { fetching: false, models: [], error: false },
    cers: { fetching: false, cers: [], error: false },
    notebooks: { fetching: false, notebooks: [], error: false },
    forecastjobs: { fetching: false, forecast_jobs: [], error: false },
    jobs: { fetching: false, jobs: [], error: false },
    glueendpoints: { fetching: false, glue_endpoints: [], error: false },
    etlnotebooks: { fetching: false, notebooks: [], error: false },
    libraries: { fetching: false, libraries: [], error: false },
    schedules: { fetching: false, schedules: [], error: false },
    parameters: { fetching: false, parameters: [], error: false },
    deepsearchIndices: { fetching: false, parameters: [], error: false },
    users: { fetching: false, users: [], error: false },
    groups: { fetching: false, groups: [], error: false }
};

export default ( state = initialState, action ) => {
    switch ( action.type ) {
    case CHANGE_COMMON_FETCHING:
    case RESTAPI_RESULT:
    case COMMON_UPDATE:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    case RESET_RESOURCES:
        return initialState;
    default:
        return state;
    }
};

export const resetForm = ( formName ) => {
    return ( dispatch ) => {
        return dispatch( destroy( formName ));
    };
};

export const restAPICall = ( headers = {}, method, path, requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state, headers.authorization || false, headers.role_id || false );
        const url = urlBuilder([ path ]);
        let makeCall;
        dispatch({ type: CHANGE_COMMON_FETCHING, data: { fetching: true } });
        if (['post', 'put', 'delete'].includes( method )){
            if ( method === 'post' ){
                makeCall = client.post( url, requestBody );
            } else if ( method === 'put' ){
                makeCall = requestBody ? client.put( url, requestBody ) : client.put( url );
            } else if ( method === 'delete' ){
                makeCall = requestBody ? client.delete( url, requestBody ) : client.put( url );
            }
        } else {
            makeCall = client.get( url );
        }
        return makeCall
            .then( response => {
                dispatch({
                    type: RESTAPI_RESULT,
                    data: {
                        restResult: response,
                        fetching: undefined
                    }
                });
            })
            .catch( error => {
                const errorObj = error.response ? error.response : error.toJSON();
                dispatch({
                    type: RESTAPI_RESULT,
                    data: {
                        restResult: errorObj,
                        fetching: undefined
                    }
                });
            });
    };
};

const updatePendingRequests = ( resource, operation = 'remove' ) => {
    return ( dispatch, state ) => {
        const current = state()?.common?.requests?.pending || [];
        if ( current.length > 0 && operation === 'remove' ){
            const filtered = current.filter( el => el !== resource );
            dispatch({ type: COMMON_UPDATE, data: { requests: { pending: [...filtered] } }});
        } else if ( operation === 'add' ) {
            dispatch({ type: COMMON_UPDATE, data: { requests: { pending: [...current, resource] } }});
        }
    };
};

export const getResource = ( resourceType, ...args ) => {
    return ( dispatch, state ) => {
        if ( Object.keys( commonResourcesMapping ).includes( resourceType )){
            const { urlPath, responseMapping, fields } = commonResourcesMapping[resourceType];
            const client = getHttp( dispatch, state );
            const extraProjections = args.length > 0 ? `,${args.join( ',' )}` : '';
            const getUserDatasetsAPI = urlBuilder([urlPath], [`projectionExpression=${fields.join( ',' )}${extraProjections}`]);
            dispatch({ type: COMMON_UPDATE, data: { [resourceType]: { fetching: true, error: false, [responseMapping]: state().common?.[resourceType]?.[responseMapping] || [] } }});
            return client.get( getUserDatasetsAPI )
                .then( response => {
                    new Promise(( resolve, _reject ) =>  {
                        dispatch( updatePendingRequests( resourceType ));
                        resolve();
                    }).then(() => {
                        dispatch({
                            type: COMMON_UPDATE,
                            data: {
                                [resourceType]: {
                                    fetching: false,
                                    [responseMapping]: response.data?.[responseMapping],
                                    error: false
                                }
                            }
                        });
                    });
                })
                .catch( error_obj => {
                    new Promise(( resolve, _reject ) => {
                        dispatch( updatePendingRequests( resourceType ));
                        resolve();
                    }).then(() => {
                        dispatch({
                            type: COMMON_UPDATE,
                            data: {
                                [resourceType]: {
                                    fetching: false,
                                    [responseMapping]: [],
                                    error: extractError( error_obj )
                                }
                            }
                        });
                    });
                });
        }
    };
};

export const userResources = () => {
    return ( dispatch, getState ) => {
        const userRole = getState()?.account?.userRole || {};
        // abandon and reset all pending tasks if not auto reset in 90secs
        setTimeout(() => {
            dispatch({ type: COMMON_UPDATE, data: { requests: { pending: [] } }});
        }, 90000 );
        Object.keys( commonResourcesMapping ).forEach( resource => {
            if ( typeof resource !== 'undefined' ) {
                if ( hasAccess( userRole, commonResourcesMapping[resource]?.permission || 'none.none' )){
                    dispatch( updatePendingRequests( resource, 'add' ));
                    dispatch( getResource( resource ));
                } else {
                    dispatch({ type: COMMON_UPDATE, data: { [resource]: initialState[resource] } });
                }
            }
        });
    };

};