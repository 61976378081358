import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';

const SET_SMTP_ERROR = 'SET_SMTP_ERROR';
const CHANGE_SMTP_FETCHING = 'CHANGE_SMTP_FETCHING';
const RESET_SMTP_PROPERTIES = 'RESET_SMTP_PROPERTIES';
const FETCH_SMTP_MAPPINGS = 'FETCH_SMTP_MAPPINGS';
const ADD_SMTP_SUCCESS = 'ADD_SMTP_SUCCESS';
const UPDATE_SMTP_SUCCESS = 'UPDATE_SMTP_SUCCESS';
const DELETE_SMTP_SUCCESS = 'DELETE_SMTP_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_SMTP_ERROR:
    case CHANGE_SMTP_FETCHING:
    case FETCH_SMTP_MAPPINGS:
    case ADD_SMTP_SUCCESS:
    case UPDATE_SMTP_SUCCESS:
    case DELETE_SMTP_SUCCESS:
    case RESET_SMTP_PROPERTIES:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const getMailServers = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSMTPAPI = urlBuilder(['mailservers']);
        dispatch({ type: CHANGE_SMTP_FETCHING, data: { fetchingMS: true } });
        return client.get( getSMTPAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_SMTP_MAPPINGS,
                        data: {
                            mailservers: response.data.mailservers || [],
                            fetchingMS: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SMTP_ERROR,
                    data: {
                        fetchMSError: extractError( error_obj ),
                        fetchingMS: undefined
                    }
                });
                respond( 'getMailServers', error_obj, dispatch );
            });
    };
};

export const deleteMailserver = ( serverId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteMSAPI = urlBuilder(['mailservers', serverId]);
        return client.delete( deleteMSAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: DELETE_SMTP_SUCCESS,
                        data: {
                            deleteMSSuccess: extractMessage( response.data )
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SMTP_ERROR,
                    data: {
                        deleteMSError: extractError( error_obj )
                    }
                });
                respond( 'deleteMailserver', error_obj, dispatch );
            });
    };
};

export const addMailserver = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addMSAPI = urlBuilder(['mailservers']);
        return client.post( addMSAPI, requestBody )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: ADD_SMTP_SUCCESS,
                        data: {
                            addMSSuccess: extractMessage( response.data )
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SMTP_ERROR,
                    data: {
                        addMSError: extractError( error_obj )
                    }
                });
                respond( 'addMailserver', error_obj, dispatch );
            });
    };
};

export const updateMailserver = ( requestBody, serverId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateMSAPI = urlBuilder(['mailservers', serverId]);
        return client.put( updateMSAPI, requestBody )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: UPDATE_SMTP_SUCCESS,
                        data: {
                            updateMSSuccess: extractMessage( response.data )
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SMTP_ERROR,
                    data: {
                        updateMSError: extractError( error_obj )
                    }
                });
                respond( 'updateMailserver', error_obj, dispatch );
            });
    };
};

export const resetSmtpProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_SMTP_PROPERTIES, data: propsToReset });
    };
};
