import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ICON_MAP = {
    refresh: 'sync',
    clone: '',
    edit: 'pencil-alt',
    delete: 'trash',
    login: '',
    password: 'key',
    caretRight: 'caret-right',
    caretLeft: 'caret-left',
    download: 'download',
    ctaLink: 'long-arrow-right',
    appNavigator: 'route',
    addUser: 'user-plus',
    deleteUser: 'user-times',
    favourite: 'star',
    quickActions: 'gavel',
    infoCircle: 'info-circle',
    tasks: 'tasks',
    connection: 'bolt',
    add: 'plus',
    back: 'angle-double-left',
    play:'play-circle',
    stop:'stop-circle',
    restart: 'undo',
    warning: 'exclamation-triangle',
    'external-link': 'external-link-square-alt'
};

const Icon = ({
    icon,
    spin,
    ...props
}) => {
    const spinValue = spin || false;

    return <>
        <FontAwesomeIcon icon={ICON_MAP[icon] || icon} spin={spinValue} { ...props } fixedWidth />
    </>;
};

export default Icon;