import React, { useState } from 'react';
import ADPIcon from './Icon';
import { showToast } from '../utils/popUpUtils';

const Switch = ({
    onClick,
    onDisable,
    isCheckedByDefault = false,
    checkedLabel,
    unCheckedLabel,
    className,
    disabled = false
}) => {
    const [ isChecked, setIsChecked ] = useState( isCheckedByDefault );
    const onSwitchToggle = () => {
        onClick( !isChecked );
        setIsChecked( !isChecked );
    };
    const switchDisabled = () => {
        if ( typeof onDisable !== 'undefined' ) {
            onDisable();
        } else {
            showToast( 'warning', 'Operation not permitted !!' );
        }
    };

    return <div className={`form-switch ${className}`}>
        <label>{isChecked ? checkedLabel : unCheckedLabel } </label>
        <ADPIcon icon={isChecked ? 'toggle-on' : 'toggle-off' }
            onClick={ disabled ? switchDisabled : onSwitchToggle }
        />
    </div>;
};

export default Switch;