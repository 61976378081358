import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, FormGroup, InputGroup,
    ControlLabel, Checkbox, Tabs, Tab } from 'react-bootstrap';

import * as restActions from '../../modules/common';
import ADPIcon from '../common/Icon';
import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';
import { withHelperText } from '../utils/renderUtils';
import { showToast } from '../utils/popUpUtils';
import configuration from '../../config.json';
const enAPI = configuration.API_gateway;
const defaultMethod = { label: 'GET', value: 'get'};

class RestAPI extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            tab: 'tab_0',
            method: 'get',
            path: '',
            methodOption: defaultMethod,
            headers: {
                authorization: true,
                role_id: true
            }
        };
    }

    toggleHeader = ( prop ) => {
        this.setState(( prevState ) => ({
            headers : {
                ...prevState.headers,
                [prop]: !prevState.headers[prop]
            }
        }));
    }

    keyCheck = ( event ) => {
        if ( event.key === 'Enter' ) {
            this.sendRequest();
        }
    }

    sendRequest = () => {
        const { headers, method, path, requestBody } = this.state;
        this.setState({ tab: 'tab_0' });
        const req = requestBody && requestBody.length > 0 ? JSON.parse( requestBody ) : undefined;
        this.props.restAPICall( headers, method, path, req );
    }

    downloadAsFile = ( obj ) => {
        const element = document.createElement( 'a' );
        const file = new Blob([JSON.stringify( obj, null, 4 )], {type : 'application/json'});
        element.href = URL.createObjectURL( file );
        element.download = 'data.json';
        document.body.appendChild( element );
        element.click();
    }

    render() {
        const { fetching, restResult = {} } = this.props;
        const responseKeys = Object.keys( restResult ).filter( key => ( key && restResult[key] && ['object', 'string', 'boolean'].includes( typeof restResult[key])));
        return ( <Page title={ 'API test area' }>
            <Row className="restAPI-Container">
                <Col sm={12}>
                    <Card>
                        <Row>
                            <Col sm={3} md={2}>
                                <FormGroup>
                                    <Select
                                        name="method"
                                        placeholder="Select method"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        value={ this.state.methodOption }
                                        onChange= {( e ) => this.setState({ method: e ? e.value : defaultMethod.value, methodOption: e || defaultMethod })}
                                        options={[
                                            { label: 'GET', value: 'get' },
                                            { label: 'POST', value: 'post' },
                                            { label: 'PUT', value: 'put' },
                                            { label: 'DELETE', value: 'delete' }
                                        ]}
                                        isSearchable
                                        menuPosition={'absolute'}
                                        menuPortalTarget={document.body}
                                        classNamePrefix="select"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6} md={9}>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Addon>{ enAPI }</InputGroup.Addon>
                                        <input type="text" className="form-control pathString" value={ this.state.path } onChange={( e ) => this.setState({ path: e.target.value })} onKeyPress={ this.keyCheck }/>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={3} md={1}>
                                <button className="btn btn-primary btn-fill btn-block" onClick={() => this.sendRequest()}>Send</button>
                            </Col>
                        </Row>
                        <Row>
                            { ['put', 'post', 'delete'].includes( this.state.method ) && <Col sm={12}>
                                <ControlLabel>Request Body</ControlLabel>
                                <textarea className="form-control" rows={5} onChange={( e ) => this.setState({ requestBody: e.target.value })} >{this.state.requestBody}</textarea>
                                <br />
                            </Col> }
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <ControlLabel>Headers</ControlLabel>
                                <div className="form-control">
                                    <Checkbox inline checked={ this.state.headers.authorization } onChange={() => this.toggleHeader( 'authorization' ) }>Authorization</Checkbox>
                                    <Checkbox inline checked={ this.state.headers.role_id } onChange={() => this.toggleHeader( 'role_id' ) }>role_id</Checkbox>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={12}>
                    <Card>
                        { fetching && <Card ctTextCenter>
                            <ADPIcon icon="refresh" size="4x" spin/>
                        </Card>
                        }
                        { !fetching && restResult && <React.Fragment>
                            <p className={'pull-right'}>Status:
                                <b className={`${ restResult.status && restResult.status === 200 ? 'text-success' : 'text-warning'}`}>
                                &nbsp;{ restResult.status ? restResult.status : (( restResult.response && restResult.response.status ) ? restResult.response.status : 'Unknown' ) }
                                </b>
                            </p>
                            <br />
                            <Tabs defaultActiveKey={ this.state.tab } id="restResults">
                                { responseKeys.map(( key, index ) => {
                                    if (
                                        ( typeof restResult[key] === 'string' && restResult[key].length > 0 )                                    ||
                                    ( typeof restResult[key] === 'object' && Object.keys( restResult[key]).length > 0 )                                    ||
                                    (['boolean', 'bigint'].includes( typeof restResult[key]))
                                    ){
                                        return <Tab eventKey={`tab_${index}`} title={key} key={index} className="word-break">
                                            <pre className="json-holder">
                                                { withHelperText( 'Copy to clipboard', <CopyToClipboard text={JSON.stringify( restResult[key], null, 2 )}>
                                                    <button className="btn btn-fill btn-xs pull-right" onClick={() => showToast( 'info', 'Data copied !!' )}><ADPIcon icon="copy" /></button>
                                                </CopyToClipboard> ) }
                                                { withHelperText( 'Download as file',
                                                    <button className="btn btn-fill btn-xs pull-right" onClick={() => this.downloadAsFile( restResult[key])}><ADPIcon icon="download" /></button>
                                                ) }
                                                { JSON.stringify( restResult[key], null, 4 ) }
                                            </pre>
                                        </Tab>;
                                    } else {
                                        return null;
                                    }
                                }) }
                            </Tabs>
                        </React.Fragment>
                        }
                    </Card>
                </Col>
            </Row>
        </Page> );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        fetching: state.common?.fetching,
        restResult: state.common?.restResult
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        restAPICall: ( headers, method, path, requestBody ) => dispatch( restActions.restAPICall( headers, method, path, requestBody ))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( RestAPI );