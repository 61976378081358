import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { LinkContainer } from 'react-router-bootstrap';
import { FormGroup, ControlLabel, Row, Col } from 'react-bootstrap';
import 'react-table/react-table.css';

import Card from '../layout/Cards/Card';
import config from '../../config';
import * as authActions from '../../modules/auth';
import Tooltips from '../../mappings/helper_tooltips.json';
import { showLoading, alertAndHide } from '../utils/popUpUtils';
import { renderInput, formFieldHelper } from '../utils/renderUtils';

class Register extends React.Component {

    constructor( props ) {
        super( props );
        this.isValidatedform = this.isValidatedform.bind( this );
    }

    componentDidUpdate() {
        if ( this.props.isRegistered ) { this.registered(); }
    }

    isValidatedform( values ) {
        const [ _user_ignore, domain ] = values.Email.split( '@' );
        showLoading( 'Registering user...' );
        if ( config.Domain.indexOf( '*' ) > -1 || config.Domain.indexOf( domain ) > -1 ) {
            this.props.Register( values );
        }
        else {
            console.error( `${_user_ignore}@${domain} is not a valid email domain` );
            alertAndHide( 'error', 'Registration Error', `Please use any one from the list of ( ${config.Domain} ) email domain for registration` );
        }
    }

    registered() {
        Swal.fire({
            type: 'success',
            title: 'One Last Step !!',
            showCancelButton: false,
            text: 'Verification link has been sent to your email. Please click on the link to complete your registration.'
        }).then(( _results ) => {
            this.props.resetAuthProps({
                isRegistered: undefined
            });
            this.props.history.push( '/' );
        });
    }

    render() {
        const { handleSubmit, globalConfig } = this.props;
        const { permanentPaths = {} } = globalConfig;
        return ( <Row>
            <Col md={8} xs={10} mdOffset={2} xsOffset={1} id="registerform-container">
                <form onSubmit={handleSubmit( this.isValidatedform )} name="Register" id="Register">
                    <Card
                        textCenter
                        title="Register"
                        content={
                            <Row>
                                <Col sm={12} md={6}>
                                    <FormGroup controlId="Username">
                                        {formFieldHelper( Tooltips.User.UsernameRequirement )}
                                        <ControlLabel className="required-label" id="registerform-username-label">Username</ControlLabel>
                                        <Field name="Username" type="text" className="form-control" id="Username" placeholder="Username" component={renderInput} />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6}>
                                    <FormGroup controlId="Fullname">
                                        {formFieldHelper( 'Full Name with no special characters' )}
                                        <ControlLabel className="required-label" id="registerform-fullname-label">Full Name</ControlLabel>
                                        <Field name="name" type="text" className="form-control" id="Fullname" placeholder="Fullname" component={renderInput} />
                                    </FormGroup>
                                </Col>
                                <div className="clearfix"></div>
                                <Col sm={12} md={6}>
                                    <FormGroup controlId="Password">
                                        {formFieldHelper( Tooltips.User.PasswordRequirement )}
                                        <ControlLabel className="required-label" id="registerform-password-label">Password</ControlLabel>
                                        <Field name="Password" type="password" className="form-control" id="Password" placeholder="Password" component={renderInput} />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6}>
                                    <FormGroup controlId="ConfirmPassword">
                                        {formFieldHelper( 'Confirm Password must be same as Password.' )}
                                        <ControlLabel className="required-label" id="registerform-confirmpassword-label">Confirm Password</ControlLabel>
                                        <Field name="ConfirmPassword" type="password" className="form-control" id="ConfirmPassword" placeholder="Confirm Password" component={renderInput} />
                                    </FormGroup>
                                </Col>
                                <div className="clearfix"></div>
                                <Col sm={12} md={6}>
                                    <FormGroup controlId="Email">
                                        {formFieldHelper( `Use ${config.Domain.indexOf( '*' ) > -1 ? 'any' : config.Domain.join( ' or ' )} domain Email Address.` )}
                                        <ControlLabel className="required-label" id="registerform-email-label">Email Address</ControlLabel>
                                        <Field name="Email" type="email" className="form-control" id="Email" placeholder="Email Address" component={renderInput} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        ftTextCenter
                        legend={
                            <div>
                                <button className="btn btn-fill btn-wd" id="registerform-submit-btn" type="submit">Register</button>
                                <br /><br/>
                                {/* eslint-disable-next-line */}
                                <span className="text-muted">Already Registered ? <LinkContainer to={permanentPaths.login.path}><a className="text-anchor" id="registerform-login-link">Login</a></LinkContainer></span>
                            </div>
                        }
                    />
                </form>
            </Col>
        </Row> );
    }
}

const validate = values => {
    const errors = {};
    if ( !values.Username ) {
        errors.Username = 'Required';
    } else if ( !/^[a-z][a-z0-9]{4,19}$/g.test( values.Username )) {
        if ( !/^.{5,20}$/.test( values.Username )) {
            errors.Username = 'Username must be 5-50 characters';
        } else if ( !/^[a-z]/.test( values.Username )) {
            errors.Username = 'Username must start with an lowercase alphabet character';
        } else {
            errors.Username = 'Username must be lowercase alphanumeric charcaters only';
        }
    }
    if ( !values.name ) {
        errors.name = 'Required!';
    } else if ( !/^[a-zA-Z0-9.\s]*$/.test( values.name )) {
        errors.name = 'Name cannot contain special characters';
    }
    if ( !values.Email ) {
        errors.Email = 'Required!';
        // eslint-disable-next-line
    } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.Email)) {
        errors.Email = 'Invalid email address format';
    }
    if ( !values.Password ) {
        errors.Password = 'Required!';
        //eslint-disable-next-line
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]\{\}\(\)\?\-!@#%&/\\><':;|_~`])(?=.{8,99})/.test(values.Password)) {
        errors.Password = 'Password does not meet requirements';
    }
    if ( !values.ConfirmPassword ) {
        errors.ConfirmPassword = 'Required!';
    }
    if ( values ) {
        if ( values.Password !== values.ConfirmPassword ) {
            errors.ConfirmPassword = 'Passwords must be same!';
        }
    }

    return errors;
};

Register = reduxForm({
    form: 'Register',
    validate
})( Register );

const mapStateToProps = ( state, _props ) => {
    return {
        isRegistered: state.auth.isRegistered,
        globalConfig: state.globalConfig
    };
};


const mapDispatchToProps = ( dispatch ) => {
    return {
        Register: ( values ) => dispatch( authActions.Register( values )),
        resetAuthProps: ( propsToReset ) => dispatch( authActions.resetAuthProps( propsToReset ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Register ));
