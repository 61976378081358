import { reducer as reduxFormReducer } from 'redux-form';

import account from './account';
import auth from './auth';
import common from './common';
import connections from './connections';
import dashboards from './dashboards';
import dataset from './dataset';
import endpoints from './endpoints';
import error from './error';
import etl from './etl';
import language from './language';
import ml from './ml';
import notebooks from './notebooks';
import query from './query';
import rbac from './rbac';
import saml from './saml';
import smtp from './smtp';
import grants from './grants';
import schedule from './schedule';
import globalConfig from './globalConfig/reducer';
import forecast from './forecast/forecast';
import parameterStore from './parameters/parameterStore';
import deepsearch from './deepsearch';

// error should be the last entry

export default {
    form: reduxFormReducer,
    globalConfig,
    error,
    auth,
    account,
    common,
    connections,
    dashboards,
    dataset,
    endpoints,
    etl,
    language,
    ml,
    notebooks,
    query,
    rbac,
    schedule,
    saml,
    smtp,
    forecast,
    parameterStore,
    deepsearch,
    grants
};
