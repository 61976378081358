import Swal from 'sweetalert2';

import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';
import { convertToUTCTime } from '../components/utils/dateUtils';

const SET_CONNECTIONS_ERROR = 'SET_CONNECTIONS_ERROR';
const CHANGE_CONNECTION_FETCHING = 'CHANGE_CONNECTION_FETCHING';
const RESET_CONNECTION_PROPS = 'RESET_CONNECTION_PROPS';
const FETCH_CONNECTIONS = 'FETCH_CONNECTIONS';
const FETCH_CONNECTION_DETAILS = 'FETCH_CONNECTION_DETAILS';
const DELETE_CONNECTION_FAILURE = 'DELETE_CONNECTION_FAILURE';
const DELETE_CONNECTION_SUCCESS = 'DELETE_CONNECTION_SUCCESS';
const CONNECTION_SUCCESS = 'CONNECTION_SUCCESS';
const REGISTER_CONNECTION_FAILURE = 'REGISTER_CONNECTION_FAILURE';
const CHANGE_CONNECTION_REGISTRATION_PROCESSING = 'CHANGE_CONNECTION_REGISTRATION_PROCESSING';
const CHANGE_CONNECTION_UPDATE_PROCESSING = 'CHANGE_CONNECTION_UPDATE_PROCESSING';
const TASK_SUCCESS = 'TASK_SUCCESS';
const REGISTER_TASK_FAILURE = 'REGISTER_TASK_FAILURE';
const TASK_FAILURE = 'ASK_FAILURE';
const FETCH_CONNECTION_LOGS = 'FETCH_CONNECTION_LOGS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_CONNECTIONS_ERROR:
    case CHANGE_CONNECTION_FETCHING:
    case FETCH_CONNECTIONS:
    case FETCH_CONNECTION_DETAILS:
    case DELETE_CONNECTION_FAILURE:
    case DELETE_CONNECTION_SUCCESS:
    case CONNECTION_SUCCESS:
    case REGISTER_CONNECTION_FAILURE:
    case CHANGE_CONNECTION_REGISTRATION_PROCESSING:
    case CHANGE_CONNECTION_UPDATE_PROCESSING:
    case TASK_SUCCESS:
    case REGISTER_TASK_FAILURE:
    case TASK_FAILURE:
    case FETCH_CONNECTION_LOGS:
    case RESET_CONNECTION_PROPS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const getConnections = ( offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const getConnectionsAPI = ( offset && limit && sortOrder && sortBy )
            ? urlBuilder(['connections'], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`])
            : urlBuilder(['connections'], ['bulkdataload=false']);
        const client = getHttp( dispatch, state );
        dispatch({ type: CHANGE_CONNECTION_FETCHING, data: { fetchingConnections: true, connectionsList: undefined, connectionsListError: undefined } });
        return client.get( getConnectionsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_CONNECTIONS,
                        data: {
                            connectionsList: response.data,
                            fetchingConnections: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_CONNECTIONS_ERROR,
                    data: {
                        connectionsListError: extractError( error_obj ),
                        fetchingConnections: undefined
                    }
                });
                respond( 'getConnections', error_obj, dispatch );
            });
    };
};

export const getConnectionsWithFields = ( ...args ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getConnectionsAPI = urlBuilder(['connections'], [`projectionExpression=${args.join( ',' )}`]);
        dispatch({ type: CHANGE_CONNECTION_FETCHING, data: { fetchingConnections: true, connectionsList: undefined, connectionsListError: undefined } });
        return client.get( getConnectionsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_CONNECTIONS,
                        data: {
                            connectionsList: response.data,
                            fetchingConnections: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_CONNECTIONS_ERROR,
                    data: {
                        connectionsListError: extractError( error_obj ),
                        fetchingConnections: undefined
                    }
                });
                respond( 'getConnectionsWithFields', error_obj, dispatch );
            });
    };
};

export const getConnectionDetails = ( connectionId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getConnectionAPI = urlBuilder(['connections', connectionId]);
        dispatch({ type: CHANGE_CONNECTION_FETCHING, data: { fetchingConnectionDetails: true, connectionError: undefined } });
        return client.get( getConnectionAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_CONNECTION_DETAILS,
                        data: {
                            connection: response.data,
                            fetchingConnectionDetails: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_CONNECTIONS_ERROR,
                    data: {
                        connectionError: extractError( error_obj ),
                        fetchingConnectionDetails: undefined
                    }
                });
                respond( 'getConnectionDetails', error_obj, dispatch );
            });
    };
};

export const deleteConnection = ( connectionId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteConnectionAPI = urlBuilder(['connections', connectionId]);
        return client.delete( deleteConnectionAPI )
            .then( response => {
                dispatch({
                    type: DELETE_CONNECTION_SUCCESS,
                    data: { deleteconnectionSuccess: response.data }
                });
            })
            .catch( error_obj => {
                if ( error_obj.response.data.DependentResources ) {
                    Swal.close();
                    dispatch({
                        type: DELETE_CONNECTION_FAILURE,
                        data: {
                            entitiesDependencies: error_obj.response.data.DependentResources,
                            cancelFailed: extractError( error_obj )
                        }
                    });
                } else {
                    dispatch({
                        type: DELETE_CONNECTION_FAILURE,
                        data: { deleteconnectionError: extractError( error_obj ) }
                    });
                }
                respond( 'deleteConnection', error_obj, dispatch );
            });
    };
};

export const addconnection = ( connection, bulkdataload = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addconnectionAPI = urlBuilder(['connections'], [`bulkdataload=${bulkdataload}`]);
        dispatch({ type: CHANGE_CONNECTION_REGISTRATION_PROCESSING, data: { isRegistering: true, addconnectionError: undefined } });
        return client.post( addconnectionAPI, connection )
            .then( response => {
                dispatch({
                    type: CONNECTION_SUCCESS,
                    data: {
                        addconnectionSuccess: response.data,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_CONNECTION_FAILURE,
                    data: {
                        addconnectionError: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'addconnection', error_obj, dispatch );
            });
    };
};

export const editconnection = ( connection, connectionId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const editconnectionAPI = urlBuilder(['connections', connectionId]);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { updateConnectionProcessing: true, connectionUpdateError: undefined } });
        return client.put( editconnectionAPI, connection )
            .then( response => {
                const results = response.data;
                dispatch({
                    type: CONNECTION_SUCCESS,
                    data: {
                        connectionUpdateSuccess: results,
                        updateConnectionProcessing: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_CONNECTION_FAILURE,
                    data: {
                        connectionUpdateError: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj ),
                        updateConnectionProcessing: undefined
                    }
                });
                respond( 'editconnection', error_obj, dispatch );
            });
    };
};

export const getTasks = ( connectionId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getTasksAPI = urlBuilder(['connections', connectionId, 'dataloads']);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { fetchingTasks: true, fetchTasksError: undefined, task: undefined } });
        return client.get( getTasksAPI )
            .then( response => {
                const results = response.data.tasks;
                dispatch({
                    type: CONNECTION_SUCCESS,
                    data: {
                        connectionTasks: results,
                        fetchingTasks: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_CONNECTION_FAILURE,
                    data: {
                        fetchTasksError: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj ),
                        fetchingTasks: undefined
                    }
                });
                respond( 'getTasks', error_obj, dispatch );
            });
    };
};

export const getUserTasks = ( filterRegFailedTasks, ...args ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const params = typeof filterRegFailedTasks === 'boolean'
            ? `filterRegFailedTasks=${filterRegFailedTasks}&projectionExpression=${args.join( ',' )}`
            : `projectionExpression=${filterRegFailedTasks},${args.join( ',' )}`;
        const getTasksAPI = urlBuilder(['connections', 'tasks'], [params]);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { fetchingTasks: true, fetchTasksError: undefined, task: undefined } });
        return client.get( getTasksAPI )
            .then( response => {
                dispatch({
                    type: CONNECTION_SUCCESS,
                    data: {
                        userTasks: response.data.Tasks,
                        fetchingTasks: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_CONNECTION_FAILURE,
                    data: {
                        fetchTasksError: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj ),
                        fetchingTasks: undefined
                    }
                });
                respond( 'getTasks', error_obj, dispatch );
            });
    };
};

export const getSchemas = ( connectionId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSchemasAPI = urlBuilder(['connections', connectionId, 'extracttables']);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { fetchingSchemas: true, fetchSchemasError: undefined } });
        return client.get( getSchemasAPI )
            .then( response => {
                dispatch({
                    type: CONNECTION_SUCCESS,
                    data: {
                        connectionSchemas: response.data,
                        fetchingSchemas: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_CONNECTION_FAILURE,
                    data: {
                        fetchSchemasError: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj ),
                        fetchingSchemas: undefined
                    }
                });
                respond( 'getSchemas', error_obj, dispatch );
            });
    };
};

export const addTask = ( requestBody, connectionId, schedulable ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addTaskAPI = urlBuilder(['connections', connectionId, 'dataloads']);
        return client.post( addTaskAPI, requestBody )
            .then( response => {
                const results = response.data;
                dispatch({
                    type: TASK_SUCCESS,
                    data: {
                        taskCreated: results,
                        schedulable
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_TASK_FAILURE,
                    data: { taskCreationFailure: extractError( error_obj ) }
                });
                respond( 'addTask', error_obj, dispatch );
            });
    };
};

export const getTask = ( connectionId, taskId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getTaskAPI = urlBuilder(['connections', connectionId, 'dataloads', taskId]);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { fetchingTask: taskId } });
        return client.get( getTaskAPI )
            .then( response => {
                dispatch({
                    type: TASK_SUCCESS,
                    data: {
                        task: response.data,
                        fetchingTask: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: TASK_FAILURE,
                    data: {
                        taskError: extractError( error_obj ),
                        fetchingTask: undefined
                    }
                });
                respond( 'getTask', error_obj, dispatch );
            });
    };
};

export const runTask = ( connectionId, taskId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const runTaskAPI = urlBuilder(['connections', connectionId, 'dataloads', taskId]);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { runTaskFailed: undefined } });
        return client.post( runTaskAPI, {})
            .then( response => {
                dispatch({
                    type: TASK_SUCCESS,
                    data: {
                        runTaskSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: TASK_FAILURE,
                    data: {
                        runTaskFailed: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj )
                    }
                });
                respond( 'runTask', error_obj, dispatch );
            });
    };
};

export const getTaskRuns = ( connectionId, taskId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getTaskRunsAPI = urlBuilder(['connections', connectionId, 'dataloads', taskId, 'runs']);
        dispatch({
            type: CHANGE_CONNECTION_UPDATE_PROCESSING,
            data: {
                fetchingTaskRuns: taskId,
                taskRunsError: undefined
            }
        });
        return client.get( getTaskRunsAPI )
            .then( response => {
                dispatch({
                    type: TASK_SUCCESS,
                    data: {
                        taskRuns: response.data.TaskRuns,
                        fetchingTaskRuns: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: TASK_FAILURE,
                    data: {
                        taskRunsError: extractError( error_obj ),
                        fetchingTaskRuns: undefined
                    }
                });
                respond( 'getTask', error_obj, dispatch );
            });
    };
};

export const deleteTask = ( connectionId, taskId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteTaskAPI = urlBuilder(['connections', connectionId, 'dataloads', taskId]);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { abortTaskFailed: undefined } });
        return client.delete( deleteTaskAPI )
            .then( response => {
                dispatch({
                    type: TASK_SUCCESS,
                    data: {
                        abortTaskSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: TASK_FAILURE,
                    data: {
                        abortTaskFailed: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj )
                    }
                });
                respond( 'deleteTask', error_obj, dispatch );
            });
    };
};

export const updateTaskState = ( connectionId, taskId, requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateTaskStateAPI = urlBuilder(['connections', connectionId, 'dataloads', taskId]);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { updateTaskStateFailed: undefined } });
        return client.post( updateTaskStateAPI, requestBody )
            .then( response => {
                dispatch({
                    type: TASK_SUCCESS,
                    data: {
                        updateTaskStateSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: TASK_FAILURE,
                    data: {
                        updateTaskStateFailed: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj )
                    }
                });
                respond( 'updateTaskState', error_obj, dispatch );
            });
    };
};

export const updateTaskInstance = ( connectionId, taskId, requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updateTaskInstanceAPI = urlBuilder(['connections', connectionId, 'dataloads', taskId]);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { updateTaskInstanceFailed: undefined } });
        return client.put( updateTaskInstanceAPI, requestBody )
            .then( response => {
                dispatch({
                    type: TASK_SUCCESS,
                    data: {
                        updateTaskInstanceSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: TASK_FAILURE,
                    data: {
                        updateTaskInstanceFailed: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj )
                    }
                });
                respond( 'updateTaskInstance', error_obj, dispatch );
            });
    };
};

export const testConnection = ( connectionId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const testConnectionAPI = urlBuilder(['connections', connectionId, 'test']);
        dispatch({ type: CHANGE_CONNECTION_UPDATE_PROCESSING, data: { testingConnection: true, connectionTestFailed: undefined } });
        return client.get( testConnectionAPI )
            .then( response => {
                dispatch({
                    type: CONNECTION_SUCCESS,
                    data: {
                        connectionTestSuccess: extractMessage( response.data ),
                        testingConnection: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_CONNECTION_FAILURE,
                    data: {
                        connectionTestFailed: extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj ),
                        testingConnection: undefined
                    }
                });
                respond( 'testConnection', error_obj, dispatch );
            });
    };
};

export const resetConnectionProps = ( propsToReset = {}) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_CONNECTION_PROPS, data: propsToReset });
    };
};

export const fetchTaskLog = ( isDownload, connectionId, taskid, onErrorFetchLogs ) => {
    return function ( dispatch, getState ) {
        const { form: { showLogs } } = getState();

        if ( !showLogs.syncErrors ) {
            dispatch({
                type: FETCH_CONNECTION_LOGS,
                data: {
                    tasksLog: {
                        fetchingLogs: true,
                        logApiResponse: {},
                        logFetchSuccessful: true,
                        logFetchError: false
                    }
                }

            });

            const client = getHttp( dispatch, getState );
            const testConnectionAPI = urlBuilder(['connections', connectionId, 'dataloads', taskid, 'logs']);
            const utcLogStartTime = convertToUTCTime( showLogs.values.LogStartTime, 'YYYY-MM-DDTHH:mm:ss' );
            const utcLogEndTime = convertToUTCTime( showLogs.values.LogEndTime, 'YYYY-MM-DDTHH:mm:ss' );

            return client.post( `${testConnectionAPI}?servicename=dms`, {
                startTime: utcLogStartTime,
                endTime: utcLogEndTime
            })
                .then( response => {
                    dispatch({
                        type: FETCH_CONNECTION_LOGS,
                        data: {
                            tasksLog: {
                                fetchingLogs: false,
                                logApiResponse: response.data,
                                logFetchSuccessful: true,
                                logSuccessMessage: `Showing logs between UTC Time ${utcLogStartTime} till ${utcLogEndTime}`,
                                logFetchError: false
                            }
                        }
                    });

                    if ( isDownload ) {
                        window.open( response.data.url, '_self' );
                    }
                })
                .catch( error_obj => {
                    const errorMessage = extractMessage( error_obj?.response?.data?.connectionStatus, null ) || extractError( error_obj );
                    onErrorFetchLogs( errorMessage );
                    dispatch({
                        type: FETCH_CONNECTION_LOGS,
                        data: {
                            tasksLog: {
                                fetchingLogs: false,
                                logApiResponse: errorMessage,
                                logFetchError: true,
                                logFetchSuccessful: false
                            }
                        }
                    });
                });
        }

    };
};