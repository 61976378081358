import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import ADPIcon from '../common/Icon';

import Page from '../layout/Cards/ComponentView';
import { hasAccess } from '../../routes/RouteUtils';
import SubComp from '../layout/Cards/SubComponentListCard';

const component = 'etl';
class Etl extends React.Component {

    render() {
        const { globalConfig } = this.props;
        const { permanentPaths } = globalConfig;
        return ( <Page
            title={permanentPaths.etl.name}
            content={<Row>
                { Object.values( permanentPaths ).filter( el => el.parent === component ).map(( subComp, index ) => {
                    const id = `${component}-widget-${subComp.name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}`;
                    return hasAccess( this.props.userRole, subComp.permission ) ? <Col sm={6} md={3} key={index}>
                        <SubComp
                            id={id}
                            title={subComp.name}
                            description={subComp.description}
                            icon={<ADPIcon icon={subComp.icon}  />}
                            legend={<LinkContainer to={subComp.path} id={`${id}-link`}><span>{subComp.helperLinkText} <ADPIcon icon="ctaLink"  /></span></LinkContainer>}
                        />
                    </Col> : null;
                }) }
            </Row>}
        />
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        userRole: state.account.userRole,
        globalConfig: state.globalConfig
    };
};

export default connect( mapStateToProps, null )( Etl );
