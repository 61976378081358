import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';

const SET_ENDPOINT_ERROR = 'SET_ENDPOINT_ERROR';
const CHANGE_ENDPOINT_FETCHING = 'CHANGE_ENDPOINT_FETCHING';
const RESET_ENDPOINT_PROPS = 'RESET_ENDPOINT_PROPS';
const FETCH_ENDPOINTS = 'FETCH_ENDPOINTS';
const ADD_ENDPOINT_SUCCESS = 'ADD_ENDPOINT_SUCCESS';
const UPDATE_ENDPOINT_SUCCESS = 'UPDATE_ENDPOINT_SUCCESS';
const DELETE_ENDPOINT_SUCCESS = 'DELETE_ENDPOINT_SUCCESS';
const CHANGE_ENDPOINTS_AUTH_USERS_FETCHING = 'CHANGE_ENDPOINTS_AUTH_USERS_FETCHING';
const FETCH_ENDPOINTS_AUTHORIZED_USERS_SUCCESS = 'FETCH_ENDPOINTS_AUTHORIZED_USERS_SUCCESS';
const SET_ENDPOINTS_AUTHORIZED_USERS_ERROR = 'SET_ENDPOINTS_AUTHORIZED_USERS_ERROR';
const DELETE_ENDPOINTS_USER_ACCESS_SUCCESS = 'DELETE_ENDPOINTS_USER_ACCESS_SUCCESS';
const UPDATE_ENDPOINTS_USER_ACCESS_SUCCESS = 'UPDATE_ENDPOINTS_USER_ACCESS_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_ENDPOINT_ERROR:
    case CHANGE_ENDPOINT_FETCHING:
    case RESET_ENDPOINT_PROPS:
    case FETCH_ENDPOINTS:
    case ADD_ENDPOINT_SUCCESS:
    case UPDATE_ENDPOINT_SUCCESS:
    case DELETE_ENDPOINT_SUCCESS:
    case CHANGE_ENDPOINTS_AUTH_USERS_FETCHING :
    case FETCH_ENDPOINTS_AUTHORIZED_USERS_SUCCESS :
    case SET_ENDPOINTS_AUTHORIZED_USERS_ERROR :
    case DELETE_ENDPOINTS_USER_ACCESS_SUCCESS :
    case UPDATE_ENDPOINTS_USER_ACCESS_SUCCESS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const addEndpoint = ( endpoint ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addEndpointAPI = urlBuilder([ 'glueendpoints' ]);
        dispatch({ type: CHANGE_ENDPOINT_FETCHING, data: { isRegistering: true, addEndpointError: undefined } });
        return client.post( addEndpointAPI, endpoint )
            .then( response => {
                dispatch({
                    type: ADD_ENDPOINT_SUCCESS,
                    data: {
                        addEndpointSuccess: response.data,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ENDPOINT_ERROR,
                    data: {
                        addEndpointError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'addEndpoint', error_obj, dispatch );
            });
    };
};

export const getEndpoints = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getEndpointsAPI = urlBuilder([ 'glueendpoints' ]);
        dispatch({ type: CHANGE_ENDPOINT_FETCHING, data: { fetchingEndpoints: true, endpointsList: undefined, fetchEndpointsError: undefined } });
        return client.get( getEndpointsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_ENDPOINTS,
                    data: {
                        endpointsList: response.data.glue_endpoints,
                        fetchingEndpoints: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ENDPOINT_ERROR,
                    data: {
                        fetchEndpointsError: extractError( error_obj ),
                        fetchingEndpoints: undefined
                    }
                });
                respond( 'getEndpoints', error_obj, dispatch );
            });
    };
};

export const getEndpointDetails = ( id, silent = false ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getEndpointAPI = urlBuilder([ 'glueendpoints', id ]);
        dispatch({ type: CHANGE_ENDPOINT_FETCHING, data: { fetchingEndpoint: !silent, silentFetchingEP: silent, fetchEndpointError: undefined } });
        return client.get( getEndpointAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    response.data.PublicKeys = response.data.PublicKeys ? response.data.PublicKeys.map( function ( item ){ return atob( item ); }) : undefined;
                    dispatch({
                        type: FETCH_ENDPOINTS,
                        data: {
                            endpoint: response.data,
                            fetchingEndpoint: undefined, silentFetchingEP: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ENDPOINT_ERROR,
                    data: {
                        fetchEndpointError: extractError( error_obj ),
                        fetchingEndpoint: undefined, silentFetchingEP: undefined
                    }
                });
                respond( 'getEndpointDetails', error_obj, dispatch );
            });
    };
};

export const editEndpoint = ( endpoint, accessObj, endpointId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const editEndpointAPI = urlBuilder([ 'glueendpoints', endpointId ], ['operation=update']);
        const editEndpointAccessAPI = urlBuilder([ 'glueendpoints', endpointId ], ['operation=update-access']);
        return client.put( editEndpointAPI, endpoint )
            .then( response => {
                client.put( editEndpointAccessAPI, accessObj )
                    .then( _response2 => {
                        dispatch({
                            type: UPDATE_ENDPOINT_SUCCESS,
                            data: {
                                updateEndpointSuccess: response.data
                            }
                        });
                    })
                    .catch( error_obj => {
                        dispatch({
                            type: SET_ENDPOINT_ERROR,
                            data: {
                                updateEndpointError: extractError( error_obj )
                            }
                        });
                    });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ENDPOINT_ERROR,
                    data: {
                        updateEndpointError: extractError( error_obj )
                    }
                });
                respond( 'editEndpoint', error_obj, dispatch );
            });
    };
};

export const deleteEndpoint = ( id ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteEndpointAPI = urlBuilder([ 'glueendpoints', id ]);
        return client.delete( deleteEndpointAPI )
            .then( _response => {
                dispatch({
                    type: DELETE_ENDPOINT_SUCCESS,
                    data: {
                        deleteEndpointSuccess: true
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ENDPOINT_ERROR,
                    data: {
                        deleteEndpointError: extractError( error_obj )
                    }
                });
                respond( 'deleteEndpoint', error_obj, dispatch );
            });
    };
};

export const getAuthorizations = ( endpointId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder([ 'glueendpoints', endpointId, 'authorizedusers' ]);
        dispatch({ type: CHANGE_ENDPOINTS_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_ENDPOINTS_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_ENDPOINTS_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( requestBody, endpointId, userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder([ 'glueendpoints', endpointId, 'users', userId, 'grants' ]);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                dispatch({
                    type: UPDATE_ENDPOINTS_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ENDPOINTS_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( userId, endpointId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder([ 'glueendpoints', endpointId, 'users', userId, 'grants' ]);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                dispatch({
                    type: DELETE_ENDPOINTS_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_ENDPOINTS_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetEndpointProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_ENDPOINT_PROPS, data: propsToReset });
    };
};


