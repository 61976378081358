import React from 'react';

import BreadCrumbs from '../../common/Breadcrumbs';

class PageHeader extends React.Component {
    render() {
        return <div className="card-pageHeader">
            <BreadCrumbs title={ this.props.title } />

            <span className="title">{ this.props.title }</span>
            { this.props.actions }
        </div>;
    }
}

export default PageHeader;