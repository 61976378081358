import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import ADPIcon from '../common/Icon';

import HeaderLinks from './HeaderLinks';
import { withHelperText } from '../utils/renderUtils';
import { toMoment, findDuration } from '../utils/dateUtils';
import * as authActions from '../../modules/auth';

class Header extends React.Component {
    constructor( props ) {
        super( props );
        this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind( this );
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind( this );
    }

    checkActivity = () => {
        // Check if login is greater than 29mins old
        if ( this.props.profile && findDuration( toMoment( null, 'epoch' ), toMoment( this.props.loginTime, 'epoch' )).toFixed( 2 ) > 29 ) {
            // login is greater than 29mins old and hence needs a refresh
            this.props.refreshTokens( toMoment( null, 'epoch' ), this.props.refreshToken, this.props.profile.UserName, true );
            // reset the timer to checkActivity to 30Mins now
            setTimeout(() => this.checkActivity(), 1800000 );
        } else if ( typeof this.props.loginTime !== 'undefined' ) {
            // run the checkactivity every 15mins now
            setTimeout(() => this.checkActivity(), 900000 );
        }
    }

    componentDidMount(){
        this.handleMinimizeSidebar();
        setTimeout(() => this.checkActivity(), 10000 ); // kickoff initial check after 10Secs
    }

    componentDidUpdate( prevProps ) {
        const { profile } = this.props;
        const { permanentPaths } = this.props.globalConfig;
        if ( profile && typeof prevProps.profile === 'undefined' ){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                showCancelButton: true,
                timer: 10000,
                confirmButtonText: 'Settings',
                cancelButtonText: 'Close'
            });
            if ( profile.EmailSubscription === 'no' ){
                Toast.fire({
                    type: 'info',
                    title: 'Not subscribed for email alerts'
                }).then(( result ) => {
                    if ( result.value ){
                        this.props.history.push( permanentPaths.profile.path );
                    }
                });
            } else if ( profile.EmailSubscription === 'pending' ) {
                Toast.fire({
                    type: 'warning',
                    title: 'Email subscription verification pending'
                }).then(( result ) => {
                    if ( result.value ){
                        this.props.history.push( permanentPaths.profile.path );
                    }
                });
            }
        }
    }

    // function that makes the sidebar from normal to mini and vice-versa
    handleMinimizeSidebar() {
        document.body.classList.toggle( 'sidebar-mini' );
    }
    // function for responsive that hides/shows the sidebar
    mobileSidebarToggle( _e ) {
        document.documentElement.classList.toggle( 'nav-open' );
    }
    render() {
        return (
            <Navbar fluid>
                <div className="navbar-minimize">
                    <button
                        id="minimizeSidebar"
                        className="btn btn-default btn-fill btn-icon"
                        onClick={this.handleMinimizeSidebar}
                    >
                        { withHelperText( 'Close side menu', <ADPIcon icon="chevron-left" className="visible-on-sidebar-regular fa-fw" />, 'right' )}
                        { withHelperText( 'Expand side menu', <ADPIcon icon="bars" className="visible-on-sidebar-mini fa-fw" />, 'right' )}
                    </button>
                </div>
                <Navbar.Header>
                    <Navbar.Brand>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                </Navbar.Header>

                {/* Here we import the links that appear in navbar */}
                {window.innerWidth > 992 ? (
                    <Navbar.Collapse >
                        <HeaderLinks />
                    </Navbar.Collapse>
                ) : null}
            </Navbar>
        );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        profile: state.account.user,
        globalConfig: state.globalConfig,
        refreshToken: state.auth.refreshToken,
        loginTime: state.auth.loginTime
    };
};


const mapDispatchToProps = ( dispatch ) => {
    return {
        logout: () => dispatch( authActions.logout()),
        refreshTokens: ( loginTime, refreshToken, userName, forceReset ) => dispatch( authActions.keepAlive( loginTime, refreshToken, userName, forceReset ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Header ));
