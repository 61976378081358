import React from 'react';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import ReactPaginate from 'react-paginate';
import CreatableSelect from 'react-select/creatable';
import Flatpickr from 'react-flatpickr';
import { Controlled as CodeMirror } from 'react-codemirror2';

import {
    MenuItem,
    Row,
    Col,
    ControlLabel,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import { Field } from 'redux-form';
import ReactTooltip from 'react-tooltip';

import { accessAlert } from './popUpUtils';
import ADPIcon from '../common/Icon';

import 'react-tagsinput/react-tagsinput.css';
import 'flatpickr/dist/themes/material_blue.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

export const withHelperText = ( helperText = '', content, placement = 'bottom', type = 'dark', effect = 'float', rightOffset = 0 ) => {
    const generatedId = `tooltip-${( Math.random() * 20000000000 * 40000000000 ).toString( 36 )}`;
    return ( <React.Fragment>
        {/* <span data-tip data-for={generatedId} >{content}</span> */}
        <>
            {
                React.cloneElement(
                    content,
                    { 'data-tip': true, 'data-for': generatedId }
                )
            }
        </>
        <ReactTooltip aria-haspopup='true' id={generatedId} place={placement} globalEventOff={'click'} offset={{ 'right': rightOffset }}
            type={type} effect={effect} multiline={true} className={'standard-icon-tooltip'} html={true}>
            {helperText ? helperText : ' '}
        </ReactTooltip>
    </React.Fragment> );
};

export const refreshResource = ( hasAccess, helperText, onClickFn, disabledOn, cardAction = true, placement = 'bottom', id = '', prefix = 'Refresh' ) => {
    return hasAccess
        ? withHelperText(
            `${prefix} ${helperText}`,
            <button type="button" className={`btn btn-success btn-fill ${cardAction ? 'btn-xs cardAddButton' : 'btn-xs-fit'}`} onClick={() => onClickFn()} disabled={disabledOn} id={id}><ADPIcon icon={cardAction ? 'refresh' : 'redo'} spin={disabledOn} /></button>,
            placement
        )
        : withHelperText(
            `No access to ${helperText.toLowerCase()}, Please contact administrator for access !!`,
            <button type="button" className={`btn btn-success btn-fill ${cardAction ? 'btn-xs cardAddButton' : 'btn-xs-fit'}`} style={{ opacity: '0.5' }} id={id}><ADPIcon icon={cardAction ? 'refresh' : 'redo'} /></button>,
            placement
        );
};

export const cardAction = ( hasAccess, helperText, icon, onClickFn, disabledOn = false, noSpin = false, placement = 'bottom' ) => {
    return hasAccess
        ? withHelperText(
            `${helperText}`,
            <button type="button" className={'btn btn-fill btn-sm cardAction\''} onClick={() => onClickFn()} disabled={disabledOn}><ADPIcon icon={icon} spin={disabledOn && !noSpin} /></button>,
            placement
        )
        : withHelperText(
            `No access to ${helperText.toLowerCase()}, Please contact administrator for access !!`,
            <button type="button" className={'btn btn-fill btn-sm cardAction'} style={{ opacity: '0.5' }}><ADPIcon icon={icon} /></button>,
            placement
        );
};

export const menuItem = ( hasAccess, onSelect, icon, text, disabled = false, placement = 'left', id = '' ) => {
    return hasAccess
        ? <MenuItem id={id} eventKey={( Math.random() * 100 ).toFixed( 0 )} onSelect={onSelect} disabled={disabled}>
            {icon} {text}
        </MenuItem>
        : withHelperText(
            `No access to ${text.toLowerCase()} !!`,
            <MenuItem id={id} eventKey={( Math.random() * 100 ).toFixed( 0 )} onSelect={() => accessAlert( text )} disabled={true}>
                {icon} {text}
            </MenuItem>,
            placement
        );
};

export const protectedBtn = ( hasAccess, helperText, classes, onClick, icon, text = '', disabledOn, placement = 'bottom', id = '' ) => {
    return hasAccess
        ? withHelperText(
            helperText,
            <button type="button" className={`btn btn-fill ${classes}`} onClick={onClick} disabled={disabledOn} id={id}>{icon} {text}</button>,
            placement
        )
        : withHelperText(
            `No access to ${text ? text.toLowerCase() : ( helperText ? helperText.toLowerCase() : 'this service' )} !!`,
            <button type="button" className={`btn btn-fill ${classes}`} style={{ opacity: '0.5' }} id={id}>
                {icon} {text}
            </button>,
            placement
        );
};

export const withShortPopover = (( content, title, placeholder, placement = 'right', type = 'dark', effect = 'solid', id = '' ) => {
    const generatedId = `popover-${title.replace( ' ', '-' )}-${Math.floor( Math.random() * 1000 )}`;
    return ( <React.Fragment>
        <span data-for={generatedId} data-tip>
            <button id={id} type="button" className="link-button">{placeholder}</button>
        </span>
        <ReactTooltip aria-haspopup='true' id={generatedId} className={'standard-popover-tooltip'}
            place={placement} type={type} effect={effect} multiline={true} >
            {content}
        </ReactTooltip>
    </React.Fragment> );
});

export const imageByDatasetType = ( fileType ) => {
    return (( ext ) => {
        switch ( ext ) {
        case 'csv':
        case 'tsv':
            return 'file-csv';
        case 'txt':
            return 'file-alt';
        case 'pdf':
            return 'file-pdf';
        case 'doc':
        case 'docx':
            return 'file-word';
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'svg':
            return 'file-image';
        case 'wav':
        case 'mp3':
            return 'file-audio';
        case 'mp4':
        case 'mov':
        case 'mpg':
            return 'file-video';
        case 'py':
        case 'egg':
        case 'whl':
        case 'xhtml':
        case 'html':
        case 'htm':
            return 'file-code';
        case 'zip':
            return 'file-archive';
        default:
            return 'file';
        }
    })( fileType.toLowerCase());
};

export const renderInput = ({ input, data, type, min, max, step, className, placeholder, disabled, id, meta: { touched, error, active } }) => (
    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <input id={id} {...input} type={type} value={data ? data : input.value} min={min} max={max} step={step} className={className} placeholder={placeholder} disabled={disabled} formNoValidate />
        {touched && error && !active && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderInputWithAddon = ({ input, data, type, min, max, step, className, placeholder, disabled, addonPosition, addon, meta: { touched, error, active } }) => (
    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <InputGroup>
            {addonPosition === 'pre' && <InputGroup.Addon>{addon}</InputGroup.Addon>}
            <input {...input} type={type} value={data ? data : input.value} min={min} max={max} step={step} className={className} placeholder={placeholder} disabled={disabled} formNoValidate />
            {addonPosition === 'post' && <InputGroup.Addon>{addon}</InputGroup.Addon>}
        </InputGroup>
        {touched && error && !active && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderInputEditForms = ({ input, data, type, min, max, className, placeholder, disabled, meta: { touched, error } }) => (
    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <input {...input} type={type} value={data ? data : input.value} min={min} max={max} className={className} placeholder={placeholder} disabled={disabled} />
        {touched && error && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderInputCheckbox = ({ input, data, type, min, max, className, placeholder, disabled, meta: { touched, error, active } }) => (
    <span className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <input {...input} type={type} value={data ? data : input.value} min={min} max={max} className={className} placeholder={placeholder} disabled={disabled} />
        {touched && error && !active && <small className="text-danger pull-right">{error}</small>}
    </span>
);

export const renderCodeMirror = ({ input, data, type, options, className, placeholder, disabled, meta: { touched, error, active } }) => <span className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
    <input {...input} type={type} value={data ? data : input.value} className={`hidden ${className}`} placeholder={placeholder} disabled={disabled} />
    <CodeMirror className={className}
        value={data ? data : input.value}
        options={options}
        onBeforeChange={( editor, dataVal, value ) => {
            input.onChange( value );
        }}
        onChange={( editor, dataVal, value ) => {
            input.onChange( value );
        }} />
    {touched && error && !active && <small className="text-danger pull-right">{error}</small>}
</span>;

export const renderTagsInput = ({ input, initValue = [], className, meta: { touched, error }, props }) => (
    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <TagsInput
            {...input}
            {...props}
            value={initValue}
            className={className}
            onChange={( _e ) => { }}
            onlyUnique={true}
        />
        {touched && error && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderTextarea = ({ input, rows, className, data, placeholder, id, meta: { touched, error } }) => (

    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <textarea id={id} {...input} rows={rows} className={className} placeholder={placeholder}>{data}</textarea>
        {touched && error && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderSelect = ({ input, children, disabled, meta: { touched, error } }) => (
    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <select {...input} disabled={disabled} >
            {children}
        </select>
        {touched && error && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderFilterSelect = ({ input, options, id, meta: { error, touched, active },
    multi = false, disabled = false, isLoading = false, isClearable = true, isSearchable = true,
    placeholder = 'Select from options...', simpleErrorMode = false }) => (
    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <Select
            id={id}
            value={handleInputReactSelect( multi, input, options )}
            onChange={( selected ) => handleChangeReactSelect( selected, multi, input )}
            options={options}
            isClearable={isClearable}
            isSearchable={isSearchable}
            classNamePrefix="select"
            isMulti={multi}
            isDisabled={disabled}
            closeMenuOnSelect={!multi}
            captureMenuScroll={!multi}
            menuPlacement="auto"
            isLoading={isLoading}
            menuPosition={'absolute'}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            noOptionsMessage={() => 'No options found !!'}
            placeholder={placeholder ? placeholder.toString() : placeholder}
        />
        {!simpleErrorMode && touched && error && !active && <small className="text-danger pull-right">{error}</small>}
        {simpleErrorMode && error && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderCalendar = ({ input, minDate, maxDate, className, placeholder, disabled, hasSubmitted, meta: { touched, error, active } }) => {
    const fpNode = React.createRef();
    return <div className={(( touched || hasSubmitted ) && error ) ? 'form-group flatpickr-conatiner has-error' : 'form-group flatpickr-conatiner '}>
        <Flatpickr data-enable-time
            value={input.value}
            className={className}
            options={{
                ...( minDate ? { minDate } : {}),
                ...( maxDate ? { maxDate } : {})
            }}
            placeholder={placeholder}
            disabled={disabled}
            {...input}
            onChange={date => input.onChange( date )}
            ref={fpNode}
        />
        <ADPIcon icon="times" className="text-danger secondIcon"
            onClick={() => fpNode.current && fpNode.current.flatpickr.clear()} />
        <ADPIcon icon="calendar-alt" className="text-primary firstIcon" />
        {( touched || hasSubmitted ) && !active && error && <small className="text-danger pull-right">{error}</small>}
    </div>;
};

export const rendertagsReactSelect = ({ input, options, components, id, meta: { error, touched, active }, ...rest }) => (
    <div className={( touched && error ) ? 'form-group has-error' : 'form-group'}>
        <CreatableSelect
            id={id}
            value={input.value ? input.value.map( curr => ({ value: curr, label: curr })) : []}
            onChange={( selected ) => handleChangeReactSelect( selected, true, input )}
            options={options}
            noOptionsMessage={() => 'Type Something and press enter'}
            components={components}
            isClearable
            isSearchable
            classNamePrefix="select"
            isMulti
            {...rest}
        />
        {touched && error && !active && <small className="text-danger pull-right">{error}</small>}
    </div>
);

export const renderMembers = ({ addLabel, helperText, onlyIcon = false, fields, hasKey = true, mdWidth = 4, allowedMax = 100, meta: { _touched, _error } }) => {
    return <Col sm={12} md={mdWidth}>
        <Col sm={12} md={12} >{formFieldHelper( helperText )}
            <ControlLabel>{addLabel}</ControlLabel>
        </Col>


        {fields.length <= 0 && <Col sm={12} md={12} >
            {
                withHelperText( 'Add parameter',
                    <button type="button" onClick={() => fields.push()} className="btn btn-primary">
                        <ADPIcon icon="plus-circle" />{!onlyIcon && <>Add {addLabel}</>}
                    </button>, 'right' )
            }
        </Col>}
        {fields && fields.length > 0 && fields.length <= allowedMax &&
            <FormGroup controlId="APIAuthentication">
                {fields.map(( pair, index ) =>
                    <Row className="argumentBoxed" key={index}>
                        {hasKey && <Col sm={12} md={5}>
                            <Field name={`${pair}.key`}
                                type="text"
                                id={`${pair}.key`}
                                className="form-control"
                                placeholder="Key"
                                label="Key"
                                component={renderInput} />
                        </Col>}
                        <Col sm={10} md={hasKey ? 5 : 10}>
                            <Field name={`${pair}.value`}
                                type="text"
                                id={`${pair}.value`}
                                placeholder="Value"

                                className="form-control"
                                label="Value"
                                component={renderInput} />
                        </Col>
                        <Col sm={2} md={2}>
                            {withHelperText( 'Remove parameter', <button type="button" onClick={() => fields.remove( index )} className="btn btn-cancel">
                                <ADPIcon icon="times" className={'removeParam text-danger'} />
                            </button> )}
                        </Col>
                        <Col sm={10}>
                            {fields.length > 0 && fields.length - 1 === index && withHelperText( 'Add parameter',
                                <button type="button" onClick={() => fields.push()} className="btn btn-primary pull-right">
                                    <ADPIcon icon="plus-circle" />{!onlyIcon && <>Add {addLabel}</>}
                                </button>, 'right' )}
                        </Col>
                    </Row>
                )}
            </FormGroup>
        }
    </Col>;
};

export const formFieldHelper = ( helperText, tooltipPlacement = 'bottom', icon = 'info-circle', colorClass = 'text-info', postionClass = 'pull-left' ) => {
    return withHelperText( helperText, <ADPIcon icon={icon} className={`formFieldHelper ${colorClass} ${postionClass}`} />, tooltipPlacement, 'dark', 'float', 55 );
};

export const tagFormatter = ( tags = [], _list, _maxCount = 6 ) => {
    if ( tags.length > 0 ) {
        return tags.map(( tag ) => (
            `<span class='badge space-well badge-dark'>${tag}</span>`
        )).join( ' ' );
    } else {
        return '<span class=\'badge space-well badge-dark\'>None</span>';
    }
};

const handleChangeReactSelect = ( selected, multi, input ) => {
    if ( multi ) {
        return input.onChange( selected ? selected.map( curr => ( typeof curr === 'object' ? curr.value : curr )) : []);
    } else {
        return input.onChange( selected ? selected.value : '' );
    }
};

const handleInputReactSelect = ( multi, input, options ) => {
    if ( multi ) {
        const selectedObjs = [];
        if ( input.value ) {
            const optionValues = options.map( x => x.value );
            if ( typeof input.value === 'string' ) {
                if ( optionValues.includes( input.value )) {
                    selectedObjs.push(
                        options.filter( x => x.value === input.value )[0]
                    );
                }
            } else {
                input.value.forEach( obj => {
                    if ( optionValues.includes( obj )) {
                        selectedObjs.push(
                            options.filter( x => x.value === obj )[0]
                        );
                    }
                });
            }
        }
        return selectedObjs;
    } else {
        const selectedObj = options.find( curr => curr.value === input.value );
        return selectedObj ? selectedObj : '';
    }
};

export const abbrNumber = ( value ) => {
    let newValue = isNaN( value ) ? 0 : parseFloat( value );
    if ( value >= 1000 ) {
        const suffixes = ['', 'k', 'm', 'b', 't'];
        const suffixNum = Math.floor(( `${value}` ).length / 3 );
        let shortValue = '';
        for ( let precision = 2; precision >= 1; precision-- ) {
            shortValue = parseFloat(( suffixNum !== 0 ? ( value / Math.pow( 1000, suffixNum )) : value ).toPrecision( precision ));
            const dotLessShortValue = ( `${shortValue}` ).replace( /[^a-zA-Z 0-9]+/g, '' );
            if ( dotLessShortValue.length <= 2 ) { break; }
        }
        if ( shortValue % 1 !== 0 ) { shortValue = shortValue.toFixed( 1 ); }
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
};

export const paginator = ( selected, pageCount, handleChange ) => {
    return <ReactPaginate previousLabel={<ADPIcon icon="caret-left" />}
        nextLabel={<ADPIcon icon="caret-right" />}
        breakLabel={'...'}
        initialPage={selected}
        forcePage={selected}
        disableInitialCallback={true}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handleChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        previousClassName={'paginationLink'}
        nextClassName={'paginationLink'}
        activeClassName={'active'}
    />;
};

export const renderCustomMembers = ({ addLabel, children, fields, mdWidth = 4, meta: { _touched, _error } }) => (
    <Col sm={12} md={mdWidth}>
        <Col sm={12} >
            <h6>{addLabel} {
                withHelperText( 'Add parameter',
                    <button type="button"
                        onClick={() => fields.push()}
                        className="btn btn-primary">
                        <ADPIcon icon="plus-circle" />
                    </button>, 'right' )
            }</h6>
        </Col>

        {fields && fields.length > 0 &&
            <FormGroup controlId="APIAuthentication">
                {fields.map(( pair, index ) =>
                    <div key={index}>
                        {children}
                        <Col sm={2} md={2}>

                            {withHelperText( 'Remove parameter', <button
                                type="button"
                                onClick={() => fields.remove( index )}
                                className="btn btn-cancel">
                                <ADPIcon icon="times" className={'removeParam text-danger'} />
                            </button> )}

                        </Col>
                        <Col sm={10}>
                            {fields.length > 0 && fields.length - 1 === index && withHelperText( 'Add parameter',
                                <button type="button"
                                    onClick={() => fields.push()}
                                    className="btn btn-primary pull-right">
                                    <ADPIcon icon="plus-circle" />
                                </button>, 'right' )}
                        </Col>
                    </div>
                )}
            </FormGroup>
        }
    </Col>
);