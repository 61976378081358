import { reset } from 'redux-form';
import Swal from 'sweetalert2';

import { getHttp, urlBuilder, extractError, respond } from '../utils';
import { showLoading, alertAndHide } from '../../components/utils/popUpUtils';
import { USER_LOGOUT } from '../common_actions';

const initialState = {
    forecastLists: [],
    forecastJobDetails: {},
    forecastJobDatasets: [],
    selectedDataset: {},
    forecastJobPredictors: undefined,
    selectedPredictors: {},
    forecastJobVersions: [],
    selectedVersions: {},
    fetchingPredictors: undefined,
    fetchingPredictorsError: undefined,
    fetchingVersions: undefined,
    fetchingVersionsError: undefined,
    masterReportObj: {},
    forecastReportsList: []
};

export const RESET_ALL = 'RESET_ALL';
export const SET_FORECAST_JOB_LISTS = 'SET_FORECAST_JOB_LISTS';
export const SET_FORECAST_DATASET_LISTS = 'SET_FORECAST_DATASET_LISTS';
export const SET_FORECAST_DATASET_REGISTRATION_STATUS = 'SET_FORECAST_DATASET_REGISTRATION_STATUS';
export const SET_FORECAST_INFERENCE_LISTS = 'SET_FORECAST_INFERENCE_LISTS';
export const SET_FORECAST_INFERENCE_REGISTRATION_STATUS = 'SET_FORECAST_INFERENCE_REGISTRATION_STATUS';
export const RESET_FORECAST_PROPS = 'RESET_FORECAST_PROPS';
export const SET_USER_GRANT_LIST = 'SET_USER_GRANT_LIST';
export const SET_FORECAST_PREDICTORS_LISTS = 'SET_FORECAST_PREDICTORS_LISTS';
export const SET_FORECAST_PREDICTORS_DETAILS = 'SET_FORECAST_PREDICTORS_DETAILS';
export const SET_FORECAST_PREDICTORS_VERSIONS = 'SET_FORECAST_PREDICTORS_VERSIONS';

export default ( state = initialState, action ) => {
    switch ( action.type ) {
    case SET_FORECAST_JOB_LISTS:
    case SET_FORECAST_INFERENCE_LISTS:
    case SET_FORECAST_INFERENCE_REGISTRATION_STATUS:
    case SET_FORECAST_DATASET_LISTS:
    case SET_FORECAST_DATASET_REGISTRATION_STATUS:
    case RESET_FORECAST_PROPS:
    case SET_USER_GRANT_LIST:
    case SET_FORECAST_PREDICTORS_LISTS:
    case SET_FORECAST_PREDICTORS_DETAILS:
    case SET_FORECAST_PREDICTORS_VERSIONS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    case RESET_ALL:
        return initialState;
    default:
        return state;
    }
};

export const getForecastJobs = ( offset = 1, limit = 200, sortOrder = 'asc', sortBy = 'ForecastJobName' ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getForecastJobsURL = urlBuilder(['forecastjobs'], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]);

        dispatch({
            type: RESET_ALL
        });
        dispatch({
            type: SET_FORECAST_JOB_LISTS,
            data: {
                fetching: true
            }
        });

        return client.get( getForecastJobsURL )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: SET_FORECAST_JOB_LISTS,
                        data: {
                            fetching: undefined,
                            forecastLists: response.data.forecast_jobs,
                            total_count: response.data.total_count
                        }
                    });
                }
            }).catch(( error_obj ) => {
                dispatch({
                    type: SET_FORECAST_JOB_LISTS,
                    data: {
                        fetchingForecastListError: error_obj.Message || extractError( error_obj ) || 'Network Error',
                        forecastLists: undefined,
                        fetching: undefined
                    }
                });
                respond( 'getForecastJobs', error_obj, dispatch );
            });
    };
};

export const getForecastJobDetails = ( jobId ) => {
    return ( dispatch, getState ) => {
        const {
            forecastLists
        } = getState().forecast;

        dispatch({
            type: RESET_FORECAST_PROPS,
            data: {
                ...initialState,
                forecastLists
            }
        });

        dispatch({
            type: SET_FORECAST_JOB_LISTS,
            data: {
                fetching: true,
                forecastJobDetails: undefined
            }
        });

        const client = getHttp( dispatch, getState );
        const getForecastJobsURL = urlBuilder(['forecastjobs', jobId]);

        return client.get( getForecastJobsURL )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: SET_FORECAST_JOB_LISTS,
                        data: {
                            fetching: undefined,
                            forecastJobDetails: response.data
                        }
                    });
                }
            }).catch(( error_obj ) => {
                dispatch({
                    type: SET_FORECAST_JOB_LISTS,
                    data: {
                        fetching: undefined,
                        forecastJobDetails: undefined
                    }
                });
                respond( 'getForecastJobs', error_obj, dispatch );
            });

    };
};

export const postForecastJobs = ( values, onSuccessCallback ) => {
    return ( dispatch, getState ) => {
        const client = getHttp( dispatch, getState );
        const getForecastJobsURL = urlBuilder(['forecastjobs']);
        dispatch({
            type: SET_FORECAST_JOB_LISTS,
            data: {
                fetching: true,
                forecastLists: []
            }
        });

        showLoading( 'Creating the forecast job ' );

        return client.post( getForecastJobsURL, values )
            .then( response => {
                if ( response.status === 200 ) {
                    alertAndHide( 'success', 'Forecast job created successfully', `${values.ForecastJobName}  with Id ${response.data.ForecastJobId} has been created`, () => {
                        onSuccessCallback();
                        dispatch( reset( 'NewForecastJobForm' ));
                    });
                }
            }).catch(( err ) => {
                alertAndHide( 'error', 'Forecast job creation failed', extractError( err ));
            });
    };
};

export const onUpdateForecastJob = ( jobId, description, setIsInEditMode ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const editForecastJobURL = urlBuilder(['forecastjobs', jobId]);
        showLoading( 'Updating forecast job ' );
        return client.put( editForecastJobURL, {
            Description: description
        }).then( _response => {
            alertAndHide( 'success', 'Forecast job updated successfully', 'Description has been updated', () => {
                dispatch( getForecastJobDetails( jobId, true ));
                setIsInEditMode( false );
            });
        }).catch(( err ) => {
            alertAndHide( 'error', 'Update failed', extractError( err ));
        });
    };
};

export const onDeleteForecastJob = ( jobId, onDeleteCallback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteForecastJobURL = urlBuilder(['forecastjobs', jobId]);
        alertAndHide( 'warning', 'Are you sure?', 'Forecast job will be permanently deleted. You won\'t be able to revert this!', () => {}, true )
            .then(( result ) => {
                if ( result.value ){
                    showLoading( 'Deleting Forecast job ' );
                    return client.delete( deleteForecastJobURL ).then( _response => {
                        alertAndHide( 'success', 'Deletion completed', 'Forecast job deleted successfully', () => {
                            onDeleteCallback();
                        });
                    }).catch(( err ) => {
                        if ( err.response.data.DependentResources ) {
                            Swal.close();
                            dispatch({
                                type: SET_FORECAST_JOB_LISTS,
                                data: {
                                    entitiesDependencies: err.response.data.DependentResources,
                                    cancelFailed: extractError( err )
                                }
                            });
                        } else {
                            alertAndHide( 'error', extractError( err ), '' );
                        }
                    });
                }
            });
    };
};

export const resetForecastProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_FORECAST_PROPS, data: propsToReset });
    };
};