import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';

const SET_SCHEDULE_ERROR = 'SET_SCHEDULE_ERROR';
const CHANGE_SCHEDULES_FETCHING = 'CHANGE_SCHEDULES_FETCHING';
const FETCH_SCHEDULES = 'FETCH_SCHEDULES';
const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
const EXECUTION_LIST = 'EXECUTION_LIST';
const SCHEDULE_DOWNLOAD_LOG = 'SCHEDULE_DOWNLOAD_LOG';
const RESET_SCHEDULE_PROPS = 'RESET_SCHEDULE_PROPS';
const CHANGE_SCHEDULE_AUTH_USERS_FETCHING = 'CHANGE_SCHEDULE_AUTH_USERS_FETCHING';
const FETCH_SCHEDULE_AUTHORIZED_USERS_SUCCESS = 'FETCH_SCHEDULE_AUTHORIZED_USERS_SUCCESS';
const SET_SCHEDULE_AUTHORIZED_USERS_ERROR = 'SET_SCHEDULE_AUTHORIZED_USERS_ERROR';
const DELETE_SCHEDULE_USER_ACCESS_SUCCESS = 'DELETE_SCHEDULE_USER_ACCESS_SUCCESS';
const UPDATE_SCHEDULE_USER_ACCESS_SUCCESS = 'UPDATE_SCHEDULE_USER_ACCESS_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_SCHEDULE_ERROR:
    case CHANGE_SCHEDULES_FETCHING:
    case FETCH_SCHEDULES:
    case ADD_SCHEDULE_SUCCESS:
    case DELETE_SCHEDULE_SUCCESS:
    case EXECUTION_LIST:
    case SCHEDULE_DOWNLOAD_LOG:
    case RESET_SCHEDULE_PROPS:
    case CHANGE_SCHEDULE_AUTH_USERS_FETCHING :
    case FETCH_SCHEDULE_AUTHORIZED_USERS_SUCCESS :
    case SET_SCHEDULE_AUTHORIZED_USERS_ERROR :
    case DELETE_SCHEDULE_USER_ACCESS_SUCCESS :
    case UPDATE_SCHEDULE_USER_ACCESS_SUCCESS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const addSchedule = ( schedule ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addScheduleAPI = urlBuilder(['schedules/schedule']);
        dispatch({ type: CHANGE_SCHEDULES_FETCHING, data: { isRegistering: true } });
        return client.post( addScheduleAPI, schedule )
            .then( response => {
                dispatch({
                    type: ADD_SCHEDULE_SUCCESS,
                    data: {
                        scheduleSuccess: response.data,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_ERROR,
                    data: {
                        scheduleError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'addSchedule', error_obj, dispatch );
            });
    };
};

export const getSchedulesList = ( offset, limit, sortOrder, sortBy  ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getSchedulesAPI =  ( offset && limit && sortOrder && sortBy ) ? urlBuilder(['schedules'], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]) : urlBuilder(['schedules']);
        dispatch({ type: CHANGE_SCHEDULES_FETCHING, data: { fetchingSchedules: true, fetchSchedulesError: undefined } });
        return client.get( getSchedulesAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_SCHEDULES,
                        data: {
                            schedulesList: response.data,
                            fetchingSchedules: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_ERROR,
                    data: {
                        fetchSchedulesError: extractError( error_obj ),
                        fetchingSchedules: undefined
                    }
                });
                respond( 'getSchedulesList', error_obj, dispatch );
            });
    };
};

export const getScheduleDetails = ( id ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getScheduleAPI =  urlBuilder(['schedules', id]);
        dispatch({ type: CHANGE_SCHEDULES_FETCHING, data: { fetchingSchedule: true, fetchScheduleError: undefined } });
        return client.get( getScheduleAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_SCHEDULES,
                        data: {
                            schedule: response.data,
                            fetchingSchedule: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_ERROR,
                    data: {
                        fetchScheduleError: extractError( error_obj ),
                        fetchingSchedule: undefined
                    }
                });
                respond( 'getScheduleDetails', error_obj, dispatch );
            });
    };
};

export const toggleScheduleStatus = ( id, status, requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const toggleScheduleStatusAPI = urlBuilder([ 'schedules', id, status ]);
        dispatch({ type: CHANGE_SCHEDULES_FETCHING, data: { isRegistering: true } });
        return client.put( toggleScheduleStatusAPI, requestBody || ' ' )
            .then( response => {
                dispatch({
                    type: DELETE_SCHEDULE_SUCCESS,
                    data: {
                        scheduleStatus: extractMessage( response.data ),
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_ERROR,
                    data: {
                        scheduleStatusError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'toggleScheduleStatus', error_obj, dispatch );
            });
    };
};

export const deleteSchedule = ( id ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteScheduleAPI = urlBuilder([ 'schedules', id ]);
        dispatch({ type: CHANGE_SCHEDULES_FETCHING, data: { isRegistering: true } });
        return client.delete( deleteScheduleAPI )
            .then( _response => {
                dispatch({
                    type: DELETE_SCHEDULE_SUCCESS,
                    data: {
                        deleteScheduleSuccess: true,
                        isRegistering: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_ERROR,
                    data: {
                        deleteScheduleError: extractError( error_obj ),
                        isRegistering: undefined
                    }
                });
                respond( 'deleteSchedule', error_obj, dispatch );
            });
    };
};

export const logdownload = ( scheduleId, executionId, type ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const logdownloadAPI = urlBuilder([ 'schedules', scheduleId, 'executions', executionId, 'logs' ], ['servicename=glue', `logtype=${type}`]);
        dispatch({ type: CHANGE_SCHEDULES_FETCHING, data: { downloading: true } });
        return client.get( logdownloadAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({ type: SCHEDULE_DOWNLOAD_LOG, data: { logDownloadSuccess: true, downloadSuccess: true, downloading: undefined } });
                    window.open( response.data.url, '_blank' );
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_ERROR,
                    data: {
                        logDownloadError: extractError( error_obj ),
                        downloading: undefined
                    }
                });
                respond( 'logdownload', error_obj, dispatch );
            });
    };
};

export const getExecutionStatus = ( scheduleId, silent, offset, limit, sortOrder, sortBy ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const jobsAPI = urlBuilder([ 'schedules', scheduleId, 'executions' ], [`offset=${offset}`, `limit=${limit}`, `sortorder=${sortOrder}`, `sortby=${sortBy}`]);
        dispatch({ type: CHANGE_SCHEDULES_FETCHING, data: { isFetching: !silent, isRefreshing: silent } });
        return client.get( jobsAPI )
            .then( response => {
                dispatch({
                    type: EXECUTION_LIST,
                    data: {
                        executions: response.data,
                        isFetching: undefined,
                        isRefreshing: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_ERROR,
                    data: {
                        error: extractError( error_obj ),
                        isFetching: undefined, isRefreshing: undefined
                    }
                });
                respond( 'getExecutionStatus', error_obj, dispatch );
            });
    };
};

export const getAuthorizations = ( scheduleId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder([ 'schedules', scheduleId, 'authorizedusers' ]);
        dispatch({ type: CHANGE_SCHEDULE_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_SCHEDULE_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_SCHEDULE_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( requestBody, scheduleId, userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder([ 'schedules', scheduleId, 'users', userId, 'grants' ]);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                dispatch({
                    type: UPDATE_SCHEDULE_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( userId, scheduleId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder([ 'schedules', scheduleId, 'users', userId, 'grants' ]);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                dispatch({
                    type: DELETE_SCHEDULE_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_SCHEDULE_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetScheduleProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_SCHEDULE_PROPS, data: propsToReset });
    };
};


