import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';
import ADPIcon from '../common/Icon';

import { showLoading } from '../utils/popUpUtils';
import * as authActions from '../../modules/auth';
import * as accountActions from '../../modules/account';

const terms = require( '../../variables/agreement/en_us.md' );

class UserAgreement extends React.Component {

    constructor( props ) {
        super( props );
        this.userCreated = this.userCreated.bind( this );
        this.userCreationFailed = this.userCreationFailed.bind( this );
        this.state = {
            agreeTerms: false,
            fetching: false,
            fetchError: undefined,
            terms: ''
        };
    }

    componentDidMount() {
        this.loadTerms();
    }

    componentDidUpdate() {
        const { userCreated, userCreationFailed, DBUserName, DBPassword } = this.props;
        if ( userCreationFailed ) { this.userCreationFailed( userCreationFailed ); }
        if ( userCreated ) { this.userCreated( DBUserName, DBPassword ); }
    }

    backupTerms = () => {
        this.setState({
            fetching: true
        }, () => {
            fetch( terms )
                .then( res => res.text())
                .then( text => this.setState({
                    fetching: false,
                    terms: text
                }))
                .catch( err => this.setState({
                    fetching: false,
                    fetchError: err
                }));
        });
    }

    loadTerms = () => {
        const online = `${window.location.origin}/en_us.md`;
        this.setState({
            fetching: true
        }, () => {
            fetch( online )
                .then( res => res.text())
                .then( text => text.includes( '<!doctype html>' ) ? this.backupTerms() : this.setState({
                    fetching: false,
                    terms: text
                }))
                .catch( err => this.backupTerms( err ));
        });
    }

    agreeTerms = () => {
        this.setState(( prevState ) => ({
            agreeTerms: !prevState.agreeTerms
        }));
    }

    initiateUserCreation() {
        showLoading( 'Registering user...' );
        this.props.createUser();
    }

    userCreated( dbUsername, dbPassword ) {
        this.props.resetAccountProps({
            userCreated: undefined
        });
        Swal.fire({
            title: 'Thank you for registering',
            allowOutsideClick: false,
            allowEscapeKey: false,
            type: 'success'
        }).then(( _result ) => {
            if ( typeof dbUsername !== 'undefined' && typeof dbPassword !== 'undefined' && dbUsername.length > 0 && dbPassword.length > 0 ) {
                Swal.fire({
                    title: 'Data Warehouse Credentials',
                    type: 'info',
                    html:
                            `${'<br/>' +
                            'Username: <b>'}${  dbUsername  }</b><br/>` +
                            `Password: <b>${  dbPassword  }</b><br/><br/>` +
                            '<b>Note:</b> If forgot, You can reset these credentials from your profile section',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    onClose: () => {
                        this.props.loadAccount();
                        this.props.history.push( '/' );
                    }
                });
            } else {
                this.props.loadAccount();
                this.props.history.push( '/' );
            }
        });
    }

    userCreationFailed( errorMessage ) {
        Swal.fire({
            title: 'User creation failed',
            text: errorMessage || 'Please try again later !!',
            type: 'error',
            showCancelButton: true,
            confirmButtonText: 'Retry',
            cancelButtonText: 'Sign out',
            reverseButtons: true
        }).then(( result ) => {
            if ( result.value ) {
                this.initiateUserCreation();
            } else if ( result.dismiss === Swal.DismissReason.cancel ) {
                this.props.logout();
            }
        });
    }

    render() {
        const { user, globalConfig } = this.props;
        const { permanentPaths = {} } = globalConfig;
        return ( <Page title={ 'User Agreement' }>
            <Row>
                { !user && <Redirect to="/" /> }
                { user && user.UserId && <Redirect to={ permanentPaths.home.path } /> }
                <Col sm={12}>
                    { this.state.fetching ? <Card
                        content={<span>
                            <ADPIcon icon="refresh" size="3x" className="text-primary" />
                            <br /><br />
                            Loading legal stuff...
                        </span>
                        }
                        ctTextCenter
                        ctFullWidth
                    /> : <Row>
                        { this.state.fetchError ? <Col sm={12}>
                            <Card
                                title="Error loading legal stuff..."
                                content={<span id="userAgreement-error-container">
                                    {this.state.fetchError} <br/>
                                    <button className="btn btn-info btn-xs btn-fill" onClick={() => this.loadTerms()}>Click here</button> to retry !!
                                </span>}
                                ctTextCenter
                                textCenter
                            />
                        </Col> : <Card
                            content={<>
                                <Row className="TandC_Container">
                                    <Col sm={12} id="userAgreement-terms-container">
                                        <ReactMarkdown source={this.state.terms} />
                                    </Col> <br/>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <blockquote>
                                            <div className="checkbox">
                                                <input type="checkbox" id="agreeTerms-checkbox" onChange={() => this.agreeTerms()} checked={this.state.agreeTerms} />&nbsp;&nbsp;
                                                <label htmlFor="agreeTerms-checkbox" id="agreeTerms-label">By clicking here, you certify that you have read, understood and agree to terms of use.</label>
                                            </div>
                                        </blockquote>
                                    </Col>
                                </Row>
                            </>
                            }
                            legend={
                                <span>
                                    <button onClick={() => this.props.logout()} className="btn btn-danger btn-fill btn-wd" id="userAgreement-decline-btn">Decline</button> &nbsp;
                                    <button onClick={() => this.initiateUserCreation()} className="btn btn-success btn-fill btn-wd" disabled={!this.state.agreeTerms} id="userAgreement-agree-btn">Agree</button>
                                </span>
                            }
                            ftTextCenter
                        />}
                    </Row>
                    }
                </Col>
            </Row>
        </Page> );
    }
}

// Mapping the current state props to ths following:
const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        globalConfig: state.globalConfig,
        userCreated: state.account.userCreated,
        userCreationFailed: state.account.userCreationFailed,
        DBUserName: state.account.DBUserName,
        DBPassword: state.account.DBPassword
    };
};

const mapDispatchToProps = ( dispatch, props ) => {
    return {
        logout: () => dispatch( authActions.logout( props.history )),
        loadAccount: () => dispatch( accountActions.loadAccount()),
        createUser: () => dispatch( accountActions.createUser()),
        resetAccountProps: ( propsToReset ) => dispatch( accountActions.resetAccountProps( propsToReset ))
    };
};


export default withRouter( connect( mapStateToProps, mapDispatchToProps )( UserAgreement ));