// import { reset } from 'redux-form';

import Swal from 'sweetalert2';
import { getHttp, urlBuilder, extractError, respond } from '../utils';
import { showLoading, alertAndHide } from '../../components/utils/popUpUtils';
import { USER_LOGOUT } from '../common_actions';


const initialState = {
    parametersList: [],
    fetchingParameters: false,
    fetchingParameter: false
};

const RESET_ALL = 'RESET_ALL';
const SET_PARAMETERS_LISTS = 'SET_PARAMETERS_LISTS';
const RESET_PARAMETER_STORE_PROPS = 'RESET_PARAMETER_STORE_PROPS';
const ADD_PARAMETER = 'ADD_PARAMETER';
const SET_PARAMETER = 'SET_PARAMETER';
const SET_PARAMETER_SETTINGS = 'SET_PARAMETER_SETTINGS';

export default ( state = initialState, action ) => {
    switch ( action.type ) {
    case SET_PARAMETERS_LISTS:
    case ADD_PARAMETER:
    case SET_PARAMETER:
    case SET_PARAMETER_SETTINGS:
    case RESET_PARAMETER_STORE_PROPS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    case RESET_ALL:
        return initialState;
    default:
        return state;
    }
};

export const getParameters = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getParametersURL = urlBuilder(['parameters']);

        dispatch({
            type: RESET_ALL
        });
        dispatch({
            type: SET_PARAMETERS_LISTS,
            data: {
                fetchingParameters: true
            }
        });

        return client.get( getParametersURL )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: SET_PARAMETERS_LISTS,
                        data: {
                            fetchingParameters: undefined,
                            parametersList: response.data.parameters
                        }
                    });
                }
            }).catch(( error_obj ) => {
                dispatch({
                    type: SET_PARAMETERS_LISTS,
                    data: {
                        fetchParametersError: error_obj.Message || extractError( error_obj ) || 'Network Error',
                        fetchingParameters: undefined
                    }
                });
                respond( 'getParameters', error_obj, dispatch );
            });
    };
};

export const getParameterDetails = ( key ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getParameterURL = urlBuilder(['parameters', key]);

        dispatch({
            type: SET_PARAMETERS_LISTS,
            data: {
                fetchingParameter: true
            }
        });
        return client.get( getParameterURL )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: SET_PARAMETER,
                        data: {
                            fetchingParameter: false,
                            parameter: response.data
                        }
                    });
                }
            }).catch(( error_obj ) => {
                dispatch({
                    type: SET_PARAMETER,
                    data: {
                        fetchParameterError: error_obj.Message || extractError( error_obj ) || 'Network Error',
                        fetchingParameter: false
                    }
                });
                respond( 'getParameters', error_obj, dispatch );
            });
    };
};

export const addParameter = ( values, onSuccessCallback ) => {
    return ( dispatch, getState ) => {
        const client = getHttp( dispatch, getState );
        const addParameterURL = urlBuilder(['parameters']);
        showLoading( 'Adding parameter to store' );

        return client.post( addParameterURL, values )
            .then( response => {
                if ( response.status === 200 ) {
                    alertAndHide( 'success', 'Parameter added successfully', response.Message || '', () => {
                        onSuccessCallback();
                        // dispatch( reset( 'NewForecastJobForm' ));
                    });
                }
            }).catch(( err ) => {
                alertAndHide( 'error', 'Parameter creation failed', extractError( err ));
                respond( 'addParameter', err, dispatch );
            });
    };
};

export const updateParameter = ( key, values, onSuccessCallback ) => {
    return ( dispatch, getState ) => {
        const client = getHttp( dispatch, getState );
        const addParameterURL = urlBuilder(['parameters', key]);
        showLoading( 'Updating parameter' );

        return client.put( addParameterURL, values )
            .then( response => {
                if ( response.status === 200 ) {
                    alertAndHide( 'success', 'Parameter updated successfully', response.Message || '', () => {
                        onSuccessCallback();
                    });
                }
            }).catch(( err ) => {
                alertAndHide( 'error', 'Parameter update failed', extractError( err ));
                respond( 'updateParameter', err, dispatch );
            });
    };
};

export const deleteParameter = ( key, onSuccessCallback ) => {
    return ( dispatch, getState ) => {
        const client = getHttp( dispatch, getState );
        const deleteParameterURL = urlBuilder(['parameters', key]);
        showLoading( 'Deleting parameter...' );

        return client.delete( deleteParameterURL )
            .then( response => {
                if ( response.status === 200 ) {
                    alertAndHide( 'success', 'Parameter deleted successfully', response.Message || '', () => {
                        onSuccessCallback();
                    });
                }
            }).catch(( err ) => {
                if ( err.response.data.DependentResources ) {
                    Swal.close();
                    dispatch({
                        type: SET_PARAMETER_SETTINGS,
                        data: {
                            entitiesDependencies: err.response.data.DependentResources,
                            cancelFailed: extractError( err )
                        }
                    });
                } else {
                    alertAndHide( 'error', 'Parameter deletion failed', extractError( err ));
                }

                respond( 'deleteParameter', err, dispatch );
            });
    };
};

export const resetParameterStoreProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_PARAMETER_STORE_PROPS, data: propsToReset });
    };
};