import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';

class Test extends React.Component {
    render() {
        return ( <Page title={ 'Developer Test Area' }>
            <Row>
                <Col sm={12}>
                    <Card>
                        Developer test area !!
                    </Card>
                </Col>
            </Row>
        </Page> );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user
    };
};

export default connect( mapStateToProps, null )( Test );