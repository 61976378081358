import { reset } from 'redux-form';
import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';
import { alertAndHide, showLoading } from '../components/utils/popUpUtils';

const SET_DEEPSEARCH_ERROR = 'SET_DEEPSEARCH_ERROR';
const CHANGE_DEEPSEARCH_FETCHING = 'CHANGE_DEEPSEARCH_FETCHING';
const RESET_DEEPSEARCH_PROPS = 'RESET_DEEPSEARCH_PROPS';
const FETCH_DEEPSEARCH_INDICIES = 'FETCH_DEEPSEARCH_INDICIES';
const FETCH_DEEPSEARCH_DATSETS = 'FETCH_DEEPSEARCH_DATSETS';
const FETCH_DEEPSEARCH_QUERY = 'FETCH_DEEPSEARCH_QUERY';
const CHANGE_DEEPSEARCH_AUTH_USERS_FETCHING = 'CHANGE_DEEPSEARCH_AUTH_USERS_FETCHING';
const FETCH_DEEPSEARCH_AUTHORIZED_USERS_SUCCESS = 'FETCH_DEEPSEARCH_AUTHORIZED_USERS_SUCCESS';
const SET_DEEPSEARCH_AUTHORIZED_USERS_ERROR = 'SET_DEEPSEARCH_AUTHORIZED_USERS_ERROR';
const DELETE_DEEPSEARCH_USER_ACCESS_SUCCESS = 'DELETE_DEEPSEARCH_USER_ACCESS_SUCCESS';
const UPDATE_DEEPSEARCH_USER_ACCESS_SUCCESS = 'UPDATE_DEEPSEARCH_USER_ACCESS_SUCCESS';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_DEEPSEARCH_ERROR:
    case CHANGE_DEEPSEARCH_FETCHING:
    case FETCH_DEEPSEARCH_INDICIES:
    case FETCH_DEEPSEARCH_DATSETS:
    case FETCH_DEEPSEARCH_QUERY:
    case CHANGE_DEEPSEARCH_AUTH_USERS_FETCHING:
    case FETCH_DEEPSEARCH_AUTHORIZED_USERS_SUCCESS:
    case SET_DEEPSEARCH_AUTHORIZED_USERS_ERROR:
    case DELETE_DEEPSEARCH_USER_ACCESS_SUCCESS:
    case UPDATE_DEEPSEARCH_USER_ACCESS_SUCCESS:
    case RESET_DEEPSEARCH_PROPS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const getDeepsearchIndicies = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIAPI = urlBuilder(['deepsearch-indices']);
        dispatch({ type: CHANGE_DEEPSEARCH_FETCHING, data: { fetchingDSIS: true, fetchDSISError: undefined } });
        return client.get( getDSIAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_DEEPSEARCH_INDICIES,
                        data: {
                            deepsearchIndices: response.data,
                            fetchingDSIS: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DEEPSEARCH_ERROR,
                    data: {
                        fetchDSISError: extractError( error_obj ),
                        fetchingDSIS: undefined
                    }
                });
                respond( 'getDeepsearchIndicies', error_obj, dispatch );
            });
    };
};

export const createDeepsearchIndex = ( requestBody, onSuccessCallback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const newDSIAPI = urlBuilder(['deepsearch-indices']);
        showLoading( 'Creating Index...' );
        return client.post( newDSIAPI, requestBody )
            .then( _response => {
                alertAndHide( 'success', 'Index created successfully', '', () => {
                    onSuccessCallback();
                    dispatch( reset( 'NewDeepsearchIndexForm' ));
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed !!', extractError( error_obj ));
                respond( 'createDeepsearchIndex', error_obj, dispatch );
            });
    };
};

export const getIndexDetails = ( indexId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIAPI = urlBuilder(['deepsearch-indices', indexId ]);
        dispatch({ type: CHANGE_DEEPSEARCH_FETCHING, data: { fetchingDSI: true, fetchDSIError: undefined } });
        return client.get( getDSIAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DEEPSEARCH_INDICIES,
                    data: {
                        index: response.data,
                        fetchingDSI: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DEEPSEARCH_ERROR,
                    data: {
                        fetchDSIError: extractError( error_obj ),
                        fetchingDSI: undefined
                    }
                });
                respond( 'getIndexDetails', error_obj, dispatch );
            });
    };
};

export const getIndexedDatasets = ( indexId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIAPI = urlBuilder(['deepsearch-indices', indexId, 'datasets' ]);
        dispatch({ type: CHANGE_DEEPSEARCH_FETCHING, data: { fetchingDatasets: true, fetchDatasetsError: undefined } });
        return client.get( getDSIAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DEEPSEARCH_DATSETS,
                    data: {
                        datasets: response?.data?.['deepsearch-datasets'],
                        fetchingDatasets: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DEEPSEARCH_ERROR,
                    data: {
                        fetchDatasetsError: extractError( error_obj ),
                        fetchingDatasets: undefined
                    }
                });
                respond( 'getIndexedDatasets', error_obj, dispatch );
            });
    };
};

export const addDatasetsToIndex = ( indexId, list = [], callback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIAPI = urlBuilder(['deepsearch-indices', indexId, 'datasets' ]);
        showLoading( 'Updating Datasets...' );
        return client.post( getDSIAPI, { 'DatasetIds': list })
            .then( response => {
                alertAndHide( 'success', 'Datasets added', response?.data?.Message, () => {
                    callback();
                    dispatch( getIndexedDatasets( indexId ));
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'addDatasetsToIndex', error_obj, dispatch );
            });
    };
};

export const getSyncjobs = ( indexId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIAPI = urlBuilder(['deepsearch-indices', indexId, 'sync-jobs' ]);
        dispatch({ type: CHANGE_DEEPSEARCH_FETCHING, data: { fetchingSyncjobs: true, fetchSyncjobsError: undefined } });
        return client.get( getDSIAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DEEPSEARCH_DATSETS,
                    data: {
                        syncjobs: response?.data?.['deepsearch-syncjobs'],
                        fetchingSyncjobs: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DEEPSEARCH_ERROR,
                    data: {
                        fetchSyncjobsError: extractError( error_obj ),
                        fetchingSyncjobs: undefined
                    }
                });
                respond( 'getSyncjobs', error_obj, dispatch );
            });
    };
};

export const triggerSyncjob = ( indexId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIAPI = urlBuilder(['deepsearch-indices', indexId, 'sync-jobs' ]);
        showLoading( 'Triggering Syncjob' );
        return client.post( getDSIAPI )
            .then( response => {
                alertAndHide( 'success', 'Syncjob Triggered', response?.data?.Message, () => {
                    dispatch( getSyncjobs( indexId ));
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'triggerSyncjob', error_obj, dispatch );
            });
    };
};

export const cancelSyncjob = ( indexId, syncjobId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIAPI = urlBuilder(['deepsearch-indices', indexId, 'sync-jobs', syncjobId ]);
        showLoading( 'Cancelling Syncjob...' );
        return client.delete( getDSIAPI )
            .then( response => {
                alertAndHide( 'success', 'Syncjob Cancelled', response?.data?.Message, () => {
                    dispatch( getSyncjobs( indexId ));
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'cancelSyncjob', error_obj, dispatch );
            });
    };
};

export const submitSearch = ( indexId, queryString, resultsPerPage, pageSelected ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getDSIQueryAPI = urlBuilder(['deepsearch-indices', indexId, 'search' ], [`queryString=${queryString}`, `page_size=${resultsPerPage}`, `page_number=${pageSelected}`]);
        dispatch({ type: CHANGE_DEEPSEARCH_FETCHING, data: { fetchingQuery: true, fetchQueryError: undefined } });
        return client.get( getDSIQueryAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DEEPSEARCH_QUERY,
                    data: {
                        queryResults: response?.data,
                        fetchingQuery: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DEEPSEARCH_ERROR,
                    data: {
                        fetchQueryError: extractError( error_obj ),
                        fetchingQuery: undefined
                    }
                });
                respond( 'submitSearch', error_obj, dispatch );
            });
    };
};

export const deleteIndex = ( indexId, callback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteDSIAPI = urlBuilder(['deepsearch-indices', indexId ]);
        showLoading( 'Deleting Deepsearch Index' );
        return client.delete( deleteDSIAPI )
            .then( response => {
                alertAndHide( 'success', 'Index deletion initiated.', response?.data?.Message, () => {
                    callback();
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Error !!', extractError( error_obj ));
                respond( 'deleteIndex', error_obj, dispatch );
            });
    };
};


export const getAuthorizations = ( indexId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getAuthorizationsAPI = urlBuilder(['deepsearch-indices', indexId, 'authorizedusers']);
        dispatch({ type: CHANGE_DEEPSEARCH_AUTH_USERS_FETCHING, data: { fetchingAuthorizations: true, authorizationsError: undefined } });
        return client.get( getAuthorizationsAPI )
            .then( response => {
                dispatch({
                    type: FETCH_DEEPSEARCH_AUTHORIZED_USERS_SUCCESS,
                    data: {
                        authorizedUsers: response.data.users, authorizedGroups: response.data.groups,
                        fetchingAuthorizations: undefined
                    }
                });
            })
            .catch( error_obj => {

                dispatch({
                    type: SET_DEEPSEARCH_AUTHORIZED_USERS_ERROR,
                    data: {
                        authorizationsError: extractError( error_obj ),
                        fetchingAuthorizations: undefined
                    }
                });
                respond( 'getAuthorizations', error_obj, dispatch );
            });
    };
};

export const addUserAccess = ( requestBody, indexId, userId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addUserAccessAPI = urlBuilder(['deepsearch-indices', indexId, 'users', userId, 'grants']);
        return client.post( addUserAccessAPI, requestBody )
            .then( response => {
                dispatch({
                    type: UPDATE_DEEPSEARCH_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DEEPSEARCH_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'addUserAccess', error_obj, dispatch );
            });
    };
};

export const deleteUserAccess = ( userId, indexId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteUserAccessAPI = urlBuilder(['deepsearch-indices', indexId, 'users', userId, 'grants']);
        return client.delete( deleteUserAccessAPI )
            .then( response => {
                dispatch({
                    type: DELETE_DEEPSEARCH_USER_ACCESS_SUCCESS,
                    data: {
                        accessSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_DEEPSEARCH_AUTHORIZED_USERS_ERROR,
                    data: { accessError: extractError( error_obj ) }
                });
                respond( 'deleteUserAccess', error_obj, dispatch );
            });
    };
};

export const resetDeepsearchProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_DEEPSEARCH_PROPS, data: propsToReset });
    };
};
