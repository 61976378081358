import React from 'react';
import { Grid } from 'react-bootstrap';

import PageHeader from './PageHeader';

class ComponentView extends React.Component {
    render() {
        return <React.Fragment>
            <PageHeader title={this.props.title} actions={this.props.actions} />
            <Grid fluid className="component-content">
                { this.props.content }
                { this.props.children }
            </Grid>
        </React.Fragment>;
    }
}

export default ComponentView;