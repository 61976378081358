import React from 'react';
// courtesy of https://codepen.io/aholachek/pen/pwRNMX
// utility functions
function shallowEquals( arr1, arr2 ) {
    if ( !arr1 || !arr2 || arr1.length !== arr2.length ) {return false;}
    let equals = true;
    for ( let i = 0; i < arr1.length; i++ ) {
        if ( arr1[i] !== arr2[i]) {equals = false;}
    }
    return equals;
}

function arrayDiff( arr1, arr2 ){
    return arr1.map(( a, i )=>{
        return a - arr2[i];
    });
}

// display a single cell
function GridCell( props ) {
    const classes = `grid-cell
    ${props.foodCell ? 'grid-cell--food' : ''}
    ${props.snakeCell ? 'grid-cell--snake' : ''}
    `;
    return (
        <div
            className={classes}
            style={{ height: `${props.size  }px`, width: `${props.size  }px` }}
        />
    );
}

// the main view
class SnakeGame extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            snake: [],
            food: [],
            // 0 = not started, 1 = in progress, 2 = paused, 3 = finished
            status: 0,
            // using keycodes to indicate direction
            direction: 39,
            gameCount: 0,
            highestScore: 0
        };

        this.moveFood = this.moveFood.bind( this );
        this.checkIfAteFood = this.checkIfAteFood.bind( this );
        this.startGame = this.startGame.bind( this );
        this.pauseGame = this.pauseGame.bind( this );
        this.resumeGame = this.resumeGame.bind( this );
        this.endGame = this.endGame.bind( this );
        this.moveSnake = this.moveSnake.bind( this );
        this.doesntOverlap = this.doesntOverlap.bind( this );
        this.setDirection = this.setDirection.bind( this );
        this.removeTimers = this.removeTimers.bind( this );
    }
    // randomly place snake food
    moveFood() {
        // if (this.moveFoodTimeout) clearTimeout(this.moveFoodTimeout)
        const x = parseInt( Math.random() * this.numCells, 10 );
        const y = parseInt( Math.random() * this.numCells, 10 );
        this.setState({ food: [x, y] });
        // this.moveFoodTimeout = setTimeout(this.moveFood, 5000)
    }

    setDirection({ keyCode }) {
        // if it's the same direction or simply reversing, ignore
        let changeDirection = [37, 38, 39, 40].includes( keyCode );
        [[38, 40], [37, 39]].forEach( dir => {
            if ( dir.indexOf( this.state.direction ) > -1 && dir.indexOf( keyCode ) > -1 ) {
                changeDirection = false;
            }
        });

        if ( keyCode === 32 ) {
            switch ( this.state.status ){
            case 1:
                this.pauseGame();
                break;
            case 2:
                this.resumeGame();
                break;
            default:
                this.startGame();
                break;
            }
        }

        if ( changeDirection ) {this.setState({ direction: keyCode });}
    }

    moveSnake() {
        const newSnake = [];
        // set in the new "head" of the snake
        switch ( this.state.direction ) {
        // down
        case 40:
            newSnake[0] = [this.state.snake[0][0], this.state.snake[0][1] + 1];
            break;
            // up
        case 38:
            newSnake[0] = [this.state.snake[0][0], this.state.snake[0][1] - 1];
            break;
            // right
        case 39:
            newSnake[0] = [this.state.snake[0][0] + 1, this.state.snake[0][1]];
            break;
            // left
        case 37:
            newSnake[0] = [this.state.snake[0][0] - 1, this.state.snake[0][1]];
            break;
        default:
                // do nothing
        }
        // now shift each "body" segment to the previous segment's position
        [].push.apply(
            newSnake,
            this.state.snake.slice( 1 ).map(( s, i ) => {
            // since we're starting from the second item in the list,
            // just use the index, which will refer to the previous item
            // in the original list
                return this.state.snake[i];
            })
        );

        this.setState({ snake: newSnake });

        this.checkIfAteFood( newSnake );
        if ( !this.isValid( newSnake[0]) || !this.doesntOverlap( newSnake )) {
            // end the game
            this.endGame();
        }
    }

    checkIfAteFood( newSnake ) {
        if ( !shallowEquals( newSnake[0], this.state.food )) {return;}
        // snake gets longer
        let newSnakeSegment;
        const lastSegment = newSnake[newSnake.length - 1];

        // where should we position the new snake segment?
        // here are some potential positions, we can choose the best looking one
        const lastPositionOptions = [[-1, 0], [0, -1], [1, 0], [0, 1]];

        // the snake is moving along the y-axis, so try that instead
        if ( newSnake.length > 1 ) {
            lastPositionOptions[0] = arrayDiff( lastSegment, newSnake[newSnake.length - 2]);
        }

        for ( let i = 0; i < lastPositionOptions.length; i++ ) {
            newSnakeSegment = [
                lastSegment[0] + lastPositionOptions[i][0],
                lastSegment[1] + lastPositionOptions[i][1]
            ];
            if ( this.isValid( newSnakeSegment )) {
                break;
            }
        }

        this.setState({
            snake: newSnake.concat([newSnakeSegment]),
            food: []
        });
        this.moveFood();
    }

    // is the cell's position inside the grid?
    isValid( cell ) {
        return (
            cell[0] > -1 &&
            cell[1] > -1 &&
            cell[0] < this.numCells &&
            cell[1] < this.numCells
        );
    }

    doesntOverlap( snake ) {
        return (
            snake.slice( 1 ).filter( c => {
                return shallowEquals( snake[0], c );
            }).length === 0
        );
    }

    startGame() {
        this.removeTimers();
        this.moveSnakeInterval = setInterval( this.moveSnake, 130 );
        this.moveFood();

        this.setState({
            status: 1,
            snake: [[5, 5]],
            food: [10, 10]
        });
        //need to focus so keydown listener will work!
        this.el.focus();
    }

    pauseGame() {
        if ( this.moveSnakeInterval ) { clearInterval( this.moveSnakeInterval ); }
        this.setState({
            status : 2
        });
    }

    resumeGame(){
        this.moveSnakeInterval = setInterval( this.moveSnake, 130 );
        this.setState({
            status : 1
        });
        //need to focus so keydown listener will work!
        this.el.focus();
    }

    endGame(){
        this.removeTimers();
        this.setState(( prevState ) => ({
            status : 3,
            gameCount: ( prevState.gameCount + 1 ),
            highestScore: ( prevState.highestScore > prevState.snake.length ? prevState.highestScore : prevState.snake.length )
        }));
    }

    removeTimers() {
        if ( this.moveSnakeInterval ) { clearInterval( this.moveSnakeInterval ); }
        // if (this.moveFoodTimeout) clearTimeout(this.moveFoodTimeout)
    }

    componentWillUnmount() {
        this.removeTimers();
    }

    render() {
        // each cell should be approximately 15px wide, so calculate how many we need
        const defaultSize = this.props.size || 525;
        this.numCells = Math.floor( defaultSize / 15 );
        const cellSize = defaultSize / this.numCells;
        const cellIndexes = Array.from( Array( this.numCells ).keys());
        const cells = cellIndexes.map( y => {
            return cellIndexes.map( x => {
                const foodCell = this.state.food[0] === x && this.state.food[1] === y;
                let snakeCell = this.state.snake.filter( c => c[0] === x && c[1] === y );
                snakeCell = snakeCell.length && snakeCell[0];

                return (
                    <GridCell
                        foodCell={foodCell}
                        snakeCell={snakeCell}
                        size={cellSize}
                        key={`${x  } ${  y}`}
                    />
                );
            });
        });

        let overlay;
        if ( this.state.status === 0 ) {
            overlay = (
                <div className="snake-app__overlay">
                    <button className="btn btn-success btn-fill" onClick={this.startGame}>Start game!</button>
                </div>
            );
        } else if ( this.state.status === 2 ) {
            overlay = (
                <div className="snake-app__overlay">
                    <h3 className="mb-1"><b>Game Paused!</b></h3>
                    <button className="btn btn-success btn-fill" onClick={this.resumeGame}>Resume game</button>
                </div>
            );
        } else if ( this.state.status === 3 ) {
            overlay = (
                <div className="snake-app__overlay">
                    <h3 className="mb-1"><b>Game Over!</b></h3>
                    <h3 className="mb-1">Your score: {this.state.snake.length} </h3>
                    <button className="btn btn-primary btn-fill" onClick={this.startGame}>Start a new game</button>
                </div>
            );
        }
        return ( <div>
            <div className="centrify-content">
                <div className="git-badges">
                    <div className="git-badge key">Games Played</div>
                    <div className="git-badge value">{ this.state.gameCount }</div>
                </div>
                <div className="git-badges">
                    <div className="git-badge key">Highest Score</div>
                    <div className="git-badge value">{ this.state.highestScore }</div>
                </div>
            </div>
            <div className="snake-app" onKeyDown={this.setDirection} style={{ width: `${defaultSize  }px`, height: `${defaultSize  }px`}} ref={el => ( this.el = el )} tabIndex={-1}>
                {overlay}
                <div className="grid" style={{ width: `${defaultSize  }px`, height: `${defaultSize  }px`}}>
                    {cells}
                </div>
            </div>
            <div className="centrify-content">
                <div className="git-badges">
                    <div className="git-badge key">Current Score</div>
                    <div className="git-badge value">{ this.state.snake.length }</div>
                </div>
            </div>
        </div>
        );
    }
}

export default SnakeGame;