import React, { useState, useEffect } from 'react';
import FileViewer from 'react-file-viewer';
import Loader from 'react-loader-spinner';

import ADPIcon from '../common/Icon';
import { extractError }  from '../../modules/utils';

const axios = require( 'axios' );

const getFileExtension = ( fileType ) => {
    return (( ext ) => {
        switch ( ext ) {
        case 'jpg':
        case 'jpeg':
            return 'jpeg';
        case 'doc':
        case 'docx':
            return 'docx';
        case 'mp3':
        case 'wav':
            return 'mp3';
        default:
            return ext;
        }
    })( fileType.toLowerCase());
};

const FileDisplayError = ( error ) => {
    return <div className="jumbotron text-center">
        <h3 className="text-danger">Cannot display the file, Please try again later !!</h3>
        <p>{ error }</p>
    </div>;
};

export const getFilePreview = ( filename, path, fileKey ) => {
    const randomKey = `tooltip-${fileKey}-${( Math.random() * Math.pow( 10, 18 )).toString( 36 )}`;
    const extension = getFileExtension( /(?:\.([^.]+))?$/.exec( filename )[1]);
    return <div className="file-preview-container" id={`file-preview-container-${randomKey}`} key={`file-preview-container-${randomKey}`}>
        { !['txt', 'json', 'html', 'htm'].includes( extension )
            ? <GenericFileViewer path={path} extension={extension} randomKey={randomKey}/>
            : <TextFileViewer url={path} filetype={extension} />
        }
    </div>;
};

const GenericFileViewer = ({
    path,
    extension,
    randomKey
}) => {
    return <FileViewer
        key={`fileViewer-${randomKey}`}
        fileType={extension}
        filePath={`${path}`}
        errorComponent={FileDisplayError}
    />;
};

const TextFileViewer = ({
    url,
    filetype
}) => {
    const [ loading, setLoading ] = useState( false );
    const [ filecontent, setFilecontent ] = useState({});
    const [ error, setError ] = useState();
    const getFile = () => {
        setLoading( true );
        axios.get( url ).then( response => {
            setLoading( false );
            try {
                setFilecontent({
                    value: JSON.parse( response.data )
                });
            } catch ( e ) {
                setFilecontent({
                    value: response.data
                });
            }
        }).catch( error_obj => {
            setLoading( false );
            setFilecontent({});
            setError( extractError( error_obj ));
        });
    };
    useEffect(() => {
        getFile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? <Loader
        type="TailSpin"
        color="#337AB7"
        height="50"
        width="50"
    /> : error
        ? <p className="text-danger">
            { error }<br/><br/>
            <button className="btn btn-fill btn-sm navBack" onClick={() => getFile()}>Retry <ADPIcon icon="restart" /></button>
        </p>
        : filecontent?.value ? <>
            { filetype === 'json'
                ? <pre>{JSON.stringify( filecontent?.value || {}, null, 2 )}</pre>
                : ['html', 'htm'].includes( filetype )
                    ? <span dangerouslySetInnerHTML={{ __html: filecontent?.value }}></span>
                    : filecontent?.value?.split( '\n' )?.map(( line, lindex ) => <p key={lindex} className="text-preview">{line}</p> )
            }
        </> : '';
};