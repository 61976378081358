import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Col } from 'react-bootstrap';

import Card from './layout/Cards/Card';
import ListCard from './layout/Cards/ListCard';
import Page from './layout/Cards/ComponentView';
import PageHeaderButtons from './layout/Cards/PageHeaderButtons';

import ADPIcon from './common/Icon';

import AccessRequestsComponent from './datasets/AccessRequestComponent';
import { hasAccess } from '../routes/RouteUtils';
import DashboardStatsCard from './layout/Cards/DashboardStatsCard';
import * as authActions from '../modules/auth';
import { getPathObject } from '../routes/RouteUtils';
import { withHelperText } from './utils/renderUtils';
import { userResources, commonResourcesMapping } from '../modules/common';

class Home extends React.Component {
    constructor( props ){
        super( props );
        this.state = {
            bookmarkFilter: '',
            callCheck: 0
        };
    }

    componentDidMount() {
        const uiMFAStatus = this.props.mfaEnabled ? 'enabled' : 'disabled';
        if ( this.props?.user?.MfaStatus !== uiMFAStatus ) {
            this.props.updateUserStatus( this.props.user.UserId, uiMFAStatus, true );
        }
    }

    render() {
        const { user, globalConfig, userRole, commonResources, pendingRequests } = this.props;
        const { bookmarks = [] } = ( user && globalConfig.userPreferences ) ? globalConfig.userPreferences : {};
        const { permanentPaths } = globalConfig;
        const { bookmarkFilter } = this.state;
        // const initials = ( user && user.Name ) ? user.Name.match( /\b\w/g ) || [] : [];
        return (
            <Page
                title={'Overview'}
                actions={
                    <PageHeaderButtons title="Actions" id="home" listOfButtons={
                        [
                            {
                                hasAccess: 'any',
                                title: 'Refresh resources',
                                icon : 'refresh',
                                onClick: () => this.props.getUserResources(),
                                disabled: pendingRequests.length > 0,
                                loadingState: pendingRequests.length > 0
                            }
                        ]
                    }
                    />
                }
                content={ !user ? <Redirect to={permanentPaths.login.path} />
                    : <Row>
                        { Object.keys( commonResourcesMapping ).map(( key ) => {
                            const resource = commonResourcesMapping[key];
                            if ( resource.permPathKey  && hasAccess( userRole, permanentPaths[resource.permPathKey].permission )){
                                const permPathObj = permanentPaths[resource.permPathKey];
                                return <Col sm={6} md={3} lg={2} key={key}>
                                    <DashboardStatsCard
                                        title={permPathObj.name}
                                        icon={<ADPIcon icon={permPathObj.icon} />}
                                        statsValue={
                                            commonResources?.[key]?.fetching ? <ADPIcon icon="refresh" className="text-primary" spin={true} />
                                                : ( commonResources?.[key]?.error ? withHelperText( commonResources?.[key]?.error, <span>Err</span> ) : (
                                                    ( commonResources?.[key]?.[resource.responseMapping])
                                                        ? commonResources?.[key]?.[resource.responseMapping]?.length
                                                        : '0'
                                                ))
                                        }
                                        legend={<LinkContainer to={permPathObj.path}><span>{permPathObj.name} <ADPIcon icon="ctaLink" /></span></LinkContainer>}
                                    />
                                </Col>;
                            } else {
                                return null;
                            }
                        })
                        }
                        <div className="clearfix"></div>
                        <Col sm={12} md={6}>
                            <Card extraClasses="normalizeHeight">
                                <ListCard _title={ <span>Your Bookmarks</span> }
                                    _caller={'dashboard'}
                                    _contentType="activity" _options={{ scroll: true, columnsCount: 3, header: true, columnWidths: [65]}}
                                    _list={[]}
                                />
                                { bookmarks.length > 0
                                    ? <Row>
                                        <Col sm={12}>
                                            <input type="text" className="form-control" placeholder="Filter bookmarks" onChange={( e ) => this.setState({ bookmarkFilter: e.target.value })} value={bookmarkFilter} />
                                            <div className="clearfix"></div>
                                        </Col>
                                        <Col sm={12} className="bookmarkLinksContainer">
                                            <ListCard
                                                _showNoRecords={true}
                                                _caller={'dashboard'}
                                                _contentType="activity" _options={{ scroll: this.props.caller === 'dashboard',
                                                    columnsCount: 1,
                                                    header: true, columnWidths: [ 100 ]}}
                                                _list={ bookmarks.filter( el => el.name.toLowerCase().indexOf( bookmarkFilter.toLowerCase()) > -1 || el.description.toLowerCase().indexOf( bookmarkFilter.toLowerCase()) > -1 ).length > 0
                                                    ? bookmarks.filter( el => el.name.toLowerCase().indexOf( bookmarkFilter.toLowerCase()) > -1 || el.description.toLowerCase().indexOf( bookmarkFilter.toLowerCase()) > -1 )
                                                        .map(( bookmark, _index ) => {
                                                            return [
                                                                <span onClick={() => this.props.history.push( bookmark.path )} className="link" key={_index}>
                                                                    <ADPIcon icon={ getPathObject( permanentPaths, bookmark.path ).icon || 'star' } className="text-warning" />&nbsp;
                                                                    { bookmarkFilter
                                                                        ? <span className="text-primary" dangerouslySetInnerHTML={{ __html: bookmark.name.replace( new RegExp( bookmarkFilter, 'gi' ), ( match ) => `<span class="highlight">${match}</span>` ) }} />
                                                                        : <span>{bookmark.name}</span>
                                                                    }
                                                                </span>,
                                                                bookmarkFilter
                                                                    ? <span key={_index} dangerouslySetInnerHTML={{ __html: bookmark.description.replace( new RegExp( bookmarkFilter, 'gi' ), ( match ) => `<span class="highlight">${match}</span>` ) }} />
                                                                    : <span key={_index}>{bookmark.description}</span>
                                                            ];
                                                        })
                                                    : [
                                                        [ 'No bookmarks found matching the search query', '', '', '' ]
                                                    ]
                                                }
                                            />
                                        </Col>
                                    </Row> : <Row>
                                        <Col sm={12}>
                                            <div className="jumbotron NoBookmarkContainer">
                                                <p>Click the <ADPIcon icon="star" /> icon at the top of the page when you want to bookmark a page</p>
                                                <br />
                                                <p>Your bookmarks will be available here</p>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Card>
                        </Col>
                        <Col sm={12} md={6}>
                            <Card extraClasses="normalizeHeight">
                                <AccessRequestsComponent caller={'dashboard'}/>
                            </Card>
                        </Col>
                    </Row>
                }
            />
        );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        commonResources: state.common,
        userRole: state.account.userRole,
        globalConfig: state.globalConfig,
        mfaEnabled: state.auth.mfaEnabled || false,
        pendingRequests: state.common.requests?.pending || []
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        getUserResources: () => dispatch( userResources()),
        updateUserStatus: ( username, status, silent ) => dispatch( authActions.updateUserStatus( username, status, silent ))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Home );