import React from 'react';
import ADPIcon from '../../common/Icon';

import { withHelperText } from '../../utils/renderUtils';

const CardActionButton = ({
    hasAccess,
    title,
    icon,
    id = '',
    loadingState = false,
    titlePlacement = 'bottom',
    extraClasses,
    disabled = false,
    ...props
}) => ( hasAccess ? withHelperText(
    `${title}`,
    <button type="button" className={`btn btn-fill btn-sm cardAction ${extraClasses}`} {...props} disabled={ loadingState || disabled } id={`${id}-${title?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-btn`}>
        <ADPIcon icon={ icon } spin={ loadingState }  />
    </button>, titlePlacement
) : withHelperText(
    `No access to ${title.toLowerCase()}, Please contact administrator for access !!`,
    <button type="button" className={`btn btn-fill btn-sm cardAction ${extraClasses}`} style={{ opacity: '0.5'}} {...props} onClick={() => {}} id={`${id}-${title?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-btn`}>
        <ADPIcon icon={ icon } />
    </button>, titlePlacement
)
);

export default CardActionButton;