import React from 'react';
import ADPIcon from '../common/Icon';

//default height of visible container
const setInitialHeight = 80;
//default step to show hidden container
const setMinStep = 80;

// This component expands and collapses the content like a Harmonium. Has step-through support as well.
class Harmonium extends React.PureComponent {
    constructor( props ) {
        super( props );
        const { step, initial } = this.props;

        // The 'initial' prop must be of number type, finite and greater than already default 'setInitialHeight'.
        const initialHeight = initial && typeof initial === 'number' && initial > setInitialHeight ? initial : setInitialHeight;

        // The 'step' prop must be of number type, finite and greater than or equal already default 'setMinStep'
        // , else the component goes into simple expand-collapse mode without step-through.
        const stepThroughWindow = step && typeof step === 'number' && step >= setMinStep ? step : undefined;

        this.state = {
            showBtn: true,
            hideBtn: false,
            initialHeight,
            scrollableHeight: 0,
            stepThroughWindow,
            calculatedHeight: stepThroughWindow ? initialHeight : undefined
        };
    }

    // Setting the scroll-height of the div to state.
    // +100px for buffer added as type of data entered may not respect the parent div
    // and break out due to its individual styles.
    componentDidMount() {
        this.setState({ scrollableHeight: this.contentRef.scrollHeight + 100 });
    }

    // Setting the ref to the content div to determine the scrollhieght wiz. necessary to
    // finding height of incoming content on-the-fly.
    setRef = node => { this.contentRef = node; }

    // Calculating the style object on the fly depending upon height and action of the user.
    computeStyle = ( scrollableHeight ) => {
        const { showBtn, hideBtn, initialHeight, stepThroughWindow, calculatedHeight } = this.state;
        let returnStyle = {
            'overflow': 'hidden',
            'maxHeight': `${initialHeight}px`
        };

        if ( stepThroughWindow ) {
            if ( showBtn && hideBtn ) {
                if ( calculatedHeight > initialHeight ) {
                    returnStyle = {
                        'overflow': 'hidden',
                        'maxHeight': `${calculatedHeight}px`
                    };
                }
            } else if ( !showBtn && hideBtn ) {
                returnStyle = { 'maxHeight': `${scrollableHeight}px` };
            }
        } else {
            if ( !showBtn ) {
                returnStyle = { 'maxHeight': `${scrollableHeight}px` };
            }
        }

        return returnStyle;

    }

    // Show-hide rules of buttons and calculating effective container hieght.
    wellDefMethod = ( e, actionName, scrollableHeight ) => {
        e.preventDefault();
        const { initialHeight, stepThroughWindow, calculatedHeight } = this.state;
        const wellDefObj = {};
        switch ( actionName ) {
        case 'showBtn':
        {
            if ( stepThroughWindow ) {
                if (( calculatedHeight + stepThroughWindow ) > scrollableHeight ) {
                    wellDefObj.showBtn = false;
                    wellDefObj.calculatedHeight = scrollableHeight;
                } else {
                    wellDefObj.showBtn = true;
                    wellDefObj.calculatedHeight = ( calculatedHeight + stepThroughWindow );
                }
            } else {
                wellDefObj.showBtn = false;
            }

            wellDefObj.hideBtn = true;
            break;
        }
        case 'hideBtn':
        {
            if ( stepThroughWindow ) {
                wellDefObj.calculatedHeight = initialHeight;
            }
            wellDefObj.hideBtn = false;
            wellDefObj.showBtn = true;
            break;
        }
        default:
            break;
        }
        this.setState( wellDefObj );
    }

    render() {
        const { showBtn, hideBtn, initialHeight, scrollableHeight } = this.state;
        const { shortButtons } = this.props;
        return (
            scrollableHeight > initialHeight ?
                <div id="show" >
                    <div className="a_content" style={this.computeStyle( scrollableHeight )} ref={this.setRef}>
                        {this.props.children}
                    </div>
                    <div className={showBtn ? 'a_fade' : 'a_fade_hide'}></div>
                    <div className="btn-group pull-right">
                        {hideBtn && <button onClick={( e ) => this.wellDefMethod( e, 'hideBtn' )} className={'btn btn-fill btn-warning btn-xs a_show'}>
                            {shortButtons ? <ADPIcon icon={'minus-square'} /> : '  Show Less'}
                        </button>}
                        {showBtn && <button onClick={( e ) => this.wellDefMethod( e, 'showBtn', scrollableHeight )} className={'btn btn-fill btn-xs a_show'} >
                            {shortButtons ? <ADPIcon icon={'plus'} /> : '  Show More'}
                        </button>}
                    </div>
                </div>
                : <div ref={this.setRef}>
                    {this.props.children}
                </div>
        );
    }
}

export default Harmonium;