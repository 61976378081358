import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Col, Breadcrumb } from 'react-bootstrap';

import { getPathObject } from '../../routes/RouteUtils';
import { withHelperText } from '../utils/renderUtils';

import ADPIcon from '../common/Icon';

const hasInEligiblePaths = ( permanentPaths, path ) => [
    '/', permanentPaths.userAgreement.path, permanentPaths.home.path
].includes( path );


const formBreadCrumb = ( permanentPaths, path, title ) => {
    const splitPath = path.substring( 1 ).split( '/' );

    if ( hasInEligiblePaths( permanentPaths, path )) {
        return [];
    }

    const currentPathObj = getPathObject( permanentPaths, path );

    const crumbList = splitPath.filter(( obj ) => obj !== '' ).map(( obj, index ) => {
        const ancestorPath = ( index < splitPath.length ) ? `/${splitPath.slice( 0, index ).join( '/' )}` : path;
        const ancestorObj = getPathObject( permanentPaths, ancestorPath );
        const homePath = permanentPaths['home'];

        if ( obj === '' || ancestorPath === '/' ) {
            return {
                name: homePath.name,
                icon: homePath.icon,
                description: homePath.description,
                path: homePath.path
            };
        }

        if ( ancestorObj.name ) {
            return {
                name: ancestorObj.name,
                icon: ancestorObj.icon,
                description: ancestorObj.description,
                path: ancestorObj.path
            };
        }

        return {
            name: title,
            description: title ?
                `${title}` :
                `${splitPath.slice( 0, -2 ).join( ' ' ).replace( /s$/, '' )}`,
            icon: 'file',
            path: `${ancestorPath}/details`
        };
    });

    if ( crumbList[crumbList.length - 1].path.endsWith( 'details' )) {
        crumbList[crumbList.length - 2].path = `${crumbList[crumbList.length - 2].path}/list`;
    }

    return [
        ...crumbList,
        ...[
            ( currentPathObj.name ) ? {
                name: ( currentPathObj.parent.toLowerCase() === currentPathObj.name.toLowerCase()) ?
                    currentPathObj.helperLinkText :
                    currentPathObj.name,
                icon: currentPathObj.icon,
                description: currentPathObj.description
            } : {
                name: splitPath[splitPath.length - 1],
                description: title ? `${title} ${splitPath[splitPath.length - 1]}` : `${splitPath.slice( 0, -2 ).join( ' ' ).replace( /s$/, '' )} ${splitPath[splitPath.length - 1]}`,
                icon: 'file'
            }
        ]
    ];
};

const NewBreadCrumb = ({
    title,
    permanentPaths,
    location
}) => {
    const crumbList = formBreadCrumb( permanentPaths, location.pathname, title );

    return <Col sm={12}>
        <Breadcrumb id="breadcrumbs">
            {
                crumbList.map(( route, index ) => ( index === crumbList.length - 1 && crumbList.length > 1 ) ?
                    <Breadcrumb.Item active key={index} id={`breadcrumbs-${route.name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-link`}>
                        {withHelperText( route.description,
                            <span className="capitalizeFirstLetter">
                                <ADPIcon icon={route.icon} />
                                {' '}{route.name}
                            </span> )}
                    </Breadcrumb.Item>
                    : <Breadcrumb.Item key={index} id={`breadcrumbs-${route.name?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-link`}>
                        <LinkContainer to={route.path}>
                            <span>
                                {withHelperText( route.description,
                                    <span>
                                        <ADPIcon icon={route.icon} />
                                        {' '}{route.name}
                                    </span> )
                                }
                            </span>
                        </LinkContainer>
                    </Breadcrumb.Item>
                )
            }
        </Breadcrumb>
    </Col>;
};

const mapStateToProps = ( state ) => {
    return {
        user: state.account.user,
        userRole: state.account.userRole,
        permanentPaths: state.globalConfig.permanentPaths
    };
};

export default withRouter( connect( mapStateToProps, null )( NewBreadCrumb ));
