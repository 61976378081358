import React from 'react';
import { connect } from 'react-redux';
import { FormControl, FormGroup, ControlLabel, Modal } from 'react-bootstrap';

import ADPIcon from '../common/Icon';

import { toMoment } from '../utils/dateUtils';
import * as accountActions from '../../modules/account';


class Footer extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            show: false,
            value: ''
        };
    }

    displayFeedbackForm = () => {
        this.setState(( prevState ) => ({
            show: !prevState.show
        }));
    }

    render() {
        const { user, globalConfig } = this.props;
        return (
            <footer
                className={
                    `footer${   this.props.transparent !== undefined ? ' footer-transparent' : ''}`
                }
            >
                <div
                    className={
                        `container${   this.props.fluid !== undefined ? '-fluid' : ''}`
                    }
                >
                    <Modal
                        show={this.state.show}
                        onHide={() => this.displayFeedbackForm()}
                        aria-labelledby="ModalHeader"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="ModalHeader">Feedback</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Tell us about your experience:</ControlLabel>
                                <FormControl
                                    componentClass="textarea"
                                    placeholder="Type here.."
                                    value={this.state.value}
                                    rows={5}
                                    onChange={( e ) => this.setState({ value: e?.target?.value || '' })}
                                />
                                <small className={this.state.value.length <= 1000 ? 'text-success' : 'text-danger'}>{this.state.value.length}/1000 characters</small>
                            </FormGroup>
                            <button className="btn btn-fill btn-wd pull-right"
                                onClick={() => {
                                    this.props.submitFeedback({
                                        Message: this.state.value
                                    });
                                }} disabled={( !this.state.value || this.state.value.length <= 0 || this.state.value.length > 1000 )}>
                                Submit
                            </button>
                        </Modal.Body>
                    </Modal>
                    {/* eslint-disable  */}
                    <b>V{globalConfig['VERSION']}</b>
                    <a href="https://amorphicdata.com/" rel="noopener noreferrer" target="_blank"><ADPIcon icon="copyright" /> 2017 - {toMoment(null, "obj", "YYYY")}, Amorphic Data <ADPIcon icon="external-link"  /></a>
                    <a href="https://www.amorphicdata.com/support-services-and-legal/" rel="noopener noreferrer" target="_blank">Terms of Use <ADPIcon icon="external-link" /></a>
                    { user && <a onClick={() => this.displayFeedbackForm() }><ADPIcon icon="comment" /> Feedback</a> }
                    {/* eslint-enable  */}
                </div>
            </footer>
        );
    }
}
const mapStateToProps = ( state, _props ) => {
    return {
        user: state.account.user,
        globalConfig: state.globalConfig
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        submitFeedback: ( feedback ) => dispatch( accountActions.submitFeedback( feedback ))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Footer );
