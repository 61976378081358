import { USER_LOGOUT } from '../common_actions';
import permanentPaths from '../../appConstants/permanentPaths.json';

import appConfig from '../../appConstants/appConfig';
import { getAllRoutes } from '../../routes/RouteUtils';

const config = require( '../../config.json' );

const coreConfigIgnoreKeys = ['region', 'userPool', 'identityPool', 'clientId', 'APP_WEB_DOMAIN'];
const loginConfigKeys = [ 'PROJECT_NAME', 'PROJECT_SHORT_NAME', 'VERSION', 'Domain', 'ENFORCE_COGNITO_MFA'];
const noAuthAllowedKeys = [ 'notFound', 'notAuthorized', 'auth', 'login', 'register', 'forgotPassword', 'callback', 'userAgreement', 'home', 'apidocs', 'userdocs' ];
const coreConfig = Object.assign({}, ...Object.keys( config ).filter( k => !coreConfigIgnoreKeys.includes( k )).map( k => ({[k]: config[k]})));
const loginConfig = Object.assign({}, ...Object.keys( config ).filter( k => loginConfigKeys.includes( k )).map( k => ({[k]: config[k]})));

const computedPaths = () => getAllRoutes( permanentPaths );

const getNoAuthPaths = ( allPaths = computedPaths()) => Object.keys( allPaths )
    .filter( key => noAuthAllowedKeys.includes( key ))
    .reduce(( obj, key ) => {
        obj[key] = allPaths[key];
        return obj;
    }, {});

const checkIdpLogin = () => config.ENABLE_IDP ? config.ENABLE_IDP === 'yes' : false;

const initialState = {
    ...coreConfig,
    ...appConfig,
    permanentPaths: computedPaths(),
    idpLogin: checkIdpLogin(),
    session: false,
    userPreferences: {
        quickActions : [],
        bookmarks : []
    }
};

export const SET_SESSION = 'SET_SESSION';
export const RELOAD_PATHS = 'RELOAD_PATHS';
export const CHANGE_USER_PREFERENCES =  'CHANGE_USER_PREFERENCES';

export default function globalConfigReducer( state = initialState, action ) {
    switch ( action.type ) {
    case CHANGE_USER_PREFERENCES:
        return {
            ...state,
            userPreferences: {
                ...state.userPreferences,
                ...action.userPreferences
            }
        };

    case SET_SESSION:
        return action.session ? {
            ...initialState,
            session: action.session,
            idpLogin: checkIdpLogin(),
            permanentPaths: computedPaths()
        } : {
            ...loginConfig,
            session: action.session,
            idpLogin: checkIdpLogin(),
            permanentPaths: getNoAuthPaths( computedPaths())
        };

    case USER_LOGOUT:
        return {
            ...loginConfig,
            session: false,
            idpLogin: checkIdpLogin(),
            permanentPaths: getNoAuthPaths( computedPaths())
        };

    case RELOAD_PATHS:
        return {
            ...state,
            permanentPaths: state.session ? computedPaths() : getNoAuthPaths( computedPaths())
        };

    default:
        return state;
    }
}