import Swal from 'sweetalert2';

export const showLoading = ( message ) => {
    return Swal.fire({
        title: message || 'Processing Request...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        onOpen: () => {
            Swal.showLoading();
        }
    });
};

export const showToast = (
    type = 'info',
    title = '',
    confirmButtonText = '',
    cancelButtonText = 'X',
    confirmAction = () => {},
    position = 'top-end',
) => {
    Swal.fire({
        type: type !== 'loading' ? type : '',
        title,
        toast: true,
        position,
        showConfirmButton: confirmButtonText.length > 0,
        showCancelButton: ( cancelButtonText.length > 0 && type !== 'loading' ),
        timer: type === 'loading' ? 60000 : 8000,
        confirmButtonText,
        cancelButtonText,
        onOpen: () => {
            if ( type === 'loading' ){
                Swal.showLoading();
            } else {
                Swal.hideLoading();
            }
        }
    }).then(( result ) => {
        if ( result.value ){
            confirmAction();
        } else {
            Swal.close();
        }
    });
};

export const alertAndHide = (
    type,
    title,
    message,
    onCloseCallback,
    hasCancel = false,
    confirmButtonText = 'OK'
) => {
    return Swal.fire({
        title,
        type,
        text: message,
        allowOutsideClick: true,
        allowEscapeKey: true,
        reverseButtons: true,
        confirmButtonText,
        showCancelButton: hasCancel,
        onOpen: () => {
            Swal.hideLoading();
        },
        onClose: () => {
            if ( onCloseCallback ) {
                onCloseCallback();
            }
        }
    });
};

export const accessAlert = ( service ) => {
    return Swal.fire({
        title: 'Access Denied',
        type: 'warning',
        text: `You do not have enough permissions to perform this action. Please contact your system administrator regarding ${service || 'service'} permission(s)`
    });
};