import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';
import * as authActions from '../../modules/auth';
import MFAModal from './MFAModal';

class SetupMFA extends React.Component {

    componentDidUpdate( prevProps ) {
        const { globalConfig, mfaEnabled } = this.props;
        const { ENFORCE_COGNITO_MFA = 'OFF' } = globalConfig;
        if ( ENFORCE_COGNITO_MFA === 'MANDATORY' && mfaEnabled && prevProps.mfaEnabled !== mfaEnabled ) {
            this.props.logout();
        }
    }

    componentDidMount() {
        this.props.toggleMFA( this.props.userName );
    }

    render() {
        const { userName, globalConfig } = this.props;
        const { ENFORCE_COGNITO_MFA = 'OFF' } = globalConfig;
        return ( <Page title={ 'Setup MFA' }>
            <Row id="mfa-container">
                { ENFORCE_COGNITO_MFA === 'MANDATORY' &&
                <Col sm={12}>
                    <Card extraClasses="warning">
                        The global settings require you to enable Multi-factor authentication (MFA) for your account
                    </Card>
                </Col>
                }
                <Col sm={12}>
                    <Row>
                        <Col sm={12} md={3}>
                            <h4 className="text-primary" id="mfa-register-header">
                                Register MFA
                            </h4>
                            Use an time-based one-time password authenticator on your mobile device or computer to enable multi-factor authentication (mfa)
                            <br/><br/>
                            <button className="btn btn-fill btn-sm btn-success" id="mfa-newcode-btn" onClick={() => this.props.toggleMFA( userName, true )}>Get New Code</button>
                        </Col>
                        <Col sm={12} md={9}>
                            <MFAModal />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Page>
        );
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        mfaEnabled: state.auth.mfaEnabled || false,
        userName: state.auth.userName,
        globalConfig: state.globalConfig
    };
};


const mapDispatchToProps = ( dispatch, props ) => {
    return {
        toggleMFA: ( username, enable = true ) => dispatch( authActions.toggleMFA( username, enable )),
        updateUserStatus: ( username, enable, logoutOnSuccess ) => dispatch( authActions.updateUserStatus( username, enable, logoutOnSuccess )),
        logout: () => dispatch( authActions.logout( props.history ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( SetupMFA ));
