import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import {
    Modal, Row, Col, Nav, NavItem, Tab, FormGroup, InputGroup,
    ControlLabel, FormControl
} from 'react-bootstrap';
import 'react-table/react-table.css';

import Card from '../layout/Cards/Card';
import Page from '../layout/Cards/ComponentView';
import LoaderTemplate from '../loaders/LoaderTemplate';
import PageHeaderButtons from '../layout/Cards/PageHeaderButtons';
import ADPIcon from '../common/Icon';
import BuildListing from './BuildListing';
import SortingSection from '../common/SortingSection';

import { getResource } from '../../modules/common';
import * as datasetActions from '../../modules/dataset';
import { AccessCheck, AccessBoundary } from '../../routes/RouteUtils';
import { renderTextarea, renderFilterSelect, paginator } from '../utils/renderUtils';
import { showLoading } from '../utils/popUpUtils';

const DATASET_SORT_OPTIONS = {
    Name: 'DatasetName',
    Description: 'DatasetDescription',
    Domain: 'Domain',
    LastModifiedTime: 'LastModified'
};

// This Component Displays the Search Results of the given query String.
class DatasetSearch extends React.Component {
    constructor( props ) {
        super( props );

        const { userPreferences, DEFAULT_SORT_PREFERENCE } = this.props.globalConfig;

        const {
            sortBy,
            resultsPerPage,
            sortOrder
        } = userPreferences;

        this.state = {
            open: false,
            id: '',
            queryString: this.props.match.params.query ? decodeURIComponent( this.props.match.params.query ) : '',
            permCopy: this.props.searchResults.datasets ? this.props.searchResults.datasets : [],
            list: this.props.searchResults.datasets ? this.props.searchResults.datasets : [],
            dnFilter: '',
            domainFilter: 'all',
            targetFilter: 'all',
            ftFilter: 'all',

            offset: 1,
            limit: resultsPerPage || DEFAULT_SORT_PREFERENCE.resultsPerPage,
            totalCount: 0,
            sortBy: DATASET_SORT_OPTIONS[sortBy] || DATASET_SORT_OPTIONS[DEFAULT_SORT_PREFERENCE.sortBy],
            sortOrder: sortOrder || DEFAULT_SORT_PREFERENCE.sortOrder,
            pageCount: 0,
            pageSelected: 0
        };
    }

    showMessage = ( type, title, msg, resetProp ) => {
        const propsHandler = this.props;
        const { offset, limit, sortOrder, sortBy } = this.state;
        Swal.fire({
            title,
            type,
            text: msg ? msg : '',
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: () => {
                Swal.hideLoading();
            }
        }).then(( _result ) => {
            propsHandler.resetDatasetProps({
                [resetProp]: undefined
            });
            if ( resetProp === 'accessSuccess' ) {
                propsHandler.resetForm( 'accessRequestForm' );
                this.resetFilters();
                this.displayAccessModal( false );
                propsHandler.searchDatasets( this.state.queryString, offset, limit, sortOrder, sortBy );
            }
        });
    }

    componentDidMount() {
        const { offset, limit, sortOrder, sortBy } = this.state;

        this.props.resetDatasetProps({
            searchResults: undefined,
            searchingDatasets: undefined
        });
        if ( this.props.domains.length === 0 ) {
            this.props.getDomains();
        }
        if ( this.props.match.params.query && this.props.access.searchAccess ) {
            this.props.searchDatasets( decodeURIComponent( this.props.match.params.query ), offset, limit, sortOrder, sortBy );
        }
    }

    componentDidUpdate( prevProps, prevState ) {
        const { accessSuccess, accessError, searchResults,
            fileDownloadSuccess, downloadingFile, fileDownloadError } = this.props;
        const { datasets } = searchResults;
        const stateObj = {};

        //sort and pagination logic
        if ( searchResults && prevProps.searchResults !== searchResults && this.props.access.searchAccess ) {
            const { limit, offset } = this.state;

            stateObj.pageCount = Math.ceil( searchResults.total_count / limit );
            stateObj.pageSelected = ( Math.ceil( offset / limit ) - 1 );

            if ( prevState.totalCount !== searchResults.total_count ) {
                stateObj.totalCount = searchResults.total_count;
            }
            this.setState( stateObj );
        }

        // filter logic
        if ( datasets && datasets.length > 0 && datasets !== prevProps.searchResults.datasets ) {

            stateObj.permCopy = datasets;
            stateObj.list = datasets;

            this.setState( stateObj );
        }

        //query param for search change logic
        if ( prevProps.match.params.query !== this.props.match.params.query && this.props.match.params.query ) {
            stateObj.queryString = decodeURIComponent( this.props.match.params.query );

            this.setState( stateObj, () => {
                this.navigatetosearch( this.state.queryString );
            });
        }



        //Messgaes
        if ( prevProps.downloadingFile !== downloadingFile && downloadingFile ) {
            showLoading( 'Initialising download' );
        }
        if ( prevProps.downloadingFile !== downloadingFile && !downloadingFile && fileDownloadSuccess ) {
            Swal.close();
        }
        if ( prevProps.downloadingFile !== downloadingFile && !downloadingFile && fileDownloadError ) {
            this.showMessage( 'error', 'File Download Error', fileDownloadError, 'networkError' );
        }

        if ( accessSuccess ) { this.showMessage( 'success', 'Access Requested', '', 'accessSuccess' ); }
        if ( accessError ) { this.showMessage( 'error', 'Request Failed', accessError, 'accessError' ); }
    }

    filterNow = () => {
        const domainFilter = ( this.state.domainFilter && this.state.domainFilter !== 'all' ) ? this.state.domainFilter : '';
        const targetFilter = ( this.state.targetFilter && this.state.targetFilter !== 'all' ) ? this.state.targetFilter : '';
        const ftFilter = ( this.state.ftFilter && this.state.ftFilter !== 'all' ) ? this.state.ftFilter : '';
        const temp = this.state.permCopy.filter( obj => (
            ( obj.DatasetName.toLowerCase().indexOf( this.state.dnFilter.toLowerCase()) > -1 ) &&
            ( domainFilter === '' ? true : obj.Domain === domainFilter ) &&
            ( targetFilter === '' ? true : obj.TargetLocation === targetFilter ) &&
            ( ftFilter === '' ? true : obj.FileType === ftFilter )
        ));
        this.setState({
            list: temp
        });
    }

    resetFilters = () => {
        this.setState({
            list: this.state.permCopy,
            dnFilter: '',
            domainFilter: 'all',
            targetFilter: 'all',
            ftFilter: 'all'
        });
    }

    handleFilterUpdate = ( e ) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        }, () => {
            this.filterNow();
        });
    }

    submit = ( e ) => {
        e.preventDefault();
        this.navigatetosearch( this.state.queryString );
    }

    displayAccessModal = ( display, datasetId ) => {
        this.setState({
            open: display,
            id: datasetId
        }, () => {
            this.props.resetForm();
        });
    }

    navigatetosearch = ( data ) => {
        const { offset, limit, sortOrder, sortBy } = this.state;
        if ( data.length > 0 ) {
            this.props.history.push( `${this.props.globalConfig.permanentPaths.search.path}/${data}` );
            this.props.searchDatasets( data, offset, limit, sortOrder, sortBy );
        } else {
            Swal.fire( 'Search query is empty', '', 'warning' );
        }
    }

    filterMethod = ( filter, row ) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String( row[id].toLowerCase()).includes( filter.value.toLowerCase()) : true;
    }

    onChange = ( e ) => {
        this.setState({
            queryString: e.target.value
        });
    }

    handleChange = ( e ) => {
        const { name } = e.target;
        const { value } = e.target;
        this.setState({
            [name]: value
        });
    }

    handlePaginationHeaderUpdate = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value, offset: 1 }, () => {
            const { queryString, offset, limit, sortOrder, sortBy } = this.state;
            this.props.searchDatasets( queryString, offset, limit, sortOrder, sortBy );
        });
    }

    handlePageClick = ( data ) => {
        const offset = Math.ceil( data.selected * this.state.limit ) + 1;
        this.setState({ offset }, () => {
            const { queryString, limit, sortOrder, sortBy } = this.state;
            this.props.searchDatasets( queryString, this.state.offset, limit, sortOrder, sortBy );
        });
    }

    isValidatedform = ( values ) => {
        showLoading();
        this.props.requestDatasetAccess( JSON.stringify( values ), this.state.id );
    }

    render() {
        const {
            searchError,
            searchResults = {},
            fetchingResults = undefined,
            handleSubmit,
            pristine,
            submitting,
            globalConfig,
            filedownloadAction,
            filedownloadSuccess,
            downloadingFile,
            domains = [],
            fetchingFileAnalytics,
            fileAnalytics,
            fetchFileAnalyticsError
        } = this.props;

        const { permanentPaths = {} } = globalConfig;
        const { searchAccess } = this.props.access;
        const { parent } = permanentPaths.search;
        const resultDatasets = searchResults.datasets ? searchResults.datasets : [];
        const fileAnalyticsObj = { fetchingFileAnalytics, fileAnalytics, fetchFileAnalyticsError };

        return ( <Page
            title={permanentPaths.search.name}
            actions={<PageHeaderButtons id="datasetSearch" listOfButtons={
                [
                    {
                        hasAccess: permanentPaths[parent].permission,
                        title: permanentPaths[parent].name,
                        icon: 'back',
                        onClick: () => this.props.history.push( permanentPaths[parent].path )
                    }
                ]
            }
            />}
            content={<AccessBoundary
                permanentPaths={globalConfig.permanentPaths}
                title="Search Datasets"
                hasAccessTo={searchAccess}
                content={<Row>
                    <Modal
                        show={this.state.open}
                        onHide={() => this.displayAccessModal( false )}
                        aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                            <Modal.Title id='ModalHeader'>Request Access</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <form onSubmit={handleSubmit( this.isValidatedform )} name="accessRequestForm" id="accessRequestForm">
                                    <Col sm={12}>
                                        <small className='pull-right text-danger'>* required</small>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormGroup controlId="AccessType">
                                            <ControlLabel className="required-label">Access Type</ControlLabel>
                                            <Field
                                                name="AccessType"
                                                id="AccessType"
                                                component={renderFilterSelect}
                                                options={[
                                                    { value: 'owner', label: 'Full Access' },
                                                    { value: 'read-only', label: 'Read Only' }
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormGroup controlId="Comment">
                                            <ControlLabel className="required-label">Request Note</ControlLabel>
                                            <Field rows="3" name="Comment" id="Comment" className="form-control" placeholder="Reason for requesting access to this dataset" component={renderTextarea} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <br />
                                        <button className="btn btn-fill pull-right" type="submit" disabled={pristine || submitting} >Request Access</button>
                                    </Col>
                                </form>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Col sm={12}>
                        <Card
                            content={<form onSubmit={this.submit} className="search">
                                <FormGroup>
                                    <InputGroup>
                                        <FormControl type="text" placeholder="Search datasets / Search '*' for all datasets" name="queryString" className="searchInput" value={this.state.queryString} onChange={this.onChange} title="Search Query Term" />
                                        <InputGroup.Button>
                                            <button className="searchbutton btn btn-fill" onClick={() => this.preventDefault}>Search</button>
                                        </InputGroup.Button>
                                    </InputGroup>
                                </FormGroup>
                            </form>
                            }
                            ctTextCenter
                        />
                    </Col>
                    <Col sm={12}>
                        <Row>
                            <Col sm={12}>
                                <Card
                                    content={
                                        <Row className="paginationHeader">
                                            <SortingSection
                                                listingItems={resultDatasets}
                                                sortByOptions={Object.values( DATASET_SORT_OPTIONS )}
                                                sortByValue={this.state.sortBy}
                                                sortOrderValue={this.state.sortOrder}
                                                resultsPerPageValue={this.state.limit}
                                                isSectionDisabled={fetchingResults || this.state.totalCount === 0 || searchError || !searchAccess}
                                                onSortFunction={this.handlePaginationHeaderUpdate}
                                                sortOrderOptions={globalConfig['PREFERENCES_SORT_ORDER']}
                                                resultsPerPageOptions={globalConfig['PREFERENCES_RESULTS_NO']} />

                                            {!searchError && !fetchingResults && this.state.totalCount > 0 &&
                                            <Col sm={12}>
                                                <p className="text-primary">showing {this.state.offset} - {( parseInt( this.state.offset, 10 ) + parseInt( resultDatasets.length, 10 ) - 1 )} of {this.state.totalCount} results</p>
                                                {this.state.pageCount > 0 && paginator( this.state.pageSelected, this.state.pageCount, this.handlePageClick )}
                                            </Col>
                                            }
                                        </Row>
                                    }
                                />
                            </Col>
                            <Col sm={12}>
                                {( resultDatasets && resultDatasets.length > 0 ) && <Row>
                                    <Col sm={12}>
                                        <Card
                                            content={<Row>
                                                <Col sm={12} md={3}>
                                                    <FormGroup controlId="DatasetName">
                                                        <ControlLabel>Filter by Dataset Name</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={this.state.dnFilter}
                                                            name="dnFilter"
                                                            onChange={this.handleFilterUpdate}
                                                            placeholder="Filter by dataset name"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={3}>
                                                    <FormGroup controlId="Domain">
                                                        <ControlLabel>Filter by Domain</ControlLabel>
                                                        <FormControl componentClass="select" placeholder="Filter by domain" name="domainFilter" value={this.state.domainFilter} onChange={this.handleFilterUpdate}>
                                                            <option value="all">All</option>
                                                            {[...new Set( this.state.permCopy.map( item => item.Domain ))].sort(( a, b ) => { return a.toLowerCase().localeCompare( b.toLowerCase()); }).map(( obj, optIndex ) => {
                                                                if ( typeof obj !== 'undefined' && obj.length > 0 ) {
                                                                    return <option value={obj} key={optIndex}>{
                                                                        domains.filter( el => el.DomainName === obj ).length > 0
                                                                            ? `${domains.filter( el => el.DomainName === obj )[0]['DisplayName']} (${obj})`
                                                                            : obj
                                                                    }
                                                                    </option>;
                                                                } else {
                                                                    return null;
                                                                }
                                                            }).map( el => el )
                                                            }
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={2}>
                                                    <FormGroup controlId="TargetType">
                                                        <ControlLabel>Filter by Target</ControlLabel>
                                                        <FormControl componentClass="select" placeholder="Filter by target" name="targetFilter" value={this.state.targetFilter} onChange={this.handleFilterUpdate}>
                                                            <option value="all">All</option>
                                                            {[...new Set( this.state.permCopy.map( item => item.TargetLocation ))].sort(( a, b ) => a.toLowerCase().localeCompare( b.toLowerCase())).map(( obj, optIndex ) => {
                                                                if ( typeof obj !== 'undefined' && obj.length > 0 ) {
                                                                    return <option value={obj} key={optIndex}>{obj}</option>;
                                                                } else {
                                                                    return null;
                                                                }
                                                            }).map( el => el )
                                                            }
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={2}>
                                                    <FormGroup controlId="FileType">
                                                        <ControlLabel>Filter by FileType</ControlLabel>
                                                        <FormControl componentClass="select" placeholder="Filter by file type" name="ftFilter" value={this.state.ftFilter} onChange={this.handleFilterUpdate}>
                                                            <option value="all">All</option>
                                                            {
                                                                [...new Set( this.state.permCopy.map( item => item.FileType ))].sort(( a, b ) => { return a.toLowerCase().localeCompare( b.toLowerCase()); }).map(( obj, optIndex ) => {
                                                                    if ( typeof obj !== 'undefined' && obj.length > 0 ) {
                                                                        return <option value={obj} key={optIndex}>{obj}</option>;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }).map( el => el )
                                                            }
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={2}>
                                                    <FormGroup>
                                                        <ControlLabel>&nbsp;</ControlLabel>
                                                        <button className="btn btn-fill btn-sm form-control" onClick={() => this.resetFilters()}>Reset</button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                        />
                                    </Col>
                                </Row>
                                }
                            </Col>
                            <Col sm={12}>
                                {fetchingResults
                                    ? <LoaderTemplate hasIcon={true} type={'innerList'} count={3} />
                                    : <Row>
                                        <Col sm={12}>
                                            {searchError ? <Card
                                                title="Error loading search results !!"
                                                content={<div>
                                                    <p>{searchError}</p>
                                                    <p><button className="btn btn-xs btn-fill" onClick={() => this.navigatetosearch( this.state.queryString )}>Click here</button> to retry !!</p>
                                                </div>}
                                                textCenter
                                                ctTextCenter
                                            /> : ( !resultDatasets
                                                ? <Card
                                                    content={<div>
                                                        <p>Please use the search bar to search through dataset & files metadata</p>
                                                        <p><button className="btn btn-xs btn-fill" onClick={() => this.navigatetosearch( '*' )}>Click here</button> to view all datasets.</p>
                                                    </div>}
                                                    textCenter
                                                    ctTextCenter
                                                />
                                                : ( resultDatasets.length > 0
                                                    ? <Card
                                                        content={<Tab.Container id="tabs-with-dropdown" defaultActiveKey="all">
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Nav bsStyle="tabs">
                                                                        <NavItem eventKey="all">
                                                                            <ADPIcon icon="globe" /> All
                                                                        </NavItem>
                                                                        <NavItem eventKey="requestPending">
                                                                            <ADPIcon icon="user-clock" /> Request Pending
                                                                        </NavItem>
                                                                        <NavItem eventKey="hasAccess">
                                                                            <ADPIcon icon="user-check" /> Has Access
                                                                        </NavItem>
                                                                    </Nav>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <Tab.Content animation>
                                                                        <br />
                                                                        <Tab.Pane eventKey="all">
                                                                            {this.state.list.length > 0
                                                                                ? <BuildListing recordSet={this.state.list}
                                                                                    currentState={this}
                                                                                    queryString={this.state.queryString}
                                                                                    filedownloadAction={filedownloadAction}
                                                                                    filedownloadSuccess={filedownloadSuccess}
                                                                                    downloadingFile={downloadingFile}
                                                                                    fileAnalyticsObj={fileAnalyticsObj}
                                                                                    fileAnalyticsAction={this.props.getAnalyticsOnFile}
                                                                                    domains={domains}
                                                                                />
                                                                                : <Card
                                                                                    title="No results matched filters"
                                                                                    category={
                                                                                        <button className="btn btn-fill btn-xs" onClick={() => this.resetFilters()}>Reset filters</button>
                                                                                    }
                                                                                />
                                                                            }
                                                                        </Tab.Pane>
                                                                        <Tab.Pane eventKey="requestPending">
                                                                            {this.state.list
                                                                                .filter( obj => obj.AccessType === 'none' && obj.RequestStatus === 'pending' ).length > 0
                                                                                ? <BuildListing recordSet={this.state.list.filter( obj => obj.AccessType === 'none' && obj.RequestStatus === 'pending' )}
                                                                                    currentState={this}
                                                                                    queryString={this.state.queryString}
                                                                                    filedownloadAction={filedownloadAction}
                                                                                    filedownloadSuccess={filedownloadSuccess}
                                                                                    downloadingFile={downloadingFile}
                                                                                    fileAnalyticsObj={fileAnalyticsObj}
                                                                                    fileAnalyticsAction={this.props.getAnalyticsOnFile}
                                                                                    domains={domains}
                                                                                />
                                                                                : <Card
                                                                                    title="No pending requests"
                                                                                    category="You do not have any pending access requests for datasets resulted from the given query"
                                                                                    ctTextCenter
                                                                                    textCenter
                                                                                />
                                                                            }
                                                                        </Tab.Pane>
                                                                        <Tab.Pane eventKey="hasAccess">
                                                                            {this.state.list
                                                                                .filter( obj => ['owner', 'read-only'].includes( obj.AccessType )).length > 0
                                                                                ? <BuildListing recordSet={this.state.list.filter( obj => ['owner', 'read-only'].includes( obj.AccessType ))}
                                                                                    currentState={this}
                                                                                    queryString={this.state.queryString}
                                                                                    filedownloadAction={filedownloadAction}
                                                                                    filedownloadSuccess={filedownloadSuccess}
                                                                                    downloadingFile={downloadingFile}
                                                                                    fileAnalyticsObj={fileAnalyticsObj}
                                                                                    fileAnalyticsAction={this.props.getAnalyticsOnFile}
                                                                                    domains={domains}
                                                                                />
                                                                                : <Card
                                                                                    title="No datasets with access"
                                                                                    category="You do not have any datasets that you have access to resulted from the given query"
                                                                                    ctTextCenter
                                                                                    textCenter
                                                                                />
                                                                            }
                                                                        </Tab.Pane>
                                                                    </Tab.Content>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Container>
                                                        }
                                                    />
                                                    : <Card
                                                        title="No results found"
                                                        content={<div>
                                                            <p>No results found for given search term</p>
                                                            <p><button className="btn btn-xs btn-fill" onClick={() => this.navigatetosearch( '*' )}>Click here</button> to view all datasets.</p>
                                                        </div>}
                                                        textCenter
                                                        ctTextCenter
                                                    />
                                                )
                                            )}
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                }
            />}
        /> );
    }
}

const validate = ( values ) => {
    const errors = {};
    if ( !values.Comment ) {
        errors.Comment = 'Required';
    }
    if ( !values.AccessType ) {
        errors.AccessType = 'Required';
    }
    return errors;
};

DatasetSearch = reduxForm({
    form: 'accessRequestForm',
    destroyOnUnmount: true,
    validate
})( DatasetSearch );

const mapStateToProps = ( state, _props ) => {
    const perms = {
        searchAccess: 'datasets.search'
    };
    return {
        access: AccessCheck( state.account.userRole, perms ),
        domains: state.common?.domains?.domains || [],
        searchResults: state.dataset.searchResults || {},
        fetchingResults: state.dataset.searchingDatasets,
        searchError: state.dataset.searchError,
        accessSuccess: state.dataset.accessSuccess,
        accessError: state.dataset.accessError,
        globalConfig: state.globalConfig,
        fileDownloadSuccess: state.dataset.fileDownloadSuccess,
        fileDownloadError: state.dataset.fileDownloadError,
        downloadingFile: state.dataset.downloadingFile,
        fetchingFileAnalytics: state.dataset.fetchingFileAnalytics,
        fileAnalytics: state.dataset.fileAnalytics,
        fetchFileAnalyticsError: state.dataset.fetchFileAnalyticsError
    };
};

const mapDispatchToProps = {
    getDomains: () => getResource( 'domains' ),
    filedownloadAction: datasetActions.filedownload,
    resetForm: reset,
    searchDatasets: datasetActions.searchDatasets,
    resetDatasetProps: datasetActions.resetDatasetProps,
    getAnalyticsOnFile: datasetActions.getAnalyticsOnFile,
    requestDatasetAccess: datasetActions.requestDatasetAccess
};


export default connect( mapStateToProps, mapDispatchToProps )( DatasetSearch );