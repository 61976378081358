import React from 'react';
import TextLoader from './TextLoader';
import IconLoader from './IconLoader';

const CardLoader = ({
    // type,
    // count,
    // width,
    hasIcon,
    titleFirst
    // showAnimation
}) => <>
    <div className="card card-loader">
        { titleFirst
            ? <div className="">
                <div className="loader__row-withIcon">
                    <TextLoader lines={1} width={50} />
                </div>
                <div className="loader__row-withIcon">
                    <IconLoader items={1} width={20} height={20} />
                    <TextLoader lines={1} width={25} />
                </div>
                <TextLoader lines={1} width={100} />
                <TextLoader lines={1} width={100} />
            </div>
            : <div className="">
                <TextLoader lines={1} width={100} />
                {
                    hasIcon && <div className="loader__row-withIcon">
                        <IconLoader items={1} width={20} height={20} />
                        <TextLoader lines={1} width={25} />
                    </div>
                }
                <TextLoader lines={1} width={100} />
            </div>
        }
    </div>
</>;

export default CardLoader;