import { respond, getHttp, urlBuilder, extractError, extractMessage } from './utils';
import { USER_LOGOUT } from './common_actions';
import { showLoading, alertAndHide } from '../components/utils/popUpUtils';

const SET_RBAC_ERROR = 'SET_RBAC_ERROR';
const CHANGE_RBAC_FETCHING = 'CHANGE_RBAC_FETCHING';
const FETCH_GROUPS = 'FETCH_GROUPS';
const GROUP_SUCCESS = 'GROUP_SUCCESS';
const DELETE_GROUP = 'DELETE_GROUP';
const GROUP_FAILURE = 'GROUP_FAILURE';
const RESET_GROUP = 'RESET_GROUP';
const RESET_RBAC = 'RESET_RBAC';
const TOKEN_STATUS_SUCCESS = 'TOKEN_STATUS_SUCCESS';
const TOKEN_STATUS_ERROR = 'TOKEN_STATUS_ERROR';
const DELETE_PAT_SUCCESS = 'DELETE_PAT_SUCCESS';
const DELETE_PAT_ERROR = 'DELETE_PAT_ERROR';
const CHANGE_TOKEN_FETCHING = 'CHANGE_TOKEN_FETCHING';
const FETCH_TOKENS_SUCCESS = 'FETCH_TOKENS_SUCCESS';
const FETCH_TOKENS_ERROR = 'FETCH_TOKENS_ERROR';
const REGISTER_TOKEN_SUCCESS = 'REGISTER_TOKEN_SUCCESS';
const REGISTER_TOKEN_FAILURE = 'REGISTER_TOKEN_FAILURE';
export default ( state = {}, action ) => {
    switch ( action.type ) {
    case SET_RBAC_ERROR:
    case CHANGE_RBAC_FETCHING:
    case FETCH_GROUPS:
    case GROUP_SUCCESS:
    case DELETE_GROUP:
    case GROUP_FAILURE:
    case RESET_GROUP:
    case RESET_RBAC:
    case TOKEN_STATUS_SUCCESS:
    case TOKEN_STATUS_ERROR:
    case DELETE_PAT_SUCCESS:
    case DELETE_PAT_ERROR:
    case CHANGE_TOKEN_FETCHING:
    case FETCH_TOKENS_SUCCESS:
    case FETCH_TOKENS_ERROR:
    case REGISTER_TOKEN_SUCCESS:
    case REGISTER_TOKEN_FAILURE:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return {};
    default:
        return state;
    }
};

export const getGroups = ( ...args ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const projectionExpression = args.length > 0 ? [`projectionExpression=${args.join( ',' )}`] : [];
        const getgroupsAPI = urlBuilder(['groups'], projectionExpression );
        dispatch({ type: CHANGE_RBAC_FETCHING, data: { fetchingGroups: true, fetchGroupsError: undefined } });
        return client.get( getgroupsAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_GROUPS,
                        data: {
                            groupsList: response.data.groups,
                            fetchingGroups: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_RBAC_ERROR,
                    data: {
                        fetchGroupsError: extractError( error_obj ),
                        fetchingGroups: undefined
                    }
                });
                respond( 'getGroups', error_obj, dispatch );
            });
    };
};

export const addgroup = ( group, callback ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addgroupAPI = urlBuilder(['groups']);
        showLoading( 'Creating group...' );
        return client.post( addgroupAPI, group )
            .then(() => {
                alertAndHide( 'success', 'Group created successfully', '', () => {
                    if ( typeof callback === 'function' ){
                        callback();
                    }
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed !!', extractError( error_obj ));
                respond( 'addgroup', error_obj, dispatch );
            });
    };
};

export const addusertogroup = ( group, groupId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const addusertogroupAPI = urlBuilder(['groups', groupId, 'users']);
        return client.post( addusertogroupAPI, group )
            .then( response => {
                dispatch({ type: GROUP_SUCCESS, data: { groupsuccess: extractMessage( response.data ) } });
            })
            .catch( error_obj => {
                dispatch({
                    type: GROUP_FAILURE,
                    data: { grouperror: extractError( error_obj ) }
                });
                respond( 'addusertogroup', error_obj, dispatch );
            });
    };
};

export const AdminPrivlage = ( group, groupId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const adminPrivlageAPI = urlBuilder(['groups', groupId]);
        return client.put( adminPrivlageAPI, group )
            .then( response => {
                dispatch({ type: GROUP_SUCCESS, data: { groupsuccess: extractMessage( response.data ) } });
            })
            .catch( error_obj => {
                dispatch({
                    type: GROUP_FAILURE,
                    data: { grouperror: extractError( error_obj ) }
                });
                respond( 'AdminPrivlage', error_obj, dispatch );
            });
    };
};

export const deleteusersfromgroup = ( group, groupId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deleteusersfromgroupAPI = urlBuilder(['groups', groupId, 'users']);
        return client.put( deleteusersfromgroupAPI, group )
            .then( response => {
                dispatch({ type: GROUP_SUCCESS, data: { groupsuccess: extractMessage( response.data ) } });
            })
            .catch( error_obj => {
                dispatch({
                    type: GROUP_FAILURE,
                    data: { grouperror: extractError( error_obj ) }
                });
                respond( 'deleteusersfromgroup', error_obj, dispatch );
            });
    };
};

export const adddatasetstogroup = ( requestBody, groupId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const adddatasetstogroupAPI = urlBuilder(['groups', groupId, 'datasets']);
        return client.put( adddatasetstogroupAPI, requestBody )
            .then( _response => {
                dispatch({ type: GROUP_SUCCESS, data: { groupsuccess: true } });
            })
            .catch( error_obj => {
                dispatch({
                    type: GROUP_FAILURE,
                    data: { adddatasetstogrouperror: extractError( error_obj ) }
                });
                respond( 'adddatasetstogroup', error_obj, dispatch );
            });
    };
};

export const deletegroup = ( groupId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deletegroupAPI = urlBuilder(['groups', groupId]);
        dispatch({ type: CHANGE_RBAC_FETCHING, data: { isFetching: true } });
        return client.delete( deletegroupAPI )
            .then( response => {
                dispatch({
                    type: DELETE_GROUP,
                    data: {
                        deletegroupsuccess: extractMessage( response.data ),
                        isFetching: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: SET_RBAC_ERROR,
                    data: {
                        error: extractError( error_obj ),
                        isFetching: undefined
                    }
                });
                respond( 'deletegroup', error_obj, dispatch );
            });
    };
};


export const mapResource = ( requestBody, groupId, resource, callback = () => {}) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const mapDashboardsAPI = urlBuilder(['groups', groupId, resource]);
        showLoading();
        return client.put( mapDashboardsAPI, requestBody )
            .then( response => {
                alertAndHide( 'success', 'Group access updated !!', extractMessage( response.data, 'successfully updated resources in group' ), () => {
                    callback();
                });
            })
            .catch( error_obj => {
                alertAndHide( 'error', 'Request failed', extractError( error_obj ));
                respond( 'mapResource', error_obj, dispatch );
            });
    };
};

export const resetGroupProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_GROUP, data: propsToReset });
    };
};

export const resetgroupProps = () => {
    return ( dispatch ) => {
        dispatch({
            type: RESET_GROUP, data: {
                error: undefined,
                isRegistering: undefined,
                isRefreshing: undefined,
                groupsuccess: undefined,
                grouperror: undefined,
                deletegroupsuccess: undefined,
                adddatasetstogrouperror: undefined,
                mapdashboardstogrouperror: undefined
            }
        });
    };
};


export const getPATokens = () => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const getPATokensAPI = urlBuilder(['access-tokens']);
        dispatch({ type: CHANGE_TOKEN_FETCHING, data: { fetchingTokens: true, fetchTokenError: undefined } });
        return client.get( getPATokensAPI )
            .then( response => {
                if ( response.status === 200 ) {
                    dispatch({
                        type: FETCH_TOKENS_SUCCESS,
                        data: {
                            tokensList: response.data.AccessTokens,
                            fetchingTokens: undefined
                        }
                    });
                }
            })
            .catch( error_obj => {
                dispatch({
                    type: FETCH_TOKENS_ERROR,
                    data: {
                        fetchTokenError: extractError( error_obj ),
                        fetchingTokens: undefined
                    }
                });
                respond( 'getPATokens', error_obj, dispatch );
            });
    };
};

export const createNewPAToken = ( requestBody ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const newPATokenAPI = urlBuilder(['access-tokens']);
        return client.post( newPATokenAPI, requestBody )
            .then( response => {
                dispatch({
                    type: REGISTER_TOKEN_SUCCESS,
                    data: {
                        tokenCreateSuccess: response.data
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: REGISTER_TOKEN_FAILURE,
                    data: { tokenCreateError: extractError( error_obj ) }
                });
                respond( 'createNewPAToken', error_obj, dispatch );
            });
    };
};

export const updatePATokenStatus = ( type, tokenId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const updatePatStatusAPI = urlBuilder(['access-tokens', tokenId], [`operation=${type}`]);
        return client.put( updatePatStatusAPI )
            .then( response => {

                const dispatchObj =
                    type === 'deactivate' ? { tokenDeactivateSuccess: extractMessage( response.data ) }
                        : { tokenReactivateSuccess: extractMessage( response.data ) };

                dispatch(
                    {
                        type: TOKEN_STATUS_SUCCESS,
                        data: dispatchObj
                    });
            })
            .catch( error_obj => {

                const dispatchObj =
                    type === 'deactivate' ? { tokenDeactivateError: extractError( error_obj ) }
                        : { tokenReactivateError: extractError( error_obj ) };

                dispatch({
                    type: TOKEN_STATUS_ERROR,
                    data: dispatchObj
                });
                respond( 'updatePATokenStatus', error_obj, dispatch );
            });
    };
};

export const deletePAToken = ( tokenId ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const deletePatAPI = urlBuilder(['access-tokens', tokenId]);
        return client.delete( deletePatAPI )
            .then( response => {
                dispatch({
                    type: DELETE_PAT_SUCCESS,
                    data: {
                        tokenDeleteSuccess: extractMessage( response.data )
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: DELETE_PAT_ERROR,
                    data: {
                        tokenDeleteError: extractError( error_obj )
                    }
                });
                respond( 'deletePAToken', error_obj, dispatch );
            });
    };
};


export const resetRbacProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_RBAC, data: propsToReset });
    };
};


