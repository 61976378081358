import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Modal, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';

import ADPIcon from '../common/Icon';
import Card from '../layout/Cards/Card';

import { submitFeedback, resetAccountProps } from '../../modules/account';
import { withHelperText, formFieldHelper } from '../utils/renderUtils';
import { RELOAD_PATHS } from '../../modules/globalConfig/reducer';
import Tooltips from '../../mappings/helper_tooltips.json';

class AppErrorBoundary extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            error: null,
            errorInfo: null,
            open: false,
            issueDescription: ''
        };
    }

    static getDerivedStateFromError( error ) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch( error, errorInfo ) {
        this.setState({
            errorInfo
        });
    }

    displayModal = ( display = false ) => {
        const stateObj = {
            open: display
        };
        if ( !display ) {
            stateObj.issueDescription = '';
        }
        this.setState( stateObj );
    }

    handleInputUpdate = ( e ) => {
        const { value } = e.target;

        this.setState({
            'issueDescription': value
        });
    }

    recoverByReload = () => {
        this.props.reloadConstants();
        window.location.reload();
    }

    submitFeedback = () => {
        this.displayModal( false );
        this.props.submitFeedback({
            Message: this.state.issueDescription ? this.state.issueDescription : '',
            IssueName: this.state.error.toString(),
            StackTrace: this.state.errorInfo ? this.state.errorInfo.componentStack : '',
            Path: this.props.history.location.pathname
        }, () => {
            this.props.history.push( '/home' );
        });
    }

    render() {
        if ( this.state.error || this.state.errorInfo ) {
            return ( <Row>
                <Col sm={10} smOffset={1}>
                    <br /><br />
                    <Card
                        legend={<div>
                            { withHelperText( 'Attempt error recovery', <button onClick={this.recoverByReload} className="btn btn-fill" ><ADPIcon icon="refresh" /></button> ) } &nbsp;
                            { withHelperText( 'Report issue', <button className="btn btn-fill" onClick={() => this.displayModal( true )} ><ADPIcon icon="file-export" /></button> ) } &nbsp;
                        </div>
                        }
                    >
                        <Row>
                            <Col sm={12} className="text-muted">
                                <span className="fa-layers" style={{ margin: '5rem 0rem' }}>
                                    <ADPIcon icon="brackets-curly" size="9x" className="text-warning" />
                                    <ADPIcon icon="bug" size="5x" transform="right-8" className="text-danger" />
                                </span><br />
                                <h3>Content Error !!</h3>
                                <p>The page you are trying to access has an error and cannot be safely rendered.</p>
                                <p className="text-danger">{this.state.error.toString()}</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Modal
                    show={this.state.open}
                    onHide={() => this.displayModal( false )}
                    aria-labelledby="ModalHeader" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader'>Report Issue</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <form>
                                <Col sm={12}>
                                    <FormGroup controlId="IssueDescription">
                                        { formFieldHelper( Tooltips.Error.IssueDescription ) }
                                        <ControlLabel className="required-label">Issue Description</ControlLabel>&nbsp;&nbsp;
                                        <FormControl
                                            name="issueDescription"
                                            componentClass="textarea"
                                            placeholder="Write Issue Description here"
                                            rows={5}
                                            value={this.state.issueDescription}
                                            onChange={this.handleInputUpdate}
                                        />
                                        {this.state.issueDescription && this.state.issueDescription.length > 500 ?
                                            <small className="text-danger pull-right">* Max character limit is 500</small> : ''}
                                    </FormGroup>
                                </Col>
                                <div className="clearfix"></div>
                                <Col sm={12}>
                                    <FormGroup controlId="IssueName">
                                        <ControlLabel>Issue Name</ControlLabel>&nbsp;&nbsp;
                                        <FormControl
                                            componentClass="textarea"
                                            readOnly
                                            rows={2}
                                            value={this.state.error.toString()}
                                        />
                                    </FormGroup>
                                </Col>
                                <div className="clearfix"></div>
                                <Col sm={12}>
                                    <FormGroup controlId="StackTrace">
                                        <ControlLabel>Stack Trace</ControlLabel>&nbsp;&nbsp;
                                        <FormControl
                                            componentClass="textarea"
                                            readOnly
                                            rows={5}
                                            value={this.state.errorInfo ? this.state.errorInfo.componentStack : ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <button type="button" className="btn btn-fill btn-wd pull-right"
                                        onClick={this.submitFeedback} disabled={!this.state.error || !this.state.issueDescription || this.state.issueDescription.length > 500}>
                                        Submit
                                    </button>
                                </Col>
                            </form>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Row> );
        }
        return this.props.children;
    }
}

const mapStateToProps = ( _state, _props ) => {
    return {};
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        reloadConstants: () => dispatch({ type: RELOAD_PATHS }),
        submitFeedback: ( requestBody, callback ) => dispatch( submitFeedback( requestBody, 'AppErrorBoundary', callback )),
        resetAccountProps: ( propsToReset ) => dispatch( resetAccountProps( propsToReset ))
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( AppErrorBoundary ));
