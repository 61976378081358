const key = '__Amorphic_Language__';

const CHANGE_LANG = 'CHANGE_LANG';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case CHANGE_LANG:
        return {...state, ...action.data};
    default:
        return state;
    }
};

export const initializeCountry = () => {
    return dispatch => {
        const domainName = window.location.hostname;
        if ( domainName.indexOf( '.ca' ) !== -1 ) {
            dispatch({
                type: CHANGE_LANG,
                data: {
                    language: 'en_CA'
                }
            });
        } else {
            dispatch({
                type: CHANGE_LANG,
                data: {
                    language: 'en_US'
                }
            });
        }
    };
};

export const initializeAppLanguage = () => {
    return dispatch => {
        const lang = getLanguageFromLocalStorage();
        dispatch({
            type: CHANGE_LANG,
            data: {
                language: lang
            }
        });
    };
};

export const changeAppLanguage = ( lang ) => {
    return ( dispatch ) => {
        setLanguageOnLocalStorage({ lang });
        dispatch({
            type: CHANGE_LANG,
            data: {
                language: lang
            }
        });
    };
};

const setLanguageOnLocalStorage = ({ lang }) => {
    try {
        localStorage.setItem( key, lang );
    } catch ( error ) {
        // eslint-disable-next-line no-console
        console.log( error );
    }
};

const getLanguageFromLocalStorage = () => {
    try {
        const lang = localStorage.getItem( key );
        if ( lang && lang.length > 2 ) {
            return lang;
        } else {
            return 'en_US';
        }
    } catch ( error ) {
        return 'en_US';
    }
};
