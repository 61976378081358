const routes = [
    {
        pathIdentifier: 'home',
        action: 'any'
    },
    {
        pathIdentifier: 'listConnections',
        action: 'connectionsDisplay'
    },
    {
        collapse: true,
        pathIdentifier: 'datasets',
        state: 'openDatasets',
        action: 'datasetsDisplay',
        views: [
            {
                pathIdentifier: 'listDatasets',
                hiddenRoutes: [ /\/datasets\/(new|list)/, /\/datasets\/([^/]+)\/(details|clone|edit)/]
            }, {
                pathIdentifier: 'listViews',
                exactPathMatch: true
            }, {
                pathIdentifier: 'listDeepsearchIndices'
            }, {
                pathIdentifier: 'listDomains',
                exactPathMatch: true
            }, {
                pathIdentifier: 'search',
                exactPathMatch: true
            }, {
                pathIdentifier: 'listDatasetAccessRequests',
                exactPathMatch: true
            }, {
                pathIdentifier: 'dataClassifications',
                exactPathMatch: true
            }
        ]
    },
    {
        pathIdentifier: 'runQuery',
        beta: false
    },
    {
        pathIdentifier: 'listDashboards'
    },
    {
        collapse: true,
        pathIdentifier: 'analytics',
        state: 'openMl',
        action: 'analyticsDisplay',
        views: [
            {
                pathIdentifier: 'listModels'
            }, {
                pathIdentifier: 'listEntityRecognizers'
            }, {
                pathIdentifier: 'listNotebooks'
            }, {
                pathIdentifier: 'listForecasts'
            }
        ]
    },
    {
        collapse: true,
        pathIdentifier: 'etl',
        state: 'openEtl',
        action: 'etlDisplay',
        views: [
            {
                pathIdentifier: 'listJobs'
            },
            {
                pathIdentifier: 'listEndpoints'
            },
            {
                pathIdentifier: 'listETLNotebooks'
            },
            {
                pathIdentifier: 'listLibraries'
            }
        ]
    },
    {
        pathIdentifier: 'listSchedules'
    },
    {
        collapse: true,
        pathIdentifier: 'management',
        state: 'openManage',
        action: 'manageDisplay',
        views: [
            {
                pathIdentifier: 'listUsers'
            }, {
                pathIdentifier: 'listRoles'
            }, {
                pathIdentifier: 'listMailservers'
            }, {
                pathIdentifier: 'listSaml'
            }, {
                pathIdentifier: 'listParameters'
            }, {
                pathIdentifier: 'usageStats',
                exactPathMatch: true
            }, {
                pathIdentifier: 'systemHealth',
                exactPathMatch: true
            }, {
                pathIdentifier: 'bulkManagement',
                exactPathMatch: true
            }, {
                pathIdentifier: 'grantReports',
                exactPathMatch: true
            }
        ]
    }
];

export default routes;

export const extraRoutesWithPermissions = routes.concat(
    [
        {
            pathIdentifier: 'groupsAccess',
            action: 'extra'
        },
        {
            pathIdentifier: 'systemDatasetsAccess',
            action: 'extra'
        },
        {
            pathIdentifier: 'systemAlertsAccess',
            action: 'extra'
        }
    ]
);