import React from 'react';
import { connect } from 'react-redux';

import * as authActions from '../../modules/auth';

class Callback extends React.Component {
    componentDidMount() {
        this.props.auth( window.location.href );
        this.props.history.push( '/' );
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return {
        auth: ( location ) => dispatch( authActions.authReceived( location ))
    };
};

export default connect( null, mapDispatchToProps )( Callback );
