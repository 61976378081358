import React from 'react';
import Loader from 'react-loader-spinner';

const Loading = ({
    noPadding = false
}) =>  {
    const style = {
        'paddingTop': noPadding ? '0%' : '20%'
    };

    return <div style={style} className="center-block paddingtop_20">
        <center>
            <Loader
                type="Bars"
                color="#337AB7"
                height="100"
                width="100"
            />
        </center>
    </div>;
};

export default Loading;