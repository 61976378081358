import React from 'react';
import { MenuItem } from 'react-bootstrap';

import ADPIcon from '../../common/Icon';

import { withHelperText } from '../../utils/renderUtils';
import { accessAlert } from '../../utils/popUpUtils';

const CardActionMenuItem = ({
    hasAccess,
    title,
    icon,
    loadingState = false,
    titlePlacement = 'bottom',
    extraClasses = '',
    disabled = false,
    id,
    ...props
}) => ( hasAccess ?
    <MenuItem eventKey={ ( Math.random() * 100 ).toFixed( 0 ) } {...props} disabled={disabled} className={extraClasses} id={`${id}-${title?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-btn`}>
        <ADPIcon icon={ icon } spin={ loadingState } className="text-warning" /> { title }
    </MenuItem>
    : withHelperText(
        `No access to ${title.toLowerCase()} !!`,
        <MenuItem eventKey={( Math.random() * 100 ).toFixed( 0 )} onSelect={() => accessAlert( title ) } disabled={true} id={`${id}-${title?.replace( /[^a-zA-Z0-9]+/ig, '-' ).toLowerCase()}-btn`}>
            <ADPIcon icon={ icon } className="text-warning" /> { title }
        </MenuItem>, titlePlacement
    )
);

export default CardActionMenuItem;