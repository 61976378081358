import React from 'react';

const Card = ({
    hidden,
    calendar,
    plain,
    wizard,
    extraClasses,
    menuOption,
    title,
    category,
    textCenter,
    nonDescriptiveCategory,
    ctFullWidth,
    ctTextCenter,
    tableFullWidth,
    content,
    children,
    stats,
    legend,
    ftTextCenter,
    id = '',
    onClick = () => { }
}) => (
    <div
        onClick={onClick}
        id={ id ? `${id}-card` : '' }
        className={
            `card${
                hidden ? ' card-hidden' : ''
            }${calendar ? ' card-calendar' : ''
            }${plain ? ' card-plain' : ''
            }${wizard ? ' card-wizard' : ''
            }${extraClasses ? ` ${extraClasses}` : ''}`
        }
    >
        {menuOption !== undefined ? menuOption : ''}
        {title !== undefined || category !== undefined ? (
            <div
                className={`header${textCenter ? ' text-center' : ''}`}
                id={ id ? `${id}-card-title` : '' }
            >
                <h4 className="title">{title}</h4>
                {nonDescriptiveCategory ? category : <span className="category">{category}</span>}
            </div>
        ) : (
            ''
        )}
        <div
            className={
                `content${ctFullWidth ? ' content-full-width' : ''
                }${ctTextCenter ? ' text-center' : ''
                }${tableFullWidth ? ' table-full-width' : ''}`
            }
            id={ id ? `${id}-card-content` : '' }
        >
            {content}
            {children}
        </div>
        {stats !== undefined || legend !== undefined ? (
            <div
                className={
                    `footer${ftTextCenter ? ' text-center' : ''}`
                }
                id={ id ? `${id}-card-footer-container` : '' }
            >
                {legend !== undefined ? (
                    <div
                        className="legend"
                        id={id ? `${id}-card-footer` : ''}
                    >
                        {legend}
                    </div>
                ) : null}
                {stats !== undefined ? <hr /> : null}
                {stats !== undefined ? (
                    <div className="stats" id={ id ? `${id}-card-footer-stats` : '' }>{stats}</div>
                ) : null}
            </div>
        ) : null}
    </div>
);

export default Card;
