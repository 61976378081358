import { RESET_ERROR, USER_LOGOUT } from './common_actions';
import { SET_SESSION, RELOAD_PATHS } from './globalConfig/reducer';

export default ( state = {}, action ) => {
    switch ( action.type ) {
    case RESET_ERROR:
        return {};
    default:
        return state;
    }
};

export const resetError = () => {
    return ( dispatch ) => {
        dispatch({ type: RESET_ERROR });
    };
};

export const resetApplication = () => {
    return ( dispatch, _state ) => {
        try {
            localStorage.removeItem( 'amorphic-data-platform' );
            localStorage.removeItem( 'persist:amorphic-data-platform' );
        } finally {
            dispatch({ type: RELOAD_PATHS });
            dispatch({ type: SET_SESSION, session: false});
            dispatch({ type: USER_LOGOUT });
            window.location.replace( '/' );
        }
    };
};