import React from 'react';
import { Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

const frameOptions = ( optionList ) => optionList.map(({
    value,
    label
}) => <option key={value} value={value}>{label}</option> );

const SortingSection = ({
    listingItems,
    sortByOptions,
    isSectionDisabled,
    onSortFunction,
    sortOrderOptions = [],
    resultsPerPageOptions = [],
    sortByValue,
    sortOrderValue,
    arrangeSortOptions = true,
    resultsPerPageValue,
    moduleName = ''
}) => {

    return <>
        {
            sortByOptions.length > 0 && <Col sm={12} md={3}>
                <FormGroup >
                    <ControlLabel id={`${moduleName}-column-sort-label`} >Sort By</ControlLabel>
                    <FormControl
                        id={`${moduleName}-column-sort-input`}
                        componentClass="select"
                        placeholder="Sort by column"
                        name="sortBy"
                        value={sortByValue}
                        onChange={onSortFunction}
                        disabled={isSectionDisabled}>

                        {listingItems.length > 0 && arrangeSortOptions ?
                            Object.keys( listingItems[0]).filter(( el ) => {
                                return sortByOptions.includes( el );
                            }).sort(( a, b ) => { return a.toLowerCase().localeCompare( b.toLowerCase()); }).map( function ( obj, optIndex ) {
                                return <option value={obj} key={optIndex}>{obj.split( /(?=[A-Z])/ ).join( ' ' )}</option>;
                            }) : frameOptions( Object.values( sortByOptions ).map(( option ) => ({
                                label: option,
                                value: option
                            })))
                        }
                    </FormControl>
                </FormGroup>
            </Col>
        }
        {
            sortOrderOptions.length > 0 && <Col sm={12} md={3}>
                <FormGroup >
                    <ControlLabel id={`${moduleName}-sort-order-label`} >Sort Order</ControlLabel>
                    <FormControl
                        id={`${moduleName}-sort-order-input`}
                        componentClass="select"
                        placeholder="Sort Order"
                        name="sortOrder"
                        value={sortOrderValue}
                        onChange={onSortFunction}
                        disabled={isSectionDisabled}>
                        {frameOptions( sortOrderOptions )}
                    </FormControl>
                </FormGroup>
            </Col>
        }
        {
            resultsPerPageOptions.length > 0 && <Col sm={12} md={3}>
                <FormGroup >
                    <ControlLabel id={`${moduleName}-per-page-label`} >Results per page</ControlLabel>
                    <FormControl
                        id={`${moduleName}-per-page-input`}
                        componentClass="select"
                        placeholder="Limit"
                        name="limit"
                        value={resultsPerPageValue}
                        onChange={onSortFunction}
                        disabled={isSectionDisabled}>
                        {frameOptions( resultsPerPageOptions )}
                    </FormControl>
                </FormGroup>
            </Col>
        }
    </>;
};

export default SortingSection;