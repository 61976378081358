import React, { useState } from 'react';

import {
    Row,
    Col,
    Table
} from 'react-bootstrap';

import {
    withHelperText,
    withShortPopover,
    imageByDatasetType
} from '../utils/renderUtils';

import { LinkContainer } from 'react-router-bootstrap';

import Card from '../layout/Cards/Card';

import ADPIcon from '../common/Icon';

import { convertToLocalTime } from '../utils/dateUtils';

import FilesModal, { formatFileName } from './FilesModal';


const BuildListing = ({
    recordSet,
    currentState,
    filedownloadAction,
    queryString,
    fileAnalyticsAction,
    fileAnalyticsObj = {},
    domains
}) => {
    const [ showFilesModal, setShowFilesModal ] = useState( false );
    const [ dataset, setDataset ] = useState({});

    return <>

        <FilesModal isVisible={showFilesModal}
            dataset={dataset}
            filedownloadAction={filedownloadAction}
            fileAnalyticsAction={fileAnalyticsAction}
            fileAnalyticsObj={fileAnalyticsObj}
            queryString={queryString}
            onHide={() => {
                setShowFilesModal( false );
                setDataset({});
            }}/>
        {
            recordSet.map(( record, index ) => {
                return <Row key={index}>
                    <Col sm={12}>
                        <Card
                            extraClasses="datasetListing"
                            content={ <span>
                                { record.AccessType === 'none' && <>
                                    { ( record.RequestStatus === 'none' && !['owner', 'read-only'].includes( record.AccessType )) &&
                                        <button className="btn btn-fill btn-sm pull-right" onClick={() => currentState.displayAccessModal( true, record.DatasetId )}>Request Access</button>
                                    }
                                    { record.RequestStatus === 'pending' &&
                                        <span className="pull-right">You have already requested for access</span>
                                    }
                                </>
                                }
                                { ['owner', 'read-only'].includes( record.AccessType ) &&
                                    <span className="pull-right">You have <i className="text-warning">{record.AccessType}</i> access</span>
                                }
                                <h4 className="title text-primary">
                                    <LinkContainer to={`/datasets/${record.DatasetId}/details`}>
                                        {/* eslint-disable-next-line */}
                                        <a>
                                            <ADPIcon icon={imageByDatasetType( record.FileType ? record.FileType : '' )} transform="shrink-2" />&nbsp;
                                            {record.DatasetName}
                                        </a>
                                    </LinkContainer>
                                </h4>
                                <span>{record.DatasetDescription}</span>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th width="20%">File Type</th>
                                            <th width="20%">Files Matched</th>
                                            <th width="20%">Target</th>
                                            <th width="20%">Domain</th>
                                            <th width="20%">Last Modified</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="20%">{record.FileType ? record.FileType : withHelperText( 'Private field', <ADPIcon icon="lock" /> ) }</td>
                                            <td width="20%">
                                                { ['owner', 'read-only'].includes( record.AccessType ) ?
                                                    <>
                                                        { record.Files && record.Files.length > 0
                                                            ? withShortPopover( <>
                                                                { record.Files.slice( 0, 3 ).map(( obj, idx ) => {
                                                                    return <p key={idx} className="popover-list-item" >
                                                                        <ADPIcon
                                                                            icon={imageByDatasetType( obj.split( '.' ).pop())}
                                                                            transform="shrink-1" />&nbsp;
                                                                        {formatFileName( obj )}
                                                                    </p>;
                                                                })
                                                                }
                                                                {record.Files.length >= 4 && <p className="popover-list-item">
                                                                    {record.Files.length - 3} more...

                                                                </p>}
                                                            </>,
                                                            'File(s) matching search term',
                                                            <span className="btn" onClick={() => {
                                                                setShowFilesModal( true );
                                                                setDataset( record );
                                                            }}>
                                                                {
                                                                    record.Files.length === 1 ? '1 file' : `${record.Files.length} files`
                                                                }
                                                            </span>,
                                                            'right'
                                                            )
                                                            : withHelperText( 'No files matched the search term', <span>0</span> )
                                                        }
                                                    </> : withHelperText( 'You do not have access to dataset', <ADPIcon icon="lock" /> )
                                                }
                                            </td>
                                            <td width="20%">{record.TargetLocation ? record.TargetLocation : withHelperText( 'Private field', <ADPIcon icon="lock" /> ) }</td>
                                            <td width="20%">{
                                                domains.filter( el => el.DomainName === record.Domain ).length > 0
                                                    ? `${domains.filter( el => el.DomainName === record.Domain )[0]['DisplayName']} (${record.Domain})`
                                                    : record.Domain
                                            }</td>
                                            <td width="20%">
                                                { record.LastModifiedBy || 'N/A' }
                                                { record.LastModified ? ` (${convertToLocalTime( record.LastModified )})` : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </span>
                            }
                        />
                    </Col>
                </Row>;
            })

        }
    </>;
};

export default BuildListing;