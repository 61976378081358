import React from 'react';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { TagCloud } from 'react-tagcloud';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

import {
    Tabs, Tab, Table, Row, Col, Panel, PanelGroup, FormGroup,
    FormControl, ControlLabel
} from 'react-bootstrap';

import Card from '../layout/Cards/Card';
import ADPIcon from '../common/Icon';

const COLORS = { Mixed: '#e6ae35', Positive: '#36e364', Neutral: '#2d8bb9', Negative: '#dc3737' };

class Analytics extends React.PureComponent {

    constructor( props ) {
        super( props );
        this.handleChange = this.handleChange.bind( this );
        this.state = {
            filterByType: 'all',
            showAll: 'hide',
            list: []
        };
    }

    componentDidMount() {
        if ( this.props.type !== 'dataset' ) {
            this.filterNow();
        }
    }

    handleChange = ( e ) => {
        this.setState({
            filterByType: e.target.value
        }, () => {
            this.filterNow();
        });
    }

    filterNow() {
        const statusFilter = this.state.filterByType;
        const analyticsObj = this.props.type === 'dataset' ? this.props.data.DatasetAnalytics : this.props.data.analytics;
        const temp = ( statusFilter.length > 0 && statusFilter !== 'all' )
            ? ( analyticsObj.CustomModelOutputs || []).filter(( obj ) => {
                if ( statusFilter === 'models' ) {
                    return typeof obj.ModelOutput !== 'object';
                } else {
                    return typeof obj.ModelOutput === 'object';
                }
            })
            : ( analyticsObj.CustomModelOutputs || []);
        this.setState({
            list: temp
        });
    }

    toggleShow = ( show ) => {
        this.setState({
            showAll: show
        });
    }

    computeSentimentObj = memoizeOne(( dataset, analytics ) => {
        if ( dataset && analytics ) {
            if ( analytics.SentimentAnalysis && analytics.SentimentAnalysis.SentimentScore && Object.keys( analytics.SentimentAnalysis.SentimentScore ).length > 0 ) {
                return Object.keys( analytics.SentimentAnalysis.SentimentScore ).map(( key, index ) => {
                    return ({ name: key, value: Number( analytics.SentimentAnalysis.SentimentScore[key]), key: index });
                });
            } else {
                return [];
            }
        } else {
            return [];
        }
    })

    render() {
        const dataset = this.props.data;
        const { type } = this.props;
        const analytics = ( typeof type !== 'undefined' && type === 'dataset' ) ? dataset.DatasetAnalytics : dataset.analytics;
        const { list, showAll } = this.state;
        const sentimentobj = this.computeSentimentObj( dataset, analytics );

        return ( <Tabs id="dataset-fileAnalytics-tabs-container">
            {typeof dataset.transformed_file_url !== 'undefined'
                ? dataset.transformed_file_url !== 'N/A' && <Tab eventKey='Transformed-File' title="Transformed File">
                    <div className="panel-body">
                        <a href={dataset.transformed_file_url} id="dataset-fileAnalytics-downloadTransformedFile-link">Click here <ADPIcon icon="external-link" /></a> to download transformed file
                    </div>
                </Tab>
                : <span></span>
            }
            {analytics.KeyPhrases &&
                <Tab eventKey='Key-Phrases' title="Key-Phrases">
                    <div className="panel-body">
                        {analytics.KeyPhrases.length > 0 ?
                            <TagCloud
                                id="dataset-fileAnalytics-tabs-keyPhrases-tagCloud"
                                minSize={10}
                                maxSize={45}
                                tags={analytics.KeyPhrases
                                    .map(( item, iIndex ) => {
                                        return { value: item.Value, count: item.Count, key: iIndex };
                                    })
                                }
                            />
                            : <Card
                                id="dataset-fileAnalytics-tabs-keyPhrases-noKeyPhrases"
                                title="No Key-phrases found"
                                textCenter
                            />
                        }
                        {/* <span dangerouslySetInnerHTML={{ __html: tagFormatter(dataset.analytics.KeyPhrases)}}></span> */}
                    </div>
                </Tab>
            }
            {analytics.Entities &&
                <Tab eventKey='Entities' title="Entities">
                    <div className="panel-body">
                        {( Object.keys( analytics.Entities ).some( elem => analytics.Entities[elem].length > 0 )) ?
                            <Table striped bordered condensed hover responsive id="dataset-fileAnalytics-tabs-entities-table">
                                <tbody className="EntitiesTable">
                                    {Object.keys( analytics.Entities ).map( function ( item, index ) {
                                        if ( analytics.Entities[item].length > 0 ) {
                                            return (
                                                <tr key={index}>
                                                    <td className="col-sm-4 text-primary" id={`dataset-fileAnalytics-tabs-entities-${index}-label`}>{item}</td>
                                                    <td id={`dataset-fileAnalytics-tabs-entities-${index}-values`}>
                                                        {analytics.Entities[item].map(( entity, eIndex ) => {
                                                            return <span className="badge space-well badge-dark" key={eIndex}>{entity}</span>;
                                                        })
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        } else { return null; }
                                    }).filter(( el ) => el )}
                                </tbody>
                            </Table>
                            : <Card
                                id="dataset-fileAnalytics-tabs-entities-noEntities"
                                title="No Entities found"
                                textCenter
                            />
                        }
                    </div>
                </Tab>
            }
            {analytics.Languages &&
                <Tab eventKey='Languages' title="Languages">
                    <div className="panel-body">
                        {analytics.Languages.length > 0
                            ? <table className="table table-bordered property" id="dataset-fileAnalytics-tabs-languages-table">
                                <tbody>
                                    <tr>
                                        <td id="dataset-fileAnalytics-tabs-languages-label">Languages Used</td>
                                        <td id="dataset-fileAnalytics-tabs-languages-values">
                                            {analytics.Languages.map(( lang, lIndex ) => {
                                                return <span className="badge space-well badge-dark" key={lIndex}>{lang}</span>;
                                            })
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            : <Card
                                id="dataset-fileAnalytics-tabs-languages-noLanguages"
                                title="No languages found !!"
                                textCenter
                            />
                        }
                    </div>
                </Tab>
            }
            {analytics.SentimentAnalysis &&
                <Tab eventKey='Sentiment' title="Sentiment">
                    <div className="panel-body">
                        <center><span><b><i>{analytics.SentimentAnalysis.Sentiment || ''}</i></b></span>
                            {( sentimentobj.length > 0 && Object.keys( sentimentobj ).some( elem => sentimentobj[elem].value > 0 )) ?
                                <div className="sentimentgraph" id="dataset-fileAnalytics-tabs-sentiment-graph-container">
                                    <PieChart width={300} height={200} >
                                        <Pie
                                            data={sentimentobj}
                                            cx={150}
                                            cy={110}
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            paddingAngle={1}
                                            dataKey="value"
                                        >
                                            {
                                                sentimentobj.map(( entry, index ) => <Cell fill={COLORS[entry.name]} key={`color-${index}`} /> )
                                            }
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </div>
                                : <Card
                                    id="dataset-fileAnalytics-tabs-sentiment-noSentiment"
                                    title="No sentiment analysis found !!"
                                    textCenter
                                />
                            }
                        </center>
                    </div>
                </Tab>
            }
            {analytics.Summary &&
                <Tab eventKey='Summary' title="Summary">
                    <div className="panel-body" id="dataset-fileAnalytics-tabs-summary-container">
                        <span id="dataset-fileAnalytics-tabs-summary"> <i>{analytics.Summary}</i> </span>
                    </div>
                </Tab>
            }
            {analytics.CustomModelOutputs &&
                <Tab eventKey='Custom-Model-Outputs' title="Custom Model Outputs">
                    <Row id="dataset-fileAnalytics-tabs-customModalOutputs-container">
                        <Col sm={12}>
                            <FormGroup controlId="filterByType" className="pull-right">
                                <ControlLabel id="dataset-fileAnalytics-tabs-customModalOutputs-filterType-label">Filter By Type</ControlLabel>
                                <FormControl
                                    name="filterByType"
                                    value={this.state.filterByType} onChange={this.handleChange}
                                    componentClass="select"
                                    id="dataset-fileAnalytics-tabs-customModalOutputs-filterType-input"
                                >
                                    <option value="all">All</option>
                                    <option value="models">ML Models</option>
                                    <option value="cer">Entity Recognizer</option>
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <br />
                        <Col sm={12} className="content">
                            {list && list.length > 0
                                ? <Card
                                    content={<PanelGroup accordion defaultActiveKey="0" id="model-outputs-pg">
                                        {list.map(( record, index ) => {
                                            return <Panel eventKey={`${index}`} bsStyle="info" key={index}>
                                                <Panel.Heading>
                                                    <Panel.Title toggle>
                                                        <span className="fa-layers fa-fw">
                                                            <ADPIcon icon="file" />
                                                            <span className="fa-layers-text fa-inverse" style={{ fontSize: '0.8rem', fontWeight: 'bolder' }}>{typeof record.ModelOutput === 'string' ? 'ML' : 'ER'}</span>
                                                        </span>&nbsp;
                                                        {record.ModelName}
                                                    </Panel.Title>
                                                </Panel.Heading>
                                                <Panel.Body collapsible onSelect={() => this.toggleShow( 'hide' )}>
                                                    <table className="table table-bordered property">
                                                        <tbody>
                                                            <tr>
                                                                <td>Model Output File Path</td>
                                                                <td>{record.ModelOutputFilePath}</td>
                                                            </tr>
                                                            {typeof record.ModelOutput === 'object' ?
                                                                Object.keys( record.ModelOutput ).map(( item, moIndex ) => {
                                                                    return (
                                                                        <tr key={moIndex}>
                                                                            <td className="col-sm-4 text-primary">{item}&nbsp;<i><small>{`(${record.ModelOutput[item].length})`}</small></i></td>
                                                                            <td>
                                                                                {record.ModelOutput[item].length > 0
                                                                                    ? <>
                                                                                        {record.ModelOutput[item].map(( term, tIndex ) => {
                                                                                            return <span className={`badge space-well badge-dark ${tIndex > 15 ? showAll : ''}`} key={tIndex}>{term}</span>;
                                                                                        })}
                                                                                        {record.ModelOutput[item].length > 15 && <>
                                                                                            { /* eslint-disable-next-line */}
                                                                                            {showAll === 'hide' ? <a onClick={() => this.toggleShow('noHide')}>More...</a> : <a onClick={() => this.toggleShow('hide')}>Less...</a>}
                                                                                        </>
                                                                                        }
                                                                                    </>
                                                                                    : 'No entities found !!'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }) : <tr>
                                                                    <td className="col-sm-4 text-primary">Result</td>
                                                                    <td>{record.ModelOutput}</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Panel.Body>
                                            </Panel>;
                                        })
                                        }
                                    </PanelGroup>
                                    }
                                />
                                : <Card
                                    id="dataset-fileAnalytics-tabs-customModalOutputs-noCustomModalOutputs"
                                    title={analytics.CustomModelOutputs.length > 0 ? 'No results matched Filters' : 'No results found !!'}
                                    textCenter
                                />}
                        </Col>
                    </Row>
                </Tab>
            }
        </Tabs> );
    }
}

export default connect(( _state ) => { return {}; }, null )( Analytics );