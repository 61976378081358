import React from 'react';
import ADPIcon from '../../common/Icon';

const ListCard = ({
    _title = '',
    _titleCta = {},
    _list = [],
    _contentType = 'activity',
    _showNoRecords = false,
    _caller = 'accessRequests',
    _options = null
}) => {
    const { columnsCount = 0, columnWidths = [], headerColumCount = 0,
        headerList = [], headerWidths = [], scroll = false  } = _options;
    return (
        <div className={_caller === 'accessRequests' ? 'card' : ''}>
            <Title
                title={_title}
                titleCta={_titleCta}
            />
            { (( _list.length > 0 || _showNoRecords ) && _options ) && <div className={`${scroll ? 'flash-scroll' : ''}`}>
                <table className="table-list table-responsive" >
                    <Header
                        headerColumCount={headerColumCount}
                        headerList={headerList}
                        headerWidths={headerWidths}
                    />
                    <tbody>
                        { _contentType === 'activity' &&
                            <ActivityBody
                                columnsCount={columnsCount}
                                list={_list}
                                widthArray={ columnWidths  }
                            />
                        }
                        { _contentType === 'usersData' &&
                            <UsersBody
                                columnsCount={columnsCount}
                                list={_list}
                                widthArray={ headerWidths  }
                            />
                        }
                    </tbody>
                </table>
            </div> }
        </div> );
};

export default ListCard;

const Title = ({ title, titleCta }) => {
    return  ( title ) ?
        <div className="listTitle" >
            { title }
            {titleCta &&
        <span className="pull-right" onClick={titleCta.callback && typeof titleCta.callback === 'function' ? titleCta.callback : () => {} } >
            { titleCta.icon && <ADPIcon
                icon={titleCta.icon} spin={titleCta.loadingState}
            /> }
            &nbsp; { titleCta.text ? titleCta.text : ''}
        </span>}
        </div> : '';
};

const Header = ({ headerColumCount, headerList, headerWidths }) => {
    return ( headerColumCount > 0 && headerList.length > 0  ) ? <thead>
        <tr>
            { headerList.map(( header, index ) => (
                <th key={index} style={{width: `${headerWidths[index]}%`}} colSpan={index === 2 ? 2 : 0 }>{header}</th>
            ))}
        </tr>
    </thead> : null;
};

const ActivityBody = ({ columnsCount, list, widthArray }) => {
    return ( list.length > 0 ?
        list.map(( record, index ) => <tr key={index}>
            { columnsCount >= 1 &&
            <td style={{width: `${widthArray[0]}%`}} >
                <div className="atomic-cell-content">
                    { record[0] }
                </div>
                <div className="atomic-cell-content rel-time">
                    { record[1] }
                </div>
            </td>}
            { columnsCount >= 2 &&
            <td style={{width: `${widthArray[1]}%`}}>
                <div className="atomic-cell-content centrify">
                    <ADPIcon icon={record[2] || 'atom'}  className={record[3].className || 'text-success'} />
                </div>
                <div className="atomic-cell-content rel-time centrify">
                    { record[3] && record[3].label ?
                        ( typeof record[3].callback === 'function' ?
                            <span title={record[3].label} onClick={record[3].callback}>{record[3].label}</span>
                            : <span title={record[3].label}>{record[3].label}</span> )
                        :  null
                    }
                </div>
            </td>}
            { columnsCount >= 3 &&
            <td style={{width: `${widthArray[2]}%`}}>
                <div className="atomic-cell-content centrify">
                    <ADPIcon icon={record[4] ? record[4] : 'atom'}  className="text-danger"/>
                </div>
                <div className="atomic-cell-content centrify rel-time">
                    { record[5] && record[5].label ?
                        ( typeof record[5].callback === 'function'  ?
                            <span title={record[5].label} onClick={record[5].callback}>{record[5].label}</span>
                            : <span title={record[5].label}>{record[5].label}</span> )
                        :  null
                    }
                </div>
            </td>}
        </tr> )
        : <tr>
            <td style={{ width: '100%' }}>
                <div className="atomic-cell-content centrify">
                    No Access Requests Found !!
                </div>
            </td>
        </tr>
    );
};

const UsersBody = ({ columnsCount, list, widthArray}) => {
    return (
        list.map(( record, index ) => (
            <tr key={index}>
                { columnsCount >= 1 &&
                <td style={{width: `${widthArray[0]}%`}} >
                    <div className="atomic-cell-content centrify">
                        { record[0] ? ( record[0].length <= 50 ?  record[0]
                            : <span title={record[0]}>{record[0].substr( 0, 50 )}...</span> ) :  '' }
                    </div>
                </td>}
                { columnsCount >= 2 &&
                <td style={{width: `${widthArray[1]}%`}}>
                    <div className="atomic-cell-content centrify">
                        {record[1] ? record[1] : ''}
                    </div>
                </td>}
                { columnsCount >= 3 &&  record[2] &&
                <td style={{width: `${widthArray[2]}%`}}>
                    <div className="atomic-cell-content centrify">
                        <ADPIcon icon={record[2].icon ? record[2].icon  : 'atom'}  className={record[2].class ? record[2].class : 'text-primary'}/>
                    </div>
                    <div className="atomic-cell-content centrify">
                        { record[2].label ?
                            ( record[2].label.length <= 15 && typeof record[2].callback === 'function'  ?
                                <span title={record[2].label} onClick={record[2].callback}>{record[2].label}</span>
                                : <span title={record[2].label}>{record[2].label.substr( 0, 12 )}...</span> )
                            :  null
                        }
                    </div>
                </td>}
                { columnsCount >= 4 &&  record[3] &&
                <td style={{width: `${widthArray[3]}%`}}>
                    <div className="atomic-cell-content centrify">
                        <ADPIcon icon={record[3].icon ? record[3].icon  : 'atom'}  className={record[3].class ? record[3].class : 'text-primary'}/>
                    </div>
                    <div className="atomic-cell-content centrify">
                        { record[3].label ?
                            ( record[3].label.length <= 15 && typeof record[3].callback === 'function'  ?
                                <span title={record[3].label} onClick={record[3].callback}>{record[3].label}</span>
                                : <span title={record[3].label}>{record[3].label.substr( 0, 12 )}...</span> )
                            :  null
                        }
                    </div>
                </td>}
            </tr> ))
    );
};


